/************************************************************************************
RESET
*************************************************************************************/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section, menu,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ol, ul { list-style: none; }

blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none; }

:focus { outline: 0; }

table { border-collapse: collapse; border-spacing: 0; }

article, aside, figure, footer, header, hgroup, nav, section, img { display: block; }

input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }

label, input[type=button], input[type=submit], button { cursor: pointer; overflow: visible; }

em, i { font-weight: inherit; }

/************************************************************************************
GENERAL
*************************************************************************************/

body {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 16px);
	line-height: 1.75em;
	font-weight: 400;
	color: $blue;
	letter-spacing: 0.01em;
	background: #fff;
	margin: 0;
	padding: 0;
	min-height: 100%;

	&.modal-opened {
		overflow: hidden;
	}
}

/* Box sizing */

*, *:after, *:before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

/* Anchors */

a {
	color: $green;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

/* Heading */

h1, .h1 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 60px);
	line-height: 1.16em;
	font-weight: 600;
	letter-spacing: 0em;
	color: $green;
	@include rem(margin, 0 0 30px 0);

	&.medium {
		@include rem(font-size, 45px);
	}
}

h2, .h2 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 60px);
	line-height: 1.1em;
	font-weight: 600;
	letter-spacing: 0;
	color: $green;
	@include rem(margin, 0 0 10px 0);

	&.big {
		@include rem(font-size, 80px);
		line-height: 1.3em;
	}

	&.medium {
		@include rem(font-size, 38px);
		line-height: 1.3em;
	}
}

h3, .h3 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 28px);
	line-height: 1.3em;
	font-weight: 600;
	color: $green;
	@include rem(margin, 0 0 10px 0);

	&.big {
		@include rem(font-size, 32px);
		line-height: 1.3em;
	}

	&.medium {
		@include rem(font-size, 22px);
		line-height: 1.3em;
	}

	&.small {
		@include rem(font-size, 18px);
		line-height: 1.3em;
	}

	&.alt {
		color: $blue;
	}
}

h4, .h4 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 22px);
	line-height: 1.3em;
	font-weight: 400;
	color: $green;
	@include rem(margin, 0 0 10px 0);
}

h5, .h5 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 400;
	color: $green;
	@include rem(margin, 0 0 10px 0);
}

h6, .h6 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 400;
	color: $green;
	@include rem(margin, 0 0 10px 0);
}

.suphead {
	@include rem(font-size, 32px);
	line-height: 1.3em;
	color: $green;
	font-weight: 400;
	text-transform: uppercase;
	//letter-spacing: 0.05em;
}

/* Paragraphs */

p {
	@include rem(margin, 0 0 20px 0);
}

address, cite {
	font-style: normal;
}

/* Lists */

ul {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;

	li {
		@include rem(padding, 0 0 0 20px);
		@include rem(margin, 0 0 5px 0);
	}

	&.unstyled {
		li {
			padding: 0;
			background: none;
		}
	}
}

ol {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;
	counter-reset: counter;
	margin-left: 0;

	li {
		@include rem(margin, 0 0 10px 0);
		@include rem(padding, 0 0 0 30px);
		counter-increment: counter;
		position: relative;

		&:before {
			content: counter(counter) ".";
			position: absolute;
			left: 0;
			top: 0;
			text-align: center;
		}
	}
}

dl {
	@include rem(margin, 0 0 20px 0);
}

dt {
	font-weight: bold;
}

/* Various */

sup, sub {
	font-size: 60%;
	line-height: 0;
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
	position: relative;
}

sup {
	top: -0.75em;
}

sub {
	bottom: -0.25em;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;

	&.divider {
		border-top: 1px solid $blue;
		@include rem(margin, 50px 0);
	}
}

img {
	max-width: 100%;
	height: auto;
}

.bold, b, strong {
	font-weight: 600;
}

.italic, i, em {
	font-style: italic;
}

.nowrap, .nobr {
	white-space: nowrap !important;
}

/************************************************************************************
CONTENT
*************************************************************************************/

.content {
	padding: 0;
	overflow: hidden;
}

/* Block */

.block {
	@include rem(padding, 150px 0);
	position: relative;

	&.narrow {
		@include rem(padding, 100px 0);
	}

	&.narrow-alpha {
		@include rem(padding-top, 100px);
	}

	&.narrow-omega {
		@include rem(padding-bottom, 100px);
	}

	&.alt {
		background: #E8E8E8;
	}

	&.alt-2 {
		background: $blue;
	}

	&.alt-3 {
		background: $green;
	}

	&.alpha {
		padding-top: 0 !important;
	}

	&.omega {
		padding-bottom: 0 !important;
	}

	&.block-oh {
		overflow: hidden;
	}
}

.text-narrow {
	margin: 0 auto;
	max-width: 900px;
}

.anchor {
	position: absolute;
	left: 0;
	@include rem(top, -70px);
}

.spacer {
	@include rem(height, 80px);

	&.huge {
		@include rem(height, 160px);
	}

	&.big {
		@include rem(height, 100px);
	}

	&.medium {
		@include rem(height, 60px);
	}

	&.small {
		@include rem(height, 40px);
	}
}

/* Various */

.section-head {
	@include rem(margin-bottom, 40px);
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	h1, h2, h3 {
		margin-bottom: 0;
	}

	.symbol {
		display: flex;
		justify-content: center;

		img {
			@include rem(margin-bottom, 20px);
		}
	}
}

.section-head-l {
	@include rem(margin-bottom, 40px);

	h1, h2, h3 {
		margin-bottom: 0;
	}
}

.intro {
	max-width: 500px;
	@include rem(margin, 0 auto 40px auto);
	text-align: center;

	&.wide {
		@include rem(max-width, 750px);
	}

	&.widest {
		@include rem(max-width, 1200px);
	}
}

.invert {
	color: #fff;

	h2, h3 {
		color: #fff;
	}

	.suphead {
		color: #fff;
	}
}

.invert-2 {
	color: $green;

	h2, h3 {
		color: $green;
	}

	.suphead {
		color: $green;
	}
}

.invert-3 {
	color: $blue;

	h2, h3 {
		color: $blue;
	}

	.suphead {
		color: $blue;
	}
}

.symbol-center {
	display: block;
	@include rem(margin, 0 auto 30px auto);
	@include rem(max-width, 75px);
}

/* Pager */

.pagebar {
	@include rem(margin, 0 0 40px 0);
	text-align: center;
	position: relative;
	@include rem(padding, 0 40px);

	a,
	span.current {
		@include rem(font-size, 16px);
		font-weight: 600;
		padding: 0;
		margin: 2px;
		@include rem(width, 40px);
		@include rem(height, 40px);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border: none;
		color: #fff;
		background: $blue;
		border-radius: 50%;
		text-decoration: none;
		text-align: center;
		@include transition(all, .3);
	}

	.first {
		width: auto;
		padding: 0;
		margin: 0;
		//position: absolute;
		left: 0;
		top: 40px;
		border: none;
		text-decoration: underline;
		display: none !important;

		&:hover {
			text-decoration: none;
			background: none;
			color: #00b4e7;
			border: none;
		}
	}

	.last {
		width: auto;
		padding: 0;
		margin: 0;
		//position: absolute;
		right: 0;
		top: 40px;
		border: none;
		text-decoration: underline;
		display: none !important;

		&:hover {
			text-decoration: none;
			background: none;
			color: #00b4e7;
			border: none;
		}
	}

	span.current {
		color: #fff;
		background: $green;
		border: none;
	}

	.previouspostslink {
		position: absolute;
		left: 0;
		top: 2px;
		margin: 0;
	}

	.nextpostslink {
		position: absolute;
		right: 0;
		top: 2px;
		margin: 0;
	}

	.previouspostslink:hover,
	.nextpostslink:hover,
	a:hover {
		color: #fff;
		background: $green;
		border: none;
	}

	.extend {
		display: none;
	}
}

/* Gmap */

.gmap-wrap {
	position: relative;

	.gmap {
		height: 600px;
		background: #e3e3e3;
	}

	img {
		max-width: inherit !important;
	}
}

/*================================= Various Content =================================*/

.last {
	margin-bottom: 0 !important;
}

/* Buttons */

.btn {
	@include rem(font-size, 14px);
	font-family: Montserrat,sans-serif;
	line-height: 1.3em;
	font-weight: 600;
	letter-spacing: 0.05em;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: $green;
	color: #fff;
	@include rem(padding, 0 25px);
	@include rem(height, 63px);
	text-decoration: none;
	position: relative;
	text-transform: uppercase;
	border: 2px solid $green;
	@include transition(all, .5);

	&:hover {
		background-color: $blue;
		border: 2px solid $blue;
	}

	&.big {
		@include rem(font-size, 18px);
		text-transform: uppercase;
		@include rem(height, 70px);
		@include rem(padding, 0 60px);
	}

	&.alt {
		background: $blue;
		border-color: $blue;

		&:hover {
			background: $green;
			border-color: $green;
		}
	}

	&.alt-2 {
		background: $green;
		border-color: $green;

		&:hover {
			background: $blue;
			border-color: $blue;
		}
	}

	&.alt-3 {
		background: $green;
		border-color: $green;

		&:hover {
			background: #fff;
			border-color: #fff;
			color: $green;
		}
	}
}

.btn-flip {
	font-size: 0;
	font-family: Montserrat,sans-serif;
	line-height: 1.3em;
	font-weight: 600;
	letter-spacing: 0.05em;
	opacity: 1;
	outline: 0;
	color: #fff;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	@include rem(height, 63px);
	position: relative;
	text-align: center;
	letter-spacing: 1px;
	text-decoration: none;
	text-indent: -80000px;
	text-transform: uppercase;

	&:hover {
		&:after {
			opacity: 1;
			transform: translateY(0) rotateX(0);
		}

		&:before {
			opacity: 0;
			transform: translateY(50%) rotateX(90deg);
		}
	}

	&:after {
		@include rem(font-size, 14px);
		line-height: 1.3em;
		font-weight: 600;
		left: 0;
		top: 0;
		opacity: 0;
		width: 100%;
		color: #fff;
		display: inline-flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		transition: 0.5s;
		@include rem(height, 63px);
		@include rem(padding, 0 40px);
		position: absolute;
		background: $green;
		text-indent: 0px;
		content: attr(data-back);
		transform: translateY(-50%) rotateX(90deg);
	}

	&:before {
		@include rem(font-size, 14px);
		line-height: 1.3em;
		font-weight: 600;
		left: 0;
		top: 0;
		opacity: 1;
		color: #fff;
		display: inline-flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		@include rem(padding, 0 40px);
		@include rem(height, 63px);
		transition: 0.5s;
		position: relative;
		background: $blue;
		text-indent: 0px;
		content: attr(data-front);
		transform: translateY(0) rotateX(0);
	}

	&.big {
		font-size: 0;
		text-transform: uppercase;
		@include rem(height, 70px);
		padding: 0;

		&:after {
			@include rem(font-size, 18px);
			@include rem(padding, 0 45px);
			@include rem(height, 70px);
		}

		&:before {
			@include rem(font-size, 18px);
			@include rem(padding, 0 45px);
			@include rem(height, 70px);
		}
	}

	&.alt {
		&:after {
			background: $blue;
		}

		&:before {
			background: $green;
		}
	}

	&.alt-2 {
		&:after {
			background: $blue;
		}

		&:before {
			background: #fff;
			color: $green;
		}
	}

	&.alt-3 {
		&:after {
			background: #fff;
			color: $green;
		}

		&:before {
			background: $green;
			color: #fff;
		}
	}
}

/* SVG icons */

.icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	position: relative;
	fill: currentColor;
}

/* Cookie Notice */

.cookie-notice {
	font-size: 14px;
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
	background: rgba(0,0,0,0.90);
	color: #CCC;
	text-align: center;
}

.cookie-button {
	display: inline-block;
	padding: 2px 10px;
	margin-left: 25px;
	background: $green;
	color: #fff;
	text-align: center;

	&:hover {
		background: #90ca16;
		color: #fff;
	}
}

.cookie-test {
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 150px;
	padding: 25px 15px;
	background: rgba(50,50,50,0.95);
	color: #CCC;
	text-align: center
}

/* FY Apple! */

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.btn {
	-webkit-appearance: none;
}

/************************************************************************************
ENTRY
*************************************************************************************/

.entry {
	@include rem(margin, 0 0 40px 0);

	h2, h3 {
		@include rem(margin-top, 30px);

		&:first-child {
			margin-top: 0;
		}
	}

	ul {
		li {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				@include rem(top, 12px);
				width: 4px;
				height: 4px;
				display: block;
				background: $blue;
			}
		}

		&.unstyled {
			li {
				&:before {
					content: none;
				}
			}
		}

		&.time-list {
			li {
				padding-left: 0;

				&:before {
					content: none;
				}
			}
		}
	}
}

/************************************************************************************
TABLES
*************************************************************************************/

.entry {
	table {
		width: 100%;
		@include rem(margin, 0 0 30px 0);

		th {
			font-weight: 700;
			border-bottom: 2px solid #59B0A3;
			@include rem(padding, 15px 20px);
			color: #000;
			text-align: left;
			text-transform: uppercase;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		td {
			border-bottom: 1px solid #09304C;
			@include rem(padding, 15px 20px);

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}
}

/************************************************************************************
FORMS
*************************************************************************************/

form.std {
	label {
		@include rem(font-size, 16px);
		line-height: 1.3em;
		color: $green;
		@include rem(margin, 0 0 10px 0);
		display: block;
	}

	input.text {
		font-size: 14px;
		font-weight: 500;
		font-family: Montserrat,sans-serif;
		line-height: 1.3em;
		color: $green;
		border: 1px solid $green;
		@include rem(padding, 0 25px);
		@include rem(height, 67px);
		width: 100%;

		&.error {
			background: #fcf4f4;
			border-color: #be1e19;
		}

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}

		&::placeholder {
			color: $green;
		}

		&[readonly] {
			background: #f0f0f0;
			color: #72818c;
		}
	}

	textarea {
		font-size: 14px;
		//@include rem(font-size, 14px);
		font-weight: 500;
		font-family: Montserrat,sans-serif;
		line-height: 1.3em;
		color: $green;
		border: 1px solid $green;
		@include rem(padding, 15px 25px);
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
		max-height: 300px;
		display: block;

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}

		&::placeholder {
			color: $green;
		}
	}

	select {
		font-size: 14px;
		font-weight: 500;
		font-family: Montserrat,sans-serif;
		line-height: 1.3em;
		color: $green;
		border: 1px solid $green;
		@include rem(padding, 0 25px);
		@include rem(height, 67px);
		width: 100%;
		cursor: pointer;
		background-image: url('../img/select.svg');
		background-position: right center;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	option {
		@include rem(font-size, 17px);
		line-height: 1.3em;
		@include rem(padding, 2px 10px);
	}

	input.text[required="required"] {
		//background-image: url('../img/req.png');
		//background-position: right center;
		//background-repeat: no-repeat;
	}

	.cell {
		@include rem(margin-bottom, 20px);
		position: relative;
		text-align: left;
	}

	.toggle {
		position: relative;
		@include rem(padding, 0 0 0 25px);

		label {
			display: inline-block;
			vertical-align: top;
			margin: 0;
		}

		input {
			margin: 0;
			position: absolute;
			left: 0;
			@include rem(top, 2px);
		}
	}

	.radio, .checker {
		position: absolute;
		left: 0;
		top: 0;
	}

	.checker {
    position: absolute;
    left: 0;
    top: 0;
    background: url('../img/checker.svg') 0 0 no-repeat;
    background-color: #fff;
    @include rem(background-position, 0 -23px);
    @include rem(background-size, 23px);
    @include transition(all, .5);
    border: 2px solid #fff;
    @include rem(width, 26px);
    @include rem(height, 26px);
    @include rem(margin, 0);
    border-radius: 50%;
    cursor: pointer;

    input {
    	opacity: 0;
    	position: absolute;
    	left: 0;
    	top: 0;
    	width: 100%;
    	height: 100%;
    	margin: 0;
    	cursor: pointer;
    }

    &.active {
      @include rem(background-position, 0 0);
      //background-color: transparent;
    }

    &.has-error {
      border-color: #be1e19;
    }
  }

	.combobox-container {
		position: relative;
		text-decoration: none;

		input {
			font-size: 14px;
			font-weight: 500;
			font-family: Montserrat,sans-serif;
			line-height: 1.3em;
			color: $green;
			border: 1px solid $green;
			@include rem(padding, 0 25px);
			@include rem(height, 67px);
			width: 100%;
		}

		.typeahead-long {
			width: 300px;
			border: 1px solid $green;
			border-top: none;
			position: absolute;
			z-index: 20;
			background: #fff;
		}

		.dropdown-toggle {
			width: 55px;
			height: 100%;
			border: 1px solid $green;
			background-color: #fafafa;
			background-image: url('../img/select.svg');
			background-position: right center;
			background-repeat: no-repeat;
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			cursor: pointer;
		}

		li {
			padding-top: 4px;
			padding-bottom: 4px;

			&:hover {
				background-color: #f1f1f1;
			}
		}

		a {
			text-decoration: none;
		}
	}

	.cell-jednotlivec {
		display: none;
	}

	.cell-instituce {
		display: none;
	}

	.cell-both {
		display: none;
	}
}

form.form-nl {
	max-width: 380px;
	position: relative;
	z-index: 30;

	.cell {
		display: flex;
	}

	input.text {
		@include rem(height, 70px);
		background: none;
		border: 1px solid $green;
		color: #fff;

		&::placeholder {
			color: rgba(255,255,255,.7);
		}
	}

	.btn {
		@include rem(flex, 0 0 70px);
		@include rem(height, 70px);
		@include rem(width, 70px);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background: $green url('../img/btn-arr.svg') 0 0 no-repeat;
		@include rem(background-size, 70px 140px);
		text-indent: -800000px;
		border: none;

		&:hover {
			background-color: #fff;
			border-color: #fff;
			color: $green;
			@include rem(background-position, 0 -70px);
		}
	}

	.note {
		@include rem(font-size, 14px);
	}

	&.centered {
		margin-left: auto;
		margin-right: auto;
	}

	.wpcf7-spinner {
		position: relative;
		@include rem(top, 15px);
	}
}

form.form-contact {
	margin: 0 auto;
	//max-width: 740px;
	max-width: 965px;

	&.narrow {
		max-width: 740px;
	}

	textarea {
		@include rem(height, 240px);
	}

	.cell-investice {
		display: none;
	}

	.btn-set {
		text-align: right;
		@include rem(padding-top, 35px);

		&.center {
			text-align: center;
		}
	}

	.btn, .btn-flip {
		@include rem(padding, 0 45px);

		.icon-arr-r {
			@include rem(margin-left, 20px);
		}
	}

	.row-phone {
		flex-wrap: nowrap;

		& > * {
			padding: 0 15px;

			&.col-one {
				flex: 0 0 120px;
				max-width: 120px;
				margin-right: -1px;
				padding-right: 0;

				select {
					text-overflow: ellipsis;
					padding-left: 15px;
					padding-right: 35px;
				}
			}

			&.col-two {
				padding-left: 0;
				flex-grow: 1;
			}
		}
	}

	&.center {
		.btn-set {
			text-align: center;
		}
	}

	&.left {
		.btn-set {
			text-align: left;
		}
	}
}

form.form-filter {
	.cell-set {
		justify-content: center;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -8px;
	}

	.cell {
		padding: 0 8px;
	}

	legend {
		@include rem(font-size, 18px);
		text-align: center;
		font-weight: 600;
		@include rem(margin-bottom, 30px);
	}

	label {
		@include rem(font-size, 15px);
		font-weight: 400;
		color: #fff;
	}

	.toggle {
		color: #fff;
		font-weight: 600;
		background: $green;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		@include rem(padding, 10px 15px 10px 40px);
		border-radius: 50px;
	}

	.checker {
		@include rem(left, 8px);
		@include rem(top, 7px);
	}
}

form.form-narrow {
	max-width: 750px;
	margin-left: auto;
	margin-right: auto;

	.btn-set {
		display: flex;
		justify-content: center;
	}
}

form.form-exit {
	.btn-set {
		text-align: center;
	}
}

#form-ajax-content {
	position: relative;

	&.loading:after {
		content: '';
		position: absolute;
		z-index: 90;
		left: 50%;
		top: 50%;
		width: 70px;
		height: 70px;
		background: url('../img/spinner.svg') center center no-repeat;
		background-size: cover !important;
		@include translate(-50%,-50%);
	}

	& > .inner {
		opacity: 1;
		@include transition(all,.3);
	}

	&.loading > .inner {
		opacity: 0.4;
	}
}

.custom-file-input {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include rem(height, 67px);
  cursor: pointer;
  border-radius: 4px;

  input[type="file"] {
	  width: 100%;
	  height: 100%;
	  opacity: 0;
	  position: absolute;
	  top: 0;
	  left: 0;
	  z-index: 999;
	  cursor: pointer;
	}

	.wpcf7-form-control-wrap {
		position: relative;
		@include rem(height, 67px);
		width: 100%;
		display: block;
	}

	.txt {
		font-size: 14px;
		font-weight: 500;
		font-family: Montserrat,sans-serif;
		line-height: 1.3em;
		color: $green;
		border: 1px solid $green;
		@include rem(padding, 0 25px);
		@include rem(height, 67px);
		@include rem(line-height, 67px);
		background: #fff;
		background-repeat: no-repeat;
		@include rem(margin-top, -67px);
		position: relative;

		&:after {
			content: 'Vybrat soubor';
			@include rem(font-size, 14px);
			position: absolute;
			@include rem(height, 52px);
			@include rem(width, 130px);
			@include rem(top, 7px);
			@include rem(right, 7px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: $blue;
			color: #fff;
			text-align: center;
		}
	}
}

/*================================= Contact form 7 =================================*/

/* Mesages & errors */

body {
	.wpcf7 {
		div.wpcf7-response-output {
			@include rem(font-size, 16px);
			line-height: 1.3em;
			padding: 0;
			background: #ebf5bc;
			border: 1px solid #add4b1;
			color: $green;
			@include rem(padding, 15px 20px);
			@include rem(margin, 0 0 30px 0);

			&:empty {
				display: none;
			}

			p {
				margin-bottom: 0;
			}
		}

		form.failed,
		form.invalid,
		form.unaccepted {
			div.wpcf7-response-output {
				border: 1px solid #f1c7d2;
				background: #fae7ea;
				color: #e5547a;
			}
		}
	}

	.wpcf7-spinner {
 		background-color: transparent;
		background-image: url('../img/spinner.svg');
		background-size: cover !important;
		width: 40px;
		height: 40px;
		flex: 0 0 40px;
		margin: 0 -40px 0 0;

		&:before {
			content: none;
		}
	}

	span.wpcf7-not-valid-tip {
		font-size: 13px;
		line-height: 1.3em;
		padding-top: 5px;
	}

	span.wpcf7-list-item {
		margin: 0;
	}

	.wpcf7-form-control-wrap {
		position: static;
		width: 100%;
	}

	.response {
		.success {
			@include rem(font-size, 16px);
			line-height: 1.3em;
			padding: 0;
			background: #ebf5bc;
			border: 1px solid #add4b1;
			color: $green;
			@include rem(padding, 15px 20px);
			@include rem(margin, 0 0 30px 0);
		}

		.fail {
			@include rem(font-size, 16px);
			line-height: 1.3em;
			padding: 0;
			@include rem(padding, 15px 20px);
			@include rem(margin, 0 0 30px 0);
			border: 1px solid #f1c7d2;
			background: #fae7ea;
			color: #e5547a;
		}
	}
}

/************************************************************************************
TABS
*************************************************************************************/

.tabs {
	position: relative;

	.tab-nav {
		ul {
			@include rem(margin, 0 -25px);
			padding: 0;
			display: flex;
		}

		li {
			list-style-type: none;
			@include rem(padding, 0 25px);
			background: none;
			margin: 0;

			a {
				@include rem(font-size, 19px);
				text-transform: uppercase;
				font-weight: 600;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				outline: 0;
				@include rem(padding, 0 35px);
				@include rem(height, 76px);
				background: transparent;
				border-bottom: 2px solid $blue;
				color: $blue;
				text-align: center;

				&:hover {
					background: #fff;
					color: $blue;
				}

				&.selected {
					background: $blue;
					color: #fff;
				}
			}
		}
	}

	.tab-content {
		.tab {
			width: 100%;
			@include rem(padding, 90px 0 0 0);
			display: none;

			&.selected {
				position: static !important;
				display: block;
			}
		}
	}

	&.tabs-expansion {
		.tab-nav {
			@include rem(margin-bottom, 60px);

			& > ul {
				& > li {
					flex: 1 1 0px;

					& > a {
						width: 100%;
						@include rem(font-size, 18px);
						line-height: 1.3em;
						padding: 0 10px;
						@include rem(height, 65px);
						color: #fff;
						border-bottom: 2px solid rgba(255,255,255,.22);
						position: relative;
						@include transition(all, .3);
						//border-top: 4px solid transparent;

						&:after {
							content: '';
							position: absolute;
							left: 0;
							bottom: -2px;
							width: 0;
							height: 2px;
							background: #fff;
							@include transition(all, .3);
						}

						&:hover {
							background: none;
							padding-bottom: 15px;

							&:after {
								width: 100%;
							}
						}

						&.selected {
							background: none;
							border-bottom: 2px solid #fff;

							& + ul {
								display: block;
							}
						}
					}

					ul {
						display: none;
						margin: 0;
						@include rem(padding-top, 20px);

						li {
							@include rem(padding-left, 20px);
							@include rem(margin-bottom, 10px);
							position: relative;

							&:before {
								content: '';
								position: absolute;
								left: 0;
								@include rem(top, 12px);
								width: 5px;
								height: 2px;
								background: #fff;
							}
						}
					}
				}
			}
		}

		.tab-content {
			.tab {
				@include rem(padding-top, 50px);

				&.selected {
					.map-marker:nth-child(1) {
						animation: showMapMarker 200ms ease-in-out forwards;
					}

					.map-marker:nth-child(2) {
						animation: showMapMarker 400ms ease-in-out forwards;
					}

					.map-marker:nth-child(3) {
						animation: showMapMarker 600ms ease-in-out forwards;
					}

					.map-marker:nth-child(4) {
						animation: showMapMarker 800ms ease-in-out forwards;
					}

					.map-marker:nth-child(5) {
						animation: showMapMarker 1000ms ease-in-out forwards;
					}

					.map-marker:nth-child(6) {
						animation: showMapMarker 1200ms ease-in-out forwards;
					}
				}
			}
		}
	}

	&.tabs-why {
		.tab-nav {
			ul {
				li {
					flex: 1 1 0px;

					& > a {
						width: 100%;
						@include rem(font-size, 18px);
						padding: 0 10px;
						@include rem(height, 65px);
						color: $blue;
						border-bottom: 2px solid $blue;
						border-top: 4px solid transparent;
						@include transition(all, .3);

						&:hover {
							background: none;
							color: $green;
						}

						&.selected {
							background: none;
							color: $green;
							border-top: 4px solid $green;
						}
					}
				}
			}
		}

		.tab-content {
			.tab {
				@include rem(padding-top, 50px);
			}
		}
	}
}

.tabs-links {
	@include rem(margin, 50px 0 50px 0);

	.tab-nav {
		ul {
			@include rem(margin, 0 -25px);
			padding: 0;
			display: flex;
		}

		li {
			list-style-type: none;
			@include rem(padding, 0 25px);
			background: none;
			margin: 0;
			flex: 1 1 0px;

			a {
				@include rem(font-size, 18px);
				text-transform: uppercase;
				font-weight: 600;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				outline: 0;
				background: transparent;
				text-align: center;
				width: 100%;
				padding: 0 10px;
				@include rem(height, 85px);
				color: rgba(255,255,255,.5);
				border-bottom: 2px solid rgba(255,255,255,.22);
				@include transition(all, .3);

				&:hover {
					color: #fff;
					background: none;
				}

				&.selected {
					background: none;
					color: #fff;
					border-bottom: 4px solid $green;
				}
			}
		}
	}

	&.aside {
		margin: 0;

		.tab-nav {
			ul {
				flex-wrap: wrap;

				li {
					flex: 0 0 100%;

					a {
						line-height: 1.3em;
						color: #09304C;
						border-bottom: 2px solid rgba(9,48,76,.2);
						@include rem(height, 70px);
						text-align: left;
						justify-content: flex-start;

						&.selected {
							color: $green;
							border-color: $blue;
						}

						&:hover {
							color: $green;
						}
					}

					&:last-child {
						a {
							border: none;
						}
					}
				}
			}
		}
	}
}

/************************************************************************************
SIMPLE LIGHTBOX
*************************************************************************************/

html.slbActive {
	overflow: hidden;
}

.slbArrow {
	font-size: 15px;

	&.prev {
		background: #fff;
		width: 40px;
		height: 40px;
		margin-top: -20px;
		opacity: 1;
		@include transition(all, .5);

		&:before {
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(135deg);
			width: 15px;
			height: 15px;
			margin-top: -7.5px;
			margin-left: -6px;
			@include transition(all, .5);
		}

		&:hover {
			opacity: 1;
			background: #000;

			&:before {
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}
	}

	&.next {
		background: #fff;
		width: 40px;
		height: 40px;
		margin-top: -20px;
		opacity: 1;
		@include transition(all, .5);

		&:before {
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(-45deg);
			width: 15px;
			height: 15px;
			margin-top: -7.5px;
			@include transition(all, .5);
		}

		&:hover {
			opacity: 1;
			background: #000;

			&:before {
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}
	}
}

.slbCloseBtn {
	font-size: 30px;
	line-height: 40px;
	font-weight: 400;
	font-family: peachy-keen-jf,sans-serif;
	color: #fff;
	width: 40px;
	height: 40px;
	background: #fff;
	color: #000;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	right: 0;
	top: 0;
	position: fixed;
	font-size: 0;
	@include transition(all, .5);

	&:after {
		content: '';
		width: 24px;
		height: 2px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -12px;
		margin-top: -1px;
		display: block;
		background: #000;
		@include rotate(45deg);
		@include transition(all, .5);
	}

	&:before {
		content: '';
		width: 2px;
		height: 24px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -12px;
		margin-left: -1px;
		display: block;
		background: #000;
		@include rotate(45deg);
		@include transition(all, .5);
	}

	&:hover {
		opacity: 1;
		background: #000;
		color: #fff;

		&:after {
			background: #fff;
			@include rotate(-45deg);
		}

		&:before {
			background: #fff;
			@include rotate(-45deg);
		}
	}
}

.slbImage {
	@include rem(padding, 15px 0);
}

.slbImageWrap {
	&:after {
		content: none;
	}
}

.slbCaption {
	@include rem(font-size, 16px);
	position: static;
	overflow: visible;
	white-space: normal;
	padding-top: 0;
}

/************************************************************************************
MODAL
*************************************************************************************/

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  background: rgba(0,0,0,.5);
  overflow: auto;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }

  .modal-content {
  	@include rem(padding, 50px);

  	&.alt {
  		background: #f9fbfc;
  	}
  }

  .modal-box {
	  @include rem(font-size, 18px);
	  @include rem(max-width, 400px);
	  background-color: #fff;
	  line-height: 1.5em;
	  margin: auto;
	  position: relative;

	  .modal-close {
	    position: absolute;
	    @include rem(right, -15px);
	    @include rem(top, -15px);
	    text-decoration: none;
	    @include rem(width, 30px);
	    @include rem(height, 30px);
	    display: flex;
			border-radius: 50%;
	    justify-content: center;
	    align-items: center;
	    background: $green;
	    color: #fff;
			@include transition(all, .3);
			@include rotate(0deg);

			.icon {
				@include rem(width, 16px);
	    	@include rem(height, 16px);
			}

			&:hover {
				background: $blue;
				@include rotate(180deg);
			}

	    /*&:after {
	    	content: '×';
	    	@include rem(font-size, 30px);
	    	position: absolute;
	    	left: 0;
	    	top: 0;
	    	width: 100%;
	    	height: 100%;
	    	display: flex;
	    	justify-content: center;
	    	align-items: center;
	    	color: #fff;
	    }

	    .icon {
	    	@include rem(width, 18px);
	    	@include rem(height, 18px);
	    	transform: rotate(0deg);
	    	@include transition(all, .3);
	    }

	    &:hover {
	    	background: #fff;

	    	&:after {
	    		color: #000;
	    	}

	    	.icon {
	    		transform: rotate(90deg);
	    	}
	    }*/
	  }

	  &.modal-order {
			width: 100%;
			@include rem(max-width, 580px);
			background: #e5e5e5;
		}

	  &.modal-map-detail {
			width: 100%;
			@include rem(max-width, 350px);
			background: #fff;

			.modal-content {
				@include rem(padding, 30px);
			}

			.address {
				@include rem(margin-bottom, 20px);

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

	  &.modal-exit {
			width: 100%;
			@include rem(max-width, 800px);
			background: #fff;
		}
	}

	.modal-box-wrap {
	  width: 100%;
	  //max-width: 500px;
	  @include rem(padding, 50px 15px 15px);
	  margin: auto;
	  overflow: hidden;
	  position: relative;
	  animation-name: animateTop;
	  animation-duration: .4s;
	}
}

@keyframes animateTop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/************************************************************************************
GLIDE
*************************************************************************************/

.glide__slides {
	margin: 0;
}

.glide__slide {
	padding: 0 !important;
	user-select: auto;

	&:before {
		content: none !important;
	}
}

.glide-nav {
	@include rem(margin-top, 40px);
	position: relative;

	.glide__arrow--left {
		position: absolute;
		left: 0;
		top: 0;
		width: 43px;
		height: 37px;
		cursor: pointer;
	}

	.glide__arrow--right {
		position: absolute;
		right: 0;
		top: 0;
		width: 43px;
		height: 37px;
		cursor: pointer;
	}
}

.glide__bullets {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;

	.glide__bullet {
		background: #E8E8E8;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		padding: 0;
		margin: 0 15px;
		border: none;
		cursor: pointer;
		position: relative;
		@include transition(all, .5);

		&.glide__bullet--active, &:hover {
			background: $blue;
		}
	}
}

/* ROI slider */

.roi-slider {
	max-width: 1020px;
	border-top: 1px solid #E8E8E8;
	border-bottom: 1px solid #E8E8E8;
	@include rem(padding, 50px 0);
	@include rem(margin, 100px 0);

	.panel {
		margin: 0;
	}

	.item-inner {
		display: flex;
	}

	.item {
		.img {
			@include rem(width, 140px);
			@include rem(flex, 0 0 140px);

			.icon {
				@include rem(width, 80px);
				@include rem(height, 80px);
				@include rem(flex, 0 0 80px);
			}
		}

		.desc {
			max-width: 650px;
			@include rem(margin-top, 20px);
			margin-bottom: 0;
		}

		h3 {
			color: $blue;
			text-transform: uppercase;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.glide-nav {
		@include rem(margin-top, 40px);
		@include rem(padding-left, 140px);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		ul {
			margin: 0 -5px;
			justify-content: flex-start;
		}

		.glide__arrow--left {
			position: relative;
			left: 0;
			top: 0;
			z-index: 20;
			@include rem(width, 40px);
			@include rem(height, 40px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			color: $blue;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 25px);
				@include rem(height, 25px);
				@include rem(flex, 0 0 25px);
			}

			&:hover {
				background: $blue;
				color: #fff;
			}
		}

		.glide__arrow--right {
			position: relative;
			right: 0;
			top: 0;
			z-index: 20;
			@include rem(width, 40px);
			@include rem(height, 40px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			color: $blue;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 25px);
				@include rem(height, 25px);
				@include rem(flex, 0 0 25px);
			}

			&:hover {
				background: $blue;
				color: #fff;
			}
		}
	}

	.glide__bullets {
		.glide__bullet {
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				@include rem(width, 36px);
				@include rem(height, 36px);
				border: 1px solid $blue;
				border-radius: 50%;
				@include rem(margin-top, -18px);
				@include rem(margin-left, -18px);
				@include transition(all, .3);
				@include scale(0);
			}

			&.glide__bullet--active {
				background: $blue;

				&:after {

					@include scale(1);
				}
			}

			&:hover {
				background: $blue;
			}
		}
	}

	&.no-img {
		.glide-nav {
			padding-left: 0;
		}
	}

	&.invert {
		border-top: 1px solid rgba(255,255,255,.4);
		border-bottom: 1px solid rgba(255,255,255,.4);

		.item {
			.desc {
				color: $blue;
			}
		}
	}
}

/* Phase slider */

.phase-slider-wrapper {
	position: relative;
	overflow: hidden;
	@include rem(padding-left, 130px);
	@include rem(margin-left, -130px);
}

.phase-slider {
	@include rem(margin-bottom, 100px);
	width: 116%;
	overflow: visible !important;

	.glide__slide {
		margin: 0;
		height: auto;
		display: flex;
	}

	.glide-nav {
		margin: 0;
		padding: 0;
		position: static;

		ul {
			margin: 0 -5px;
			justify-content: flex-start;
		}

		.glide__arrow--left {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 20;
			@include rem(width, 70px);
			@include rem(height, 70px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 30px);
				@include rem(height, 30px);
				@include rem(flex, 0 0 30px);
			}

			&:hover {
				background: $blue;
				color: #fff;
			}
		}

		.glide__arrow--right {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 20;
			@include rem(width, 70px);
			@include rem(height, 70px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 30px);
				@include rem(height, 30px);
				@include rem(flex, 0 0 30px);
			}

			&:hover {
				background: $blue;
				color: #fff;
			}
		}
	}

	.glide__bullets {
		.glide__bullet {
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				@include rem(width, 36px);
				@include rem(height, 36px);
				border: 1px solid $blue;
				border-radius: 50%;
				@include rem(margin-top, -18px);
				@include rem(margin-left, -18px);
				@include transition(all, .3);
				@include scale(0);
			}

			&.glide__bullet--active {
				background: $blue;

				&:after {

					@include scale(1);
				}
			}

			&:hover {
				background: $blue;
			}
		}
	}

	&:after {
		content: '';
		position: absolute;
		z-index: 10px;
		right: 0;
		top: 0;
		width: 280px;
		height: 100%;
		pointer-events: none;
		background: rgb(232,232,232);
		background: -moz-linear-gradient(90deg, rgba(232,232,232,0) 0%, rgba(232,232,232,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba(232,232,232,0) 0%, rgba(232,232,232,1) 100%);
		background: linear-gradient(90deg, rgba(232,232,232,0) 0%, rgba(232,232,232,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e8e8e8",endColorstr="#e8e8e8",GradientType=1);
	}
}

/* Gallery slider */

.gallery-slider-wrapper {
	position: relative;
}

.gallery-slider {
	@include rem(margin-bottom, 70px);

	.glide-nav {
		margin: 0;
		padding: 0;
		height: 0;
		position: static;

		.glide__arrow--left {
			position: absolute;
			left: 0;
			top: 50%;
			z-index: 20;
			@include rem(width, 70px);
			@include rem(height, 70px);
			@include rem(margin-top, -35px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 30px);
				@include rem(height, 30px);
				@include rem(flex, 0 0 30px);
			}

			&:hover {
				background: $blue;
				color: #fff;
			}
		}

		.glide__arrow--right {
			position: absolute;
			right: 0;
			top: 50%;
			z-index: 20;
			@include rem(width, 70px);
			@include rem(height, 70px);
			@include rem(margin-top, -35px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 30px);
				@include rem(height, 30px);
				@include rem(flex, 0 0 30px);
			}

			&:hover {
				background: $blue;
				color: #fff;
			}
		}
	}

	.glide__slide {
		margin: 0;
	}

	.glide__bullets {
		position: relative;
		@include rem(top, 30px);
		margin: 0;
		//@include rem(margin-top, 30px);

		.glide__bullet {
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				@include rem(width, 36px);
				@include rem(height, 36px);
				border: 1px solid $blue;
				border-radius: 50%;
				@include rem(margin-top, -18px);
				@include rem(margin-left, -18px);
				@include transition(all, .3);
				@include scale(0);
			}

			&.glide__bullet--active {
				background: $blue;

				&:after {

					@include scale(1);
				}
			}

			&:hover {
				background: $blue;
			}
		}
	}
}

/* History slider */

.history-slider-wrapper {
	padding: 0 140px;
	position: relative;

	.head {
		@include rem(font-size, 40px);
		font-weight: 600;
		color: $green;
		position: absolute;
		z-index: 20;
		left: 140px;
		@include rem(bottom, -15px);
		background: $blue;
		display: block;
		@include rem(padding, 0 20px);

		.suphead {
			@include rem(font-size, 20px);
			font-weight: 600;
			color: $green;
			position: absolute;
			left: 50%;
			top: -40px;
			cursor: pointer;
			@include translate(-50%,0);
			@include transition(all, .3);

			&:hover {
				color: #fff;
			}
		}

		.subhead {
			@include rem(font-size, 20px);
			font-weight: 600;
			color: $green;
			position: absolute;
			left: 50%;
			bottom: -40px;
			cursor: pointer;
			@include translate(-50%,0);
			@include transition(all, .3);

			&:hover {
				color: #fff;
			}
		}
	}

	.headline {
		position: absolute;
		left: 140px;
		top: 180px;

		h2 {
			color: $green;
		}
	}
}

.history-slider-inner {
	padding-left: 140px;
	position: relative;
}

.history-slider {
	position: relative;

	.panel {
		margin: 0;

		.item {
			@include rem(padding, 0 0 50px 0);
			position: relative;
			@include transition(all, .3);
			padding-top: 10px;
			top: 0;

			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				bottom: 0;
				width: 2px;
				margin-left: -1px;
				height: 0;
				background: $green;
				@include transition(all, .3);
			}

			&:hover {
				color: $green;
				top: -10px;
			}
		}

		&.glide__slide--active {
			color: $green;
			position: relative;

			.item {
				&:after {
					@include rem(height, 50px);
				}

				&:hover {
					top: 0;
				}
			}
		}
	}

	.item {
		text-align: center;
		cursor: pointer;
	}

	.glide-nav {
		@include rem(margin-top, 40px);
		@include rem(padding-left, 140px);

		ul {
			margin: 0 -5px;
			justify-content: flex-start;
		}
	}

	.glide__bullets {
		.glide__bullet {
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				@include rem(width, 36px);
				@include rem(height, 36px);
				border: 1px solid $blue;
				border-radius: 50%;
				@include rem(margin-top, -18px);
				@include rem(margin-left, -18px);
				@include transition(all, .3);
				@include scale(0);
			}

			&.glide__bullet--active {
				background: $blue;

				&:after {

					@include scale(1);
				}
			}

			&:hover {
				background: $blue;
			}
		}
	}

	&:after {
		content: '';
		position: absolute;
		left: -200%;
		bottom: -3px;
		width: 400%;
		height: 3px;
		background: $green;
	}
}

/* Milestones slider */

.milestones-slider-wrapper {
	position: relative;
	padding: 0 140px;
}

.milestones-slider-inner {
	padding-left: 30%;
}

.milestones-slider {
	@include rem(margin-bottom, 100px);
	width: 116%;
	overflow: visible !important;

	.glide__slide {
		margin: 0;
		height: auto;
		display: flex;
	}

	.glide-nav {
		margin: 0;
		padding: 0;
		position: static;

		ul {
			margin: 0 -5px;
			justify-content: flex-start;
		}

		.glide__arrow--left {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 20;
			@include rem(width, 70px);
			@include rem(height, 70px);
			@include translate(0,-50%);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 30px);
				@include rem(height, 30px);
				@include rem(flex, 0 0 30px);
				color: $blue;
			}

			&:hover {
				background: $green;
				color: #fff;
			}
		}

		.glide__arrow--right {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 20;
			@include rem(width, 70px);
			@include rem(height, 70px);
			@include translate(0,-50%);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 30px);
				@include rem(height, 30px);
				@include rem(flex, 0 0 30px);
				color: $blue;
			}

			&:hover {
				background: $green;
				color: #fff;
			}
		}
	}

	&:after {
		content: '';
		position: absolute;
		right: -15px;
		top: 0;
		height: 100%;
		width: 300px;
		background: rgb(9,48,76);
		background: -moz-linear-gradient(90deg, rgba(9,48,76,0) 0%, rgba(9,48,76,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba(9,48,76,0) 0%, rgba(9,48,76,1) 100%);
		background: linear-gradient(90deg, rgba(9,48,76,0) 0%, rgba(9,48,76,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$blue",endColorstr="$blue",GradientType=1);
	}
}

/* Offer slider */

.offer-slider-wrapper {
	position: relative;
	overflow: hidden;

	.offer-slider {
		margin: 0 -23px;
		width: 110%;
		overflow: visible !important;
	}
}

/* Text slider */

.text-slider {
	//border-top: 1px solid #E8E8E8;
	border-bottom: 1px solid #E8E8E8;
	@include rem(padding, 50px 0);

	.panel {
		margin: 0;
	}

	.item-inner {
		display: flex;
		align-items: center;
	}

	.item {
		.desc {
			max-width: 650px;
			@include rem(margin-top, 20px);
			margin-bottom: 0;
		}

		h3 {
			color: $blue;
			text-transform: uppercase;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.glide-nav {
		@include rem(margin-top, 40px);

		ul {
			margin: 0 -5px;
			justify-content: flex-start;
		}
	}

	.glide__bullets {
		.glide__bullet {
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				@include rem(width, 36px);
				@include rem(height, 36px);
				border: 1px solid $blue;
				border-radius: 50%;
				@include rem(margin-top, -18px);
				@include rem(margin-left, -18px);
				@include transition(all, .3);
				@include scale(0);
			}

			&.glide__bullet--active {
				background: $blue;

				&:after {

					@include scale(1);
				}
			}

			&:hover {
				background: $blue;
			}
		}
	}

	&.invert {
		border-top: 1px solid rgba(255,255,255,.4);
		border-bottom: 1px solid rgba(255,255,255,.4);

		.item {
			.desc {
				color: $blue;
			}
		}
	}
}

/* Media slider */

.media-slider-wrapper {
	@include rem(padding, 0 40px);
	position: relative;
}

.media-slider {
	.glide-nav {
		margin: 0;
		padding: 0;
		height: 0;
		position: static;

		.glide__arrow--left {
			position: absolute;
			left: 0;
			top: 50%;
			z-index: 20;
			@include rem(width, 40px);
			@include rem(height, 40px);
			@include rem(margin-top, -20px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 20px);
				@include rem(height, 20px);
				@include rem(flex, 0 0 20px);
			}

			&:hover {
				background: $blue;
				color: #fff;
			}
		}

		.glide__arrow--right {
			position: absolute;
			right: 0;
			top: 50%;
			z-index: 20;
			@include rem(width, 40px);
			@include rem(height, 40px);
			@include rem(margin-top, -20px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			cursor: pointer;
			@include transition(all, .3);

			.icon {
				@include rem(width, 20px);
				@include rem(height, 20px);
				@include rem(flex, 0 0 20px);
			}

			&:hover {
				background: $blue;
				color: #fff;
			}
		}
	}

	.glide__slide {
		margin: 0;
	}

	.glide__bullets {
		position: relative;
		@include rem(top, 30px);
		margin: 0;

		.glide__bullet {
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				@include rem(width, 36px);
				@include rem(height, 36px);
				border: 1px solid $blue;
				border-radius: 50%;
				@include rem(margin-top, -18px);
				@include rem(margin-left, -18px);
				@include transition(all, .3);
				@include scale(0);
			}

			&.glide__bullet--active {
				background: $blue;

				&:after {

					@include scale(1);
				}
			}

			&:hover {
				background: $blue;
			}
		}
	}

	.item {
		a {
			opacity: 0.6;
			@include transition(all, .3);

			&:hover {
				opacity: 1;
			}
		}
	}
}

/************************************************************************************
CLEARFIX
*************************************************************************************/

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}