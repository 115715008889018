/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/*========================= Viewport width 1200px and higher =========================*/

@media screen and (max-width: 1200px) {
  .xl-last {
    margin-bottom: 0 !important;
  }
}

/*========================= Viewport width 2050px and lower =========================*/

@media screen and (max-width: 2050px) {
  h2, .h2 {
    @include rem(font-size, 45px);

    &.big {
      @include rem(font-size, 60px);
      line-height: 1.1em;
    }
  }

  .block {
    padding: 120px 0;
  }

  .teaser-alt {
    h1, .desc {
      @include rem(margin-bottom, 40px);
    }
  }

  .teaser-poster {
    .teaser-text {
      .teaser-content {
        @include rem(padding, 100px 0 80px 0);
      }
    }
  }

  .suphead {
    @include rem(font-size, 25px);
  }

  .act-nav {
    .item {
      .img {
        @include rem(width, 80px);
        @include rem(flex, 0 0 80px);
      }
    }

    .item-inner {
      @include rem(font-size, 17px);
    }
  }

  .phases {
    .item {
      .img {
        @include rem(margin-bottom, 40px);
      }

      h3 {
        @include rem(font-size, 20px);
      }
    }
  }

  .stocks-program {
    .main {
      .text {
        @include rem(max-width, 760px);
        padding-right: 100px;
      }

      .img {
        padding-right: 100px;
      }
    }
  }

  .phase-slider {
    .glide-nav {
      .glide__arrow--right, .glide__arrow--left {
        @include rem(width, 60px);
        @include rem(height, 60px);

        .icon {
          @include rem(width, 25px);
          @include rem(height, 25px);
          @include rem(flex, 0 0 25px);
        }
      }
    }
  }

  .gallery-slider {
    .glide-nav {
      .glide__arrow--right, .glide__arrow--left {
        @include rem(width, 60px);
        @include rem(height, 60px);

        .icon {
          @include rem(width, 25px);
          @include rem(height, 25px);
          @include rem(flex, 0 0 25px);
        }
      }
    }
  }

  .gallery-text {
    max-width: 1545px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .investment {
    .item {
      .desc {
        @include rem(padding-top, 10px);
      }

      .price {
        @include rem(font-size, 20px);
        @include rem(top, -25px);
        @include rem(width, 240px);
        @include rem(height, 50px);
        @include rem(border-radius, 30px);
      }
    }

    .item-inner {
      @include rem(height, 440px);
    }
  }

  .development {
    h2 {
      @include rem(max-width, 800px);
    }

    .desc {
      @include rem(max-width, 800px);
    }

    .additional {
      @include rem(max-width, 800px);
    }
  }

  .milestones-slider-inner {
    padding-left: 20%;
  }

  .history-slider-wrapper {
    .headline {
      display: none;
    }
  }

  .offer {
    .head {
      padding-right: calc((100vw - 1515px)/2);
    }

    .item {
      .text {
        .suphead {
          @include rem(font-size, 16px);
        }

        h3 {
          @include rem(font-size, 22px);
        }
      }

      .icon-circle {
        margin-right: 15px;
      }
    }
  }

  .tabs {
    &.tabs-expansion {
      .tab-nav {
        ul {
          li {
            & > a {
              @include rem(font-size, 16px);
            }
          }
        }
      }
    }
  }

  .posts-big, .posts-recent, .posts-archive {
    @include rem(max-width, 1200px);
  }

  .boxes-img {
    .section-head {
      @include rem(margin-bottom, 80px);

      &.narrow {
        max-width: 850px;
      }
    }

    .item-inner {
      @include rem(padding, 0 30px 30px 30px);
    }

    .item {
      @include rem(padding-top, 100px);

      .img {
        @include rem(margin-top, -100px);
        @include rem(margin-bottom, 40px);
        @include rem(width, 200px);
        @include rem(height, 200px);
      }
    }
  }

  .farm-cats {
    .item {
      @include rem(padding, 0 20px);
		  @include rem(font-size, 22px);
    }
  }

  .schedule {
    @include rem(margin-top, 50px);

    h3, .h3 {
      @include rem(margin-bottom, 40px);
    }
  }

  .modern {
    h2 {
      @include rem(margin-bottom, 35px);
    }

    .desc {
      @include rem(margin-bottom, 50px);
    }
  }

  .numbers {
    .item {
      h2 {
        sup {
          @include rem(font-size, 20px);
				  top: -1rem;
        }
      }

      .desc {
        @include rem(font-size, 18px);
      }
    }
  }

  .bar-nav {
    max-width: 800px;

    .item-inner {
      @include rem(font-size, 18px);
      @include rem(height, 90px);
    }
  }

  .interest-nav {
    .item-inner {
      &.h3 {
        @include rem(font-size, 22px);
      }
    }

    .leaf-4 {
      @include rem(max-width, 280px);
      @include rem(top, -180px);
    }
  }

  .about {
    .about-text {
      .text {
        @include rem(padding-left, 60px);
        @include rem(padding-right, 45px);
      }
    }

    &.alt {
      .about-text {
        .text {
          @include rem(padding-left, 45px);
          @include rem(padding-right, 60px);
        }
      }
    }
  }

  .roi {
    .desc {
      @include rem(margin-bottom, 50px);
    }
  }

  .roi-slider {
    @include rem(margin, 50px 0);
  }
}

/*========================= Viewport width 1605px and lower =========================*/

@media screen and (max-width: 1605px) {
  h1, .h1 {
    @include rem(font-size, 50px);
    line-height: 1.16em;
  }

  h2, .h2 {
    @include rem(font-size, 45px);
  }

  h3, .h3 {
    @include rem(font-size, 24px);

    &.big {
      @include rem(font-size, 26px);
    }
  }

  .suphead {
    @include rem(font-size, 20px);
    margin-bottom: 5px;
  }

  .block {
    @include rem(padding, 110px 0);

    &.narrow {
      @include rem(padding, 80px 0);
    }

    &.narrow-alpha {
      @include rem(padding-top, 80px);
    }

    &.narrow-omega {
      @include rem(padding-bottom, 80px);
    }
  }

  .teaser-alt {
    .numbers {
      .item-list {
        margin: 0 -20px;
      }

      .item {
        padding: 0 20px;
        border: none;
      }
    }
  }

  .posts-overview {
    .main-text {
      width: 100%;
      flex: 0 0 100%;
      @include rem(margin-bottom, 40px);

      .inner {
        width: 100%;
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section-head {
          margin-bottom: 0;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .posts-main {
      margin-left: 0;
    }
  }

  .post-detail {
    max-width: 750px;
  }

  .persons {
    .cite {
      @include rem(font-size, 30px);
    }
  }

  .offer {
    padding-left: 15%;

    .container {
      //max-width: 1240px;
      max-width: 1140px;
    }

    .item-list {
      margin: 0 -15px;
    }

    .item {
      padding: 0 15px;

      .text {
        @include rem(padding, 30px 30px 0 30px);

        .suphead {
          @include rem(font-size, 14px);
          line-height: 1.5em;
        }

        h3 {
          @include rem(font-size, 16px);
        }
      }

      .label {
        @include rem(font-size, 14px);
      }
    }

    .head {
      padding-right: calc((100vw - 1110px)/2);
    }
  }

  .numbers {
    .main-text {
      .bg {
        left: 20%;
      }

      .bg-fish {
        left: -35%;
      }
    }
  }

  .investment-action {
    .section-head {
      @include rem(min-height, 100px);
    }
  }

  .calculator-element {
    .amount {
      @include rem(font-size, 45px);
    }
  }

  #range-calculator {
    @include rem(margin-bottom, 150px);
  }

  .calculator {
    &.double-cols {
      padding: 0;

      #range-calculator {
        @include rem(margin-bottom, 150px);

        .min {
          @include translate(-50%,0);
        }

        .max {
          @include translate(-50%,0);
        }
      }
    }
  }

  .stocks-program {
    .main {
      .img, .text {
        padding-right: 40px;
      }
    }
  }

  .tabs {
    .tab-nav {
      ul {
        @include rem(margin, 0 -15px);
      }

      li {
        @include rem(padding, 0 15px);

        a {
          @include rem(font-size, 15px);
        }
      }
    }
  }

  .ordered-list {
    .item:before {
      @include rem(font-size, 45px);
    }
  }

  .stocks-program {
    .main {
      .text {
        @include rem(max-width, 560px);
        padding-right: 50px;
      }

      .img {
        padding-right: 50px;
      }
    }
  }

  .gallery-text {
    max-width: 1140px;
    @include rem(padding-top, 110px);
    @include rem(padding-bottom, 110px);

    .img {
      .inner {
        padding-right: 40px;
      }
    }

    .text {
      .inner {
        padding-left: 0;
      }
    }
  }

  .tiles-photo {
    .item {
      .text {
        .icon {
          @include rem(width, 60px);
          @include rem(height, 60px);
          @include rem(flex, 0 0 60px);
        }
      }
    }

    .suphead {
      @include rem(font-size, 25px);
    }

    h3 {
      @include rem(font-size, 25px);
    }
  }

  .boxes-img {
    .item-inner {
      @include rem(padding, 0 30px 30px 30px);
    }

    .item {
      @include rem(padding-top, 80px);

      .img {
        @include rem(margin-top, -80px);
        @include rem(margin-bottom, 40px);
        @include rem(width, 160px);
        @include rem(height, 160px);
      }
    }
  }

  .modern {
    .img {
      .frame {
        max-width: 430px;
      }
    }
  }

  .footer {
    .f_1 {
      @include rem(padding-bottom, 30px);
    }

    .foot-item-info {
      .contact-info {
        @include rem(margin-bottom, 50px);
      }
    }

    .foot-newsletter {
      padding-left: 0;
    }

    .foot-links {
      padding-left: 0;
    }
  }

  .lector {
    .img {
      .frame {
        max-width: 180px;
      }
    }
  }
}

/*========================= Viewport width 1299px and lower =========================*/

@media screen and (max-width: 1299px) {
  h2, .h2 {
    @include rem(font-size, 35px);
    line-height: 1.2em;

    &.big {
      @include rem(font-size, 40px);
    }
  }

  .suphead {
    @include rem(font-size, 18px);
  }

  .post-info {
    position: relative;
    max-width: 100%;
    left: 0;
    //display: flex;
    //justify-content: space-between;
    @include rem(padding-bottom, 30px);
    @include rem(margin-bottom, 30px);
    border-bottom: 1px solid #59B0A3;

    .author {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
      display: flex;
      flex: 0 0 250px;
      @include rem(margin-bottom, 20px);
      //align-items: flex-end;

      .img {
        margin: 0;

        .frame {
          width: 50px;
          height: 50px;
          flex: 0 0 50px;
        }
      }

      .text {
        padding-left: 15px;
      }
    }

    .cats {
      display: flex;

      .head {
        @include rem(margin-bottom, 5px);
      }

      ul {
        margin: 0 -5px 5px -5px;
        display: flex;
        flex-wrap: wrap;

        li {
          padding: 0 5px;

          &:before {
            content: none;
          }

          a {

          }
        }
      }
    }
  }
}

/*========================= Viewport width 1199px and lower =========================*/

@media screen and (max-width: 1199px) {
  .logo {
    @include rem(max-width, 160px);
  }

  .header-nav {
    ul {
      margin: 0 -8px;

      li {
        padding: 0 8px;
      }
    }
  }

  .lng-nav {
    margin-left: 15px;
  }

  .header {
    &.alt {
      .nav-btn {
        @include rem(margin-left, 90px);
      }
    }

    .btn-flip {
      &:before {
        //@include rem(padding, 0 25px);
        @include rem(padding, 0 10px);
      }
    }
  }

  .block, .block.narrow {
    @include rem(padding, 80px 0);
  }

  .block.narrow-alpha {
    @include rem(padding-top, 80px);
  }

  .block.narrow-omega {
    @include rem(padding-bottom, 80px);
  }

  .teaser-alt {
    flex-wrap: wrap;

    .teaser-text {
      margin-left: 0;
      padding-top: 0;
      order: 0;
    }

    .teaser-img {
      order: 1;
      padding-left: 0;
      max-width: 930px;
      margin-left: auto;
      margin-right: auto;

      .frame {
        padding-left: 0;
      }
    }

    .btn-set {
      margin-bottom: 0;
    }

    .btn, .btn-flip {
      top: 0;
    }
  }

  .teaser-poster {
    .teaser-text {
      .teaser-content {
        @include rem(padding, 100px 0);
      }
    }
  }

  .offer {
    padding-left: 0;

    .container {
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
    }

    .item {
      @include rem(margin-bottom, 80px);

      .text {
        .suphead {
          @include rem(font-size, 17px);
          line-height: 1.5em;
        }

        h3 {
          @include rem(font-size, 25px);
          text-align: center;
          justify-content: center;
          flex-wrap: wrap;
          height: auto;
          display: block;

          .icon-circle {
            @include rem(margin-bottom, 20px);
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .head {
      padding-right: 0;
    }
  }

  .about {
    .about-text {
      .text {
        padding-left: 50px;
      }
    }
  }

  .persons {
    .cite {
      @include rem(font-size, 25px);
    }

    .name {
      @include rem(font-size, 24px);
      @include rem(margin-bottom, 10px);
    }

    .item-foot {
      @include rem(padding-bottom, 20px);
    }

    .sign {
      @include rem(max-width, 180px);
    }

    .foot {
      display: flex;
    }
  }

  .investment {
    .item {
      //border-top: 2px solid #fff;

      &:nth-child(3) {
        border-left: none;
      }
    }

    .item-inner {
      @include rem(height, 440px);
    }
  }

  .investment-action {
    flex-wrap: wrap;

    .section-head {
      min-height: auto;
      @include rem(margin-bottom, 70px);
    }

    .investment {
      flex: 0 0 100%;
      width: 100%;
      @include rem(margin-bottom, 80px);
    }

    .investment-form {
      flex: 0 0 100%;
      width: 100%;
      @include rem(margin-bottom, 80px);

      .section-head {
        @include rem(margin-bottom, 80px);
      }
    }
  }

  .numbers {
    .main-text {
      .bg {
        display: none;
      }
    }

    .item {
      .desc {
        @include rem(font-size, 18px);
      }
    }
  }

  .roi {
    .desc {
      @include rem(margin-bottom, 40px);
    }
  }

  .sky-img {
    display: none;
  }

  .development {
    max-width: 960px;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .phase-slider-wrapper {
    //padding-left: 0;
    //margin-left: -1px;
  }

  .phases {
    .section-head-l, .foot {
      //max-width: 960px;
      //padding: 0 15px;
      //margin-left: auto;
      //margin-right: auto;
    }
  }

  .amounts {
    .item {
      .label {
        @include rem(font-size, 20px);
      }

      .amount {
        &.small {
          @include rem(font-size, 30px);
        }
      }
    }

    &.alt {
      @include rem(margin-bottom, 60px);
    }
  }

  .stocks-program {
    .main {
      .img {
        padding-right: 0;
      }

      .text {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 960px;
        @include rem(margin, 0 auto);
      }

      .img {
        @include rem(margin, 50px 0);
      }
    }

    .tabs, .foot {
      padding-left: 15px;
      padding-right: 15px;
      max-width: 960px;
      @include rem(margin, 0 auto);
    }
  }

  .gallery-text {
    max-width: 930px;
    @include rem(padding, 80px 0);

    .img {
      @include rem(margin-bottom, 40px);

      .inner {
        padding-right: 0;
      }
    }

    .text {
      h2 {
        @include rem(margin-bottom, 30px);
      }
    }
  }

  .history-slider-wrapper {
    @include rem(padding, 0 70px);

    .head {
      left: 70px;
    }
  }

  .milestones-slider-wrapper {
    @include rem(padding, 0 70px);
  }

  .milestones-slider {
    .glide-nav {
      .glide__arrow--right, .glide__arrow--left {
        @include rem(width, 60px);
        @include rem(height, 60px);

        .icon {
          @include rem(width, 25px);
          @include rem(height, 25px);
          @include rem(flex, 0 0 25px);
        }
      }
    }
  }

  .tabs {
    &.tabs-expansion {
      .tab-nav {
        ul {
          li {
            & > a {
              @include rem(font-size, 14px);
              line-height: 1.3em;
            }
          }
        }
      }
    }

    &.tabs-why {
      .tab-nav {
        ul {
          li {
            & > a {
              @include rem(font-size, 14px);
              line-height: 1.3em;
            }
          }
        }
      }
    }
  }

  .expansion {
    .cite {
      @include rem(font-size, 30px);
    }
  }

  .platforms {
    &.alt {
      flex-wrap: wrap;

      .head {
        max-width: 100%;
        @include rem(margin-bottom, 30px);
      }
    }
  }

  .connection-list {
    @include rem(margin, 50px auto 0 auto);
    @include rem(max-width, 400px);

    .item {
      @include rem(font-size, 25px);
      @include rem(padding-bottom, 40px);
    }
  }

  .interest-nav {
    .item-inner {
      @include rem(padding, 0 20px);

      &.h3 {
        @include rem(font-size, 18px);
      }
    }

    .item {
      &:nth-child(2) {
        .item-inner {
          //border: none;
        }
      }
    }

    .leaf-4 {
      @include rem(max-width, 180px);
      @include rem(top, -100px);
    }
  }

  .contact-form {
    order: 1;
  }

  .contact-box {
    order: 0;
    @include rem(margin-bottom, 60px);
  }

  .numbers {
    .main-text {
      .bg-fish {
        display: none;
      }
    }
  }
}

/*========================= Viewport width 991px and lower =========================*/

@media screen and (max-width: 991px) {
  html {
    font-size: 15px;
  }

  .teaser-hp {
    .teaser-img, .teaser-text {
      min-height: 0;
    }
  }

  .teaser-alt {
    .teaser-img {
      max-width: 720px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .teaser-text {
      @include rem(padding-top, 130px);
    }
  }

  .teaser-alt-2 {
    .teaser-text {
      @include rem(padding-top, 100px);

      .text {
        &.a-md-c {
          text-align: center;
        }
      }
    }
  }

  .teaser-poster {
    .teaser-text {
      .teaser-content {
        @include rem(padding-top, 200px);
      }
    }
  }

  .header {
    &.alt {
      .header-r {
        .btn, .btn-flip {
          display: inline-flex;
        }
      }

      .h_0 {
        background: url('../img/header-line.svg') center bottom no-repeat;
        border-color: transparent;
      }

      .h_1 {
        display: block;
      }
    }

    .h_2 {
      display: none;
    }
  }

  .main-nav {
    display: none;
  }

  .nav-btn {
    display: block;
  }

  .mobile-nav-wrap {
    display: block;
  }

  .header-nav {
    display: none;
  }

  .header-r {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;

    .btn-nav {
      order: 2;
    }

    .btn-flip {
      margin-left: 0;
      z-index: inherit !important;
    }

    .btn-taste {
      margin-left: 10px;
    }
  }

  .about {
    flex-wrap: wrap;

    .about-img {
      flex: 0 0 100%;
      @include rem(margin-bottom, 40px);
      padding-left: 15px;
      padding-right: 15px;

      .frame {
        max-width: 100%;
      }
    }

    .about-text {
      flex: 0 0 100%;
      margin-left: 0;
      padding: 0 15px;

      .text {
        padding: 0;
        max-width: 690px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.alt {
      .about-img {
        order: 0;
      }

      .about-text {
        order: 1;
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
        padding: 0 15px;

        .text {
          padding: 0;
          max-width: 690px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .posts-overview {
    .main-text {
      .btn-flip, .btn {
        display: none;
      }
    }

    .posts-main {
      .item-inner {
        display: flex;
        flex-wrap: wrap;

        .img {
          order: 1;
          flex: 0 0 100%;
        }

        .text {
          order: 0;
          flex: 0 0 100%;
          @include rem(margin-bottom, 40px);
        }
      }
    }

    .posts-list {
      max-width: 100%;
      float: none;

      .foot {
        display: block;
        @include rem(padding-top, 40px);
      }
    }
  }

  .vision {
    .section-head {
      @include rem(margin-bottom, 0);
    }

    .main-text {
      display: flex;
      flex-direction: column;

      .btn, .btn-flip {
        display: none;
      }

      .logo-vision {
        order: 0;
        @include rem(margin-bottom, 20px);
      }

      .section-head {
        order: 1;
        @include rem(margin-bottom, 40px);
        text-align: center;
      }

      .desc {
        order: 2;
        text-align: center;
      }
    }

    .foot {
      display: block;
      @include rem(padding-top, 40px);
    }
  }

  .persons {
    .item {
      .item-foot {
        padding-left: 15px;
        padding-right: 15px;
      }

      .sign {
        @include rem(margin-bottom, 0);
      }

      .job {
        margin-bottom: 0;
      }
    }

    .item-inner {
      pointer-events: none;
    }
  }

  .investment {
    &.alt {
      .item, .item:nth-child(2) {
        border-left: none;
        @include rem(border-top, 7px solid #fff);
      }
    }

    .item {
      border-left: none;
    }
  }

  .numbers {
    .main-text {
      .btn, .btn-flip {
        display: none;
      }
    }

    .foot {
      display: flex;
      @include rem(padding-top, 40px);
    }
  }

  .roi {
    .section-head {
      //text-align: center;
    }

    .img {
      padding-top: 0;

      .frame {
        &.big {
          @include rem(max-width, 450px);
        }
      }
    }

    .foot {
      display: flex;
    }

    .btn-set {
      display: none;
    }
  }

  .roi-slider {
    border-top: none !important;
    border-bottom: none !important;
    padding-top: 0;
    @include rem(margin, 30px 0);

    .item-inner {
      display: block;
    }

    .item {
      text-align: center;

      .img {
        width: 100%;
        display: flex;
        justify-content: center;
        @include rem(margin-bottom, 20px);
        padding-top: 0;
      }
    }

    .glide-nav {
      padding-left: 0;
      display: flex;
      justify-content: center;

      .glide__arrow--left,
      .glide__arrow--right {
        display: none;
      }
    }

    &.invert {
      @include rem(padding-top, 50px);
    }
  }

  .roi-farm {
    .numbers {
      @include rem(margin-top, 50px);

      .item {
        margin-bottom: 0;
      }
    }
  }

  .development {
    max-width: 720px;
  }

  .phase-slider-wrapper {
    padding-left: 0;
    margin-left: -2px;
  }

  .phase-slider {
    @include rem(margin-bottom, 60px);
  }

  .phases {
    padding: 0;

    .section-head-l, .foot {
      max-width: 720px;
      padding: 0 15px;
      margin-left: auto;
      margin-right: auto;
    }

    .section-head-l {
      @include rem(margin-bottom, 30px);
    }
  }

  .calculator {
    overflow: hidden;
  }

  .amounts {
    .item {
      .label {
        @include rem(font-size, 20px);
      }

      .amount {
        @include rem(font-size, 35px);
      }
    }

    &.alt {
      @include rem(margin-bottom, 50px);
    }
  }

  .range-calculator-wrapper {
    @include rem(padding, 0 60px);
  }

  .gallery-text {
    max-width: 690px;
  }

  .history-slider-wrapper {
    .head {
      display: none;
    }
  }

  .history-slider-inner,
  .milestones-slider-inner {
    padding-left: 0;
  }

  .offer {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .tabs {
    &.tabs-expansion {
      .tab-nav {
        position: relative;

        ul {
          flex-wrap: wrap;

          li {
            flex: 0 0 100%;

            & > a {
              @include rem(font-size, 16px);
              line-height: 1.3em;
              text-align: left;
              justify-content: flex-start;
              @include rem(padding, 0 40px);
              display: none;

              &.selected {
                display: inline-flex;
                border-top: none;
              }
            }
          }
        }

        &.active {
          ul {
            li {
              a {
                display: inline-flex;
              }
            }
          }
        }

        &:after {
          content: '';
          position: absolute;
          @include rem(top, 20px);
          @include rem(right, 20px);
          @include rem(width, 16px);
          @include rem(height, 16px);
          @include rotate(-45deg);
          border-left: 2px solid #fff;
          border-bottom: 2px solid #fff;
        }
      }
    }

    &.tabs-why {
      .tab-nav {
        position: relative;

        ul {
          flex-wrap: wrap;

          li {
            flex: 0 0 100%;

            & > a {
              @include rem(font-size, 16px);
              line-height: 1.3em;
              text-align: left;
              justify-content: flex-start;
              @include rem(padding, 0 40px);
              display: none;

              &.selected {
                display: inline-flex;
                border-top: none;
              }
            }
          }
        }

        &.active {
          ul {
            li {
              a {
                display: inline-flex;
              }
            }
          }
        }

        &:after {
          content: '';
          position: absolute;
          @include rem(top, 20px);
          @include rem(right, 20px);
          @include rem(width, 16px);
          @include rem(height, 16px);
          @include rotate(-45deg);
          border-left: 2px solid #09304C;
          border-bottom: 2px solid #09304C;
        }
      }
    }
  }

  .posts {
    .item-inner {
      flex-wrap: wrap;
    }

    .item {
      .img {
        flex: 0 0 100%;
      }

      .text {
        flex: 0 0 100%;
      }
    }
  }

  .platforms {
    &.alt {
      display: block;
      margin: 0 auto;
      max-width: 650px;

      .head {
        text-align: center;
      }

      .item-list {
        justify-content: center;
      }
    }
  }

  .connection-list {
    .item {
      &:after {
        @include rem(left, 40px);
      }

      .img {
        @include rem(flex, 0 0 80px);
      }

      .text {
        @include rem(padding-left, 20px);
      }
    }
  }

  .boxes-img {
    .section-head {
      @include rem(margin-bottom, 40px);
    }
  }

  .farm-cats {
    .item {
      @include rem(font-size, 18px);
    }
  }

  .modern {
    .img {
      order: 0;
      justify-content: center;
      @include rem(margin-bottom, 40px);

      .inner {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .text {
      order: 1;
    }
  }

  .lector {
    display: block;
    @include rem(padding-bottom, 50px);
    @include rem(margin-bottom, 80px);

    .main {
      text-align: center;

      h2 {
        @include rem(margin, 0 auto 30px auto);
      }

      .name {
        @include rem(margin-bottom, 30px);
      }
    }

    .img {
      @include rem(margin-bottom, 30px);
    }
  }

  .tabs-links {
    .tab-nav {
      ul {
        flex-wrap: wrap;
        margin: 0 -10px;
        overflow: hidden;

        li {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
          padding: 0 10px;
          margin-bottom: -2px;
        }
      }
    }
  }

  .faq-detail {
    .box {
      @include rem(margin-bottom, 70px);
    }
  }

  .interest-nav {
    .leaf-4 {
      display: none;
    }
  }

  body.fix {
    .header {
      .logo {
        display: none;
      }

      .h_0 {
        background-image: none;
      }
    }

    &.mobile-nav-opened {
      .header {
        .btn-flip {
          opacity: .3;
          pointer-events: none;
        }
      }
    }
  }
}

/*========================= Viewport width 767px and lower =========================*/

@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }

  .header-r {
    .btn-flip {
      z-index: 130 !important;
    }
  }

  .teaser-alt {
    .teaser-img {
      max-width: 540px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .mobile-nav {
    .inner {
      max-width: 100%;
      float: none;

      &:after {
        left: -15px;
      }
    }
  }

  .persons {
    .item {
      .sign {
        @include rem(margin-bottom, 40px);
      }

      .item-foot {
        @include rem(padding, 0 30px 30px 30px);
      }
    }

    .cite {
      @include rem(font-size, 40px);
    }
  }

  .act-nav {
    .item-inner {
      border-bottom: 3px solid #fff;
    }
  }

  .faq, .faq-list {
    .section-head-l {
      padding-top: 0;

      .btn-flip {
        display: none;
      }
    }

    .item-list {
      border-bottom: 1px solid #09304C;
    }

    .foot {
      display: flex;
      @include rem(padding-top, 40px);
    }
  }

  .calculator {
    .section-head {
      @include rem(margin-bottom, 40px);
    }

    .foot {
      @include rem(padding-top, 40px);
    }
  }

  .gallery-text {
    max-width: 510px;
  }

  .interest-nav {
    .section-head {
      @include rem(margin-bottom, 50px);
    }

    .item-list {
      @include rem(margin, -10px 0);
    }

    .item {
      @include rem(margin, 10px 0);
      text-align: center;
    }

    .item-inner {
      border: none;
      margin: 0;
    }
  }

  .about {
    .about-text {
      .text {
        max-width: 510px;
      }
    }

    &.alt {
      .about-text {
        .text {
          max-width: 510px;
        }
      }
    }
  }

  .tabs {
    &.tabs-why {
      .tab-nav {
        ul {
          flex-wrap: wrap;

          li {
            flex: 0 0 100%;

            & > a {
              @include rem(font-size, 14px);
              line-height: 1.3em;
            }
          }
        }
      }
    }
  }

  .text-slider {
    .item {
      .img {
        @include rem(margin-bottom, 40px);
      }
    }
  }

  .footer {
    .europe {
      position: relative;
      right: auto;
    }

    .foot-item-info {
      .contact-info {
        @include rem(margin-bottom, 30px);
      }
    }
  }

  form.form-nl.centered {
    max-width: 100%;
    @include rem(margin-top, 40px);
  }

  body.fix {
    &.mobile-nav-opened {
      .header {
        .btn-flip {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}

/*========================= Viewport width 575px and lower =========================*/

@media screen and (max-width: 575px) {
  html {
    font-size: 13px;
  }

  h1, .h1 {
    @include rem(font-size, 40px);
    line-height: 1.16em;

    &.medium {
      @include rem(font-size, 35px);
      line-height: 1.16em;
    }
  }

  h2, .h2 {
    @include rem(font-size, 30px);
    line-height: 1.2em;

    &.big {
      @include rem(font-size, 35px);
      line-height: 1.2em;
    }
  }

  .suphead {
    @include rem(font-size, 16px);
  }

  .content {
    .btn, .btn-flip {
      width: 100%;
      max-width: 395px;

      &:before, &:after {
        width: 100%;
        max-width: 395px;
      }
    }
  }

  .nav-btn {
    @include rem(width, 40px);
  }

  .btn-taste {
    display: none !important;
  }

  .mobile-nav {
    .inner {
      //@include rem(padding-left, 30px);
      padding-left: 0;
    }
  }

  .block, .block.narrow {
    @include rem(padding, 40px 0);
  }

  .block.narrow-alpha {
    @include rem(padding-top, 40px);
  }

  .block.narrow-omega {
    @include rem(padding-bottom, 40px);
  }

  .header {
    &.alt {
      .nav-btn {
        @include rem(margin-left, 20px);

        .txt {
          display: none;
        }
      }
    }
  }

  .header-r {
    .btn-flip {
      font-size: 13px;
      text-indent: 0;
      color: #F2A921;
      padding-left: 12px;
      position: relative;

      &:before, &:after {
        content: none;
      }

      &:before {
        content: '';
        width: 5px;
        height: 5px;
        padding: 0;
        @include translate(0,0);
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -2.5px;
        background: #F2A921;
      }
    }
  }

  .logo {
    max-width: 120px;
  }

  .post-detail {
    h2 {
      @include rem(font-size, 30px);
    }

    h3 {
      @include rem(font-size, 25px);
    }
  }

  .teaser-hp {
    .teaser-img {
      height: auto;
    }

    .teaser-content {
      padding: 150px 0;
    }

    .desc {
      display: none;
    }
  }

  .teaser-alt {
    h1 {
      @include rem(margin-bottom, 20px);
    }
  }

  .offer {
    @include rem(margin-top, -130px);

    .head {
      .link-play {
        display: none;
      }
    }
  }

  .vision {
    .section-head {
      @include rem(margin-bottom, 20px);
    }

    .item {
      @include rem(font-size, 20px);

      &:before {
        margin-left: -15px;
        width: 35%;
      }
    }

    .foot {
      .btn, .btn-flip {
        width: 100%;
        max-width: 395px;

        &:before, &:after {
          width: 100%;
          max-width: 395px;
        }
      }
    }

    .logo-vision {
      display: none;
    }
  }

  .about {
    .btn-set {
      flex-wrap: wrap;

      .yt-link {
        order: 0;
        @include rem(margin-bottom, 20px);
      }

      .btn, .btn-flip {
        order: 1;
        flex: 0 0 100%;
        max-width: 395px;

        &:after {
          max-width: 395px;
          flex: 0 0 100%;
          background: $blue;
        }

        &:before {
          max-width: 395px;
          flex: 0 0 100%;
          background: $green;
        }
      }
    }
  }

  .posts-overview {
    .posts-main {
      .item {
        h3 {
          max-width: 100%;
        }
      }
    }

    .posts-list {
      .foot {
        .btn, .btn-flip {
          order: 1;
          flex: 0 0 100%;
          max-width: 395px;

          &:after {
            max-width: 395px;
            flex: 0 0 100%;
            background: $blue;
          }

          &:before {
            max-width: 395px;
            flex: 0 0 100%;
            background: $green;
          }
        }
      }
    }
  }

  .posts-big {
    .item {
      .text {
        @include rem(padding, 30px);
      }
    }
  }

  .tiles-photo {
    .item {
      .text {
        .icon {
          @include rem(width, 60px);
          @include rem(height, 60px);
          @include rem(flex, 0 0 60px);
        }
      }

      .suphead {
        @include rem(font-size, 20px);
      }

      h3 {
        @include rem(font-size, 25px);
      }
    }
  }

  .persons {
    .item {
      .sign {
        @include rem(max-width, 240px);
        @include rem(margin-bottom, 10px);
        margin-right: 30px;
      }

      .item-foot {
        @include rem(padding, 0 30px 30px 30px);
      }
    }

    .cite {
      display: none;
    }

    .foot {
      .btn, .btn-flip {
        order: 1;
        flex: 0 0 100%;
        max-width: 395px;

        &:after {
          max-width: 395px;
          flex: 0 0 100%;
          background: $blue;
        }

        &:before {
          max-width: 395px;
          flex: 0 0 100%;
          background: $green;
        }
      }
    }
  }

  .video {
    .play {
      @include rem(width, 60px);
      @include rem(height, 60px);

      .icon {
        @include rem(width, 60px);
        @include rem(height, 60px);
      }
    }
  }

  .act-nav {
    .item-inner {
      border-bottom: 2px solid #fff;
    }
  }

  .roi {
    .btn, .btn-flip {
        order: 1;
        flex: 0 0 100%;
        max-width: 395px;

        &:after {
          max-width: 395px;
          flex: 0 0 100%;
        }

        &:before {
          max-width: 395px;
          flex: 0 0 100%;
        }
      }
  }

  .sky-text {
    .block:first-child {
      padding-top: 0;
    }
  }

  .numbers {
    .item-list {
      margin-top: 0;
      @include rem(margin-bottom, -30px);
    }

    .item {
      @include rem(margin, 0 0 30px 0);
    }

    .foot {
      .btn, .btn-flip {
          order: 1;
          flex: 0 0 100%;
          max-width: 395px;

          &:after {
            flex: 0 0 100%;
            max-width: 395px;
          }

          &:before {
            flex: 0 0 100%;
            max-width: 395px;
          }
      }
    }
  }

  .phases {
    .section-head-l {
      text-align: center;
    }

    .item {
      text-align: center;
      padding-top: 0;
      align-items: center;
      border: none;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;

      .img {
        @include rem(margin-bottom, 20px);
      }

      .text {
        flex-grow: 0;
        max-width: 100%;
        margin: 0;
        //@include rem(padding, 0 60px);
        padding: 0 15px;

        h3 {
          margin: 0;
        }
      }
    }

    .foot {
      display: flex;
      justify-content: center;
    }

    &.phases-list {
      .item {
        border: none;
      }
    }
  }

  .phase-slider {
    width: 100%;
    position: relative;

    &:after {
      content: none;
    }

    .glide__slide {
      display: flex;
      align-items: center;
    }

    .glide-nav {
      .glide__arrow--right, .glide__arrow--left {
        top: 50%;
        @include rem(width, 50px);
        @include rem(height, 50px);

        .icon {
          @include rem(width, 25px);
          @include rem(height, 25px);
          @include rem(flex, 0 0 25px);
        }
      }
    }
  }

  .investment {
    .item-list {
      margin-top: 0;
    }

    .item {
      padding: 0 15px;

      .item-inner {
        @include rem(margin-bottom, 50px);
        height: auto;
        @include rem(padding, 60px 20px 40px 20px);
      }

      .desc {
        @include rem(padding-top, 20px);
      }
    }

    &.alt {
      .item {
        padding-left: 15px;
        padding-right: 15px;

        &:last-child {
          .item-inner {
            margin-bottom: 0;
          }
        }
      }

      .item-inner {
        @include rem(padding-top, 55px);
        @include rem(height, 160px);
        @include rem(margin-bottom, 50px);
      }
    }

    &.alpha {
      padding-top: inherit;
    }

    .item-inner {
      @include rem(height, 380px);
    }
  }

  .investment-action {
    .section-head {
      @include rem(margin-bottom, 50px);
    }

    .investment-form {
      padding-left: 15px;
      padding-right: 15px;
      @include rem(margin-bottom, 40px);

      .section-head {
        @include rem(margin-bottom, 40px);
      }
    }
  }

  .range-calculator-wrapper {
    padding: 0 30px;
  }

  #range-calculator {
    .min, .max {
      width: 80px;
      white-space: normal;
      display: block;
    }
  }

  .amounts {
    .item {
      @include rem(margin-top, 20px);
      @include rem(margin-bottom, 20px);

      .amount {
        @include rem(font-size, 25px);

        &.small {
          @include rem(font-size, 24px);
        }
      }
    }

    &.alt {
      @include rem(margin-bottom, 50px);

      .item {
        border-left: 1px solid #E8E8E8;
        border-top: 1px solid #E8E8E8;
        margin: 0;
        @include rem(padding, 20px);

        &:nth-child(1),
        &:nth-child(2) {
          border-top: none;
        }

        &:nth-child(1),
        &:nth-child(3) {
          border-left: none;
        }
      }
    }
  }

  .stocks-program {
    .tab-nav {
      display: flex;
      justify-content: center;
      display: block;

      ul {
        margin: 0 -30px;

        li {
          flex: 0 0 50%;
          padding: 0 5px;
        }
      }
    }

    .foot {
      display: flex;
      justify-content: center;
    }
  }

  .expansion {
    .section-head {
      @include rem(margin-bottom, 40px);
    }
  }

  .history {
    .section-head {
      @include rem(margin-bottom, 40px);
    }
  }

  .tabs {
    .tab-nav {
      li {
        padding: 0 15px;

        a {
          width: 100%;
          @include rem(padding, 0 10px);
        }
      }
    }

    .tab-content {
      .tab {
        @include rem(padding-top, 50px);
      }
    }
  }

  .gallery-text {
    @include rem(padding, 40px 0);
  }

  .history-slider-wrapper {
    @include rem(padding, 0 40px);
  }

  .milestones-slider-wrapper {
    @include rem(padding, 0 40px);
  }

  .milestones-slider {
    &:after {
      content: none;
    }

    .glide-nav {
      .glide__arrow--left {
        left: -15px;
        @include translate(0,0);
        top: 80px;
        @include rem(width, 50px);
        @include rem(height, 50px);

        .icon {
          @include rem(width, 20px);
          @include rem(height, 20px);
          @include rem(flex, 0 0 20px);
        }
      }

      .glide__arrow--right {
        right: -15px;
        @include translate(0,0);
        top: 80px;
        @include rem(width, 50px);
        @include rem(height, 50px);

        .icon {
          @include rem(width, 20px);
          @include rem(height, 20px);
          @include rem(flex, 0 0 20px);
        }
      }
    }

    .vline {
      position: relative;
      left: 30px;
      height: 60px !important;
      margin-bottom: 20px;
    }
  }

  .gff {
    width: 100%;
    max-width: 100%;
    position: relative;
    padding: 40px 15px 0 15px;
    display: flex;
    justify-content: flex-end;

    .iframe {
      max-width: 225px;
    }
  }

  .post-info {
    .cats {
      flex-wrap: wrap;

      .head {

      }
    }
  }

  .countdown {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    .item-list {
      margin: 0 -5px;
    }

    .item {
      padding: 0 5px;

      .val {
        @include rem(font-size, 25px);
        @include rem(height, 60px);
      }
    }
  }

  .schedule {
    &:after {
      @include rem(height, 70px);
    }
  }

  .boxes-img {
    .item {
      .btn, .btn-flip {
        width: 100%;
        max-width: 395px;

        &:before, &:after {
          width: 100%;
          max-width: 395px;
        }
      }
    }
  }

  .ordered-list {
    .item {
      @include rem(margin-bottom, 30px);

      &:before {
        content: none;
      }
    }

    h3 {
      &:before {
        font-weight: 600;
        content: counter(counter) ". ";
      }
    }
  }

  .trust {
    .item {
      display: block;

      .img {
        @include rem(margin-bottom, 30px);

        .frame {
          max-width: 300px;
        }
      }

      .text {
        display: block;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .icon-list {
    .item {
      @include rem(margin-bottom, 50px);
    }
  }

  .tabs-links {
    .tab-nav {
      ul {
        li {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }

  .faq-detail {
    .box {
      @include rem(padding, 25px);
      @include rem(margin-bottom, 40px);
    }
  }

  .people {
    @include rem(margin-bottom, 40px);

    .item-list {
      @include rem(margin-bottom, -40px);
    }

    .item {
      @include rem(margin-bottom, 40px);

      .img {
        @include rem(margin-bottom, 60px);
      }
    }
  }

  .interest-nav {
    @include rem(margin-bottom, 40px);
  }

  .company-structure {
    .box {
      @include rem(padding, 30px);
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .platforms {
    .item-list {
      margin-left: -10px;
      margin-right: -10px;
    }

    .item {
      padding: 0 10px;
    }
  }

  .box-contact {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    @include rem(margin-top, -40px);

    h3 {
      text-align: center;
    }
  }

  .investment-intro {
    .leaf-1, .leaf-2 {
      display: none;
    }
  }

  .footer {
    .f_1 {
      @include rem(padding-bottom, 20px);
    }

    .logo-foot {
      margin-left: auto;
      margin-right: auto;
      @include rem(margin-bottom, 40px);
    }

    .europe {
      width: 150%;
      margin-right: -50%;
      margin-left: 15px;
    }
  }

  .modal {
    .modal-box {
      &.modal-exit {
        .modal-content {
          padding: 20px 15px;
        }
      }
    }
  }

  form.form-contact {
    @include rem(margin-bottom, 30px);

    .btn-set {
      text-align: center;
      padding-top: 0;
    }
  }

  body.mobile-nav-opened {
    .header-r {
      .btn-flip {
        color: $blue;

        &:before {
          background: $blue;
        }
      }
    }
  }
}