/************************************************************************************
LAYOUT
*************************************************************************************/

.offer {
	@include rem(margin-top, -250px);
	position: relative;
	padding-left: 110px;
	z-index: 40;
	display: flex;
	justify-content: flex-end;
	overflow: hidden;

	.head {
		@include rem(font-size, 18px);
		@include rem(margin-bottom, 40px);
		color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: calc((100vw - 1965px)/2);

		.link-play {
			@include rem(font-size, 16px);
			color: #fff;
			display: inline-flex;
			align-items: center;
			white-space: nowrap;
			text-decoration: none;

			.icon {
				@include rem(width, 40px);
				@include rem(height, 40px);
				@include rem(flex, 0 0 40px);
				@include rem(margin-left, 20px);
			}
		}
	}

	.container {
		margin-left: 0;
		margin-right: -15px;
	}

	.item-list {
		margin: 0 -23px;
	}

	.item {
		display: flex;
		padding: 0 23px;
		@include rem(margin-bottom, 40px);

		.text {
			@include rem(padding, 40px 40px 0 40px);
			flex-grow: 1;

			&:last-child {
				@include rem(padding-bottom, 40px);
			}

			.suphead {
				@include rem(font-size, 18px);
				line-height: 1.3em;
				letter-spacing: -0.025em;
				font-weight: 500;
				text-transform: uppercase;
				@include rem(margin-bottom, 15px);
				@include rem(height, 40px);
				@include rem(padding-right, 30px);
				position: relative;

				.icon-arr-r {
					position: absolute;
					right: 0;
					top: 0;
				}
			}

			h3 {
				text-transform: uppercase;
				color: $blue;
				@include rem(height, 70px);
				@include rem(margin-bottom, 30px);
				display: flex;
				align-items: center;

				a {
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					text-decoration: none;
					color: $blue;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			p {
				margin-bottom: 0;
			}
		}

		.img {
			@include rem(padding-left, 40px);
			position: relative;
			@include rem(top, 40px);

			img {
				width: 100%;
			}
		}

		.label {
			position: absolute;
			right: 0;
			@include rem(top, -17px);
			@include rem(height, 34px);
			@include rem(padding, 0 20px);
			background: $green;
			color: #fff;
			text-transform: uppercase;
			display: inline-flex;
			align-items: center;
			text-align: center;
		}

		.params {
			@include rem(font-size, 18px);
			font-weight: 600;

			ul {
				li {
					@include rem(padding-left, 50px);
					position: relative;

					.icon {
						@include rem(width, 24px);
						@include rem(height, 24px);
						position: absolute;
						@include rem(left, 5px);
						@include rem(top, 1px);
					}
				}
			}
		}

		.more {
			display: inline-flex;
			align-items: center;
			text-decoration: none;
			text-transform: uppercase;
			@include rem(margin-top, 20px);

			&:hover {
				text-decoration: underline;
			}

			.icon {
				@include rem(margin-right, 15px);
			}
		}

		&.purple {
			.params {
				color: #9F1DA5;
			}

			img {
				@include rem(border-top, 12px solid #9F1DA5);
			}
		}

		&.green {
			.params {
				color: #92B42B;
			}

			img {
				@include rem(border-top, 12px solid #92B42B);
			}
		}

		&.blue {
			.params {
				color: #4EAED1;
			}

			img {
				@include rem(border-top, 12px solid #4EAED1);
			}
		}
	}

	.item-inner {
		width: 100%;
		text-decoration: none;
		display: flex;
		flex-direction: column;
		color: $blue;
		background: #E8E8E8;
		position: relative;
	}

	a.item-inner {
		&:hover {
			h3 {
				text-decoration: underline;
			}
		}
	}

	.farm-list {
		@include rem(font-size, 18px);
		font-weight: 600;
		@include rem(margin, 30px 0 0 0);

		ul {
			li {
				padding: 0;
				@include rem(margin-bottom, 12px);

				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		&.blue {
			a {
				color: #4EAED1;
			}
		}

		&.green {
			a {
				color: #92B42B;
			}
		}

		&.purple {
			a {
				color: #9F1DA5;
			}
		}
	}

	&.offer-list {
		width: 100%;
		display: block;
		@include rem(margin-top, -140px);
		padding-left: 0;
		overflow: auto;
		justify-content: center;

		.container {
			margin-left: auto;
			margin-right: auto;
		}

		.text {
			&.flex-grow {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex-grow: 1;

				.desc {
					flex-grow: 1;
				}
			}
		}

		.desc {
			max-width: 400px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	&.no-offset {
		margin-top: 0 !important;
	}
}

.about {
	display: flex;
	overflow: hidden;

	.about-img {
		flex: 0 0 100%;
		width: 100%;

		.frame {
			max-width: 50%;
		}
	}

	.about-text {
		flex: 0 0 100%;
		width: 100%;
		margin-left: -100%;
		padding-left: 50%;
		display: flex;
		align-items: center;
		padding-right: 15px;

		.text {
			@include rem(padding-left, 90px);
		}
	}

	.desc {
		max-width: 990px;
		@include rem(margin-bottom, 50px);

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.name {
		@include rem(font-size, 30px);
		color: $green;
	}

	.job {
		@include rem(font-size, 20px);
		color: $green;
		text-transform: uppercase;
	}

	.section-head {
		text-align: left;
		@include rem(margin-bottom, 40px);

		h2 {
			@include rem(max-width, 750px);
			display: inline-flex;
			align-items: center;

			.icon {
				@include rem(width, 55px);
				@include rem(height, 55px);
				@include rem(flex, 0 0 55px);
				@include rem(margin-right, 20px);
			}
		}
	}

	.btn-set {
		display: flex;
		align-items: center;

		.btn, .btn-flip {
			@include rem(margin-right, 40px);
		}
	}

	&.alt {
		.about-text {
			margin-left: 0;
			flex: 0 0 50%;
			max-width: 50%;
			order: 0;
			padding-left: 15px;
			@include rem(padding-right, 90px);
			display: flex;
			justify-content: flex-end;

			.text {
				padding-left: 0;
				max-width: 880px;
			}
		}

		.about-img {
			order: 1;
		}
	}
}

.yt-link {
	line-height: 1.3em;
	display: inline-flex;
	align-items: center;
	color: $blue;
	font-weight: 600;
	letter-spacing: 0.1;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	.icon {
		@include rem(width, 32px);
		@include rem(flex, 0 0 32px);
		@include rem(height, 32px);
		@include rem(margin-right, 18px);
	}
}

.video {
	display: block;
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		background: rgba(9,48,76,.4);
	}

	&:hover {
		.img {
			img {
				@include scale(1);
			}
		}
	}

	.img {
		img {
			@include scale(1.05);
			@include transition(all, .3);
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.play {
		position: absolute;
		z-index: 30;
		left: 50%;
		top: 50%;
		@include translate(-50%,-50%);
		@include rem(width, 90px);
		@include rem(height, 90px);
		color: #fff;

		.icon {
			@include rem(width, 90px);
			@include rem(height, 90px);
		}
	}
}

.posts-overview {
	.main-text {
		display: flex;
		align-items: center;

		.section-head {
			text-align: left;
		}
	}

	.posts {
		max-width: 710px;
	}

	.posts-main {
		margin-left: -25px;

		.item {
			h3 {
				max-width: 80%;
			}
		}
	}

	.posts-list {
		max-width: 480px;
		float: right;

		.item {
			h3 {
				max-width: 90%;
			}
		}

		.foot {
			display: none;
		}
	}
}

.posts {
	.item {
		@include rem(margin-bottom, 40px);

		.guest {
			margin-bottom: 5px;
			color: $green;
		}

		h3 {
			text-transform: uppercase;

			a {
				text-decoration: none;
				color: $blue;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.img {
			@include rem(margin-bottom, 25px);
			display: block;
			overflow: hidden;
			position: relative;

			img {
				@include scale(1);
				@include transition(all, .3);
			}

			&:hover {
				img {
					@include scale(1.05);
				}
			}
		}

		.info {
			@include rem(font-size, 14px);
			line-height: 1.5em;
			color: $green;
		}

		.desc {
			@include rem(margin-bottom, 20px);
		}

		.info {
			@include rem(margin-bottom, 20px);
		}

		.more {
			a {
				color: $blue;
			}
		}

		&:last-child {
			margin-bottom: 0;

			.item-inner {
				border: none;
				padding-bottom: 0;
			}
		}
	}

	.item-inner {
		@include rem(padding-bottom, 40px);
		border-bottom: 1px solid $blue;
	}
}

.posts-archive {
	//@include rem(max-width, 1600px);
	margin-left: auto;
	margin-right: auto;

	&.wide {
		max-width: 100%;
	}

	.item-list {
		@include rem(margin-bottom, -40px);
	}

	.item {
		&:last-child {
			@include rem(margin-bottom, 40px);
		}
	}

	.item-inner {
		border: none;
		padding-bottom: 0;
	}

	.foot {
		display: flex;
		justify-content: center;
	}
}

.posts-recent {
	//@include rem(max-width, 1600px);
	margin-left: auto;
	margin-right: auto;

	.item-list {
		@include rem(margin-bottom, -40px);
	}

	.item {
		&:last-child {
			@include rem(margin-bottom, 40px);
		}
	}

	.item-inner {
		border: none;
		padding-bottom: 0 !important;
	}
}

.posts-big {
	//@include rem(max-width, 1600px);
	@include rem(margin-bottom, 50px);
	margin-left: auto;
	margin-right: auto;

	.item-inner {
		display: flex;

		.img {
			flex: 0 0 50%;
			margin-bottom: 0;
		}

		.text {
			flex: 0 0 50%;
			@include rem(padding, 50px);
			background: #E8E8E8;
			display: flex;
			align-items: center;
		}
	}
}

.post-img-main {
	max-width: 1350px;
	margin-left: auto;
	margin-right: auto;

	img {
		width: 100%;
		/*height: 100%;
		object-fit: cover !important;*/
		position: relative;
		z-index: 20;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 200%;
		margin-left: -50%;
		@include rem(height, 200px);
		background: $blue;
	}
}

.post-excerpt {
	@include rem(font-size, 22px);
	line-height: 1.5em;
	@include rem(margin, 0 0 40px 0);
}

.post-text {
	@include rem(margin, 40px 0);
	position: relative;
}

.post-img {
	position: relative;
}

.post-detail {
	max-width: 850px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	h2 {
		@include rem(font-size, 35px);
		@include rem(margin-bottom, 20px);
	}

	h3 {
		@include rem(font-size, 25px);
		@include rem(margin-bottom, 20px);
	}

	ol {
		list-style: none;
		@include rem(margin, 0 0 20px 0);
		padding: 0;
		counter-reset: counter;
		margin-left: 0;

		li {
			@include rem(margin, 0 0 15px 0);
			@include rem(padding, 0 0 0 40px);
			counter-increment: counter;
			position: relative;

			&:before {
				@include rem(font-size, 15px);
				font-weight: 600;
				color: $green;
				background: #E8E8E8;
				border-radius: 50%;
				//border: 2px solid $green;
				content: counter(counter) ".";
				position: absolute;
				left: 0;
				top: 0;
				text-align: center;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				@include rem(width, 25px);
				@include rem(height, 25px);
			}
		}
	}
}

.post-info {
	max-width: 200px;
	position: absolute;
	left: -250px;
	top: 0;

	.author {
		@include rem(padding-bottom, 30px);
		@include rem(margin-bottom, 30px);
		border-bottom: 1px solid $green;

		.img {
			@include rem(margin-bottom, 10px);

			.frame {
				@include rem(width, 80px);
				@include rem(height, 80px);
				@include rem(flex, 0 0 80px);
				border-radius: 50%;
			}

			img {
				width: 100%;
			}
		}

		.label {
			@include rem(font-size, 13px);
			line-height: 1.5em;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			margin-bottom: 0;
		}

		.name {
			@include rem(font-size, 18px);
			color: $green;
			margin: 0;

			a {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.cats {
		.head {
			flex: 0 0 100px;
		}

		ul {
			li {
				padding: 0;
				position: relative;

				a {
					@include rem(font-size, 13px);
					line-height: 1.3em;
					color: #fff;
					font-weight: 600;
					background: $green;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					@include rem(padding, 6px 10px);
					border-radius: 50px;
					text-decoration: none;
					@include transition(all, .3);

					&:hover {
						background: $blue;
					}
				}
			}
		}
	}

	.head {
		font-weight: 600;
		color: $blue;
		@include rem(margin-bottom, 20px);
	}
}

.post-meta {
	text-align: center;
	@include rem(margin-top, 20px);

	.pipe {
		padding: 0 10px;
		display: inline-block;
	}
}

.post-share {
	display: flex;
	justify-content: flex-start;
	@include rem(padding-top, 50px);
	@include rem(margin-top, 50px);
	border-top: 1px solid $green;

	.head {
		font-weight: 600;
		@include rem(margin, 10px 20px 10px 0);
		display: inline-flex;
		align-items: center;
	}
}

.tiles-photo {
	.item-list {
		margin: 0;
	}

	.item {
		padding: 0;

		.img {
			position: relative;
			display: block;
			flex: 0 0 100%;
			width: 100%;
			z-index: 10;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: block;
				background: rgba(9,48,76,.8);
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover !important;
			}
		}

		.text {
			flex: 0 0 100%;
			width: 100%;
			margin-left: -100%;
			position: relative;
			z-index: 20;
			padding: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

			.icon {
				@include rem(width, 90px);
				@include rem(height, 90px);
				@include rem(flex, 0 0 90px);
				@include rem(margin-bottom, 20px);
			}
		}

		.inner {
			@include scale(1);
			@include transition(all, .3);
		}
	}

	.item-inner {
		display: flex;
		text-decoration: none;
		height: 100%;

		&:hover {
			.img {
				img {
					@include scale(1.05);
				}
			}

			.text {
				.inner {
					@include scale(0.95);
				}
			}
		}
	}

	.img {
		position: relative;
		overflow: hidden;

		img {
			@include scale(1);
			@include transition(all, .3);
		}
	}

	.suphead {
		@include rem(font-size, 28px);
		line-height: 1.3em;
		font-weight: 500;
		color: #fff;
		@include rem(margin-bottom, 10px);
	}

	h3 {
		@include rem(font-size, 32px);
		color: $green;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		margin: 0;
	}
}

.vision {
	@include rem(margin-bottom, 40px);

	&:last-child {
		margin-bottom: 0;
	}

	.logo-vision {
		@include rem(max-width, 142px);
		@include rem(margin-bottom, 55px);
		opacity: 0.6;
		position: relative;
		left: 5px;
	}

	.section-head {
		text-align: left;
		margin: 0;
		@include rem(margin-bottom, 70px);
		max-width: 460px;

		&.wide {
			max-width: 790px;
		}
	}

	.main-text {
		.desc {
			max-width: 820px;
		}
	}

	.item-list {
		counter-reset: counter;
		@include rem(margin-top, -50px);
		@include rem(margin-bottom, -50px);
	}

	.item {
		@include rem(font-size, 22px);
		line-height: 1.3em;
		font-weight: 500;
		counter-increment: counter;
		position: relative;
		display: flex;
		align-items: center;
		@include rem(min-height, 200px);
		position: relative;

		&:after {
			content: '';
		}

		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 80%;
			height: 1px;
			background: #fff;
		}

		&:last-child {
			&:before {
				content: none;
			}
		}
	}

	.item-inner {
		position: relative;
		@include rem(padding-top, 50px);
		@include rem(padding-bottom, 50px);
		@include rem(padding-left, 90px);

		&:before {
			@include rem(font-size, 90px);
			line-height: 0.8em;
			font-weight: 500;
			color: rgba(255,255,255,.3);
			content: counter(counter);
			position: absolute;
			left: 0;
			top: 50%;
			@include translate(0,-50%);
		}
	}

	.foot {
		display: none;
	}
}

.persons {
	.item-list {
		margin: 0;
	}

	.item {
		padding: 0;

		.text {
			display: flex;
			flex-wrap: wrap;
		}

		img {
			width: 100%;
		}
	}

	.item-inner {
		position: relative;
		display: flex;

		.img {
			flex: 0 0 100%;
			width: 100%;
			z-index: 10;
			position: relative;
			z-index: 4;
			overflow: hidden;

			img {
				@include scale(1.05);
				@include transition(all, .3);
			}
		}

		.text {
			flex: 0 0 100%;
			width: 100%;
			margin-left: -100%;
			position: relative;
			z-index: 5;
			z-index: 20;
			color: #fff;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		&:after {
			content: '';
			position: absolute;
			z-index: 10;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			pointer-events: none;
			background: rgba(255,255,255,0);
			@include transition(all, .3);
		}

		&.hoverable {
			cursor: pointer;

			&:hover {
				.cite {
					opacity: 0;
					margin-top: 30px;
				}

				.cite-btn {
					opacity: 1;
					margin-top: 0;
				}

				.img {
					img {
						@include scale(1);
					}
				}

				&:after {
					background: rgba(255,255,255,.3);
				}
			}
		}
	}

	.cite {
		@include rem(font-size, 48px);
		line-height: 1.25em;
		letter-spacing: -0.05em;
		font-weight: 300;
		font-style: italic;
		width: 100%;
		max-width: 670px;
		padding: 0 30px;
		position: absolute;
		left: 50%;
		top: 50%;
		opacity: 1;
		margin-top: 0;
		@include transition(all, .3);
		@include translate(-50%,-50%);
	}

	.cite-btn {
		width: 100%;
		max-width: 670px;
		padding: 0 30px;
		position: absolute;
		left: 50%;
		top: 50%;
		opacity: 0;
		margin-top: 30px;
		@include transition(all, .3);
		@include translate(-50%,-50%);
	}

	.sign {
		display: inline-block;
		margin-right: 70px;
		margin-bottom: 25px;

		img {
			width: auto;
		}
	}

	.item-foot {
		flex: 0 0 100%;
		max-width: 910px;
		padding: 0 30px;
		align-self: flex-end;
		padding-bottom: 10%;
		display: flex;
		justify-content: space-between;
	}

	.item-foot-r {
		text-align: right;
	}

	.name {
		@include rem(font-size, 28px);
		font-weight: 500;
		@include rem(margin, 0 0 15px 0);
	}

	.foot {
		display: flex;
		justify-content: center;
		@include rem(padding, 30px 15px);
		display: none;
	}
}

.act-nav {
	color: $green;

	h3 {
		text-transform: uppercase;
	}

	.item-inner {
		@include rem(font-size, 18px);
		width: 100%;
		color: #fff;
		@include rem(padding, 30px 0);
		border-bottom: 5px solid #fff;
		text-decoration: none;
		display: flex;
		@include transition(all, .3);

		&:hover {
			background: #fff;
			color: $blue;

			.img {
				.icon {
					color: $green;
				}
			}

			.link {
				.txt {
					opacity: 1;
					margin-left: 0;
				}
			}
		}
	}

	.item {
		display: flex;

		.img {
			@include rem(flex, 0 0 85px);
			display: flex;
			justify-content: center;

			.icon {
				@include rem(width, 42px);
				@include rem(height, 42px);
				@include rem(flex, 0 0 42px);
				color: #fff;
				position: relative;
				@include rem(top, 5px);
			}
		}

		.text {
			display: flex;
			flex-direction: column;

			.desc {
				display: block;
				flex-grow: 1;

				.icon-new-window {
					margin-left: 10px;
					@include rem(width, 10px);
					@include rem(height, 10px);
					color: #59B0A3;
					position: relative;
					@include rem(top, 5px);
				}
			}
		}

		.link {
			display: inline-flex;
			align-items: center;
			@include rem(margin-top, 10px);
			color: $green;

			.txt {
				opacity: 0;
				margin-left: 15px;
				@include transition(all, .3);
			}

			.icon {
				@include rem(width, 20px);
				@include rem(height, 20px);
				color: $green;
				margin-right: 10px;
			}
		}
	}
}

.investment-intro {
	h2 {
		position: relative;
		z-index: 10;
	}

	.desc {
		@include rem(margin-top, 40px);
		@include rem(margin-bottom, 40px);
		@include rem(max-width, 1050px);
	}

	.container {
		position: relative;
	}

	.leaf-1 {
		position: absolute;
		right: 0;
		top: 0;
		@include rem(top, -200px);
		z-index: 1;
		pointer-events: none;
	}

	.leaf-2 {
		position: absolute;
		right: 0;
		@include rem(top, -50px);
		z-index: 1;
		pointer-events: none;
	}
}

.investment {
	overflow: hidden;
	@include rem(padding-top, 40px);
	position: relative;
	//@include rem(top, -40px);
	@include rem(margin-top, -40px);

	&.no-offset {
		.item-list {
			margin-top: 0 !important;
		}
	}

	.section-head {
		&.narrow {
			max-width: 900px;
		}
	}

	.item-list {
		margin: 0;
		//@include rem(margin-top, -40px);
		//@include rem(padding-top, 40px);
		border-top: 2px solid #fff;
	}

	.item {
		text-align: center;
		padding: 0;
		border-left: 2px solid #fff;
		border-bottom: 2px solid #fff;

		&:first-child {
			border-left: none;
		}

		.desc {
			@include rem(max-width, 370px);
			@include rem(padding-top, 50px);
		}

		.icon-arr-r {
			color: $green;
		}

		.price {
			@include rem(font-size, 25px);
			font-weight: 500;
			color: $green;
			position: absolute;
			left: 50%;
			@include rem(top, -40px);
			@include translate(-50%,0);
			background: #fff;
			@include rem(width, 290px);
			@include rem(height, 80px);
			@include rem(border-radius, 40px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			border: 1px solid $blue;
		}

		h3 {
			text-transform: uppercase;
			color: $blue;
		}
	}

	.item-inner {
		@include rem(height, 490px);
		@include rem(padding, 30px);
		background: #E8E8E8;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-decoration: none;
		color: #09304C;
		@include transition(all, .3);

		h3 {
			@include transition(all, .4);
			@include rem(margin-bottom, 10px);
		}

		.btn-flip {
			display: none;
			@include transition(all, .3);
		}

		.icon-arr-r {
			@include scale(1);
			max-height: 50px;
			@include transition(all, .4);
		}

		&:hover {
			background: #09304C;
			color: #fff;

			h3 {
				color: #fff;
				margin-bottom: -10px;
			}

			.btn-flip {
				display: inline-flex;
				animation: showBtn 400ms ease-in-out forwards;
				@include rem(margin-top, 20px);
			}

			.icon-arr-r {
				@include scale(0);
				max-height: 0;
			}
		}
	}

	&.alt {
		.item {
			border-top: 7px solid #fff;
			border-left: 7px solid #fff;

			&:nth-child(odd) {
				border-left: none;
			}
		}

		.item-inner {
			@include rem(height, 310px);
		}
	}

	&.alpha {
		padding-top: 0;
	}
}

.investment-action {
	display: flex;

	.section-head {
		@include rem(min-height, 180px);
		margin: 0 auto 80px auto;

		h2 {
			@include rem(margin-bottom, 20px);
		}
	}

	.investment {
		flex: 0 0 50%;
		width: 50%;
		padding-top: 0;

		.section-head {
			max-width: 600px;
		}

		.item {
			&:nth-child(1),
			&:nth-child(2) {
				border-top: none;
			}
		}
	}

	.investment-form {
		flex: 0 0 50%;
		width: 50%;
		@include rem(padding, 0 50px);
		@include rem(margin-bottom, 40px);

		.section-head {
			max-width: 500px;

			h2 {
				@include rem(margin-bottom, 20px);
			}
		}

		.subhead {
			color: $green;
		}
	}
}

.investment-form {
	margin-left: auto;
	margin-right: auto;
	position: relative;

	.anchor {
		@include rem(top, -50px);
	}

	.section-head {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		@include rem(margin-bottom, 40px);

		&.wide {
			max-width: 900px;
		}

		h2 {
			@include rem(margin-bottom, 40px);
		}
	}

	.subhead {
		@include rem(font-size, 18px);
		font-weight: 500;
		text-transform: uppercase;
		color: $blue;
		margin: 0;
	}

	&.invert-2 {
		.subhead {
			color: $green;
		}

		form.form-contact {
			input.text, textarea {
				background: none;
				color: #fff;
			}

			select {
				background-color: transparent;
				background-image: url('../img/select-alt.svg');
				background-position: right center;
				background-repeat: no-repeat;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;

				option {
					color: #09304C;
				}
			}
		}
	}
}

.media {
	/*display: flex;
	align-items: center;
	overflow: hidden;*/

	.head {
		display: flex;
		align-items: center;
	}

	.item {
		display: flex;
		justify-content: center;
	}

	.item-inner {
		//background: #fff;
		width: 100%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 150px;
	}
}

.numbers {
	.section-head {
		text-align: left;
	}

	.main-text {
		max-width: 490px;
		align-self: center;
		position: relative;

		& > * {
			position: relative;
			z-index: 10;
		}

		.bg {
			@include rem(width, 550px);
			position: absolute;
			left: 50%;
			top: -100%;
			z-index: 1;
		}

		.bg-fish {
			@include rem(width, 550px);
			position: absolute;
			left: -10%;
			@include rem(top, -90px);
			@include translate(0,-100%);
			z-index: 1;
			pointer-events: none;
		}
	}

	.item-list {
		@include rem(margin-top, -60px);
		@include rem(margin-bottom, -60px);
	}

	.item {
		position: relative;
		z-index: 20;
		@include rem(margin-top, 60px);
		@include rem(margin-bottom, 60px);

		.img {
			color: $blue;
			@include rem(width, 60px);
			@include rem(padding-bottom, 25px);
			@include rem(margin-bottom, 40px);
			border-bottom: 1px solid $blue;

			.icon {
				@include rem(width, 45px);
				@include rem(height, 45px);
			}
		}

		.desc {
			@include rem(font-size, 22px);
			line-height: 1.7em;
			max-width: 280px;

			&.full {
				max-width: 100%;
			}
		}

		h2 {
			color: $blue;

			sup {
				@include rem(font-size, 20px);
				top: -1.8rem;
			}
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.foot {
		display: none;
	}
}

.state {
	width: 100%;
	@include rem(margin, 20px auto);

	.state-title {
		line-height: 1.3em;
		font-weight: 600;
		margin-bottom: 5px;
		display: inline-flex;
		align-items: center;

		.icon {
			@include rem(width, 40px);
			@include rem(height, 40px);
			@include rem(flex, 0 0 40px);
			margin-right: 10px;
		}
	}

	.state-amount {
		@include rem(font-size, 50px);
		font-weight: 700;
		line-height: 1.1em;
		margin-bottom: 5px;

		.small {
			@include rem(font-size, 20px);
		}
	}

	.state-note {
		@include rem(font-size, 14px);
		@include rem(margin-top, 5px);
	}

	.loader {
		width: 100%;
		background: #fff;
		border-radius: 20px;
		@include rem(height, 35px);
		overflow: hidden;
		position: relative;
		border: 2px solid #fff;

		.bar {
			font-weight: 600;
			height: 100%;
			width: 50%;
			background: #59B0A3;
			color: #09304C;
			//border: 2px solid #09304C;
			border-radius: 20px;
			display: inline-flex;
			align-items: center;
			justify-content: flex-end;
			padding: 0 15px;

			.txt {
				white-space: nowrap;
				position: absolute;
				left: 50%;
				top: 50%;
				@include translate(-50%,-50%);
			}
		}
	}
}

.numbers-state {
	.numbers {
		.item {
			border: none;
		}
	}
}

.numbers-col {
	display: flex;
	align-items: center;
}

.roi {
	.section-head {
		text-align: left;
	}

	.img {
		@include rem(padding-top, 30px);

		.frame {
			@include rem(max-width, 400px);
			margin-left: auto;
			margin-right: auto;
			overflow: hidden;
			border-radius: 50%;

			&.big {
				@include rem(max-width, 880px);
			}
		}
	}

	.desc {
		@include rem(margin-top, 40px);
		@include rem(margin-bottom, 100px);
		max-width: 950px;

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.btn-set {
		//@include rem(padding-top, 100px);
	}

	.foot {
		justify-content: center;
		display: none;
		@include rem(padding-top, 30px);
	}
}

.expansion {
	.section-head {
		text-align: left;
		@include rem(margin-bottom, 80px);
	}

	.cite {
		//@include rem(font-size, 46px);
		@include rem(font-size, 26px);
		line-height: 1.3em;
		font-weight: 600;
		font-style: italic;
		@include rem(margin-bottom, 40px);
		@include rem(max-width, 650px);
	}
}

.cz-sk-map {
	margin: 0 auto;
	max-width: 1325px;
	position: relative;

	.map-marker {
		position: absolute;
		@include rem(width, 19px);
		@include rem(height, 22px);
		//@include translate(-50%,-50%);
		@include rem(margin-left, -9.5px);
		@include rem(margin-top, -11px);
		background: url('../img/map-marker.svg') 0 0 no-repeat;
		background-size: cover !important;
		@include transition(all, .3);
		cursor: pointer;

		&:after {
			content: '';
			display: block;
			width: 25px;
			height: 25px;
			border-radius: 50%;
			position: absolute;
			left: 50%;
			top: 50%;
			@include translate(-50%,-50%);
			//box-shadow: 0 0 0 50px rgba(89, 176, 163, 0.7), 0 0 0 0 rgba(89, 176, 163, 0.7);
			animation: pulsePlus 4s infinite;
		}

		.popup {
			@include rem(font-size, 14px);
			font-weight: 600;
			line-height: 1.5em;
			position: absolute;
			left: 50%;
			top: 0;
			@include translate(-50%,-100%);
			@include rem(width, 240px);
			background: #fff;
			border-radius: 5px;
			text-align: center;
			color: $green;
			@include rem(padding, 15px);
			@include rem(margin-top, -15px);
			display: none;

			&:after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 7.5px 0 7.5px;
				border-color: #fff transparent transparent transparent;
				position: absolute;
				left: 50%;
				bottom: -7px;
				margin-left: -8px;
			}
		}

		&:hover {
			.popup {
				display: block;
			}
		}

		&.alt {
			@include rem(width, 40px);
			@include rem(height, 46px);
			background-image: url('../img/map-marker-alt.svg');

			.popup {
				display: block;
			}
		}
	}
}

.faq, .faq-list {
	.section-head-l {
		@include rem(padding-top, 110px);

		.suphead {
			max-width: 550px;
		}

		h2 {
			@include rem(margin-bottom, 50px);
			max-width: 350px;
		}
	}

	.item-list {
		width: 100%;
	}

	.item {
		width: 100%;
		margin: 0;
		border-top: 1px solid $blue;

		.question {
			cursor: pointer;
			display: block;
			position: relative;
			@include transition(all, .3);
			text-decoration: none;
			color: #09304C;

			.plus {
				@include rem(width, 22px);
				@include rem(height, 22px);
				position: absolute;
				@include rem(right, 30px);
				top: 50%;
				@include translate(0,-50%);

				&:before {
					content: '';
					@include rem(width, 22px);
					height: 2px;
					position: absolute;
					left: 50%;
					top: 50%;
					@include rem(margin-left, -11px);
					@include rem(margin-top, -1px);
					background: $blue;
					@include rotate(90deg);
					@include transition(all, .3);
				}

				&:after {
					content: '';
					@include rem(width, 22px);
					height: 2px;
					position: absolute;
					left: 50%;
					top: 50%;
					@include rem(margin-left, -11px);
					@include rem(margin-top, -1px);
					background: $blue;
					@include rotate(0deg);
					@include transition(all, .3);
				}
			}

			&:hover {
				color: $green;
			}
		}

		.answer {
			display: none;
		}

		&.active {
			.answer {
				display: block;
			}

			.question {
				.plus {
					&:before {
						@include rotate(0deg);
					}

					&:after {
						@include rotate(-180deg);
					}
				}
			}
		}
	}

	.question {
		font-weight: 600;
		@include rem(padding, 35px 90px 35px 20px);
	}

	.answer {
		@include rem(padding, 0 90px 35px 20px);
	}

	.foot {
		display: none;
		justify-content: center;
	}
}

.faq-detail {
	.box {

	}
}

.roi-farm {
	.primary {
		max-width: 890px;
		@include rem(margin-bottom, 100px);

		.desc {
			@include rem(margin-bottom, 40px);
		}
	}

	.secondary {
		@include rem(font-size, 18px);
		font-weight: 600;
		color: $blue;

		h2, .h2, h3, .h3 {
			color: $blue;
			@include rem(margin-bottom, 45px);
			text-transform: uppercase;
		}

		ol {
			list-style: none;
			@include rem(margin, 0 0 20px 0);
			padding: 0;
			counter-reset: counter;
			margin-left: 0;

			li {
				@include rem(padding, 0 0 0 40px);

				&:before {
					color: $blue;
					content: "0" counter(counter) ". ";
				}
			}
		}
	}

	.numbers {
		.item-list {
			margin-top: 0;
			margin-bottom: 0;
		}

		.item {
			margin-top: 0;
			@include rem(margin-bottom, 130px);

			.desc {
				color: #fff;
			}
		}
	}
}

.phases {
	overflow: hidden;
	position: relative;

	.section-head-l {
		@include rem(margin-bottom, 100px);
		max-width: 980px;

		.desc {
			@include rem(margin-top, 40px);
		}
	}

	.item {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		@include rem(padding, 0 40px);
		border-left: 1px solid rgba(12,49,75,.4);
		@include rem(padding-top, 50px);

		.img {
			@include rem(width, 60px);
			@include rem(height, 60px);
			@include rem(margin-bottom, 70px);

			.icon {
				@include rem(width, 60px);
				@include rem(height, 60px);
				@include rem(flex, 0 0 60px);
			}
		}

		.text {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			@include rem(max-width, 320px);
		}

		h3 {
			//@include rem(margin-bottom, 50px);
			color: $blue;
			flex-grow: 1;
		}

		p {
			line-height: 1.5em;
		}
	}

	p:last-child {
		margin-bottom: 0;
	}

	.foot {
		position: relative;
		z-index: 20;
	}

	&.invert {
		.item-list {
			@include rem(margin, 0 -40px);
		}

		.item {
			border-left: 1px solid rgba(255,255,255,.2);

			.img {
				.icon {
					color: #fff;
				}
			}

			h3 {
				color: #fff;
			}
		}
	}

	&.phases-list {
		.item {
			@include rem(margin-bottom, 40px);
			@include rem(padding-top, 40px);

			.img {
				@include rem(width, 70px);
				@include rem(height, 70px);
				@include rem(margin-bottom, 50px);
				position: relative;

				.icon {
					@include rem(width, 70px);
					@include rem(height, 70px);
				}

				&.cross {
					&:after {
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						width: 130%;
						height: 4px;
						margin-left: -65%;
						background: $green;
						@include rotate(45deg);
					}
				}
			}
		}
	}
}

.development {
	padding-right: 15px;

	h2 {
		//max-width: 890px;
		max-width: 1180px;
		@include rem(margin-bottom, 40px);
	}

	.desc {
		max-width: 1130px;
	}

	.numbers {
		@include rem(padding-top, 50px);
		margin-bottom: 0;
		max-width: 945px;
	}

	.additional {
		max-width: 1130px;

		.text {
			max-width: 480px;
		}
	}

	.foot {
		@include rem(margin-top, 50px);
	}
}

.skyscraper-image {
	display: flex;

	.sky-img {
		flex: 0 0 30%;
		max-width: 30%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.sky-text {
		overflow: hidden;
		display: flex;
		flex-direction: column;

		.block.alt {
			flex-grow: 1;
		}
	}
}

.stocks-program {
	overflow: hidden;

	.container-fluid {
		padding: 0;
	}

	.main {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;

		.text {
			max-width: 960px;
			//@include rem(max-width, 790px);
			@include rem(margin-bottom, 100px);
			padding-right: 175px;
			padding-left: 15px;
		}

		.img {
			width: 100%;
			flex-grow: 1;
			margin-left: -15px;
			padding-right: 175px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover !important;
			}
		}
	}

	.tabs {
		padding-right: 15px;
	}
}

.ordered-list {
	counter-reset: counter;

	.item {
		@include rem(margin-bottom, 60px);
		counter-increment: counter;

		.desc {
			max-width: 400px;
		}

		&:before {
			@include rem(font-size, 60px);
			line-height: 1em;
			font-weight: 600;
			content: counter(counter) ". ";
			@include rem(margin-bottom, 20px);
			display: block;
		}
	}

	h3 {
		color: $blue;
		text-transform: uppercase;
	}
}

.gallery-text {
	@include rem(padding, 130px 130px);

	&:last-child {
		padding-bottom: 0;
	}

	.text {
		display: flex;
		align-items: center;

		.inner {
			@include rem(max-width, 970px);
			@include rem(padding-left, 50px);
		}

		h2 {
			@include rem(margin-bottom, 60px);
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.img {
		.inner {

		}
	}

	.gallery-slider-wrapper {
		max-width: 1280px;
	}
}

.platforms {
	max-width: 1180px;
	margin-left: auto;
	margin-right: auto;

	.head {
		@include rem(font-size, 18px);
		text-align: center;
		font-weight: 600;
		@include rem(margin-bottom, 30px);
	}

	.item-list {
		justify-content: space-between;
		margin: 0 -15px;
	}

	.item {
		padding: 0 15px;
		@include rem(margin-bottom, 30px);

		.item-inner {
			width: 100%;
			@include rem(height, 150px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			border-radius: 10px;
			@include rem(padding, 15px);
			box-shadow: 0 0 15px 0 rgba(9,48,76,.1);
		}
	}

	&.alt {
		margin: 0;
		max-width: 100%;
		text-align: left;
		display: flex;
		align-items: center;

		.head {
			text-align: left;
			max-width: 200px;
			margin-bottom: 0;
		}

		.item-list {
			justify-content: flex-start;
			margin: 0 -30px;
		}

		.item {
			flex: 0 0 auto;
			padding: 0 30px;
			margin-bottom: 0;

			img {
				opacity: .5;
				filter: brightness(0) invert(1);
				-ms-filter: brightness(0) invert(1);
				@include transition(all, .3);
			}
		}

		.item-inner {
			box-shadow: none;
			height: 80px;
			padding: 0;
			justify-content: flex-start;
			background: none;

			&:hover {
				img {
					opacity: 1;
				}
			}
		}
	}
}

.chapters {
	h2 {
		@include rem(margin-bottom, 40px);
	}

	.time {
		//width: 80px;
		color: $green;
		//padding-right: 20px;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 10px;
	}

	ul {
		li {
			position: relative;
			padding: 10px 10px 10px 80px !important;
			border-bottom: 1px solid #e8e8e8;

			a {
				color: $blue;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.box-guest {
	background: $blue;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	.img {
		flex: 0 0 100%;
		padding: 40px 40px 0 0;
		display: flex;
		justify-content: flex-end;

		img {
			opacity: 0.2;
		}
	}

	.text {
		flex: 0 0 100%;
		margin-left: -100%;
		@include rem(padding, 40px);

		.desc {
			max-width: 350px;
		}

		h3 {
			@include rem(margin-bottom, 40px);
		}
	}
}

.box {
	background: #E8E8E8;
	@include rem(padding, 40px);
}

.box-contact {
	background: #59B0A3;
	@include rem(padding, 40px);

	h2, h3, a {
		color: #fff;
	}

	.contact-list-medium {
		a {
			color: #09304C;
		}
	}
}

.contact-data {
	.item-list {
		@include rem(margin-top, -20px);
		@include rem(margin-bottom, -20px);
	}

	.item {
		@include rem(margin, 20px 0);
		display: flex;

		h3 {
			@include rem(margin, 0 0 30px 0);
		}
	}

	.item-inner {
		//max-width: 300px;
		background: #E8E8E8;
		@include rem(padding, 40px);
		width: 100%;

		&.alt {
			background: #fff;
		}
	}

	.link-map {
		display: inline-flex;
		align-items: center;

		.icon {
			@include rem(margin-right, 10px);
		}
	}
}

/* Calculator */

.calculator {
	.section-head {
		max-width: 680px;
		margin-left: auto;
		margin-right: auto;
		@include rem(margin-bottom, 90px);

		&.wide {
			max-width: 950px;
			@include rem(margin-bottom, 40px);
		}
	}

	.foot {
		display: flex;
		justify-content: center;
		//@include rem(padding-top, 110px);
	}

	&.double-cols {
		padding: 0 50px;

		#range-calculator {
			@include rem(margin-bottom, 120px);

			.min {
				@include translate(0,0);
			}

			.max {
				@include translate(-100%,0);
			}
		}
	}
}

.calculator-element {
	.head {
		@include rem(font-size, 18px);
		line-height: 1.3em;
		font-weight: 600;
		color: $blue;
		text-transform: uppercase;
		text-align: center;
		@include rem(margin-bottom, 20px);
	}

	.amount {
		@include rem(font-size, 50px);
		line-height: 1.3em;
		font-weight: 600;
		color: #fff;
		text-align: center;
		@include rem(margin-bottom, 60px);
		white-space: nowrap;
	}

	&.alt {
		#range-calculator {
			&.noUi-horizontal {
				background: $blue;

				.noUi-connect {
					background: $green;
				}
			}
		}

		.amount {
			@include rem(font-size, 37px);
			color: $green;
		}
	}
}

.amounts {
	max-width: 1150px;
	margin-left: auto;
	margin-right: auto;
	@include rem(margin-bottom, 150px);

	.item-list {
		justify-content: center;
		//@include rem(margin-bottom, -30px);
	}

	.item {
		text-align: center;
		color: $blue;
		@include rem(margin-top, 30px);
		@include rem(margin-bottom, 30px);

		.label {
			@include rem(font-size, 22px);
			margin-bottom: 0;
		}

		.amount {
			font-weight: 600;
			color: $blue;
			margin: 0;
			white-space: nowrap;

			&.small {
				@include rem(font-size, 40px);
				line-height: 1.3em;
				@include rem(margin-top, 10px);
			}

			&.light {
				font-weight: 400;
			}
		}
	}
}

#range-calculator {
	max-width: 740px;
	margin-left: auto;
	margin-right: auto;
	@include rem(margin-bottom, 230px);

	&.noUi-horizontal {
		height: 1px;
		box-shadow: none;
		border: none;
		background: #fff;

		.noUi-handle {
			border-radius: 50%;
			@include rem(width, 68px);
			@include rem(height, 68px);
			border: none;
			@include rem(right, -34px);
			@include rem(top, -34px);
			cursor: grab;
			border: 2px solid $green;
			background: #fff url('../img/handle.svg') center center no-repeat;
			@include rem(background-size, 34px);

			&:before, &:after {
				content: none;
			}
		}

		.noUi-connects {
			height: 6px;
			top: -3px;
			z-index: 1;
		}

		.noUi-connect {
			background: #fff;
		}

		.noUi-base {
			z-index: auto;
		}

		.noUi-origin {
			z-index: 3;
		}
	}

	&:before {
		content: '';
		position: absolute;
		left: -10.5px;
		top: -10.5px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: #fff;
		border: 2px solid $green;
		z-index: 2;
	}

	&:after {
		content: '';
		position: absolute;
		right: -10.5px;
		top: -10.5px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: #fff;
		border: 2px solid $green;
		z-index: 2;
	}

	.min {
		@include rem(font-size, 18px);
		font-weight: 600;
		position: absolute;
		left: 0;
		top: 45px;
		text-align: center;
		white-space: nowrap;
		@include translate(-50%,0);
		white-space: nowrap;
	}

	.max {
		@include rem(font-size, 18px);
		font-weight: 600;
		position: absolute;
		left: 100%;
		top: 45px;
		text-align: center;
		white-space: nowrap;
		@include translate(-50%,0);
		white-space: nowrap;
	}
}

.history {
	max-width: 2200px;
	margin: 0 auto;

	.section-head {
		@include rem(margin-bottom, 100px);
	}

	.entry {
		ul {
			li {
				&:before {
					background: #fff;
				}
			}
		}
	}
}

.milestones {
	.item-inner {
		max-width: 350px;
		padding-right: 30px;
	}

	.head {
		@include rem(font-size, 18px);
		font-weight: 600;
		color: #fff;
		@include rem(margin-bottom, 10px);
	}

	.date {
		font-size: 14px;
		color: $green;
		@include rem(margin-bottom, 10px);
	}

	.vline {
		width: 1px;
		height: 90px;
		background: $green;
		@include rem(margin-bottom, 25px);
	}
}

.link-nav {
	@include rem(margin-top, 40px);
	//@include rem(margin-bottom, -20px);

	.item-list {
		margin: 0 -10px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.item {
			padding: 0 10px;
			margin: 5px 0;

			a {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				@include rem(padding, 5px 15px);
				color: #fff;
				background: $green;
				text-decoration: none;
				border-radius: 20px;
				@include transition(all, .3);

				&:hover {
					background: #fff;
					color: $green;
				}
			}
		}
	}
}

.contact-list-big {
	@include rem(font-size, 28px);

	ul {
		li {
			font-weight: 500;
			line-height: 1.3em;
			@include rem(margin-bottom, 10px);
			padding: 0;
		}
	}

	a {
		color: $green;
	}
}

.contact-list-medium {
	h3 {
		@include rem(margin-bottom, 40px);
	}

	ul {
		li {
			@include rem(font-size, 24px);
			font-weight: 600;
			line-height: 1.3em;
			@include rem(margin-bottom, 10px);
			@include rem(padding, 0 0 0 60px);
			position: relative;

			.icon {
				@include rem(width, 40px);
				@include rem(height, 40px);
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	a {
		color: $green;
	}

	.note {
		@include rem(padding, 0 0 0 60px);
		@include rem(margin-bottom, 30px);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.tiles-downloads {
	.item-list {
		margin: 0;
	}

	.item {
		padding: 0;
		display: flex;
	}

	.item-inner {
		display: flex;

		.img {
			flex: 0 0 100%;
			margin-left: -100%;
			position: relative;
			z-index: 5;

			&:after {
				content: '';
				position: absolute;
				z-index: 10;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: block;
				background: rgba(9,48,76,.8);
			}

			img {
				position: relative;
				z-index: 5;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.text {
			flex: 0 0 100%;
			position: relative;
			z-index: 10;
			@include rem(padding, 50px);
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;

			h3 {
				max-width: 650px;
				margin-left: auto;
				margin-right: auto;
				@include rem(margin-bottom, 40px);
			}

			ul {
				li {
					padding: 0;
					margin: 0 auto;
					border-top: 1px solid $green;
					max-width: 300px;

					&:first-child {
						border-top: none;
					}
				}
			}

			a {
				display: block;
				@include rem(padding, 20px 0);
				color: #fff;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.symbol {
			display: flex;
			justify-content: center;

			img {
				@include rem(margin-bottom, 20px);
			}
		}
	}
}

.newsletter {
	&.lined {
		text-align: left;
		@include rem(padding, 40px 0);

		.section-head {
			text-align: left;
			@include rem(margin-bottom, 10px);

			.symbol {
				justify-content: flex-start;
			}
		}

		.intro {
			text-align: left;
			max-width: 100%;

			p {
				margin-bottom: 0;
			}
		}

		.row {
			align-items: center;
		}

		.note {
			margin: 0;
		}
	}
}

.schedule {
	@include rem(margin, 90px auto 0 auto);
	@include rem(padding-top, 50px);
	max-width: 560px;
	border-top: 1px solid rgba(255,255,255,.5);

	h2, .h2 {
		text-transform: uppercase;
	}

	h3, .h3 {
		@include rem(margin-bottom, 65px);
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 10000%;
		margin-left: -5000%;
		@include rem(height, 80px);
		background: $blue;
	}
}

.schedule-foot {
	@include rem(padding-top, 40px);

	.note {
		@include rem(margin-bottom, 80px);
	}
}

.countdown {
	//@include rem(margin-bottom, -80px);
	position: relative;
	z-index: 10;

	.item-list {
		margin: 0 -10px;
	}

	.item {
		padding: 0 10px;

		.val {
			@include rem(font-size, 35px);
			font-weight: 600;
			background: #fff;
			color: $green;
			@include rem(height, 80px);
			@include rem(border-radius, 40px);
			display: inline-flex;
			width: 100%;
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		.label {
			display: block;
			@include rem(margin-top, 12px);
			text-align: center;
			color: #fff;
			@include rem(height, 28px);
		}
	}
}

.interest-nav {
	position: relative;

	.section-head {
		@include rem(margin-bottom, 70px);
	}

	.item-list {
		justify-content: center;
		@include rem(margin, -30px 0);
	}

	.item-inner {
		color: $blue;
		text-transform: uppercase;
		@include rem(padding, 8px 40px);
		margin: 0;
		text-decoration: none;
		border-right: 1px solid $blue;
		display: block;
		width: 100%;

		&:hover {
			text-decoration: underline;
		}
	}

	.item {
		padding: 0;
		@include rem(margin, 30px 0);
		text-align: center;

		&:last-child {
			.item-inner {
				border: none;
			}
		}
	}

	.leaf-4 {
		position: absolute;
		right: 0;
		@include rem(bottom, -110px);
		pointer-events: none;
	}
}

.boxes-img {
	.section-head {
		@include rem(margin-bottom, 120px);

		&.narrow {
			max-width: 1410px;
		}
	}

	.item {
		@include rem(margin-bottom, 40px);
		display: flex;
		@include rem(padding-top, 160px);

		h3 {
			text-transform: uppercase;
			color: $blue;
			@include rem(margin-bottom, 30px);
		}

		.img {
			@include rem(margin-bottom, 80px);
			@include rem(max-width, 320px);
			@include rem(margin, -160px auto 80px auto);
			overflow: hidden;
			border-radius: 50%;
		}

		.text {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}

		.main {
			width: 100%;

			&.flex-grow {
				flex-grow: 1;
			}
		}

		.additional {
			width: 100%;
			@include rem(margin-top, 40px);
			@include rem(padding-top, 50px);
			border-top: 1px solid #E8E8E8;
			@include rem(font-size, 18px);
			font-weight: 600;
			flex-grow: 1;
		}

		.foot {
			width: 100%;
			@include rem(margin-top, 40px);
			@include rem(padding-top, 50px);
			border-top: 1px solid #E8E8E8;
			@include rem(font-size, 18px);
			font-weight: 600;
		}
	}

	.item-inner {
		background: #fff;
		@include rem(padding, 0 80px 80px 80px);
		display: flex;
		flex-direction: column;
	}
}

.connection-list {
	.item {
		@include rem(font-size, 30px);
		text-transform: uppercase;
		font-weight: 600;
		@include rem(padding-bottom, 115px);
		position: relative;

		&:after {
			content: '';
			position: absolute;
			@include rem(left, 75px);
			top: 0;
			height: 100%;
			width: 2px;
			background: $blue;
		}

		.img {
			@include rem(flex, 0 0 150px);
			justify-content: center;
			display: flex;
			background: #59B0A3;
			@include rem(padding, 30px 0);
			position: relative;
			z-index: 10;

			.icon {
				@include rem(width, 75px);
				@include rem(height, 75px);
				@include rem(flex, 0 0 75px);
			}
		}

		.text {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.icon-arr-r {
			color: #fff;
			display: block;
			@include rem(margin-top, 15px);
		}

		&:last-child {
			&:after {
				content: none;
			}
		}
	}

	.item-inner {
		display: flex;
	}
}

.share-success {
	.section-head-l {
		@include rem(margin-bottom, 70px);
	}
}

.farm-cats {
	max-width: 1940px;
	margin-left: auto;
	margin-right: auto;

	.section-head {
		@include rem(margin-bottom, 70px);
	}

	.item-list {
		@include rem(margin, 0 -27px);
		justify-content: center;
	}

	.item {
		@include rem(padding, 0 27px);
		@include rem(font-size, 28px);
		@include rem(margin-bottom, 40px);
		font-weight: 600;
		text-transform: uppercase;
		justify-content: center;
		align-items: center;

		.item-inner {
			border-bottom: 7px solid #9F1DA5;
			@include rem(height, 90px);
			@include rem(padding, 0 30px);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.blue {
			.item-inner {
				border-bottom: 7px solid #4EAED1;
			}
		}

		&.green {
			.item-inner {
				border-bottom: 7px solid #92B42B;
			}
		}
	}
}

.icon-circle {
	@include rem(margin-right, 30px);
	@include rem(width, 54px);
	@include rem(height, 54px);
	@include rem(flex, 0 0 54px);
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid $blue;
	border-radius: 50%;

	.icon {
		@include rem(width, 28px);
		@include rem(height, 28px);
		@include rem(flex, 0 0 28px);
	}
}

.modern {
	h2 {
		@include rem(margin-bottom, 65px);
	}

	.symbol {
		@include rem(margin-bottom, 50px);
	}

	.desc {
		@include rem(margin-bottom, 100px);
	}

	.img {
		display: flex;
		justify-content: flex-end;
	}
}

.trust {
	.item {
		display: flex;
		max-width: 640px;
		margin: 0 auto;

		.img {
			flex: 0 0 100%;
			max-width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.frame {
				max-width: 400px;
			}
		}

		.text {
			flex: 0 0 100%;
			max-width: 100%;
			margin-left: -100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.intro {
		max-width: 730px;
	}

	.btn-set {
		display: flex;
		justify-content: center;
	}
}

.icon-list {
	color: $blue;

	.item {
		text-align: center;
		@include rem(margin-bottom, 100px);

		h3 {
			color: $blue;
		}

		.icon {
			@include rem(width, 65px);
			@include rem(height, 65px);
			@include rem(margin-bottom, 20px);
		}
	}
}

.bar-nav {
	max-width: 1150px;

	.item-list {
		margin: 0 -10px;
	}

	.item {
		@include rem(margin-bottom, 20px);
		padding: 0 10px;

		.icon-circle {
			@include rem(margin-right, 20px);

			.icon {
				@include rem(width, 32px);
				@include rem(height, 32px);
				@include rem(flex, 0 0 32px);
			}
		}
	}

	.item-inner {
		@include rem(font-size, 25px);
		text-transform: uppercase;
		font-weight: 600;
		background: #E8E8E8;
		@include rem(height, 105px);
		@include rem(padding, 0 35px);
		display: inline-flex;
		align-items: center;
		width: 100%;
		text-decoration: none;
		color: $blue;
	}
}

.lector {
	display: flex;
	@include rem(padding-bottom, 120px);
	@include rem(margin-bottom, 120px);
	border-bottom: 1px solid rgba(255,255,255,.5);

	.main {
		flex: 0 0 35%;

		h2 {
			max-width: 580px;
			@include rem(margin-bottom, 50px);
		}

		.name {
			@include rem(font-size, 28px);
			font-weight: 600;
		}
	}

	.img {
		flex: 0 0 30%;

		.frame {
			display: block;
			margin: 0 auto;
			max-width: 258px;
		}
	}

	.text {
		flex: 0 0 35%;
	}

	.entry {
		ul {
			li {
				&:before {
					background: #fff;
				}
			}
		}
	}
}

.vocabulary {
	.item {
		//margin-left: auto;
		//margin-right: auto;
		position: relative;
		top: 0;
		@include transition(all, .3);

		&:hover {
			top: -5px;
		}
	}

	.item-inner {
		width: 100%;
		font-weight: 600;
		@include rem(padding, 20px 70px 20px 0);
		position: relative;
		cursor: pointer;
		margin-bottom: 0;
		display: inline-flex;
		align-items: center;
		border-top: 1px solid $blue;
		height: 100%;
		color: $blue;
		text-decoration: none;

		&:hover {
			color: $green;
		}
	}
}

.people {
	.section-head {
		@include rem(margin-bottom, 60px);
	}

	.item-list {
		@include rem(margin-bottom, -80px);
	}

	.item {
		@include rem(margin-bottom, 80px);

		.img {
			@include rem(margin-bottom, 80px);
			@include rem(padding-left, 30px);
			@include rem(padding-right, 30px);

			.frame {
				position: relative;

				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					z-index: -1;
					position: absolute;
					@include rem(left, -30px);
					@include rem(top, 30px);
					background: #E8E8E8;
				}
			}
		}

		.text {
			.job {
				font-weight: 700;
				max-width: 250px;
			}
		}
	}
}

.steps {
	.item-list {
		margin: 0 -1px;
	}

	.item {
		display: flex;
		padding: 0 1px;
		margin-bottom: 2px;

		.img {
			@include rem(margin-bottom, 20px);
		}

		.icon {
			@include rem(width, 70px);
			@include rem(height, 70px);
		}

		h3 {
			@include rem(margin-bottom, 30px);
			flex-grow: 1;
			max-width: 315px;
		}
	}

	.text {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.item-inner {
		background: #fff;
		@include rem(padding, 35px);
		display: flex;
		flex-direction: column;
	}

	.foot {
		display: flex;
		justify-content: center;
		@include rem(padding-top, 40px);
	}
}

.development-photo {
	display: flex;
	color: #fff;
	position: relative;

	.section-head {
		max-width: 950px;
	}

	.img {
		flex: 0 0 100%;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			background: rgba(9,48,76,.8);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.symbol {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		opacity: 0.5;
		text-align: center;

		img {
			width: 150%;
			height: 150%;
			display: inline-block;
			mix-blend-mode: exclusion;
			position: absolute;
			left: 50%;
			top: 50%;
			@include translate(-50%,-50%);
		}
	}

	.text {
		flex: 0 0 100%;
		margin-left: -100%;
		position: relative;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		position: relative;
		overflow: hidden;

		.section-head, .intro {
			position: relative;
			z-index: 10;
		}
	}
}

.company-structure {
	h2 {
		@include rem(margin-bottom, 40px);
	}

	.box {
		@include rem(margin-bottom, 40px);
	}

	.item {
		@include rem(margin-bottom, 40px);
		display: flex;

		.img {
			width: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 150px;
			border-bottom: 2px solid #E8E8E8;
			@include rem(margin-bottom, 40px);
		}

		.text {
			@include rem(font-size, 15px);

			h4 {
				@include rem(min-height, 65px);
				font-weight: 600;
			}
		}
	}

	.item-posts {
		.head {
			width: 100%;
			@include rem(margin-bottom, 20px);
			@include rem(padding-bottom, 20px);
			height: 150px;
			display: inline-flex;
			align-items: center;
			border-bottom: 2px solid #E8E8E8;
		}

		h3 {
			@include rem(font-size, 25px);
			margin-bottom: 0;
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			.icon {
				@include rem(margin-right, 20px);
				@include rem(width, 50px);
				@include rem(height, 50px);
				order: 1;
			}
		}

		ul {
			li {
				@include rem(font-size, 16px);
				font-weight: 700;
				@include rem(margin, 10px 0);
				padding: 0 0 0 30px;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					@include rem(left, 10px);
					@include rem(top, 10px);
					width: 4px;
					height: 4px;
					border-radius: 2px;
					background: $green;
				}

				a {
					text-decoration: none;
					@include rem(padding, 10px 0);

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.item-inner {
		width: 100%;
		background: #fff;
		@include rem(padding, 20px);
	}
}

.base-timer {
  position: relative;
  @include rem(width, 200px);
  @include rem(height, 200px);
	margin: 0 auto 20px auto;

	.base-timer__svg {
		transform: scaleX(-1);
	}

	.base-timer__circle {
		fill: none;
		stroke: none;
	}

	.base-timer__path-elapsed {
		stroke-width: 7px;
		stroke: #09304C;
	}

	.base-timer__path-remaining {
		stroke-width: 7px;
		stroke-linecap: round;
		transform: rotate(90deg);
		transform-origin: center;
		transition: 1s linear all;
		fill-rule: nonzero;
		stroke: #59B0A3;
	}

	.base-timer__label {
		position: absolute;
		@include rem(width, 200px);
  	@include rem(height, 200px);
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		@include rem(font-size, 25px);
	}
}

.base-timer-head {
	text-align: center;
}

.media {

}

/* Form search suggestion */

.search-bar {
  .wp_autosearch_form_wrapper {
    background: none !important;
    height: auto !important;
    border: none !important;
    padding-right: 50px;
  }

  .wp_autosearch_input, input.text {
    font: 500 15px/24px Montserrat,sans-serif;
    color: $green !important;
    border: 1px solid $green !important;
    padding: 0 10px !important;
    height: 50px !important;
    width: 100% !important;
    float: none !important;
		background: transparent !important;

    &.wp_autosearch_indicator {
      background-image: url('../img/spinner.svg') !important;
      background-position: right center !important;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }

		&::placeholder {
			color: #fff;
		}
  }

  .wp_autosearch_submit, .btn {
    background: $green !important;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    padding: 0;
		@include transition(all, .3);

		&:hover {
			width: 50px;
			background: #74d9ca !important;
			border-color: #74d9ca !important;
		}

		&:after {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -10px;
			margin-top: -10px;
			width: 20px;
			height: 20px;
			background: url('../img/search.svg') 0 0 no-repeat;
			background-size: contain;
		}
  }

  &.main {
    margin-bottom: 40px;

     .wp_autosearch_form_wrapper {
      padding-right: 50px;
    }

    .wp_autosearch_input {
      background-color: #f4f4f4 !important; height: 50px !important; float: none !important;
    }

    .wp_autosearch_submit,
    .wp_autosearch_submit:hover {
      width: 50px; height: 50px;
    }
  }
}

body {
	.wp_autosearch_result {
		padding-left: 10px;
		padding-right: 10px;
	}

	.wp_autosearch_suggestions {
		font-family: Montserrat,sans-serif;
		-webkit-box-shadow: 0 0 4px rgba(0,0,0,0.2);
		-moz-box-shadow: 0 0 4px rgba(0,0,0,0.2);
		-ms-box-shadow: 0 0 4px rgba(0,0,0,0.2);
		-o-box-shadow: 0 0 4px rgba(0,0,0,0.2);
		box-shadow: 0 0 4px rgba(0,0,0,0.2);

		.wp_autosearch_image {
			display: none !important;
		}

		.wp_autosearch_category {
			font-size: 16px;
			font-family: Montserrat,sans-serif;
			font-weight: 600;
			padding: 15px 10px;
			height: auto;
			background: #fff !important;
			border-bottom: 1px solid #e5e5e5 !important;
			color: #000 !important;
		}

		.wp_autosearch_more {
			display: none !important;
		}

		li {
			padding-left: 0;

			a {
				display: flex;
				align-items: center;
				height: 45px;
				border-bottom: 1px solid #e5e5e5;

				img {
					float: none;
				}

				.searchheading {
					font-family: Montserrat,sans-serif;
					font-weight: 400;
					height: 100%;
					display: block;
					display: flex;
					align-items: center;
					height: 50px;
					padding-top: 0;
				}
			}

			&:before {
				content: none;
			}

			&:hover {
				background: rgba(0,0,0,0.05) !important;
			}

			&.wps_odd {
				background: #fff !important;

				&.ac_over {
					background: rgba(0,0,0,0.05) !important;
					color: #000;

					a .searchheading {
						color: #000 !important;
					}
				}
			}

			&.wps_even {
				background: #fff !important;

				&.ac_over {
					background: rgba(0,0,0,0.05) !important;
					color: #000;

					a .searchheading {
						color: #000 !important;
					}
				}
			}
		}
	}
}

.search-results {
	max-width: 850px;
	margin-left: auto;
	margin-right: auto;

	h3 {
		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	p {
		margin: 0;
	}

	.item {
		@include rem(padding-bottom, 40px);
		@include rem(margin-bottom, 40px);
		border-bottom: 2px solid #e5e5e5;
	}
}

/* Various */

.text-big-18 {
	@include rem(font-size, 18px);
	font-weight: 600;
}

.text-big-20 {
	@include rem(font-size, 20px);
}

.text-big-22 {
	@include rem(font-size, 22px);
}

.text-orange {
	color: $orange;
}

.pl-130 {
	@include rem(padding-left, 130px);
}

.pb-140 {
	@include rem(padding-bottom, 140px);
}

.mb-90 {
	@include rem(margin-bottom, 90px);
}

.mb-40 {
	@include rem(margin-bottom, 40px);
}

.ttu {
	text-transform: uppercase;
}

@keyframes showBtn {
  0% {
    display: none;
    @include scale(0);
		max-height: 0;
  }
  5% {
    display: inline-flex;
    @include scale(0);
		max-height: 0;
  }
  100% {
    display: inline-flex;
    @include scale(1);
		max-height: 63px;
  }
}

@keyframes showMapMarker {
  0% {
    @include scale(0);
  }
  5% {
    @include scale(0);
  }
  100% {
    @include scale(1);
  }
}

@keyframes pulsePlus {
	0% {
    box-shadow: 0 0 0 0 rgba(89, 176, 163, 1), 0 0 0 0 rgba(89, 176, 163, 1);
  }
  40% {
    box-shadow: 0 0 0 30px rgba(89, 176, 163, 0), 0 0 0 0 rgba(89, 176, 163, 1);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(89, 176, 163, 0), 0 0 0 30px rgba(89, 176, 163, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(89, 176, 163, 0), 0 0 0 30px rgba(89, 176, 163, 0);
  }
}

.dropdown-menu {
	li {
		a {
			font-size: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
			display: block;
			white-space: nowrap;
			position: relative;
			padding-left: 30px;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -7.5px;
				width: 20px;
				height: 15px;
				background-repeat: no-repeat;
				background-size: cover !important;
				background-position: 0 0;
			}
		}
	}

	li[data-value="+93 (Afghanistan)"] a:after {
		background-image: url('../img/flags/af.svg');
	}

	li[data-value="+358 (Aland Islands)"] a:after {
		background-image: url('../img/flags/ax.svg');
	}

	li[data-value="+355 (Albania)"] a:after {
		background-image: url('../img/flags/al.svg');
	}

	li[data-value="+213 (Algeria)"] a:after {
		background-image: url('../img/flags/dz.svg');
	}

	li[data-value="+1684 (American Samoa)"] a:after {
		background-image: url('../img/flags/as.svg');
	}

	li[data-value="+376 (Andorra)"] a:after {
		background-image: url('../img/flags/ad.svg');
	}

	li[data-value="+244 (Angola)"] a:after {
		background-image: url('../img/flags/ao.svg');
	}

	li[data-value="+1264 (Anguilla)"] a:after {
		background-image: url('../img/flags/ai.svg');
	}

	li[data-value="+672 (Antarctica)"] a:after {
		background-image: url('../img/flags/aq.svg');
	}

	li[data-value="+1268 (Antigua and Barbuda)"] a:after {
		background-image: url('../img/flags/ag.svg');
	}

	li[data-value="+54 (Argentina)"] a:after {
		background-image: url('../img/flags/ar.svg');
	}

	li[data-value="+374 (Armenia)"] a:after {
		background-image: url('../img/flags/am.svg');
	}

	li[data-value="+297 (Aruba)"] a:after {
		background-image: url('../img/flags/aw.svg');
	}

	li[data-value="+61 (Australia)"] a:after {
		background-image: url('../img/flags/au.svg');
	}

	li[data-value="+43 (Austria)"] a:after {
		background-image: url('../img/flags/at.svg');
	}

	li[data-value="+994 (Azerbaijan)"] a:after {
		background-image: url('../img/flags/az.svg');
	}

	li[data-value="+1242 (Bahamas)"] a:after {
		background-image: url('../img/flags/bs.svg');
	}

	li[data-value="+973 (Bahrain)"] a:after {
		background-image: url('../img/flags/bh.svg');
	}

	li[data-value="+880 (Bangladesh)"] a:after {
		background-image: url('../img/flags/bd.svg');
	}

	li[data-value="+1246 (Barbados)"] a:after {
		background-image: url('../img/flags/bb.svg');
	}

	li[data-value="+375 (Belarus)"] a:after {
		background-image: url('../img/flags/by.svg');
	}

	li[data-value="+32 (Belgium)"] a:after {
		background-image: url('../img/flags/be.svg');
	}

	li[data-value="+501 (Belize)"] a:after {
		background-image: url('../img/flags/bz.svg');
	}

	li[data-value="+229 (Benin)"] a:after {
		background-image: url('../img/flags/bj.svg');
	}

	li[data-value="+1441 (Bermuda)"] a:after {
		background-image: url('../img/flags/bm.svg');
	}

	li[data-value="+975 (Bhutan)"] a:after {
		background-image: url('../img/flags/bt.svg');
	}

	li[data-value="+591 (Bolivia)"] a:after {
		background-image: url('../img/flags/bo.svg');
	}

	li[data-value="+599 (Bonaire, Sint Eustatius and Saba)"] a:after {
		background-image: url('../img/flags/bq.svg');
	}

	li[data-value="+387 (Bosnia and Herzegovina)"] a:after {
		background-image: url('../img/flags/ba.svg');
	}

	li[data-value="+267 (Botswana)"] a:after {
		background-image: url('../img/flags/bw.svg');
	}

	li[data-value="+55 (Bouvet Island)"] a:after {
		background-image: url('../img/flags/bv.svg');
	}

	li[data-value="+55 (Brazil)"] a:after {
		background-image: url('../img/flags/br.svg');
	}

	li[data-value="+246 (British Indian Ocean Territory)"] a:after {
		background-image: url('../img/flags/io.svg');
	}

	li[data-value="+673 (Brunei Darussalam)"] a:after {
		background-image: url('../img/flags/bn.svg');
	}

	li[data-value="+359 (Bulgaria)"] a:after {
		background-image: url('../img/flags/bg.svg');
	}

	li[data-value="+226 (Burkina Faso)"] a:after {
		background-image: url('../img/flags/bf.svg');
	}

	li[data-value="+257 (Burundi)"] a:after {
		background-image: url('../img/flags/bi.svg');
	}

	li[data-value="+855 (Cambodia)"] a:after {
		background-image: url('../img/flags/kh.svg');
	}

	li[data-value="+237 (Cameroon)"] a:after {
		background-image: url('../img/flags/cm.svg');
	}

	li[data-value="+1 (Canada)"] a:after {
		background-image: url('../img/flags/ca.svg');
	}

	li[data-value="+238 (Cape Verde)"] a:after {
		background-image: url('../img/flags/cv.svg');
	}

	li[data-value="+1345 (Cayman Islands)"] a:after {
		background-image: url('../img/flags/ky.svg');
	}

	li[data-value="+236 (Central African Republic)"] a:after {
		background-image: url('../img/flags/cf.svg');
	}

	li[data-value="+235 (Chad)"] a:after {
		background-image: url('../img/flags/td.svg');
	}

	li[data-value="+56 (Chile)"] a:after {
		background-image: url('../img/flags/cl.svg');
	}

	li[data-value="+86 (China)"] a:after {
		background-image: url('../img/flags/cn.svg');
	}

	li[data-value="+61 (Christmas Island)"] a:after {
		background-image: url('../img/flags/cx.svg');
	}

	li[data-value="+672 (Cocos (Keeling) Islands)"] a:after {
		background-image: url('../img/flags/cc.svg');
	}

	li[data-value="+57 (Colombia)"] a:after {
		background-image: url('../img/flags/co.svg');
	}

	li[data-value="+269 (Comoros)"] a:after {
		background-image: url('../img/flags/km.svg');
	}

	li[data-value="+242 (Congo)"] a:after {
		background-image: url('../img/flags/cg.svg');
	}

	li[data-value="+242 (Congo, Democratic Republic of the Congo)"] a:after {
		background-image: url('../img/flags/cd.svg');
	}

	li[data-value="+682 (Cook Islands)"] a:after {
		background-image: url('../img/flags/ck.svg');
	}

	li[data-value="+506 (Costa Rica)"] a:after {
		background-image: url('../img/flags/cr.svg');
	}

	li[data-value="+385 (Croatia)"] a:after {
		background-image: url('../img/flags/hr.svg');
	}

	li[data-value="+53 (Cuba)"] a:after {
		background-image: url('../img/flags/cu.svg');
	}

	li[data-value="+599 (Curacao)"] a:after {
		background-image: url('../img/flags/cw.svg');
	}

	li[data-value="+357 (Cyprus)"] a:after {
		background-image: url('../img/flags/cy.svg');
	}

	li[data-value="+420 (Czech Republic)"] a:after {
		background-image: url('../img/flags/cz.svg');
	}

	li[data-value="+45 (Denmark)"] a:after {
		background-image: url('../img/flags/dk.svg');
	}

	li[data-value="+253 (Djibouti)"] a:after {
		background-image: url('../img/flags/dj.svg');
	}

	li[data-value="+1767 (Dominica)"] a:after {
		background-image: url('../img/flags/dm.svg');
	}

	li[data-value="+1809 (Dominican Republic)"] a:after {
		background-image: url('../img/flags/do.svg');
	}

	li[data-value="+593 (Ecuador)"] a:after {
		background-image: url('../img/flags/ec.svg');
	}

	li[data-value="+20 (Egypt)"] a:after {
		background-image: url('../img/flags/eg.svg');
	}

	li[data-value="+503 (El Salvador)"] a:after {
		background-image: url('../img/flags/sv.svg');
	}

	li[data-value="+240 (Equatorial Guinea)"] a:after {
		background-image: url('../img/flags/gq.svg');
	}

	li[data-value="+291 (Eritrea)"] a:after {
		background-image: url('../img/flags/er.svg');
	}

	li[data-value="+372 (Estonia)"] a:after {
		background-image: url('../img/flags/ee.svg');
	}

	li[data-value="+251 (Ethiopia)"] a:after {
		background-image: url('../img/flags/et.svg');
	}

	li[data-value="+500 (Falkland Islands (Malvinas) )"] a:after {
		background-image: url('../img/flags/fk.svg');
	}

	li[data-value="+298 (Faroe Islands)"] a:after {
		background-image: url('../img/flags/fo.svg');
	}

	li[data-value="+679 (Fiji)"] a:after {
		background-image: url('../img/flags/fj.svg');
	}

	li[data-value="+358 (Finland)"] a:after {
		background-image: url('../img/flags/fi.svg');
	}

	li[data-value="+33 (France)"] a:after {
		background-image: url('../img/flags/fr.svg');
	}

	li[data-value="+594 (French Guiana)"] a:after {
		background-image: url('../img/flags/gf.svg');
	}

	li[data-value="+689 (French Polynesia)"] a:after {
		background-image: url('../img/flags/pf.svg');
	}

	li[data-value="+262 (French Southern Territories)"] a:after {
		background-image: url('../img/flags/tf.svg');
	}

	li[data-value="+241 (Gabon)"] a:after {
		background-image: url('../img/flags/ga.svg');
	}

	li[data-value="+220 (Gambia)"] a:after {
		background-image: url('../img/flags/gm.svg');
	}

	li[data-value="+995 (Georgia)"] a:after {
		background-image: url('../img/flags/ge.svg');
	}

	li[data-value="+49 (Germany)"] a:after {
		background-image: url('../img/flags/de.svg');
	}

	li[data-value="+233 (Ghana)"] a:after {
		background-image: url('../img/flags/gh.svg');
	}

	li[data-value="+350 (Gibraltar)"] a:after {
		background-image: url('../img/flags/gi.svg');
	}

	li[data-value="+30 (Greece)"] a:after {
		background-image: url('../img/flags/gr.svg');
	}

	li[data-value="+299 (Greenland)"] a:after {
		background-image: url('../img/flags/gl.svg');
	}

	li[data-value="+1473 (Grenada)"] a:after {
		background-image: url('../img/flags/gd.svg');
	}

	li[data-value="+590 (Guadeloupe)"] a:after {
		background-image: url('../img/flags/gp.svg');
	}

	li[data-value="+1671 (Guam)"] a:after {
		background-image: url('../img/flags/gu.svg');
	}

	li[data-value="+502 (Guatemala)"] a:after {
		background-image: url('../img/flags/gt.svg');
	}

	li[data-value="+44 (Guernsey)"] a:after {
		background-image: url('../img/flags/gg.svg');
	}

	li[data-value="+224 (Guinea)"] a:after {
		background-image: url('../img/flags/gn.svg');
	}

	li[data-value="+245 (Guinea-Bissau)"] a:after {
		background-image: url('../img/flags/gw.svg');
	}

	li[data-value="+592 (Guyana)"] a:after {
		background-image: url('../img/flags/gy.svg');
	}

	li[data-value="+509 (Haiti)"] a:after {
		background-image: url('../img/flags/ht.svg');
	}

	li[data-value="+39 (Holy See (Vatican City State) )"] a:after {
		background-image: url('../img/flags/va.svg');
	}

	li[data-value="+504 (Honduras)"] a:after {
		background-image: url('../img/flags/hn.svg');
	}

	li[data-value="+852 (Hong Kong)"] a:after {
		background-image: url('../img/flags/hk.svg');
	}

	li[data-value="+36 (Hungary)"] a:after {
		background-image: url('../img/flags/hu.svg');
	}

	li[data-value="+354 (Iceland)"] a:after {
		background-image: url('../img/flags/is.svg');
	}

	li[data-value="+91 (India)"] a:after {
		background-image: url('../img/flags/in.svg');
	}

	li[data-value="+62 (Indonesia)"] a:after {
		background-image: url('../img/flags/id.svg');
	}

	li[data-value="+98 (Iran, Islamic Republic of)"] a:after {
		background-image: url('../img/flags/ir.svg');
	}

	li[data-value="+964 (Iraq)"] a:after {
		background-image: url('../img/flags/iq.svg');
	}

	li[data-value="+353 (Ireland)"] a:after {
		background-image: url('../img/flags/ie.svg');
	}

	li[data-value="+44 (Isle of Man)"] a:after {
		background-image: url('../img/flags/im.svg');
	}

	li[data-value="+972 (Israel)"] a:after {
		background-image: url('../img/flags/il.svg');
	}

	li[data-value="+39 (Italy)"] a:after {
		background-image: url('../img/flags/it.svg');
	}

	li[data-value="+1876 (Jamaica)"] a:after {
		background-image: url('../img/flags/jm.svg');
	}

	li[data-value="+81 (Japan)"] a:after {
		background-image: url('../img/flags/jp.svg');
	}

	li[data-value="+44 (Jersey)"] a:after {
		background-image: url('../img/flags/je.svg');
	}

	li[data-value="+962 (Jordan)"] a:after {
		background-image: url('../img/flags/jo.svg');
	}

	li[data-value="+7 (Kazakhstan)"] a:after {
		background-image: url('../img/flags/kz.svg');
	}

	li[data-value="+254 (Kenya)"] a:after {
		background-image: url('../img/flags/ke.svg');
	}

	li[data-value="+686 (Kiribati)"] a:after {
		background-image: url('../img/flags/ki.svg');
	}

	li[data-value="+850 (Korea, Democratic Peoples Republic of)"] a:after {
		background-image: url('../img/flags/kp.svg');
	}

	li[data-value="+82 (Korea, Republic of)"] a:after {
		background-image: url('../img/flags/kr.svg');
	}

	li[data-value="+383 (Kosovo)"] a:after {
		background-image: url('../img/flags/xk.svg');
	}

	li[data-value="+965 (Kuwait)"] a:after {
		background-image: url('../img/flags/kw.svg');
	}

	li[data-value="+996 (Kyrgyzstan)"] a:after {
		background-image: url('../img/flags/kg.svg');
	}

	li[data-value="+856 (Lao Peoples Democratic Republic)"] a:after {
		background-image: url('../img/flags/la.svg');
	}

	li[data-value="+371 (Latvia)"] a:after {
		background-image: url('../img/flags/lv.svg');
	}

	li[data-value="+961 (Lebanon)"] a:after {
		background-image: url('../img/flags/lb.svg');
	}

	li[data-value="+266 (Lesotho)"] a:after {
		background-image: url('../img/flags/ls.svg');
	}

	li[data-value="+231 (Liberia)"] a:after {
		background-image: url('../img/flags/lr.svg');
	}

	li[data-value="+218 (Libyan Arab Jamahiriya)"] a:after {
		background-image: url('../img/flags/ly.svg');
	}

	li[data-value="+423 (Liechtenstein)"] a:after {
		background-image: url('../img/flags/li.svg');
	}

	li[data-value="+370 (Lithuania)"] a:after {
		background-image: url('../img/flags/lt.svg');
	}

	li[data-value="+352 (Luxembourg)"] a:after {
		background-image: url('../img/flags/lu.svg');
	}

	li[data-value="+853 (Macao)"] a:after {
		background-image: url('../img/flags/mo.svg');
	}

	li[data-value="+389 (Macedonia, the Former Yugoslav Republic of)"] a:after {
		background-image: url('../img/flags/mk.svg');
	}

	li[data-value="+261 (Madagascar)"] a:after {
		background-image: url('../img/flags/mg.svg');
	}

	li[data-value="+265 (Malawi)"] a:after {
		background-image: url('../img/flags/mw.svg');
	}

	li[data-value="+60 (Malaysia)"] a:after {
		background-image: url('../img/flags/my.svg');
	}

	li[data-value="+960 (Maldives)"] a:after {
		background-image: url('../img/flags/mv.svg');
	}

	li[data-value="+223 (Mali)"] a:after {
		background-image: url('../img/flags/ml.svg');
	}

	li[data-value="+356 (Malta)"] a:after {
		background-image: url('../img/flags/mt.svg');
	}

	li[data-value="+692 (Marshall Islands)"] a:after {
		background-image: url('../img/flags/mh.svg');
	}

	li[data-value="+596 (Martinique)"] a:after {
		background-image: url('../img/flags/mq.svg');
	}

	li[data-value="+222 (Mauritania)"] a:after {
		background-image: url('../img/flags/mr.svg');
	}

	li[data-value="+230 (Mauritius)"] a:after {
		background-image: url('../img/flags/mu.svg');
	}

	li[data-value="+262 (Mayotte)"] a:after {
		background-image: url('../img/flags/yt.svg');
	}

	li[data-value="+52 (Mexico)"] a:after {
		background-image: url('../img/flags/mx.svg');
	}

	li[data-value="+691 (Micronesia, Federated States of)"] a:after {
		background-image: url('../img/flags/fm.svg');
	}

	li[data-value="+373 (Moldova, Republic of)"] a:after {
		background-image: url('../img/flags/md.svg');
	}

	li[data-value="+377 (Monaco)"] a:after {
		background-image: url('../img/flags/mc.svg');
	}

	li[data-value="+976 (Mongolia)"] a:after {
		background-image: url('../img/flags/mn.svg');
	}

	li[data-value="+382 (Montenegro)"] a:after {
		background-image: url('../img/flags/me.svg');
	}

	li[data-value="+1664 (Montserrat)"] a:after {
		background-image: url('../img/flags/ms.svg');
	}

	li[data-value="+212 (Morocco)"] a:after {
		background-image: url('../img/flags/ma.svg');
	}

	li[data-value="+258 (Mozambique)"] a:after {
		background-image: url('../img/flags/mz.svg');
	}

	li[data-value="+95 (Myanmar)"] a:after {
		background-image: url('../img/flags/mm.svg');
	}
	li[data-value="+264 (Namibia)"] a:after {
		background-image: url('../img/flags/na.svg');
	}
	li[data-value="+674 (Nauru)"] a:after {
		background-image: url('../img/flags/nru.svg');
	}
	li[data-value="+977 (Nepal)"] a:after {
		background-image: url('../img/flags/npl.svg');
	}
	li[data-value="+31 (Netherlands)"] a:after {
		background-image: url('../img/flags/nld.svg');
	}
	li[data-value="+599 (Netherlands Antilles)"] a:after {
		background-image: url('../img/flags/ant.svg');
	}
	li[data-value="+687 (New Caledonia)"] a:after {
		background-image: url('../img/flags/ncl.svg');
	}
	li[data-value="+64 (New Zealand)"] a:after {
		background-image: url('../img/flags/nzl.svg');
	}
	li[data-value="+505 (Nicaragua)"] a:after {
		background-image: url('../img/flags/nic.svg');
	}
	li[data-value="+227 (Niger)"] a:after {
		background-image: url('../img/flags/ne.svg');
	}
	li[data-value="+234 (Nigeria)"] a:after {
		background-image: url('../img/flags/ng.svg');
	}
	li[data-value="+683 (Niue)"] a:after {
		background-image: url('../img/flags/nu.svg');
	}
	li[data-value="+672 (Norfolk Island)"] a:after {
		background-image: url('../img/flags/nf.svg');
	}
	li[data-value="+1670 (Northern Mariana Islands)"] a:after {
		background-image: url('../img/flags/mp.svg');
	}
	li[data-value="+47 (Norway)"] a:after {
		background-image: url('../img/flags/no.svg');
	}
	li[data-value="+968 (Oman)"] a:after {
		background-image: url('../img/flags/om.svg');
	}
	li[data-value="+92 (Pakistan)"] a:after {
		background-image: url('../img/flags/pk.svg');
	}
	li[data-value="+680 (Palau)"] a:after {
		background-image: url('../img/flags/pw.svg');
	}
	li[data-value="+970 (Palestinian Territory, Occupied)"] a:after {
		background-image: url('../img/flags/ps.svg');
	}
	li[data-value="+507 (Panama)"] a:after {
		background-image: url('../img/flags/pa.svg');
	}
	li[data-value="+675 (Papua New Guinea)"] a:after {
		background-image: url('../img/flags/pg.svg');
	}
	li[data-value="+595 (Paraguay)"] a:after {
		background-image: url('../img/flags/py.svg');
	}
	li[data-value="+51 (Peru)"] a:after {
		background-image: url('../img/flags/pe.svg');
	}
	li[data-value="+63 (Philippines)"] a:after {
		background-image: url('../img/flags/ph.svg');
	}
	li[data-value="+64 (Pitcairn)"] a:after {
		background-image: url('../img/flags/pn.svg');
	}
	li[data-value="+48 (Poland)"] a:after {
		background-image: url('../img/flags/pl.svg');
	}
	li[data-value="+351 (Portugal)"] a:after {
		background-image: url('../img/flags/pt.svg');
	}
	li[data-value="+1787 (Puerto Rico)"] a:after {
		background-image: url('../img/flags/pr.svg');
	}
	li[data-value="+974 (Qatar)"] a:after {
		background-image: url('../img/flags/qa.svg');
	}
	li[data-value="+262 (Reunion)"] a:after {
		background-image: url('../img/flags/re.svg');
	}
	li[data-value="+40 (Romania)"] a:after {
		background-image: url('../img/flags/ro.svg');
	}
	li[data-value="+7 (Russian Federation)"] a:after {
		background-image: url('../img/flags/ru.svg');
	}
	li[data-value="+250 (Rwanda)"] a:after {
		background-image: url('../img/flags/rw.svg');
	}
	li[data-value="+590 (Saint Barthelemy)"] a:after {
		background-image: url('../img/flags/bl.svg');
	}
	li[data-value="+290 (Saint Helena)"] a:after {
		background-image: url('../img/flags/sh.svg');
	}
	li[data-value="+1869 (Saint Kitts and Nevis)"] a:after {
		background-image: url('../img/flags/kn.svg');
	}
	li[data-value="+1758 (Saint Lucia)"] a:after {
		background-image: url('../img/flags/lc.svg');
	}
	li[data-value="+590 (Saint Martin)"] a:after {
		background-image: url('../img/flags/mf.svg');
	}
	li[data-value="+508 (Saint Pierre and Miquelon)"] a:after {
		background-image: url('../img/flags/pm.svg');
	}
	li[data-value="+1784 (Saint Vincent and the Grenadines)"] a:after {
		background-image: url('../img/flags/vc.svg');
	}
	li[data-value="+684 (Samoa)"] a:after {
		background-image: url('../img/flags/ws.svg');
	}
	li[data-value="+378 (San Marino)"] a:after {
		background-image: url('../img/flags/sm.svg');
	}
	li[data-value="+239 (Sao Tome and Principe)"] a:after {
		background-image: url('../img/flags/st.svg');
	}
	li[data-value="+966 (Saudi Arabia)"] a:after {
		background-image: url('../img/flags/sa.svg');
	}
	li[data-value="+221 (Senegal)"] a:after {
		background-image: url('../img/flags/sn.svg');
	}
	li[data-value="+381 (Serbia)"] a:after {
		background-image: url('../img/flags/rs.svg');
	}
	li[data-value="+381 (Serbia and Montenegro)"] a:after {
		background-image: url('../img/flags/cs.svg');
	}
	li[data-value="+248 (Seychelles)"] a:after {
		background-image: url('../img/flags/sc.svg');
	}
	li[data-value="+232 (Sierra Leone)"] a:after {
		background-image: url('../img/flags/sl.svg');
	}
	li[data-value="+65 (Singapore)"] a:after {
		background-image: url('../img/flags/sg.svg');
	}
	li[data-value="+721 (Sint Maarten)"] a:after {
		background-image: url('../img/flags/sx.svg');
	}
	li[data-value="+421 (Slovakia)"] a:after {
		background-image: url('../img/flags/sk.svg');
	}
	li[data-value="+386 (Slovenia)"] a:after {
		background-image: url('../img/flags/si.svg');
	}
	li[data-value="+677 (Solomon Islands)"] a:after {
		background-image: url('../img/flags/sb.svg');
	}
	li[data-value="+252 (Somalia)"] a:after {
		background-image: url('../img/flags/so.svg');
	}
	li[data-value="+27 (South Africa)"] a:after {
		background-image: url('../img/flags/za.svg');
	}
	li[data-value="+500 (South Georgia and the South Sandwich Islands)"] a:after {
		background-image: url('../img/flags/gs.svg');
	}
	li[data-value="+211 (South Sudan)"] a:after {
		background-image: url('../img/flags/ss.svg');
	}
	li[data-value="+34 (Spain)"] a:after {
		background-image: url('../img/flags/es.svg');
	}
	li[data-value="+94 (Sri Lanka)"] a:after {
		background-image: url('../img/flags/lk.svg');
	}
	li[data-value="+249 (Sudan)"] a:after {
		background-image: url('../img/flags/sd.svg');
	}
	li[data-value="+597 (Suriname)"] a:after {
		background-image: url('../img/flags/sr.svg');
	}
	li[data-value="+47 (Svalbard and Jan Mayen)"] a:after {
		background-image: url('../img/flags/sj.svg');
	}
	li[data-value="+268 (Swaziland)"] a:after {
		background-image: url('../img/flags/sz.svg');
	}
	li[data-value="+46 (Sweden)"] a:after {
		background-image: url('../img/flags/se.svg');
	}
	li[data-value="+41 (Switzerland)"] a:after {
		background-image: url('../img/flags/ch.svg');
	}
	li[data-value="+963 (Syrian Arab Republic)"] a:after {
		background-image: url('../img/flags/sy.svg');
	}
	li[data-value="+886 (Taiwan, Province of China)"] a:after {
		background-image: url('../img/flags/tw.svg');
	}
	li[data-value="+992 (Tajikistan)"] a:after {
		background-image: url('../img/flags/tj.svg');
	}
	li[data-value="+255 (Tanzania, United Republic of)"] a:after {
		background-image: url('../img/flags/tz.svg');
	}
	li[data-value="+66 (Thailand)"] a:after {
		background-image: url('../img/flags/th.svg');
	}
	li[data-value="+670 (Timor-Leste)"] a:after {
		background-image: url('../img/flags/tl.svg');
	}
	li[data-value="+228 (Togo)"] a:after {
		background-image: url('../img/flags/tg.svg');
	}
	li[data-value="+690 (Tokelau)"] a:after {
		background-image: url('../img/flags/tk.svg');
	}
	li[data-value="+676 (Tonga)"] a:after {
		background-image: url('../img/flags/to.svg');
	}
	li[data-value="+1868 (Trinidad and Tobago)"] a:after {
		background-image: url('../img/flags/tt.svg');
	}
	li[data-value="+216 (Tunisia)"] a:after {
		background-image: url('../img/flags/tn.svg');
	}
	li[data-value="+90 (Turkey)"] a:after {
		background-image: url('../img/flags/tr.svg');
	}
	li[data-value="+7370 (Turkmenistan)"] a:after {
		background-image: url('../img/flags/tm.svg');
	}
	li[data-value="+1649 (Turks and Caicos Islands)"] a:after {
		background-image: url('../img/flags/tc.svg');
	}
	li[data-value="+688 (Tuvalu)"] a:after {
		background-image: url('../img/flags/tv.svg');
	}
	li[data-value="+256 (Uganda)"] a:after {
		background-image: url('../img/flags/ug.svg');
	}
	li[data-value="+380 (Ukraine)"] a:after {
		background-image: url('../img/flags/ua.svg');
	}
	li[data-value="+971 (United Arab Emirates)"] a:after {
		background-image: url('../img/flags/ae.svg');
	}
	li[data-value="+44 (United Kingdom)"] a:after {
		background-image: url('../img/flags/gb.svg');
	}
	li[data-value="+1 (United States)"] a:after {
		background-image: url('../img/flags/us.svg');
	}
	li[data-value="+1 (United States Minor Outlying Islands)"] a:after {
		background-image: url('../img/flags/um.svg');
	}
	li[data-value="+598 (Uruguay)"] a:after {
		background-image: url('../img/flags/uy.svg');
	}
	li[data-value="+998 (Uzbekistan)"] a:after {
		background-image: url('../img/flags/uz.svg');
	}
	li[data-value="+678 (Vanuatu)"] a:after {
		background-image: url('../img/flags/vu.svg');
	}
	li[data-value="+58 (Venezuela)"] a:after {
		background-image: url('../img/flags/ve.svg');
	}
	li[data-value="+84 (Viet Nam)"] a:after {
		background-image: url('../img/flags/vn.svg');
	}
	li[data-value="+1284 (Virgin Islands, British)"] a:after {
		background-image: url('../img/flags/vg.svg');
	}
	li[data-value="+1340 (Virgin Islands, U.s.)"] a:after {
		background-image: url('../img/flags/vi.svg');
	}
	li[data-value="+681 (Wallis and Futuna)"] a:after {
		background-image: url('../img/flags/wf.svg');
	}
	li[data-value="+212 (Western Sahara)"] a:after {
		background-image: url('../img/flags/eh.svg');
	}
	li[data-value="+967 (Yemen)"] a:after {
		background-image: url('../img/flags/ye.svg');
	}
	li[data-value="+260 (Zambia)"] a:after {
		background-image: url('../img/flags/zm.svg');
	}
	li[data-value="+263 (Zimbabwe)"] a:after {
		background-image: url('../img/flags/zw.svg');
	}
}