/************************************************************************************
FOOTER
*************************************************************************************/

.footer {
	color: #fff;
	background: $blue;
	overflow: hidden;
	position: relative;

  .head {
  	@include rem(font-size, 18px);
  	font-weight: 500;
  	text-transform: uppercase;
  }

  .f_1 {
  	@include rem(padding-bottom, 100px);
  	position: relative;
  }

  .f_2 {
  	//@include rem(padding-bottom, 100px);
  }

  .foot-item {
  	@include rem(padding, 20px 0);
  }

  .foot-item-info {
  	display: flex;
  	flex-direction: column;
  	justify-content: space-between;

  	.contact-info {
  		@include rem(margin-bottom, 200px);
  	}
  }

  .foot-newsletter {
  	padding-left: 40px;
  }

  .foot-links {
  	padding-left: 40px;
  }

  .europe {
		position: absolute;
		@include rem(max-width, 670px);
		@include rem(right, -240px);
		top: 0;
	}

	.logo-foot {
		@include rem(max-width, 142px);
	}

	.footer-inner {
		position: relative;
	}

  a {
  	color: rgba(255,255,255,.7);
  	text-decoration: none;
  	@include transition(all, .3);

  	&:hover {
  		color: #fff;
  	}
  }

  ul {
  	li {
  		padding: 0;

			a {
				display: inline-flex;
				align-items: center;
			}

			.icon-new-window {
				margin-left: 10px;
				@include rem(width, 10px);
				@include rem(height, 10px);
				color: #59B0A3;
				position: relative;
				top: -5px;
			}
  	}
  }
}

.supfooter-bar {
	@include rem(font-size, 14px);
	text-transform: uppercase;

	.inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@include rem(padding, 20px 0);
	}
}

.footer-bar {
	@include rem(font-size, 14px);
	text-transform: uppercase;

	.inner {
		display: flex;
		justify-content: center;
		border-top: 1px solid rgba(255,255,255,.4);
		@include rem(padding, 20px 0);

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			flex-wrap: wrap;
			margin: 0 -15px;

			li {
				padding: 0 15px;
				margin: 0;
			}
		}
	}
}

.contact-list-foot {
	@include rem(font-size, 28px);

	ul {
		li {
			font-weight: 500;
			line-height: 1.3em;
			@include rem(margin-bottom, 10px);
		}
	}

	a {
		color: $green;
	}
}

.gff {
	max-width: 225px;
	position: absolute;
	right: 0;
	@include rem(bottom, 35px);
	z-index: 20;
}

.social-nav {
	display: flex;
	align-items: center;

	ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 -10px;

		li {
			padding: 0 10px;
			margin: 0;

			a {
				@include rem(width, 28px);
				@include rem(height, 28px);
				@include rem(flex, 0 0 28px);
				color: #fff;
				@include transition(all, .3);

				&:hover {
					color: $green;
				}

				.icon {
					@include rem(width, 28px);
					@include rem(height, 28px);
					@include rem(flex, 0 0 28px);
				}
			}
		}
	}

	&.alt {
		ul {
			margin: 0 -5px;

			li {
				padding: 0 5px;

				a {
					border-radius: 50%;
					@include rem(width, 40px);
					@include rem(height, 40px);
					@include rem(flex, 40px);
					display: inline-flex;
					align-items: center;
					justify-content: center;
					background: #59B0A3;
					@include transition(all, .3);

					.icon {
						@include rem(width, 22px);
						@include rem(height, 22px);
						@include rem(flex, 22px);
					}

					&:hover {
						background: #09304C;
						color: #fff;
					}
				}
			}
		}
	}
}

.address-list-foot {
	@include rem(font-size, 18px);
	@include rem(margin-bottom, 30px);
}

.lng-link {
	path {
		fill: #59B0A3;
		@include transition(all, .3);
	}

	&:hover {
		path {
			fill: #fff;
		}
	}
}