/**
 * Colors
 */
$black: #000;
$white: #fff;

$green: #59B0A3;
$blue: #09304C;
$orange: #F2A921;

/**
/* Font weight variables
 */
$font-light: 100;
$font-normal: 300;
$font-medium: 500;
$font-semibold: 700;
$font-bold: 900;