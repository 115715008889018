/************************************************************************************
HEADER
*************************************************************************************/

.header {
  //position: fixed;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  .h_0 {
    background: url('../img/header-line.svg') center bottom no-repeat;
    border-bottom: 1px solid transparent;
    //position: relative;
    //z-index: 130;
    display: flex;
    align-items: center;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //position: relative;
      @include rem(height, 70px);
    }
  }

  .h_1 {
    //position: relative;
    //z-index: 90;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
  }

  .h_2 {
    //position: relative;
    //z-index: 90;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @include rem(padding, 10px 0);
    }
  }

  &.alt {
    .h_0 {
      border-bottom: 1px solid $green;
      background: none;
    }

    .h_1 {
      display: none;
    }

    .nav-btn {
      position: relative;
      top: 0;
      display: flex;
      align-items: center;
      @include rem(margin-left, 110px);

      .txt {
        @include rem(left, -60px);
        @include rem(top, 10px);
        display: inline-block;
        bottom: auto;
        right: auto;
      }
    }
  }

  &.zi-20 {
    .btn-flip, .header-nav {
      position: relative;
      z-index: 100;
    }

    .breadcrumbs {
      position: relative;
      z-index: 40;
    }
  }

  &.hp {
    //z-index: 150;

    .header-nav {
      z-index: 150;
    }

    .header-r {
      z-index: 150;
    }

    .h_0 {
      position: relative;
      z-index: 150;
    }

    .h_1 {
      position: relative;
      z-index: 140;
    }

    .nav-btn {
      right: 15px;
    }
  }

  .cta-set {
    display: flex;
    align-items: center;
  }
}

.breadcrumbs {
  @include rem(font-size, 14px);
  color: #fff;
  @include rem(padding, 15px 0 0 0);

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .pipe {
    @include rem(padding, 0 10px);
  }
}

.bg-video {
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  @include translate(-50%,-50%);
  object-fit: cover;
  //opacity: .35;
}

.search-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: block;
  z-index: 130;
  background: $blue;
  @include translate(0,-100%);
  @include transition(all, .5);

  .inner {
    //@include rem(padding, 30px 0);
    display: flex;
    align-items: center;
    width: 100%;
    @include rem(height, 71px);

    & > * {
      width: 100%;
    }
  }

  form {
    position: relative;
  }
}

body.search-bar-active {
  .header {
    z-index: 90;
  }

  .search-bar {
    display: block;
    @include translate(0,0);
    z-index: 160;
  }
}

.logo {
  position: absolute;
  left: 50%;
  top: 15px;
  @include translate(-50%,0);
  @include rem(max-width, 191px);
}

.logo-symbol {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  @include rem(margin-right, 45px);

  .txt {
    @include rem(padding-left, 25px);

    &.first {
      padding-left: 0;
    }
  }
}

.header-nav {
  display: flex;
  align-items: center;

  ul {
    display: flex;
    @include rem(margin, 0 -20px);

    li {
      margin: 0;
      @include rem(padding, 0 20px);
      white-space: nowrap;

      a {
        text-decoration: none;
        @include transition(all, .3);

        &:hover {
          color: #fff;
        }
      }

      &.active {
        a {
          color: #fff;
        }
      }
    }
  }
}

.header-r {
  display: inline-flex;
  align-items: center;

  .cta-set {
    order: 1;
  }

  .btn-flip, .btn {
    @include rem(margin-left, 30px);
    @include rem(height, 47px);
    order: 1;
    @include transition(all, .3);

    &:before {
      white-space: nowrap;
      @include rem(height, 47px);
      @include rem(padding, 0 15px);
      background: #fff;
      color: $blue;
    }

    &:after {
      white-space: nowrap;
      @include rem(height, 47px);
      background: $green;
      color: #fff;
    }
  }

  .btn-taste {
    margin-left: 15px;
  }

  .btn-nav {
    display: inline-flex;
    align-items: center;
    order: 1;
  }
}

.search-nav {
  position: relative;
  z-index: 130;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include rem(width, 35px);
    @include rem(height, 35px);
    color: $green;
    @include transition(all, .3);

    &:hover {
      color: #fff;
    }

    .icon {
      width: 17px;
      height: 17px;
      flex: 0 0 17px;
      //@include rem(width, 17px);
      //@include rem(height, 17px);
      //@include rem(flex, 0 0 17px);
    }
  }
}

.lng-nav {
  color: $green;
  font-weight: 500;
  position: relative;
  z-index: 130;
  @include rem(margin-left, 25px);

  &.active {
    ul {
      display: block;
      animation: showMenu 400ms ease-in-out forwards;
      transform-origin: top center;
    }
  }

  .current {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include rem(width, 35px);
    @include rem(height, 35px);
    @include rem(padding-right, 15px);
    border-radius: 50%;
    cursor: pointer;
    text-transform: uppercase;
    @include transition(all, .3);

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      @include rem(margin-top, -7px);
      @include rem(width, 8px);
      @include rem(height, 8px);
      @include rem(border-left, 1.8px solid $green);
      @include rem(border-bottom, 1.8px solid $green);
      @include rotate(-45deg);
      @include transition(all, .3);
    }

    &:hover {
      color: #fff;

      &:after {
        border-color: #fff;
      }
    }
  }

  /*
  ul {
    margin: 0;
    position: absolute;
    left: 0;
    @include rem(top, 35px);
    //display: none;

    animation: hideMenu 400ms ease-in-out forwards;
    transform-origin: top center;

    li {
      margin: 0;
      padding: 0;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include rem(width, 35px);
        @include rem(height, 35px);
        text-decoration: none;
        background: #fff;
        //border-radius: 50%;
      }
    }
  }
  */
}

.lng-bar {
  position: fixed;
  left: 50%;
  top: -10px;
  width: 100%;
  max-width: 300px;
  //height: 100%;
  z-index: 2005;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  background: #59B0A3;
  text-align: left;
  @include rem(padding, 70px 30px 30px 30px);
  @include translate(-50%,-100%);
  @include transition(all, .5);

  ul {
    li {
      //@include rem(margin, 10px 0);
      //border-bottom: 1px solid $green;
      border-bottom: 1px solid rgba(255,255,255,.5);

      &:last-child {
        border: none;
      }

      a {
        @include rem(font-size, 18px);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #fff;
        @include rem(padding, 20px 0);

        img {
          @include rem(margin-right, 15px);
        }
      }
    }
  }

  .close {
    position: absolute;
    @include rem(right, 30px);
    @include rem(top, 30px);
    text-decoration: none;
    @include rem(width, 30px);
    @include rem(height, 30px);
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    //background: $green;
    color: #fff;
    @include transition(all, .3);
    @include rotate(0deg);

    .icon {
      @include rem(width, 30px);
      @include rem(height, 30px);
    }

    &:hover {
      //background: $blue;
      @include rotate(180deg);
    }
  }

  &.active {
    @include translate(-50%,0);
    top: 0;
  }
}

.teaser-hp {
  display: flex;

  .teaser-img {
    flex: 0 0 100%;
    width: 100%;
    min-height: 100vh;
    //min-height: 990px;
    overflow: hidden;
    position: relative;
    z-index: 10;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
    }

    &.shaded {
      &:after {
        content: '';
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: rgba(9,48,76,.65);
      }
    }
  }

  .teaser-text {
    flex: 0 0 100%;
    width: 100%;
    margin-left: -100%;
    //height: 100vh;
    //min-height: 990px;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
    z-index: 20;
    @include rem(padding-top, 70px);
  }

  .teaser-content {
    @include rem(padding, 220px 0 320px 0);
  }

  .desc {
    max-width: 860px;
    @include rem(margin-bottom, 70px);

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn, .btn-flip {
    &:after {
      color: $blue;
      background: $white;
    }

    &:before {
      color: $blue;
      background: $green;
    }
  }

  h1 {
    max-width: 1300px;
  }
}

.teaser-alt {
  display: flex;
  background: $blue;
  color: #fff;
  display: flex;
  @include rem(padding-top, 160px);
  margin-bottom: 110px;

  h1 {
    @include rem(margin-bottom, 60px);
  }

  .teaser-img {
    position: relative;
    flex: 0 0 100%;
    position: relative;
    padding-left: 115px;
    display: flex;
    margin-bottom: -110px;

    .frame {
      width: 100%;
      flex: 0 0 100%;
      padding-left: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover !important;
      }
    }

    &.shaded {
      &:after {
        content: '';
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: rgba(9,48,76,.65);
      }
    }
  }

  .teaser-text {
    flex: 0 0 100%;
    width: 100%;
    margin-left: -100%;
    @include rem(padding-top, 80px);
    @include rem(padding-bottom, 80px);
    display: flex;

    .container, .row, .col-xl-6 {
      display: flex;
      flex-grow: 1;
    }

    .text {
      flex-grow: 1;
      max-width: 750px;
    }
  }

  .teaser-content {
    display: flex;
    flex-direction: column;
  }

  .desc {
    @include rem(margin-bottom, 60px);
    @include rem(max-width, 990px);
  }

  .btn-set {
    @include rem(margin-bottom, -80px);
  }

  .btn, .btn-flip {
    position: relative;
    @include rem(top, 35px);
    @include rem(margin-right, 15px);
    @include rem(margin-bottom, 10px);

    &:after {
      color: $blue;
      background: $white;
    }

    &:before {
      color: $blue;
      background: $green;
    }
  }

  .numbers {
    @include rem(margin-bottom, 20px);
    max-width: 680px;

    .item-list {
      @include rem(margin, 0 -60px);
    }

    .item {
      border-left: 1px solid rgba(255,255,255,.2);
      @include rem(padding, 0 60px);
      @include rem(margin, 15px 0);

      .desc {
        @include rem(font-size, 16px);
        margin-bottom: 0;
        display: inline-flex;
        align-items: center;

        .icon {
          @include rem(flex, 0 0 38px);
          @include rem(width, 38px);
          @include rem(height, 38px);
          @include rem(margin-right, 15px);
        }
      }

      h2 {
        color: #fff;
      }

      &:first-child, &:nth-child(3) {
        border: none;
      }
    }
  }
}

.teaser-alt-2 {
  display: flex;
  background: $blue;
  color: #fff;
  display: flex;
  @include rem(padding-top, 160px);

  h1 {
    //@include rem(margin-bottom, 60px);
  }

  .teaser-text {
    flex: 0 0 100%;
    width: 100%;
    @include rem(padding-top, 0);
    @include rem(padding-bottom, 80px);
    display: flex;

    .text {
      flex-grow: 1;
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      &.wide {
        max-width: 950px;
      }

      &.widest {
        max-width: 1350px;
      }

      &.full {
        width: 100%;
        max-width: 100%;
      }

      &.a-l {
        text-align: left;
        justify-content: flex-start;
      }

      h1 {
        margin-bottom: 0;
      }
    }
  }

  .teaser-content {
    display: flex;
    flex-direction: column;
  }

  .desc {
    //@include rem(margin-bottom, 60px);
    @include rem(margin-top, 40px);
    @include rem(max-width, 990px);
  }

  .btn-set {
    @include rem(margin-bottom, -80px);
  }

  .btn, .btn-flip {
    position: relative;
    @include rem(top, 35px);

    &:after {
      color: $blue;
      background: $white;
    }

    &:before {
      color: $blue;
      background: $green;
    }
  }

  .cats {
    @include rem(padding-top, 50px);
  }
}

.teaser-poster {
  display: flex;
  position: relative;
  overflow: hidden;

  .teaser-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: rgba(9,48,76,.65);
    }
  }

  .teaser-text {
    width: 100%;
    text-align: center;
    @include rem(padding-top, 80px);
    position: relative;
    z-index: 30;

    .teaser-content {
      @include rem(padding-top, 200px);
      @include rem(padding-bottom, 160px);
      max-width: 990px;
      margin-left: auto;
      margin-right: auto;
      color: #fff;

      &.omega {
        padding-bottom: 0;
      }

      &.full {
        max-width: 100%;
      }

      .desc {
        max-width: 990px;
        margin-left: auto;
        margin-right: auto;
      }

      .btn-set {
        @include rem(margin-top, 40px);
      }
    }
  }
}

.nav-btn {
  vertical-align: top;
  @include rem(width, 46px);
  @include rem(height, 46px);
  @include rem(margin-left, 20px);
  text-align: center;
  position: relative;
  z-index: 2001;
  cursor: pointer;
  align-items: center;
  position: absolute;
  right: 0;
  @include rem(top, 110px);

  .txt {
    @include rem(font-size, 14px);
    @include rem(width, 46px);
    font-weight: 500;
    text-align: center;
    display: block;
    position: absolute;
    right: 0;
    bottom: -25px;
    color: #fff;
    text-transform: uppercase;
  }

  .lines {
    width: 100%;
    height: 4px;
    flex: 0 0 30px;
    display: block;
    position: relative;
    position: absolute;
    top: 50%;
    margin-top: -2px;
  }

  .line-1,
  .line-2,
  .line-3 {
    display: block;
    width: 100%;
    height: 3px;
    background: #fff;
    transition: 0.1s;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 0.28571rem center;
  }

  .line-1 {
    top: 0;
    transition: opacity 0.1s 0.1s ease;
  }

  .line-2 {
    top: 10px;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  .line-3 {
    top: -10px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }
}

.mobile-nav {
  color: #fff;
  width: 100%;
  position: fixed;
  //right: 0;
  top: 0;
  z-index: 90;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  margin-top: -10px;
  @include transition(all, .3);
  @include translate(0,-100%);

  .inner {
    background: $green;
    //max-width: 650px;
    width: 100%;
    max-width: 530px;
    float: right;
    @include rem(padding, 130px 0 230px 100px);
    position: relative;
    //z-index: 90;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 10000%;
      height: 100%;
      background: $green;
      z-index: -1;
    }
  }

  .head {
    @include rem(font-size, 18px);
    font-weight: 500;
    text-transform: uppercase;
  }

  &.active {
    @include translate(0,0);
  }

  .primary {
    //@include rem(padding-right, 50px);
    @include rem(padding-top, 30px);
    @include rem(margin-bottom, 100px);
    @include rem(max-width, 430px);
    border-top: 1px solid rgba(255,255,255,.5);

    ul {
      margin: 0;
      padding: 0;

      li {
        color: #fff;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        position: relative;

        a {
          @include rem(font-size, 18px);
          font-weight: 500;
          display: block;
          width: 100%;
          padding: 12px 0;
          color: #fff;
          text-transform: uppercase;
          border: none;
          position: relative;
          text-decoration: none;
          @include transition(all, .3);

          &:hover {
            color: $blue;
          }

          &.active {
            .more {
              &:after {
                @include rotate(135deg);
                margin-top: -5px;
              }
            }
          }

          &[target="_blank"] {
            &:after {
              content: '';
              width: 10px;
              height: 10px;
              display: inline-block;
              background: url('../img/blank.svg') 0 0 no-repeat;
              margin-left: 5px;
              position: relative;
              top: -8px;
            }
          }
        }

        ul {
          display: none;
          padding: 8px 0;

          &.active {
            display: block;
            @include rem(padding-left, 30px);

            li {
              a {
                text-transform: none;
                padding: 8px 0;
              }
            }
          }
        }

        .more {
          position: absolute;
          right: 0;
          top: 12px;
          width: 28px;
          height: 28px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          //background: #000;

          &:hover {
            &:after {
              border-color: $blue;
            }
          }

          &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            @include rem(width, 20px);
            @include rem(height, 20px);
            @include rotate(-45deg);
            transform-origin: 50% 50%;
            @include rem(margin-left, -10px);
            @include rem(margin-top, -18px);
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            @include transition(all, .3);

          }
        }
      }
    }
  }

  .secondary {
    @include rem(padding-right, 50px);
    @include rem(padding-bottom, 30px);
    @include rem(max-width, 430px);

    ul {
      margin: 0;
      padding: 0;

      li {
        color: #fff;
        margin: 0;
        padding: 0;
        background: none;
        border: none;

        a {
          @include rem(font-size, 16px);
          font-weight: 500;
          display: block;
          width: 100%;
          padding: 6px 0;
          color: #fff;
          border: none;
          position: relative;
          text-decoration: none;
          @include transition(all, .3);

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }

  .social-nav {
    a {
      color: rgba(255,255,255,.5);

      &:hover {
        color: rgba(255,255,255,1);
      }
    }
  }
}

.mobile-nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 80;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: none;
}

.mobile-nav-wrap {
  //display: none;
}

body.mobile-nav-opened {
  overflow: hidden;

  .mobile-nav {
    @include translate(0,0);
    margin-top: 0;
  }

  .nav-btn {
    .line-1 {
      transition: opacity 0.1s 0s ease;
      background: transparent;
      opacity: 0;
    }

    .line-2 {
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    .line-3 {
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }
  }

  .mobile-nav-overlay {
    display: block;
    animation: showOverlay .4s;
  }

  .search-nav {
    a {
      color: #fff;
    }
  }

  .lng-nav {
    .current {
      color: #fff;

      &:after {
        border-color: #fff;
      }
    }
  }

  .header-nav {
    pointer-events: none;
    opacity: 0.5;
  }

  .header-r {
    .btn-flip, .btn {
      z-index: 130;

      &:after {
        background: $blue;
      }
    }
  }

  .header {
    .h_1 {
      //opacity: 0.5;
    }

    .h_0 {
      border-color: transparent;
    }

    &.hp {
      .h_1 {
        z-index: 50;
      }
    }
  }
}

body.fix {
  .header {
    position: fixed;
    z-index: 150;
    left: 0;
    top: 0;
    width: 100%;

    .h_0 {
      border-color: $blue;
      border-color: transparent;
    }

    .h_2 {
      display: none;
    }

    &.hp {
      .h_0 {
        background: none;
      }

      .h_1 {
        display: none;
      }

      .nav-btn {
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        @include rem(margin-left, 80px);

        .txt {
          @include rem(left, -60px);
          @include rem(top, 10px);
          display: inline-block;
          bottom: auto;
          right: auto;
        }
      }
    }
  }

  &:before {
    content: '';
    position: fixed;
    z-index: 70;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    @include rem(height, 71px);
    background: $blue;
  }
}

@keyframes showSearch {
  0% {
    display: none;
    @include translate(0,-100%);
  }
  5% {
    display: block;
    @include translate(0,-100%);
  }
  100% {
    display: block;
    @include translate(0,0);
  }
}

@keyframes showOverlay {
  0% {
    display: none;
    background: rgba(0,0,0,0);
  }
  5% {
    display: block;
    background: rgba(0,0,0,0);
  }
  100% {
    display: block;
    background: rgba(0,0,0,0.5);
  }
}

@keyframes showMenu {
  0% {
    transform: rotateX(-90deg)
  }
  70% {
    transform: rotateX(20deg)
  }
  100% {
    transform: rotateX(0deg)
  }
}

@keyframes hideMenu {
  0% {
    transform: rotateX(0deg)
  }
  30% {
    transform: rotateX(20deg)
  }
  100% {
    transform: rotateX(-90deg)
  }
}

html[lang="cs-CZ"] {
  .lng-bar {
    ul {
      li.cs {
        display: none;
      }
    }
  }
}

html[lang="en-US"] {
  .lng-bar {
    ul {
      li.en {
        display: none;
      }
    }
  }
}

html[lang="pl-PL"] {
  .lng-bar {
    ul {
      li.pl {
        display: none;
      }
    }
  }
}