@charset "UTF-8";
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  bottom: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3FB8AF;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

.slbOverlay, .slbWrapOuter, .slbWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slbOverlay {
  overflow: hidden;
  z-index: 2000;
  background-color: #000;
  opacity: 0.7;
  -webkit-animation: slbOverlay 0.5s;
  -moz-animation: slbOverlay 0.5s;
  animation: slbOverlay 0.5s;
}

.slbWrapOuter {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2010;
}

.slbWrap {
  position: absolute;
  text-align: center;
}

.slbWrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.slbContentOuter {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0px auto;
  padding: 0 1em;
  box-sizing: border-box;
  z-index: 2020;
  text-align: left;
  max-width: 100%;
}

.slbContentEl .slbContentOuter {
  padding: 5em 1em;
}

.slbContent {
  position: relative;
}

.slbContentEl .slbContent {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  background-color: #fff;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.4);
}

.slbImageWrap {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  position: relative;
}

.slbImageWrap:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 5em;
  bottom: 5em;
  display: block;
  z-index: -1;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
  background-color: #FFF;
}

.slbDirectionNext .slbImageWrap {
  -webkit-animation: slbEnterNext 0.4s;
  -moz-animation: slbEnterNext 0.4s;
  animation: slbEnterNext 0.4s;
}

.slbDirectionPrev .slbImageWrap {
  -webkit-animation: slbEnterPrev 0.4s;
  -moz-animation: slbEnterPrev 0.4s;
  animation: slbEnterPrev 0.4s;
}

.slbImage {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 5em 0;
  margin: 0 auto;
}

.slbCaption {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-size: 1.4em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.71429em 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.slbCloseBtn, .slbArrow {
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
}

.slbCloseBtn::-moz-focus-inner, .slbArrow::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.slbCloseBtn:hover, .slbArrow:hover {
  opacity: 0.5;
}

.slbCloseBtn:active, .slbArrow:active {
  opacity: 0.8;
}

.slbCloseBtn {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  font-size: 3em;
  width: 1.66667em;
  height: 1.66667em;
  line-height: 1.66667em;
  position: absolute;
  right: -0.33333em;
  top: 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.slbLoading .slbCloseBtn {
  display: none;
}

.slbLoadingText {
  font-size: 1.4em;
  color: #fff;
  color: rgba(255, 255, 255, 0.9);
}

.slbArrows {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
}

.slbLoading .slbArrows {
  display: none;
}

.slbArrow {
  position: absolute;
  top: 50%;
  margin-top: -5em;
  width: 5em;
  height: 10em;
  opacity: 0.7;
  text-indent: -999em;
  overflow: hidden;
}

.slbArrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.8em 0 0 -0.8em;
  border: 0.8em solid transparent;
}

.slbArrow.next {
  right: 0;
}

.slbArrow.next:before {
  border-left-color: #fff;
}

.slbArrow.prev {
  left: 0;
}

.slbArrow.prev:before {
  border-right-color: #fff;
}

.slbIframeCont {
  width: 80em;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 5em 0;
}

.slbIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
  background: #000;
}

@-webkit-keyframes slbOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@-moz-keyframes slbOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes slbOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@-webkit-keyframes slbEnter {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -1em, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnter {
  from {
    opacity: 0;
    -moz-transform: translate3d(0, -1em, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnter {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -1em, 0);
    -moz-transform: translate3d(0, -1em, 0);
    -ms-transform: translate3d(0, -1em, 0);
    -o-transform: translate3d(0, -1em, 0);
    transform: translate3d(0, -1em, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slbEnterNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnterNext {
  from {
    opacity: 0;
    -moz-transform: translate3d(4em, 0, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnterNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(4em, 0, 0);
    -moz-transform: translate3d(4em, 0, 0);
    -ms-transform: translate3d(4em, 0, 0);
    -o-transform: translate3d(4em, 0, 0);
    transform: translate3d(4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slbEnterPrev {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnterPrev {
  from {
    opacity: 0;
    -moz-transform: translate3d(-4em, 0, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnterPrev {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-4em, 0, 0);
    -moz-transform: translate3d(-4em, 0, 0);
    -ms-transform: translate3d(-4em, 0, 0);
    -o-transform: translate3d(-4em, 0, 0);
    transform: translate3d(-4em, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

@media (min-width: 768px) {
  .form-search .combobox-container,
  .form-inline .combobox-container {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
  }
  .form-search .combobox-container .input-group-addon,
  .form-inline .combobox-container .input-group-addon {
    width: auto;
  }
}

.combobox-container .dropdown-toggle {
  justify-content: center;
}

.combobox-container .dropdown-toggle.custom-icon::after {
  content: none;
}

.combobox-container.combobox-selected .dropdown-toggle::after {
  content: none;
}

.combobox-container .utf-remove::after {
  content: "\00D7";
}

.combobox-container.combobox-selected .pulldown {
  display: none;
}

/* :not doesn't work in IE8 */
.combobox-container:not(.combobox-selected) .remove {
  display: none;
}

.typeahead-long {
  max-height: 300px;
  overflow-y: auto;
}

.control-group.error .combobox-container .add-on {
  color: #B94A48;
  border-color: #B94A48;
}

.control-group.error .combobox-container .caret {
  border-top-color: #B94A48;
}

.control-group.warning .combobox-container .add-on {
  color: #C09853;
  border-color: #C09853;
}

.control-group.warning .combobox-container .caret {
  border-top-color: #C09853;
}

.control-group.success .combobox-container .add-on {
  color: #468847;
  border-color: #468847;
}

.control-group.success .combobox-container .caret {
  border-top-color: #468847;
}

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1605px) {
  .container {
    max-width: 1545px;
  }
}

@media (min-width: 2050px) {
  .container {
    max-width: 1990px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1605px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

/**
 * Colors
 */
/**
/* Font weight variables
 */
/**
 * Font family
 */
/**
 * Border radius
 */
/**
 * Scale
 */
/**
 * Translate
 */
/**
 * Skew
 */
/**
 * Rotate
 */
/**
 * Transition
 */
/**
 * REM units
 */
/************************************************************************************
RESET
*************************************************************************************/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section, menu,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

:focus {
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

article, aside, figure, footer, header, hgroup, nav, section, img {
  display: block;
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
  *vertical-align: baseline;
}

label, input[type=button], input[type=submit], button {
  cursor: pointer;
  overflow: visible;
}

em, i {
  font-weight: inherit;
}

/************************************************************************************
GENERAL
*************************************************************************************/
body {
  font-family: Montserrat,sans-serif;
  font-size: 1rem;
  line-height: 1.75em;
  font-weight: 400;
  color: #09304C;
  letter-spacing: 0.01em;
  background: #fff;
  margin: 0;
  padding: 0;
  min-height: 100%;
}

body.modal-opened {
  overflow: hidden;
}

/* Box sizing */
*, *:after, *:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* Anchors */
a {
  color: #59B0A3;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

/* Heading */
h1, .h1 {
  font-family: Montserrat,sans-serif;
  font-size: 3.75rem;
  line-height: 1.16em;
  font-weight: 600;
  letter-spacing: 0em;
  color: #59B0A3;
  margin: 0 0 1.875rem 0;
}

h1.medium, .h1.medium {
  font-size: 2.8125rem;
}

h2, .h2 {
  font-family: Montserrat,sans-serif;
  font-size: 3.75rem;
  line-height: 1.1em;
  font-weight: 600;
  letter-spacing: 0;
  color: #59B0A3;
  margin: 0 0 0.625rem 0;
}

h2.big, .h2.big {
  font-size: 5rem;
  line-height: 1.3em;
}

h2.medium, .h2.medium {
  font-size: 2.375rem;
  line-height: 1.3em;
}

h3, .h3 {
  font-family: Montserrat,sans-serif;
  font-size: 1.75rem;
  line-height: 1.3em;
  font-weight: 600;
  color: #59B0A3;
  margin: 0 0 0.625rem 0;
}

h3.big, .h3.big {
  font-size: 2rem;
  line-height: 1.3em;
}

h3.medium, .h3.medium {
  font-size: 1.375rem;
  line-height: 1.3em;
}

h3.small, .h3.small {
  font-size: 1.125rem;
  line-height: 1.3em;
}

h3.alt, .h3.alt {
  color: #09304C;
}

h4, .h4 {
  font-family: Montserrat,sans-serif;
  font-size: 1.375rem;
  line-height: 1.3em;
  font-weight: 400;
  color: #59B0A3;
  margin: 0 0 0.625rem 0;
}

h5, .h5 {
  font-family: Montserrat,sans-serif;
  font-size: 1.25rem;
  line-height: 1.3em;
  font-weight: 400;
  color: #59B0A3;
  margin: 0 0 0.625rem 0;
}

h6, .h6 {
  font-family: Montserrat,sans-serif;
  font-size: 1.25rem;
  line-height: 1.3em;
  font-weight: 400;
  color: #59B0A3;
  margin: 0 0 0.625rem 0;
}

.suphead {
  font-size: 2rem;
  line-height: 1.3em;
  color: #59B0A3;
  font-weight: 400;
  text-transform: uppercase;
}

/* Paragraphs */
p {
  margin: 0 0 1.25rem 0;
}

address, cite {
  font-style: normal;
}

/* Lists */
ul {
  list-style: none;
  margin: 0 0 1.25rem 0;
  padding: 0;
}

ul li {
  padding: 0 0 0 1.25rem;
  margin: 0 0 0.3125rem 0;
}

ul.unstyled li {
  padding: 0;
  background: none;
}

ol {
  list-style: none;
  margin: 0 0 1.25rem 0;
  padding: 0;
  counter-reset: counter;
  margin-left: 0;
}

ol li {
  margin: 0 0 0.625rem 0;
  padding: 0 0 0 1.875rem;
  counter-increment: counter;
  position: relative;
}

ol li:before {
  content: counter(counter) ".";
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}

dl {
  margin: 0 0 1.25rem 0;
}

dt {
  font-weight: bold;
}

/* Various */
sup, sub {
  font-size: 60%;
  line-height: 0;
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
}

sup {
  top: -0.75em;
}

sub {
  bottom: -0.25em;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

hr.divider {
  border-top: 1px solid #09304C;
  margin: 3.125rem 0;
}

img {
  max-width: 100%;
  height: auto;
}

.bold, b, strong {
  font-weight: 600;
}

.italic, i, em {
  font-style: italic;
}

.nowrap, .nobr {
  white-space: nowrap !important;
}

/************************************************************************************
CONTENT
*************************************************************************************/
.content {
  padding: 0;
  overflow: hidden;
}

/* Block */
.block {
  padding: 9.375rem 0;
  position: relative;
}

.block.narrow {
  padding: 6.25rem 0;
}

.block.narrow-alpha {
  padding-top: 6.25rem;
}

.block.narrow-omega {
  padding-bottom: 6.25rem;
}

.block.alt {
  background: #E8E8E8;
}

.block.alt-2 {
  background: #09304C;
}

.block.alt-3 {
  background: #59B0A3;
}

.block.alpha {
  padding-top: 0 !important;
}

.block.omega {
  padding-bottom: 0 !important;
}

.block.block-oh {
  overflow: hidden;
}

.text-narrow {
  margin: 0 auto;
  max-width: 900px;
}

.anchor {
  position: absolute;
  left: 0;
  top: -4.375rem;
}

.spacer {
  height: 5rem;
}

.spacer.huge {
  height: 10rem;
}

.spacer.big {
  height: 6.25rem;
}

.spacer.medium {
  height: 3.75rem;
}

.spacer.small {
  height: 2.5rem;
}

/* Various */
.section-head {
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.section-head h1, .section-head h2, .section-head h3 {
  margin-bottom: 0;
}

.section-head .symbol {
  display: flex;
  justify-content: center;
}

.section-head .symbol img {
  margin-bottom: 1.25rem;
}

.section-head-l {
  margin-bottom: 2.5rem;
}

.section-head-l h1, .section-head-l h2, .section-head-l h3 {
  margin-bottom: 0;
}

.intro {
  max-width: 500px;
  margin: 0 auto 2.5rem auto;
  text-align: center;
}

.intro.wide {
  max-width: 46.875rem;
}

.intro.widest {
  max-width: 75rem;
}

.invert {
  color: #fff;
}

.invert h2, .invert h3 {
  color: #fff;
}

.invert .suphead {
  color: #fff;
}

.invert-2 {
  color: #59B0A3;
}

.invert-2 h2, .invert-2 h3 {
  color: #59B0A3;
}

.invert-2 .suphead {
  color: #59B0A3;
}

.invert-3 {
  color: #09304C;
}

.invert-3 h2, .invert-3 h3 {
  color: #09304C;
}

.invert-3 .suphead {
  color: #09304C;
}

.symbol-center {
  display: block;
  margin: 0 auto 1.875rem auto;
  max-width: 4.6875rem;
}

/* Pager */
.pagebar {
  margin: 0 0 2.5rem 0;
  text-align: center;
  position: relative;
  padding: 0 2.5rem;
}

.pagebar a,
.pagebar span.current {
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  margin: 2px;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #fff;
  background: #09304C;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  transition: 0.3s all;
}

.pagebar .first {
  width: auto;
  padding: 0;
  margin: 0;
  left: 0;
  top: 40px;
  border: none;
  text-decoration: underline;
  display: none !important;
}

.pagebar .first:hover {
  text-decoration: none;
  background: none;
  color: #00b4e7;
  border: none;
}

.pagebar .last {
  width: auto;
  padding: 0;
  margin: 0;
  right: 0;
  top: 40px;
  border: none;
  text-decoration: underline;
  display: none !important;
}

.pagebar .last:hover {
  text-decoration: none;
  background: none;
  color: #00b4e7;
  border: none;
}

.pagebar span.current {
  color: #fff;
  background: #59B0A3;
  border: none;
}

.pagebar .previouspostslink {
  position: absolute;
  left: 0;
  top: 2px;
  margin: 0;
}

.pagebar .nextpostslink {
  position: absolute;
  right: 0;
  top: 2px;
  margin: 0;
}

.pagebar .previouspostslink:hover,
.pagebar .nextpostslink:hover,
.pagebar a:hover {
  color: #fff;
  background: #59B0A3;
  border: none;
}

.pagebar .extend {
  display: none;
}

/* Gmap */
.gmap-wrap {
  position: relative;
}

.gmap-wrap .gmap {
  height: 600px;
  background: #e3e3e3;
}

.gmap-wrap img {
  max-width: inherit !important;
}

/*================================= Various Content =================================*/
.last {
  margin-bottom: 0 !important;
}

/* Buttons */
.btn {
  font-size: 0.875rem;
  font-family: Montserrat,sans-serif;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 0.05em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #59B0A3;
  color: #fff;
  padding: 0 1.5625rem;
  height: 3.9375rem;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  border: 2px solid #59B0A3;
  transition: 0.5s all;
}

.btn:hover {
  background-color: #09304C;
  border: 2px solid #09304C;
}

.btn.big {
  font-size: 1.125rem;
  text-transform: uppercase;
  height: 4.375rem;
  padding: 0 3.75rem;
}

.btn.alt {
  background: #09304C;
  border-color: #09304C;
}

.btn.alt:hover {
  background: #59B0A3;
  border-color: #59B0A3;
}

.btn.alt-2 {
  background: #59B0A3;
  border-color: #59B0A3;
}

.btn.alt-2:hover {
  background: #09304C;
  border-color: #09304C;
}

.btn.alt-3 {
  background: #59B0A3;
  border-color: #59B0A3;
}

.btn.alt-3:hover {
  background: #fff;
  border-color: #fff;
  color: #59B0A3;
}

.btn-flip {
  font-size: 0;
  font-family: Montserrat,sans-serif;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 0.05em;
  opacity: 1;
  outline: 0;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 3.9375rem;
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  text-indent: -80000px;
  text-transform: uppercase;
}

.btn-flip:hover:after {
  opacity: 1;
  transform: translateY(0) rotateX(0);
}

.btn-flip:hover:before {
  opacity: 0;
  transform: translateY(50%) rotateX(90deg);
}

.btn-flip:after {
  font-size: 0.875rem;
  line-height: 1.3em;
  font-weight: 600;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  color: #fff;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: 0.5s;
  height: 3.9375rem;
  padding: 0 2.5rem;
  position: absolute;
  background: #59B0A3;
  text-indent: 0px;
  content: attr(data-back);
  transform: translateY(-50%) rotateX(90deg);
}

.btn-flip:before {
  font-size: 0.875rem;
  line-height: 1.3em;
  font-weight: 600;
  left: 0;
  top: 0;
  opacity: 1;
  color: #fff;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 2.5rem;
  height: 3.9375rem;
  transition: 0.5s;
  position: relative;
  background: #09304C;
  text-indent: 0px;
  content: attr(data-front);
  transform: translateY(0) rotateX(0);
}

.btn-flip.big {
  font-size: 0;
  text-transform: uppercase;
  height: 4.375rem;
  padding: 0;
}

.btn-flip.big:after {
  font-size: 1.125rem;
  padding: 0 2.8125rem;
  height: 4.375rem;
}

.btn-flip.big:before {
  font-size: 1.125rem;
  padding: 0 2.8125rem;
  height: 4.375rem;
}

.btn-flip.alt:after {
  background: #09304C;
}

.btn-flip.alt:before {
  background: #59B0A3;
}

.btn-flip.alt-2:after {
  background: #09304C;
}

.btn-flip.alt-2:before {
  background: #fff;
  color: #59B0A3;
}

.btn-flip.alt-3:after {
  background: #fff;
  color: #59B0A3;
}

.btn-flip.alt-3:before {
  background: #59B0A3;
  color: #fff;
}

/* SVG icons */
.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  fill: currentColor;
}

/* Cookie Notice */
.cookie-notice {
  font-size: 14px;
  position: fixed;
  z-index: 10000;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.9);
  color: #CCC;
  text-align: center;
}

.cookie-button {
  display: inline-block;
  padding: 2px 10px;
  margin-left: 25px;
  background: #59B0A3;
  color: #fff;
  text-align: center;
}

.cookie-button:hover {
  background: #90ca16;
  color: #fff;
}

.cookie-test {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  width: 150px;
  padding: 25px 15px;
  background: rgba(50, 50, 50, 0.95);
  color: #CCC;
  text-align: center;
}

/* FY Apple! */
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.btn {
  -webkit-appearance: none;
}

/************************************************************************************
ENTRY
*************************************************************************************/
.entry {
  margin: 0 0 2.5rem 0;
}

.entry h2, .entry h3 {
  margin-top: 1.875rem;
}

.entry h2:first-child, .entry h3:first-child {
  margin-top: 0;
}

.entry ul li {
  position: relative;
}

.entry ul li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.75rem;
  width: 4px;
  height: 4px;
  display: block;
  background: #09304C;
}

.entry ul.unstyled li:before {
  content: none;
}

.entry ul.time-list li {
  padding-left: 0;
}

.entry ul.time-list li:before {
  content: none;
}

/************************************************************************************
TABLES
*************************************************************************************/
.entry table {
  width: 100%;
  margin: 0 0 1.875rem 0;
}

.entry table th {
  font-weight: 700;
  border-bottom: 2px solid #59B0A3;
  padding: 0.9375rem 1.25rem;
  color: #000;
  text-align: left;
  text-transform: uppercase;
}

.entry table th:first-child {
  padding-left: 0;
}

.entry table th:last-child {
  padding-right: 0;
}

.entry table td {
  border-bottom: 1px solid #09304C;
  padding: 0.9375rem 1.25rem;
}

.entry table td:first-child {
  padding-left: 0;
}

.entry table td:last-child {
  padding-right: 0;
}

/************************************************************************************
FORMS
*************************************************************************************/
form.std label {
  font-size: 1rem;
  line-height: 1.3em;
  color: #59B0A3;
  margin: 0 0 0.625rem 0;
  display: block;
}

form.std input.text {
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat,sans-serif;
  line-height: 1.3em;
  color: #59B0A3;
  border: 1px solid #59B0A3;
  padding: 0 1.5625rem;
  height: 4.1875rem;
  width: 100%;
}

form.std input.text.error {
  background: #fcf4f4;
  border-color: #be1e19;
}

form.std input.text.req {
  background-image: url("../img/req.svg");
  background-position: right center;
  background-repeat: no-repeat;
}

form.std input.text::placeholder {
  color: #59B0A3;
}

form.std input.text[readonly] {
  background: #f0f0f0;
  color: #72818c;
}

form.std textarea {
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat,sans-serif;
  line-height: 1.3em;
  color: #59B0A3;
  border: 1px solid #59B0A3;
  padding: 0.9375rem 1.5625rem;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 150px;
  min-height: 150px;
  max-height: 300px;
  display: block;
}

form.std textarea.req {
  background-image: url("../img/req.svg");
  background-position: right center;
  background-repeat: no-repeat;
}

form.std textarea::placeholder {
  color: #59B0A3;
}

form.std select {
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat,sans-serif;
  line-height: 1.3em;
  color: #59B0A3;
  border: 1px solid #59B0A3;
  padding: 0 1.5625rem;
  height: 4.1875rem;
  width: 100%;
  cursor: pointer;
  background-image: url("../img/select.svg");
  background-position: right center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

form.std option {
  font-size: 1.0625rem;
  line-height: 1.3em;
  padding: 0.125rem 0.625rem;
}

form.std .cell {
  margin-bottom: 1.25rem;
  position: relative;
  text-align: left;
}

form.std .toggle {
  position: relative;
  padding: 0 0 0 1.5625rem;
}

form.std .toggle label {
  display: inline-block;
  vertical-align: top;
  margin: 0;
}

form.std .toggle input {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0.125rem;
}

form.std .radio, form.std .checker {
  position: absolute;
  left: 0;
  top: 0;
}

form.std .checker {
  position: absolute;
  left: 0;
  top: 0;
  background: url("../img/checker.svg") 0 0 no-repeat;
  background-color: #fff;
  background-position: 0 -1.4375rem;
  background-size: 1.4375rem;
  transition: 0.5s all;
  border: 2px solid #fff;
  width: 1.625rem;
  height: 1.625rem;
  margin: 0;
  border-radius: 50%;
  cursor: pointer;
}

form.std .checker input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
}

form.std .checker.active {
  background-position: 0 0;
}

form.std .checker.has-error {
  border-color: #be1e19;
}

form.std .combobox-container {
  position: relative;
  text-decoration: none;
}

form.std .combobox-container input {
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat,sans-serif;
  line-height: 1.3em;
  color: #59B0A3;
  border: 1px solid #59B0A3;
  padding: 0 1.5625rem;
  height: 4.1875rem;
  width: 100%;
}

form.std .combobox-container .typeahead-long {
  width: 300px;
  border: 1px solid #59B0A3;
  border-top: none;
  position: absolute;
  z-index: 20;
  background: #fff;
}

form.std .combobox-container .dropdown-toggle {
  width: 55px;
  height: 100%;
  border: 1px solid #59B0A3;
  background-color: #fafafa;
  background-image: url("../img/select.svg");
  background-position: right center;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

form.std .combobox-container li {
  padding-top: 4px;
  padding-bottom: 4px;
}

form.std .combobox-container li:hover {
  background-color: #f1f1f1;
}

form.std .combobox-container a {
  text-decoration: none;
}

form.std .cell-jednotlivec {
  display: none;
}

form.std .cell-instituce {
  display: none;
}

form.std .cell-both {
  display: none;
}

form.form-nl {
  max-width: 380px;
  position: relative;
  z-index: 30;
}

form.form-nl .cell {
  display: flex;
}

form.form-nl input.text {
  height: 4.375rem;
  background: none;
  border: 1px solid #59B0A3;
  color: #fff;
}

form.form-nl input.text::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

form.form-nl .btn {
  flex: 0 0 4.375rem;
  height: 4.375rem;
  width: 4.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #59B0A3 url("../img/btn-arr.svg") 0 0 no-repeat;
  background-size: 4.375rem 8.75rem;
  text-indent: -800000px;
  border: none;
}

form.form-nl .btn:hover {
  background-color: #fff;
  border-color: #fff;
  color: #59B0A3;
  background-position: 0 -4.375rem;
}

form.form-nl .note {
  font-size: 0.875rem;
}

form.form-nl.centered {
  margin-left: auto;
  margin-right: auto;
}

form.form-nl .wpcf7-spinner {
  position: relative;
  top: 0.9375rem;
}

form.form-contact {
  margin: 0 auto;
  max-width: 965px;
}

form.form-contact.narrow {
  max-width: 740px;
}

form.form-contact textarea {
  height: 15rem;
}

form.form-contact .cell-investice {
  display: none;
}

form.form-contact .btn-set {
  text-align: right;
  padding-top: 2.1875rem;
}

form.form-contact .btn-set.center {
  text-align: center;
}

form.form-contact .btn, form.form-contact .btn-flip {
  padding: 0 2.8125rem;
}

form.form-contact .btn .icon-arr-r, form.form-contact .btn-flip .icon-arr-r {
  margin-left: 1.25rem;
}

form.form-contact .row-phone {
  flex-wrap: nowrap;
}

form.form-contact .row-phone > * {
  padding: 0 15px;
}

form.form-contact .row-phone > *.col-one {
  flex: 0 0 120px;
  max-width: 120px;
  margin-right: -1px;
  padding-right: 0;
}

form.form-contact .row-phone > *.col-one select {
  text-overflow: ellipsis;
  padding-left: 15px;
  padding-right: 35px;
}

form.form-contact .row-phone > *.col-two {
  padding-left: 0;
  flex-grow: 1;
}

form.form-contact.center .btn-set {
  text-align: center;
}

form.form-contact.left .btn-set {
  text-align: left;
}

form.form-filter .cell-set {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

form.form-filter .cell {
  padding: 0 8px;
}

form.form-filter legend {
  font-size: 1.125rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1.875rem;
}

form.form-filter label {
  font-size: 0.9375rem;
  font-weight: 400;
  color: #fff;
}

form.form-filter .toggle {
  color: #fff;
  font-weight: 600;
  background: #59B0A3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 0.9375rem 0.625rem 2.5rem;
  border-radius: 50px;
}

form.form-filter .checker {
  left: 0.5rem;
  top: 0.4375rem;
}

form.form-narrow {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

form.form-narrow .btn-set {
  display: flex;
  justify-content: center;
}

form.form-exit .btn-set {
  text-align: center;
}

#form-ajax-content {
  position: relative;
}

#form-ajax-content.loading:after {
  content: '';
  position: absolute;
  z-index: 90;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  background: url("../img/spinner.svg") center center no-repeat;
  background-size: cover !important;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#form-ajax-content > .inner {
  opacity: 1;
  transition: 0.3s all;
}

#form-ajax-content.loading > .inner {
  opacity: 0.4;
}

.custom-file-input {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 4.1875rem;
  cursor: pointer;
  border-radius: 4px;
}

.custom-file-input input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  cursor: pointer;
}

.custom-file-input .wpcf7-form-control-wrap {
  position: relative;
  height: 4.1875rem;
  width: 100%;
  display: block;
}

.custom-file-input .txt {
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat,sans-serif;
  line-height: 1.3em;
  color: #59B0A3;
  border: 1px solid #59B0A3;
  padding: 0 1.5625rem;
  height: 4.1875rem;
  line-height: 4.1875rem;
  background: #fff;
  background-repeat: no-repeat;
  margin-top: -4.1875rem;
  position: relative;
}

.custom-file-input .txt:after {
  content: 'Vybrat soubor';
  font-size: 0.875rem;
  position: absolute;
  height: 3.25rem;
  width: 8.125rem;
  top: 0.4375rem;
  right: 0.4375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #09304C;
  color: #fff;
  text-align: center;
}

/*================================= Contact form 7 =================================*/
/* Mesages & errors */
body .wpcf7 div.wpcf7-response-output {
  font-size: 1rem;
  line-height: 1.3em;
  padding: 0;
  background: #ebf5bc;
  border: 1px solid #add4b1;
  color: #59B0A3;
  padding: 0.9375rem 1.25rem;
  margin: 0 0 1.875rem 0;
}

body .wpcf7 div.wpcf7-response-output:empty {
  display: none;
}

body .wpcf7 div.wpcf7-response-output p {
  margin-bottom: 0;
}

body .wpcf7 form.failed div.wpcf7-response-output,
body .wpcf7 form.invalid div.wpcf7-response-output,
body .wpcf7 form.unaccepted div.wpcf7-response-output {
  border: 1px solid #f1c7d2;
  background: #fae7ea;
  color: #e5547a;
}

body .wpcf7-spinner {
  background-color: transparent;
  background-image: url("../img/spinner.svg");
  background-size: cover !important;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  margin: 0 -40px 0 0;
}

body .wpcf7-spinner:before {
  content: none;
}

body span.wpcf7-not-valid-tip {
  font-size: 13px;
  line-height: 1.3em;
  padding-top: 5px;
}

body span.wpcf7-list-item {
  margin: 0;
}

body .wpcf7-form-control-wrap {
  position: static;
  width: 100%;
}

body .response .success {
  font-size: 1rem;
  line-height: 1.3em;
  padding: 0;
  background: #ebf5bc;
  border: 1px solid #add4b1;
  color: #59B0A3;
  padding: 0.9375rem 1.25rem;
  margin: 0 0 1.875rem 0;
}

body .response .fail {
  font-size: 1rem;
  line-height: 1.3em;
  padding: 0;
  padding: 0.9375rem 1.25rem;
  margin: 0 0 1.875rem 0;
  border: 1px solid #f1c7d2;
  background: #fae7ea;
  color: #e5547a;
}

/************************************************************************************
TABS
*************************************************************************************/
.tabs {
  position: relative;
}

.tabs .tab-nav ul {
  margin: 0 -1.5625rem;
  padding: 0;
  display: flex;
}

.tabs .tab-nav li {
  list-style-type: none;
  padding: 0 1.5625rem;
  background: none;
  margin: 0;
}

.tabs .tab-nav li a {
  font-size: 1.1875rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: 0;
  padding: 0 2.1875rem;
  height: 4.75rem;
  background: transparent;
  border-bottom: 2px solid #09304C;
  color: #09304C;
  text-align: center;
}

.tabs .tab-nav li a:hover {
  background: #fff;
  color: #09304C;
}

.tabs .tab-nav li a.selected {
  background: #09304C;
  color: #fff;
}

.tabs .tab-content .tab {
  width: 100%;
  padding: 5.625rem 0 0 0;
  display: none;
}

.tabs .tab-content .tab.selected {
  position: static !important;
  display: block;
}

.tabs.tabs-expansion .tab-nav {
  margin-bottom: 3.75rem;
}

.tabs.tabs-expansion .tab-nav > ul > li {
  flex: 1 1 0px;
}

.tabs.tabs-expansion .tab-nav > ul > li > a {
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.3em;
  padding: 0 10px;
  height: 4.0625rem;
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.22);
  position: relative;
  transition: 0.3s all;
}

.tabs.tabs-expansion .tab-nav > ul > li > a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background: #fff;
  transition: 0.3s all;
}

.tabs.tabs-expansion .tab-nav > ul > li > a:hover {
  background: none;
  padding-bottom: 15px;
}

.tabs.tabs-expansion .tab-nav > ul > li > a:hover:after {
  width: 100%;
}

.tabs.tabs-expansion .tab-nav > ul > li > a.selected {
  background: none;
  border-bottom: 2px solid #fff;
}

.tabs.tabs-expansion .tab-nav > ul > li > a.selected + ul {
  display: block;
}

.tabs.tabs-expansion .tab-nav > ul > li ul {
  display: none;
  margin: 0;
  padding-top: 1.25rem;
}

.tabs.tabs-expansion .tab-nav > ul > li ul li {
  padding-left: 1.25rem;
  margin-bottom: 0.625rem;
  position: relative;
}

.tabs.tabs-expansion .tab-nav > ul > li ul li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.75rem;
  width: 5px;
  height: 2px;
  background: #fff;
}

.tabs.tabs-expansion .tab-content .tab {
  padding-top: 3.125rem;
}

.tabs.tabs-expansion .tab-content .tab.selected .map-marker:nth-child(1) {
  animation: showMapMarker 200ms ease-in-out forwards;
}

.tabs.tabs-expansion .tab-content .tab.selected .map-marker:nth-child(2) {
  animation: showMapMarker 400ms ease-in-out forwards;
}

.tabs.tabs-expansion .tab-content .tab.selected .map-marker:nth-child(3) {
  animation: showMapMarker 600ms ease-in-out forwards;
}

.tabs.tabs-expansion .tab-content .tab.selected .map-marker:nth-child(4) {
  animation: showMapMarker 800ms ease-in-out forwards;
}

.tabs.tabs-expansion .tab-content .tab.selected .map-marker:nth-child(5) {
  animation: showMapMarker 1000ms ease-in-out forwards;
}

.tabs.tabs-expansion .tab-content .tab.selected .map-marker:nth-child(6) {
  animation: showMapMarker 1200ms ease-in-out forwards;
}

.tabs.tabs-why .tab-nav ul li {
  flex: 1 1 0px;
}

.tabs.tabs-why .tab-nav ul li > a {
  width: 100%;
  font-size: 1.125rem;
  padding: 0 10px;
  height: 4.0625rem;
  color: #09304C;
  border-bottom: 2px solid #09304C;
  border-top: 4px solid transparent;
  transition: 0.3s all;
}

.tabs.tabs-why .tab-nav ul li > a:hover {
  background: none;
  color: #59B0A3;
}

.tabs.tabs-why .tab-nav ul li > a.selected {
  background: none;
  color: #59B0A3;
  border-top: 4px solid #59B0A3;
}

.tabs.tabs-why .tab-content .tab {
  padding-top: 3.125rem;
}

.tabs-links {
  margin: 3.125rem 0 3.125rem 0;
}

.tabs-links .tab-nav ul {
  margin: 0 -1.5625rem;
  padding: 0;
  display: flex;
}

.tabs-links .tab-nav li {
  list-style-type: none;
  padding: 0 1.5625rem;
  background: none;
  margin: 0;
  flex: 1 1 0px;
}

.tabs-links .tab-nav li a {
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: 0;
  background: transparent;
  text-align: center;
  width: 100%;
  padding: 0 10px;
  height: 5.3125rem;
  color: rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.22);
  transition: 0.3s all;
}

.tabs-links .tab-nav li a:hover {
  color: #fff;
  background: none;
}

.tabs-links .tab-nav li a.selected {
  background: none;
  color: #fff;
  border-bottom: 4px solid #59B0A3;
}

.tabs-links.aside {
  margin: 0;
}

.tabs-links.aside .tab-nav ul {
  flex-wrap: wrap;
}

.tabs-links.aside .tab-nav ul li {
  flex: 0 0 100%;
}

.tabs-links.aside .tab-nav ul li a {
  line-height: 1.3em;
  color: #09304C;
  border-bottom: 2px solid rgba(9, 48, 76, 0.2);
  height: 4.375rem;
  text-align: left;
  justify-content: flex-start;
}

.tabs-links.aside .tab-nav ul li a.selected {
  color: #59B0A3;
  border-color: #09304C;
}

.tabs-links.aside .tab-nav ul li a:hover {
  color: #59B0A3;
}

.tabs-links.aside .tab-nav ul li:last-child a {
  border: none;
}

/************************************************************************************
SIMPLE LIGHTBOX
*************************************************************************************/
html.slbActive {
  overflow: hidden;
}

.slbArrow {
  font-size: 15px;
}

.slbArrow.prev {
  background: #fff;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  opacity: 1;
  transition: 0.5s all;
}

.slbArrow.prev:before {
  border: none;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  width: 15px;
  height: 15px;
  margin-top: -7.5px;
  margin-left: -6px;
  transition: 0.5s all;
}

.slbArrow.prev:hover {
  opacity: 1;
  background: #000;
}

.slbArrow.prev:hover:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.slbArrow.next {
  background: #fff;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  opacity: 1;
  transition: 0.5s all;
}

.slbArrow.next:before {
  border: none;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 15px;
  height: 15px;
  margin-top: -7.5px;
  transition: 0.5s all;
}

.slbArrow.next:hover {
  opacity: 1;
  background: #000;
}

.slbArrow.next:hover:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.slbCloseBtn {
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  font-family: peachy-keen-jf,sans-serif;
  color: #fff;
  width: 40px;
  height: 40px;
  background: #fff;
  color: #000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  right: 0;
  top: 0;
  position: fixed;
  font-size: 0;
  transition: 0.5s all;
}

.slbCloseBtn:after {
  content: '';
  width: 24px;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -1px;
  display: block;
  background: #000;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: 0.5s all;
}

.slbCloseBtn:before {
  content: '';
  width: 2px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -12px;
  margin-left: -1px;
  display: block;
  background: #000;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: 0.5s all;
}

.slbCloseBtn:hover {
  opacity: 1;
  background: #000;
  color: #fff;
}

.slbCloseBtn:hover:after {
  background: #fff;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.slbCloseBtn:hover:before {
  background: #fff;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.slbImage {
  padding: 0.9375rem 0;
}

.slbImageWrap:after {
  content: none;
}

.slbCaption {
  font-size: 1rem;
  position: static;
  overflow: visible;
  white-space: normal;
  padding-top: 0;
}

/************************************************************************************
MODAL
*************************************************************************************/
.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  align-items: center;
  justify-content: center;
}

.modal.active {
  display: flex;
}

.modal .modal-content {
  padding: 3.125rem;
}

.modal .modal-content.alt {
  background: #f9fbfc;
}

.modal .modal-box {
  font-size: 1.125rem;
  max-width: 25rem;
  background-color: #fff;
  line-height: 1.5em;
  margin: auto;
  position: relative;
}

.modal .modal-box .modal-close {
  position: absolute;
  right: -0.9375rem;
  top: -0.9375rem;
  text-decoration: none;
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background: #59B0A3;
  color: #fff;
  transition: 0.3s all;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  /*&:after {
	    	content: '×';
	    	@include rem(font-size, 30px);
	    	position: absolute;
	    	left: 0;
	    	top: 0;
	    	width: 100%;
	    	height: 100%;
	    	display: flex;
	    	justify-content: center;
	    	align-items: center;
	    	color: #fff;
	    }

	    .icon {
	    	@include rem(width, 18px);
	    	@include rem(height, 18px);
	    	transform: rotate(0deg);
	    	@include transition(all, .3);
	    }

	    &:hover {
	    	background: #fff;

	    	&:after {
	    		color: #000;
	    	}

	    	.icon {
	    		transform: rotate(90deg);
	    	}
	    }*/
}

.modal .modal-box .modal-close .icon {
  width: 1rem;
  height: 1rem;
}

.modal .modal-box .modal-close:hover {
  background: #09304C;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.modal .modal-box.modal-order {
  width: 100%;
  max-width: 36.25rem;
  background: #e5e5e5;
}

.modal .modal-box.modal-map-detail {
  width: 100%;
  max-width: 21.875rem;
  background: #fff;
}

.modal .modal-box.modal-map-detail .modal-content {
  padding: 1.875rem;
}

.modal .modal-box.modal-map-detail .address {
  margin-bottom: 1.25rem;
}

.modal .modal-box.modal-map-detail .address:last-child {
  margin-bottom: 0;
}

.modal .modal-box.modal-exit {
  width: 100%;
  max-width: 50rem;
  background: #fff;
}

.modal .modal-box-wrap {
  width: 100%;
  padding: 3.125rem 0.9375rem 0.9375rem;
  margin: auto;
  overflow: hidden;
  position: relative;
  animation-name: animateTop;
  animation-duration: .4s;
}

@keyframes animateTop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/************************************************************************************
GLIDE
*************************************************************************************/
.glide__slides {
  margin: 0;
}

.glide__slide {
  padding: 0 !important;
  user-select: auto;
}

.glide__slide:before {
  content: none !important;
}

.glide-nav {
  margin-top: 2.5rem;
  position: relative;
}

.glide-nav .glide__arrow--left {
  position: absolute;
  left: 0;
  top: 0;
  width: 43px;
  height: 37px;
  cursor: pointer;
}

.glide-nav .glide__arrow--right {
  position: absolute;
  right: 0;
  top: 0;
  width: 43px;
  height: 37px;
  cursor: pointer;
}

.glide__bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.glide__bullets .glide__bullet {
  background: #E8E8E8;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  padding: 0;
  margin: 0 15px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: 0.5s all;
}

.glide__bullets .glide__bullet.glide__bullet--active, .glide__bullets .glide__bullet:hover {
  background: #09304C;
}

/* ROI slider */
.roi-slider {
  max-width: 1020px;
  border-top: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  padding: 3.125rem 0;
  margin: 6.25rem 0;
}

.roi-slider .panel {
  margin: 0;
}

.roi-slider .item-inner {
  display: flex;
}

.roi-slider .item .img {
  width: 8.75rem;
  flex: 0 0 8.75rem;
}

.roi-slider .item .img .icon {
  width: 5rem;
  height: 5rem;
  flex: 0 0 5rem;
}

.roi-slider .item .desc {
  max-width: 650px;
  margin-top: 1.25rem;
  margin-bottom: 0;
}

.roi-slider .item h3 {
  color: #09304C;
  text-transform: uppercase;
}

.roi-slider .item p:last-child {
  margin-bottom: 0;
}

.roi-slider .glide-nav {
  margin-top: 2.5rem;
  padding-left: 8.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.roi-slider .glide-nav ul {
  margin: 0 -5px;
  justify-content: flex-start;
}

.roi-slider .glide-nav .glide__arrow--left {
  position: relative;
  left: 0;
  top: 0;
  z-index: 20;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #09304C;
  cursor: pointer;
  transition: 0.3s all;
}

.roi-slider .glide-nav .glide__arrow--left .icon {
  width: 1.5625rem;
  height: 1.5625rem;
  flex: 0 0 1.5625rem;
}

.roi-slider .glide-nav .glide__arrow--left:hover {
  background: #09304C;
  color: #fff;
}

.roi-slider .glide-nav .glide__arrow--right {
  position: relative;
  right: 0;
  top: 0;
  z-index: 20;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #09304C;
  cursor: pointer;
  transition: 0.3s all;
}

.roi-slider .glide-nav .glide__arrow--right .icon {
  width: 1.5625rem;
  height: 1.5625rem;
  flex: 0 0 1.5625rem;
}

.roi-slider .glide-nav .glide__arrow--right:hover {
  background: #09304C;
  color: #fff;
}

.roi-slider .glide__bullets .glide__bullet:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2.25rem;
  height: 2.25rem;
  border: 1px solid #09304C;
  border-radius: 50%;
  margin-top: -1.125rem;
  margin-left: -1.125rem;
  transition: 0.3s all;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.roi-slider .glide__bullets .glide__bullet.glide__bullet--active {
  background: #09304C;
}

.roi-slider .glide__bullets .glide__bullet.glide__bullet--active:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.roi-slider .glide__bullets .glide__bullet:hover {
  background: #09304C;
}

.roi-slider.no-img .glide-nav {
  padding-left: 0;
}

.roi-slider.invert {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.roi-slider.invert .item .desc {
  color: #09304C;
}

/* Phase slider */
.phase-slider-wrapper {
  position: relative;
  overflow: hidden;
  padding-left: 8.125rem;
  margin-left: -8.125rem;
}

.phase-slider {
  margin-bottom: 6.25rem;
  width: 116%;
  overflow: visible !important;
}

.phase-slider .glide__slide {
  margin: 0;
  height: auto;
  display: flex;
}

.phase-slider .glide-nav {
  margin: 0;
  padding: 0;
  position: static;
}

.phase-slider .glide-nav ul {
  margin: 0 -5px;
  justify-content: flex-start;
}

.phase-slider .glide-nav .glide__arrow--left {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  width: 4.375rem;
  height: 4.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.phase-slider .glide-nav .glide__arrow--left .icon {
  width: 1.875rem;
  height: 1.875rem;
  flex: 0 0 1.875rem;
}

.phase-slider .glide-nav .glide__arrow--left:hover {
  background: #09304C;
  color: #fff;
}

.phase-slider .glide-nav .glide__arrow--right {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
  width: 4.375rem;
  height: 4.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.phase-slider .glide-nav .glide__arrow--right .icon {
  width: 1.875rem;
  height: 1.875rem;
  flex: 0 0 1.875rem;
}

.phase-slider .glide-nav .glide__arrow--right:hover {
  background: #09304C;
  color: #fff;
}

.phase-slider .glide__bullets .glide__bullet:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2.25rem;
  height: 2.25rem;
  border: 1px solid #09304C;
  border-radius: 50%;
  margin-top: -1.125rem;
  margin-left: -1.125rem;
  transition: 0.3s all;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.phase-slider .glide__bullets .glide__bullet.glide__bullet--active {
  background: #09304C;
}

.phase-slider .glide__bullets .glide__bullet.glide__bullet--active:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.phase-slider .glide__bullets .glide__bullet:hover {
  background: #09304C;
}

.phase-slider:after {
  content: '';
  position: absolute;
  z-index: 10px;
  right: 0;
  top: 0;
  width: 280px;
  height: 100%;
  pointer-events: none;
  background: #e8e8e8;
  background: -moz-linear-gradient(90deg, rgba(232, 232, 232, 0) 0%, #e8e8e8 100%);
  background: -webkit-linear-gradient(90deg, rgba(232, 232, 232, 0) 0%, #e8e8e8 100%);
  background: linear-gradient(90deg, rgba(232, 232, 232, 0) 0%, #e8e8e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e8e8e8",endColorstr="#e8e8e8",GradientType=1);
}

/* Gallery slider */
.gallery-slider-wrapper {
  position: relative;
}

.gallery-slider {
  margin-bottom: 4.375rem;
}

.gallery-slider .glide-nav {
  margin: 0;
  padding: 0;
  height: 0;
  position: static;
}

.gallery-slider .glide-nav .glide__arrow--left {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 20;
  width: 4.375rem;
  height: 4.375rem;
  margin-top: -2.1875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.gallery-slider .glide-nav .glide__arrow--left .icon {
  width: 1.875rem;
  height: 1.875rem;
  flex: 0 0 1.875rem;
}

.gallery-slider .glide-nav .glide__arrow--left:hover {
  background: #09304C;
  color: #fff;
}

.gallery-slider .glide-nav .glide__arrow--right {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 20;
  width: 4.375rem;
  height: 4.375rem;
  margin-top: -2.1875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.gallery-slider .glide-nav .glide__arrow--right .icon {
  width: 1.875rem;
  height: 1.875rem;
  flex: 0 0 1.875rem;
}

.gallery-slider .glide-nav .glide__arrow--right:hover {
  background: #09304C;
  color: #fff;
}

.gallery-slider .glide__slide {
  margin: 0;
}

.gallery-slider .glide__bullets {
  position: relative;
  top: 1.875rem;
  margin: 0;
}

.gallery-slider .glide__bullets .glide__bullet:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2.25rem;
  height: 2.25rem;
  border: 1px solid #09304C;
  border-radius: 50%;
  margin-top: -1.125rem;
  margin-left: -1.125rem;
  transition: 0.3s all;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.gallery-slider .glide__bullets .glide__bullet.glide__bullet--active {
  background: #09304C;
}

.gallery-slider .glide__bullets .glide__bullet.glide__bullet--active:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.gallery-slider .glide__bullets .glide__bullet:hover {
  background: #09304C;
}

/* History slider */
.history-slider-wrapper {
  padding: 0 140px;
  position: relative;
}

.history-slider-wrapper .head {
  font-size: 2.5rem;
  font-weight: 600;
  color: #59B0A3;
  position: absolute;
  z-index: 20;
  left: 140px;
  bottom: -0.9375rem;
  background: #09304C;
  display: block;
  padding: 0 1.25rem;
}

.history-slider-wrapper .head .suphead {
  font-size: 1.25rem;
  font-weight: 600;
  color: #59B0A3;
  position: absolute;
  left: 50%;
  top: -40px;
  cursor: pointer;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  transition: 0.3s all;
}

.history-slider-wrapper .head .suphead:hover {
  color: #fff;
}

.history-slider-wrapper .head .subhead {
  font-size: 1.25rem;
  font-weight: 600;
  color: #59B0A3;
  position: absolute;
  left: 50%;
  bottom: -40px;
  cursor: pointer;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  transition: 0.3s all;
}

.history-slider-wrapper .head .subhead:hover {
  color: #fff;
}

.history-slider-wrapper .headline {
  position: absolute;
  left: 140px;
  top: 180px;
}

.history-slider-wrapper .headline h2 {
  color: #59B0A3;
}

.history-slider-inner {
  padding-left: 140px;
  position: relative;
}

.history-slider {
  position: relative;
}

.history-slider .panel {
  margin: 0;
}

.history-slider .panel .item {
  padding: 0 0 3.125rem 0;
  position: relative;
  transition: 0.3s all;
  padding-top: 10px;
  top: 0;
}

.history-slider .panel .item:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 2px;
  margin-left: -1px;
  height: 0;
  background: #59B0A3;
  transition: 0.3s all;
}

.history-slider .panel .item:hover {
  color: #59B0A3;
  top: -10px;
}

.history-slider .panel.glide__slide--active {
  color: #59B0A3;
  position: relative;
}

.history-slider .panel.glide__slide--active .item:after {
  height: 3.125rem;
}

.history-slider .panel.glide__slide--active .item:hover {
  top: 0;
}

.history-slider .item {
  text-align: center;
  cursor: pointer;
}

.history-slider .glide-nav {
  margin-top: 2.5rem;
  padding-left: 8.75rem;
}

.history-slider .glide-nav ul {
  margin: 0 -5px;
  justify-content: flex-start;
}

.history-slider .glide__bullets .glide__bullet:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2.25rem;
  height: 2.25rem;
  border: 1px solid #09304C;
  border-radius: 50%;
  margin-top: -1.125rem;
  margin-left: -1.125rem;
  transition: 0.3s all;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.history-slider .glide__bullets .glide__bullet.glide__bullet--active {
  background: #09304C;
}

.history-slider .glide__bullets .glide__bullet.glide__bullet--active:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.history-slider .glide__bullets .glide__bullet:hover {
  background: #09304C;
}

.history-slider:after {
  content: '';
  position: absolute;
  left: -200%;
  bottom: -3px;
  width: 400%;
  height: 3px;
  background: #59B0A3;
}

/* Milestones slider */
.milestones-slider-wrapper {
  position: relative;
  padding: 0 140px;
}

.milestones-slider-inner {
  padding-left: 30%;
}

.milestones-slider {
  margin-bottom: 6.25rem;
  width: 116%;
  overflow: visible !important;
}

.milestones-slider .glide__slide {
  margin: 0;
  height: auto;
  display: flex;
}

.milestones-slider .glide-nav {
  margin: 0;
  padding: 0;
  position: static;
}

.milestones-slider .glide-nav ul {
  margin: 0 -5px;
  justify-content: flex-start;
}

.milestones-slider .glide-nav .glide__arrow--left {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  width: 4.375rem;
  height: 4.375rem;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.milestones-slider .glide-nav .glide__arrow--left .icon {
  width: 1.875rem;
  height: 1.875rem;
  flex: 0 0 1.875rem;
  color: #09304C;
}

.milestones-slider .glide-nav .glide__arrow--left:hover {
  background: #59B0A3;
  color: #fff;
}

.milestones-slider .glide-nav .glide__arrow--right {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
  width: 4.375rem;
  height: 4.375rem;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.milestones-slider .glide-nav .glide__arrow--right .icon {
  width: 1.875rem;
  height: 1.875rem;
  flex: 0 0 1.875rem;
  color: #09304C;
}

.milestones-slider .glide-nav .glide__arrow--right:hover {
  background: #59B0A3;
  color: #fff;
}

.milestones-slider:after {
  content: '';
  position: absolute;
  right: -15px;
  top: 0;
  height: 100%;
  width: 300px;
  background: #09304c;
  background: -moz-linear-gradient(90deg, rgba(9, 48, 76, 0) 0%, #09304c 100%);
  background: -webkit-linear-gradient(90deg, rgba(9, 48, 76, 0) 0%, #09304c 100%);
  background: linear-gradient(90deg, rgba(9, 48, 76, 0) 0%, #09304c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$blue",endColorstr="$blue",GradientType=1);
}

/* Offer slider */
.offer-slider-wrapper {
  position: relative;
  overflow: hidden;
}

.offer-slider-wrapper .offer-slider {
  margin: 0 -23px;
  width: 110%;
  overflow: visible !important;
}

/* Text slider */
.text-slider {
  border-bottom: 1px solid #E8E8E8;
  padding: 3.125rem 0;
}

.text-slider .panel {
  margin: 0;
}

.text-slider .item-inner {
  display: flex;
  align-items: center;
}

.text-slider .item .desc {
  max-width: 650px;
  margin-top: 1.25rem;
  margin-bottom: 0;
}

.text-slider .item h3 {
  color: #09304C;
  text-transform: uppercase;
}

.text-slider .item p:last-child {
  margin-bottom: 0;
}

.text-slider .glide-nav {
  margin-top: 2.5rem;
}

.text-slider .glide-nav ul {
  margin: 0 -5px;
  justify-content: flex-start;
}

.text-slider .glide__bullets .glide__bullet:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2.25rem;
  height: 2.25rem;
  border: 1px solid #09304C;
  border-radius: 50%;
  margin-top: -1.125rem;
  margin-left: -1.125rem;
  transition: 0.3s all;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.text-slider .glide__bullets .glide__bullet.glide__bullet--active {
  background: #09304C;
}

.text-slider .glide__bullets .glide__bullet.glide__bullet--active:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.text-slider .glide__bullets .glide__bullet:hover {
  background: #09304C;
}

.text-slider.invert {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.text-slider.invert .item .desc {
  color: #09304C;
}

/* Media slider */
.media-slider-wrapper {
  padding: 0 2.5rem;
  position: relative;
}

.media-slider .glide-nav {
  margin: 0;
  padding: 0;
  height: 0;
  position: static;
}

.media-slider .glide-nav .glide__arrow--left {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 20;
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.media-slider .glide-nav .glide__arrow--left .icon {
  width: 1.25rem;
  height: 1.25rem;
  flex: 0 0 1.25rem;
}

.media-slider .glide-nav .glide__arrow--left:hover {
  background: #09304C;
  color: #fff;
}

.media-slider .glide-nav .glide__arrow--right {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 20;
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.media-slider .glide-nav .glide__arrow--right .icon {
  width: 1.25rem;
  height: 1.25rem;
  flex: 0 0 1.25rem;
}

.media-slider .glide-nav .glide__arrow--right:hover {
  background: #09304C;
  color: #fff;
}

.media-slider .glide__slide {
  margin: 0;
}

.media-slider .glide__bullets {
  position: relative;
  top: 1.875rem;
  margin: 0;
}

.media-slider .glide__bullets .glide__bullet:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2.25rem;
  height: 2.25rem;
  border: 1px solid #09304C;
  border-radius: 50%;
  margin-top: -1.125rem;
  margin-left: -1.125rem;
  transition: 0.3s all;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.media-slider .glide__bullets .glide__bullet.glide__bullet--active {
  background: #09304C;
}

.media-slider .glide__bullets .glide__bullet.glide__bullet--active:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.media-slider .glide__bullets .glide__bullet:hover {
  background: #09304C;
}

.media-slider .item a {
  opacity: 0.6;
  transition: 0.3s all;
}

.media-slider .item a:hover {
  opacity: 1;
}

/************************************************************************************
CLEARFIX
*************************************************************************************/
/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */
.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/************************************************************************************
HEADER
*************************************************************************************/
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.header .h_0 {
  background: url("../img/header-line.svg") center bottom no-repeat;
  border-bottom: 1px solid transparent;
  display: flex;
  align-items: center;
}

.header .h_0 .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.375rem;
}

.header .h_1 .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header .h_2 .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0.625rem 0;
}

.header.alt .h_0 {
  border-bottom: 1px solid #59B0A3;
  background: none;
}

.header.alt .h_1 {
  display: none;
}

.header.alt .nav-btn {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  margin-left: 6.875rem;
}

.header.alt .nav-btn .txt {
  left: -3.75rem;
  top: 0.625rem;
  display: inline-block;
  bottom: auto;
  right: auto;
}

.header.zi-20 .btn-flip, .header.zi-20 .header-nav {
  position: relative;
  z-index: 100;
}

.header.zi-20 .breadcrumbs {
  position: relative;
  z-index: 40;
}

.header.hp .header-nav {
  z-index: 150;
}

.header.hp .header-r {
  z-index: 150;
}

.header.hp .h_0 {
  position: relative;
  z-index: 150;
}

.header.hp .h_1 {
  position: relative;
  z-index: 140;
}

.header.hp .nav-btn {
  right: 15px;
}

.header .cta-set {
  display: flex;
  align-items: center;
}

.breadcrumbs {
  font-size: 0.875rem;
  color: #fff;
  padding: 0.9375rem 0 0 0;
}

.breadcrumbs a {
  text-decoration: none;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .pipe {
  padding: 0 0.625rem;
}

.bg-video {
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.search-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: block;
  z-index: 130;
  background: #09304C;
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%);
  transition: 0.5s all;
}

.search-bar .inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.4375rem;
}

.search-bar .inner > * {
  width: 100%;
}

.search-bar form {
  position: relative;
}

body.search-bar-active .header {
  z-index: 90;
}

body.search-bar-active .search-bar {
  display: block;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  z-index: 160;
}

.logo {
  position: absolute;
  left: 50%;
  top: 15px;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  max-width: 11.9375rem;
}

.logo-symbol {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  margin-right: 2.8125rem;
}

.logo-symbol .txt {
  padding-left: 1.5625rem;
}

.logo-symbol .txt.first {
  padding-left: 0;
}

.header-nav {
  display: flex;
  align-items: center;
}

.header-nav ul {
  display: flex;
  margin: 0 -1.25rem;
}

.header-nav ul li {
  margin: 0;
  padding: 0 1.25rem;
  white-space: nowrap;
}

.header-nav ul li a {
  text-decoration: none;
  transition: 0.3s all;
}

.header-nav ul li a:hover {
  color: #fff;
}

.header-nav ul li.active a {
  color: #fff;
}

.header-r {
  display: inline-flex;
  align-items: center;
}

.header-r .cta-set {
  order: 1;
}

.header-r .btn-flip, .header-r .btn {
  margin-left: 1.875rem;
  height: 2.9375rem;
  order: 1;
  transition: 0.3s all;
}

.header-r .btn-flip:before, .header-r .btn:before {
  white-space: nowrap;
  height: 2.9375rem;
  padding: 0 0.9375rem;
  background: #fff;
  color: #09304C;
}

.header-r .btn-flip:after, .header-r .btn:after {
  white-space: nowrap;
  height: 2.9375rem;
  background: #59B0A3;
  color: #fff;
}

.header-r .btn-taste {
  margin-left: 15px;
}

.header-r .btn-nav {
  display: inline-flex;
  align-items: center;
  order: 1;
}

.search-nav {
  position: relative;
  z-index: 130;
}

.search-nav a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.1875rem;
  height: 2.1875rem;
  color: #59B0A3;
  transition: 0.3s all;
}

.search-nav a:hover {
  color: #fff;
}

.search-nav a .icon {
  width: 17px;
  height: 17px;
  flex: 0 0 17px;
}

.lng-nav {
  color: #59B0A3;
  font-weight: 500;
  position: relative;
  z-index: 130;
  margin-left: 1.5625rem;
  /*
  ul {
    margin: 0;
    position: absolute;
    left: 0;
    @include rem(top, 35px);
    //display: none;

    animation: hideMenu 400ms ease-in-out forwards;
    transform-origin: top center;

    li {
      margin: 0;
      padding: 0;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include rem(width, 35px);
        @include rem(height, 35px);
        text-decoration: none;
        background: #fff;
        //border-radius: 50%;
      }
    }
  }
  */
}

.lng-nav.active ul {
  display: block;
  animation: showMenu 400ms ease-in-out forwards;
  transform-origin: top center;
}

.lng-nav .current {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.1875rem;
  height: 2.1875rem;
  padding-right: 0.9375rem;
  border-radius: 50%;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s all;
}

.lng-nav .current:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.4375rem;
  width: 0.5rem;
  height: 0.5rem;
  border-left: 0.1125rem solid #59B0A3;
  border-bottom: 0.1125rem solid #59B0A3;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: 0.3s all;
}

.lng-nav .current:hover {
  color: #fff;
}

.lng-nav .current:hover:after {
  border-color: #fff;
}

.lng-bar {
  position: fixed;
  left: 50%;
  top: -10px;
  width: 100%;
  max-width: 300px;
  z-index: 2005;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  background: #59B0A3;
  text-align: left;
  padding: 4.375rem 1.875rem 1.875rem 1.875rem;
  -webkit-transform: translate(-50%, -100%);
  -moz-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  -o-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  transition: 0.5s all;
}

.lng-bar ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.lng-bar ul li:last-child {
  border: none;
}

.lng-bar ul li a {
  font-size: 1.125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  padding: 1.25rem 0;
}

.lng-bar ul li a img {
  margin-right: 0.9375rem;
}

.lng-bar .close {
  position: absolute;
  right: 1.875rem;
  top: 1.875rem;
  text-decoration: none;
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: 0.3s all;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.lng-bar .close .icon {
  width: 1.875rem;
  height: 1.875rem;
}

.lng-bar .close:hover {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.lng-bar.active {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  top: 0;
}

.teaser-hp {
  display: flex;
}

.teaser-hp .teaser-img {
  flex: 0 0 100%;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.teaser-hp .teaser-img img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.teaser-hp .teaser-img.shaded:after {
  content: '';
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(9, 48, 76, 0.65);
}

.teaser-hp .teaser-text {
  flex: 0 0 100%;
  width: 100%;
  margin-left: -100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: #fff;
  position: relative;
  z-index: 20;
  padding-top: 4.375rem;
}

.teaser-hp .teaser-content {
  padding: 13.75rem 0 20rem 0;
}

.teaser-hp .desc {
  max-width: 860px;
  margin-bottom: 4.375rem;
}

.teaser-hp .desc p:last-child {
  margin-bottom: 0;
}

.teaser-hp .btn:after, .teaser-hp .btn-flip:after {
  color: #09304C;
  background: #fff;
}

.teaser-hp .btn:before, .teaser-hp .btn-flip:before {
  color: #09304C;
  background: #59B0A3;
}

.teaser-hp h1 {
  max-width: 1300px;
}

.teaser-alt {
  display: flex;
  background: #09304C;
  color: #fff;
  display: flex;
  padding-top: 10rem;
  margin-bottom: 110px;
}

.teaser-alt h1 {
  margin-bottom: 3.75rem;
}

.teaser-alt .teaser-img {
  position: relative;
  flex: 0 0 100%;
  position: relative;
  padding-left: 115px;
  display: flex;
  margin-bottom: -110px;
}

.teaser-alt .teaser-img .frame {
  width: 100%;
  flex: 0 0 100%;
  padding-left: 50%;
}

.teaser-alt .teaser-img .frame img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.teaser-alt .teaser-img.shaded:after {
  content: '';
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(9, 48, 76, 0.65);
}

.teaser-alt .teaser-text {
  flex: 0 0 100%;
  width: 100%;
  margin-left: -100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
}

.teaser-alt .teaser-text .container, .teaser-alt .teaser-text .row, .teaser-alt .teaser-text .col-xl-6 {
  display: flex;
  flex-grow: 1;
}

.teaser-alt .teaser-text .text {
  flex-grow: 1;
  max-width: 750px;
}

.teaser-alt .teaser-content {
  display: flex;
  flex-direction: column;
}

.teaser-alt .desc {
  margin-bottom: 3.75rem;
  max-width: 61.875rem;
}

.teaser-alt .btn-set {
  margin-bottom: -5rem;
}

.teaser-alt .btn, .teaser-alt .btn-flip {
  position: relative;
  top: 2.1875rem;
  margin-right: 0.9375rem;
  margin-bottom: 0.625rem;
}

.teaser-alt .btn:after, .teaser-alt .btn-flip:after {
  color: #09304C;
  background: #fff;
}

.teaser-alt .btn:before, .teaser-alt .btn-flip:before {
  color: #09304C;
  background: #59B0A3;
}

.teaser-alt .numbers {
  margin-bottom: 1.25rem;
  max-width: 680px;
}

.teaser-alt .numbers .item-list {
  margin: 0 -3.75rem;
}

.teaser-alt .numbers .item {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 3.75rem;
  margin: 0.9375rem 0;
}

.teaser-alt .numbers .item .desc {
  font-size: 1rem;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
}

.teaser-alt .numbers .item .desc .icon {
  flex: 0 0 2.375rem;
  width: 2.375rem;
  height: 2.375rem;
  margin-right: 0.9375rem;
}

.teaser-alt .numbers .item h2 {
  color: #fff;
}

.teaser-alt .numbers .item:first-child, .teaser-alt .numbers .item:nth-child(3) {
  border: none;
}

.teaser-alt-2 {
  display: flex;
  background: #09304C;
  color: #fff;
  display: flex;
  padding-top: 10rem;
}

.teaser-alt-2 .teaser-text {
  flex: 0 0 100%;
  width: 100%;
  padding-top: 0;
  padding-bottom: 5rem;
  display: flex;
}

.teaser-alt-2 .teaser-text .text {
  flex-grow: 1;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.teaser-alt-2 .teaser-text .text.wide {
  max-width: 950px;
}

.teaser-alt-2 .teaser-text .text.widest {
  max-width: 1350px;
}

.teaser-alt-2 .teaser-text .text.full {
  width: 100%;
  max-width: 100%;
}

.teaser-alt-2 .teaser-text .text.a-l {
  text-align: left;
  justify-content: flex-start;
}

.teaser-alt-2 .teaser-text .text h1 {
  margin-bottom: 0;
}

.teaser-alt-2 .teaser-content {
  display: flex;
  flex-direction: column;
}

.teaser-alt-2 .desc {
  margin-top: 2.5rem;
  max-width: 61.875rem;
}

.teaser-alt-2 .btn-set {
  margin-bottom: -5rem;
}

.teaser-alt-2 .btn, .teaser-alt-2 .btn-flip {
  position: relative;
  top: 2.1875rem;
}

.teaser-alt-2 .btn:after, .teaser-alt-2 .btn-flip:after {
  color: #09304C;
  background: #fff;
}

.teaser-alt-2 .btn:before, .teaser-alt-2 .btn-flip:before {
  color: #09304C;
  background: #59B0A3;
}

.teaser-alt-2 .cats {
  padding-top: 3.125rem;
}

.teaser-poster {
  display: flex;
  position: relative;
  overflow: hidden;
}

.teaser-poster .teaser-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.teaser-poster .teaser-img img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.teaser-poster .teaser-img:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(9, 48, 76, 0.65);
}

.teaser-poster .teaser-text {
  width: 100%;
  text-align: center;
  padding-top: 5rem;
  position: relative;
  z-index: 30;
}

.teaser-poster .teaser-text .teaser-content {
  padding-top: 12.5rem;
  padding-bottom: 10rem;
  max-width: 990px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}

.teaser-poster .teaser-text .teaser-content.omega {
  padding-bottom: 0;
}

.teaser-poster .teaser-text .teaser-content.full {
  max-width: 100%;
}

.teaser-poster .teaser-text .teaser-content .desc {
  max-width: 990px;
  margin-left: auto;
  margin-right: auto;
}

.teaser-poster .teaser-text .teaser-content .btn-set {
  margin-top: 2.5rem;
}

.nav-btn {
  vertical-align: top;
  width: 2.875rem;
  height: 2.875rem;
  margin-left: 1.25rem;
  text-align: center;
  position: relative;
  z-index: 2001;
  cursor: pointer;
  align-items: center;
  position: absolute;
  right: 0;
  top: 6.875rem;
}

.nav-btn .txt {
  font-size: 0.875rem;
  width: 2.875rem;
  font-weight: 500;
  text-align: center;
  display: block;
  position: absolute;
  right: 0;
  bottom: -25px;
  color: #fff;
  text-transform: uppercase;
}

.nav-btn .lines {
  width: 100%;
  height: 4px;
  flex: 0 0 30px;
  display: block;
  position: relative;
  position: absolute;
  top: 50%;
  margin-top: -2px;
}

.nav-btn .line-1,
.nav-btn .line-2,
.nav-btn .line-3 {
  display: block;
  width: 100%;
  height: 3px;
  background: #fff;
  transition: 0.1s;
  position: absolute;
  left: 0;
  content: '';
  transform-origin: 0.28571rem center;
}

.nav-btn .line-1 {
  top: 0;
  transition: opacity 0.1s 0.1s ease;
}

.nav-btn .line-2 {
  top: 10px;
  transform-origin: 50% 50%;
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
  transition: top 0.1s 0.1s ease, transform 0.1s ease;
}

.nav-btn .line-3 {
  top: -10px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
  transition: top 0.1s 0.1s ease, transform 0.1s ease;
}

.mobile-nav {
  color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 90;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  margin-top: -10px;
  transition: 0.3s all;
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%);
}

.mobile-nav .inner {
  background: #59B0A3;
  width: 100%;
  max-width: 530px;
  float: right;
  padding: 8.125rem 0 14.375rem 6.25rem;
  position: relative;
}

.mobile-nav .inner:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 10000%;
  height: 100%;
  background: #59B0A3;
  z-index: -1;
}

.mobile-nav .head {
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
}

.mobile-nav.active {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mobile-nav .primary {
  padding-top: 1.875rem;
  margin-bottom: 6.25rem;
  max-width: 26.875rem;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.mobile-nav .primary ul {
  margin: 0;
  padding: 0;
}

.mobile-nav .primary ul li {
  color: #fff;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  position: relative;
}

.mobile-nav .primary ul li a {
  font-size: 1.125rem;
  font-weight: 500;
  display: block;
  width: 100%;
  padding: 12px 0;
  color: #fff;
  text-transform: uppercase;
  border: none;
  position: relative;
  text-decoration: none;
  transition: 0.3s all;
}

.mobile-nav .primary ul li a:hover {
  color: #09304C;
}

.mobile-nav .primary ul li a.active .more:after {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  margin-top: -5px;
}

.mobile-nav .primary ul li a[target="_blank"]:after {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  background: url("../img/blank.svg") 0 0 no-repeat;
  margin-left: 5px;
  position: relative;
  top: -8px;
}

.mobile-nav .primary ul li ul {
  display: none;
  padding: 8px 0;
}

.mobile-nav .primary ul li ul.active {
  display: block;
  padding-left: 1.875rem;
}

.mobile-nav .primary ul li ul.active li a {
  text-transform: none;
  padding: 8px 0;
}

.mobile-nav .primary ul li .more {
  position: absolute;
  right: 0;
  top: 12px;
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mobile-nav .primary ul li .more:hover:after {
  border-color: #09304C;
}

.mobile-nav .primary ul li .more:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1.25rem;
  height: 1.25rem;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
  margin-left: -0.625rem;
  margin-top: -1.125rem;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transition: 0.3s all;
}

.mobile-nav .secondary {
  padding-right: 3.125rem;
  padding-bottom: 1.875rem;
  max-width: 26.875rem;
}

.mobile-nav .secondary ul {
  margin: 0;
  padding: 0;
}

.mobile-nav .secondary ul li {
  color: #fff;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}

.mobile-nav .secondary ul li a {
  font-size: 1rem;
  font-weight: 500;
  display: block;
  width: 100%;
  padding: 6px 0;
  color: #fff;
  border: none;
  position: relative;
  text-decoration: none;
  transition: 0.3s all;
}

.mobile-nav .secondary ul li a:hover {
  color: #09304C;
}

.mobile-nav .social-nav a {
  color: rgba(255, 255, 255, 0.5);
}

.mobile-nav .social-nav a:hover {
  color: white;
}

.mobile-nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 80;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

body.mobile-nav-opened {
  overflow: hidden;
}

body.mobile-nav-opened .mobile-nav {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  margin-top: 0;
}

body.mobile-nav-opened .nav-btn .line-1 {
  transition: opacity 0.1s 0s ease;
  background: transparent;
  opacity: 0;
}

body.mobile-nav-opened .nav-btn .line-2 {
  transform: rotate3d(0, 0, 1, 45deg);
  transition: top 0.1s ease, transform 0.1s 0.1s ease;
  top: 0;
}

body.mobile-nav-opened .nav-btn .line-3 {
  transform: rotate3d(0, 0, 1, -45deg);
  transition: top 0.1s ease, transform 0.1s 0.1s ease;
  top: 0;
}

body.mobile-nav-opened .mobile-nav-overlay {
  display: block;
  animation: showOverlay .4s;
}

body.mobile-nav-opened .search-nav a {
  color: #fff;
}

body.mobile-nav-opened .lng-nav .current {
  color: #fff;
}

body.mobile-nav-opened .lng-nav .current:after {
  border-color: #fff;
}

body.mobile-nav-opened .header-nav {
  pointer-events: none;
  opacity: 0.5;
}

body.mobile-nav-opened .header-r .btn-flip, body.mobile-nav-opened .header-r .btn {
  z-index: 130;
}

body.mobile-nav-opened .header-r .btn-flip:after, body.mobile-nav-opened .header-r .btn:after {
  background: #09304C;
}

body.mobile-nav-opened .header .h_0 {
  border-color: transparent;
}

body.mobile-nav-opened .header.hp .h_1 {
  z-index: 50;
}

body.fix .header {
  position: fixed;
  z-index: 150;
  left: 0;
  top: 0;
  width: 100%;
}

body.fix .header .h_0 {
  border-color: #09304C;
  border-color: transparent;
}

body.fix .header .h_2 {
  display: none;
}

body.fix .header.hp .h_0 {
  background: none;
}

body.fix .header.hp .h_1 {
  display: none;
}

body.fix .header.hp .nav-btn {
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin-left: 5rem;
}

body.fix .header.hp .nav-btn .txt {
  left: -3.75rem;
  top: 0.625rem;
  display: inline-block;
  bottom: auto;
  right: auto;
}

body.fix:before {
  content: '';
  position: fixed;
  z-index: 70;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 4.4375rem;
  background: #09304C;
}

@keyframes showSearch {
  0% {
    display: none;
    -webkit-transform: translate(0, -100%);
    -moz-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    -o-transform: translate(0, -100%);
    transform: translate(0, -100%);
  }
  5% {
    display: block;
    -webkit-transform: translate(0, -100%);
    -moz-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    -o-transform: translate(0, -100%);
    transform: translate(0, -100%);
  }
  100% {
    display: block;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes showOverlay {
  0% {
    display: none;
    background: rgba(0, 0, 0, 0);
  }
  5% {
    display: block;
    background: rgba(0, 0, 0, 0);
  }
  100% {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
}

@keyframes showMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@keyframes hideMenu {
  0% {
    transform: rotateX(0deg);
  }
  30% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(-90deg);
  }
}

html[lang="cs-CZ"] .lng-bar ul li.cs {
  display: none;
}

html[lang="en-US"] .lng-bar ul li.en {
  display: none;
}

html[lang="pl-PL"] .lng-bar ul li.pl {
  display: none;
}

/************************************************************************************
LAYOUT
*************************************************************************************/
.offer {
  margin-top: -15.625rem;
  position: relative;
  padding-left: 110px;
  z-index: 40;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.offer .head {
  font-size: 1.125rem;
  margin-bottom: 2.5rem;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: calc((100vw - 1965px)/2);
}

.offer .head .link-play {
  font-size: 1rem;
  color: #fff;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
}

.offer .head .link-play .icon {
  width: 2.5rem;
  height: 2.5rem;
  flex: 0 0 2.5rem;
  margin-left: 1.25rem;
}

.offer .container {
  margin-left: 0;
  margin-right: -15px;
}

.offer .item-list {
  margin: 0 -23px;
}

.offer .item {
  display: flex;
  padding: 0 23px;
  margin-bottom: 2.5rem;
}

.offer .item .text {
  padding: 2.5rem 2.5rem 0 2.5rem;
  flex-grow: 1;
}

.offer .item .text:last-child {
  padding-bottom: 2.5rem;
}

.offer .item .text .suphead {
  font-size: 1.125rem;
  line-height: 1.3em;
  letter-spacing: -0.025em;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.9375rem;
  height: 2.5rem;
  padding-right: 1.875rem;
  position: relative;
}

.offer .item .text .suphead .icon-arr-r {
  position: absolute;
  right: 0;
  top: 0;
}

.offer .item .text h3 {
  text-transform: uppercase;
  color: #09304C;
  height: 4.375rem;
  margin-bottom: 1.875rem;
  display: flex;
  align-items: center;
}

.offer .item .text h3 a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #09304C;
}

.offer .item .text h3 a:hover {
  text-decoration: underline;
}

.offer .item .text p {
  margin-bottom: 0;
}

.offer .item .img {
  padding-left: 2.5rem;
  position: relative;
  top: 2.5rem;
}

.offer .item .img img {
  width: 100%;
}

.offer .item .label {
  position: absolute;
  right: 0;
  top: -1.0625rem;
  height: 2.125rem;
  padding: 0 1.25rem;
  background: #59B0A3;
  color: #fff;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  text-align: center;
}

.offer .item .params {
  font-size: 1.125rem;
  font-weight: 600;
}

.offer .item .params ul li {
  padding-left: 3.125rem;
  position: relative;
}

.offer .item .params ul li .icon {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: 0.3125rem;
  top: 0.0625rem;
}

.offer .item .more {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 1.25rem;
}

.offer .item .more:hover {
  text-decoration: underline;
}

.offer .item .more .icon {
  margin-right: 0.9375rem;
}

.offer .item.purple .params {
  color: #9F1DA5;
}

.offer .item.purple img {
  border-top: 0.75rem solid #9F1DA5;
}

.offer .item.green .params {
  color: #92B42B;
}

.offer .item.green img {
  border-top: 0.75rem solid #92B42B;
}

.offer .item.blue .params {
  color: #4EAED1;
}

.offer .item.blue img {
  border-top: 0.75rem solid #4EAED1;
}

.offer .item-inner {
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  color: #09304C;
  background: #E8E8E8;
  position: relative;
}

.offer a.item-inner:hover h3 {
  text-decoration: underline;
}

.offer .farm-list {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 1.875rem 0 0 0;
}

.offer .farm-list ul li {
  padding: 0;
  margin-bottom: 0.75rem;
}

.offer .farm-list ul li a {
  text-decoration: none;
}

.offer .farm-list ul li a:hover {
  text-decoration: underline;
}

.offer .farm-list.blue a {
  color: #4EAED1;
}

.offer .farm-list.green a {
  color: #92B42B;
}

.offer .farm-list.purple a {
  color: #9F1DA5;
}

.offer.offer-list {
  width: 100%;
  display: block;
  margin-top: -8.75rem;
  padding-left: 0;
  overflow: auto;
  justify-content: center;
}

.offer.offer-list .container {
  margin-left: auto;
  margin-right: auto;
}

.offer.offer-list .text.flex-grow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.offer.offer-list .text.flex-grow .desc {
  flex-grow: 1;
}

.offer.offer-list .desc {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.offer.no-offset {
  margin-top: 0 !important;
}

.about {
  display: flex;
  overflow: hidden;
}

.about .about-img {
  flex: 0 0 100%;
  width: 100%;
}

.about .about-img .frame {
  max-width: 50%;
}

.about .about-text {
  flex: 0 0 100%;
  width: 100%;
  margin-left: -100%;
  padding-left: 50%;
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.about .about-text .text {
  padding-left: 5.625rem;
}

.about .desc {
  max-width: 990px;
  margin-bottom: 3.125rem;
}

.about .desc p:last-child {
  margin-bottom: 0;
}

.about .desc:last-child {
  margin-bottom: 0;
}

.about .name {
  font-size: 1.875rem;
  color: #59B0A3;
}

.about .job {
  font-size: 1.25rem;
  color: #59B0A3;
  text-transform: uppercase;
}

.about .section-head {
  text-align: left;
  margin-bottom: 2.5rem;
}

.about .section-head h2 {
  max-width: 46.875rem;
  display: inline-flex;
  align-items: center;
}

.about .section-head h2 .icon {
  width: 3.4375rem;
  height: 3.4375rem;
  flex: 0 0 3.4375rem;
  margin-right: 1.25rem;
}

.about .btn-set {
  display: flex;
  align-items: center;
}

.about .btn-set .btn, .about .btn-set .btn-flip {
  margin-right: 2.5rem;
}

.about.alt .about-text {
  margin-left: 0;
  flex: 0 0 50%;
  max-width: 50%;
  order: 0;
  padding-left: 15px;
  padding-right: 5.625rem;
  display: flex;
  justify-content: flex-end;
}

.about.alt .about-text .text {
  padding-left: 0;
  max-width: 880px;
}

.about.alt .about-img {
  order: 1;
}

.yt-link {
  line-height: 1.3em;
  display: inline-flex;
  align-items: center;
  color: #09304C;
  font-weight: 600;
  letter-spacing: 0.1;
  text-decoration: none;
}

.yt-link:hover {
  text-decoration: underline;
}

.yt-link .icon {
  width: 2rem;
  flex: 0 0 2rem;
  height: 2rem;
  margin-right: 1.125rem;
}

.video {
  display: block;
  position: relative;
  overflow: hidden;
}

.video:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(9, 48, 76, 0.4);
}

.video:hover .img img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.video .img img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  transition: 0.3s all;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video .play {
  position: absolute;
  z-index: 30;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 5.625rem;
  height: 5.625rem;
  color: #fff;
}

.video .play .icon {
  width: 5.625rem;
  height: 5.625rem;
}

.posts-overview .main-text {
  display: flex;
  align-items: center;
}

.posts-overview .main-text .section-head {
  text-align: left;
}

.posts-overview .posts {
  max-width: 710px;
}

.posts-overview .posts-main {
  margin-left: -25px;
}

.posts-overview .posts-main .item h3 {
  max-width: 80%;
}

.posts-overview .posts-list {
  max-width: 480px;
  float: right;
}

.posts-overview .posts-list .item h3 {
  max-width: 90%;
}

.posts-overview .posts-list .foot {
  display: none;
}

.posts .item {
  margin-bottom: 2.5rem;
}

.posts .item .guest {
  margin-bottom: 5px;
  color: #59B0A3;
}

.posts .item h3 {
  text-transform: uppercase;
}

.posts .item h3 a {
  text-decoration: none;
  color: #09304C;
}

.posts .item h3 a:hover {
  text-decoration: underline;
}

.posts .item .img {
  margin-bottom: 1.5625rem;
  display: block;
  overflow: hidden;
  position: relative;
}

.posts .item .img img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition: 0.3s all;
}

.posts .item .img:hover img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.posts .item .info {
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #59B0A3;
}

.posts .item .desc {
  margin-bottom: 1.25rem;
}

.posts .item .info {
  margin-bottom: 1.25rem;
}

.posts .item .more a {
  color: #09304C;
}

.posts .item:last-child {
  margin-bottom: 0;
}

.posts .item:last-child .item-inner {
  border: none;
  padding-bottom: 0;
}

.posts .item-inner {
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #09304C;
}

.posts-archive {
  margin-left: auto;
  margin-right: auto;
}

.posts-archive.wide {
  max-width: 100%;
}

.posts-archive .item-list {
  margin-bottom: -2.5rem;
}

.posts-archive .item:last-child {
  margin-bottom: 2.5rem;
}

.posts-archive .item-inner {
  border: none;
  padding-bottom: 0;
}

.posts-archive .foot {
  display: flex;
  justify-content: center;
}

.posts-recent {
  margin-left: auto;
  margin-right: auto;
}

.posts-recent .item-list {
  margin-bottom: -2.5rem;
}

.posts-recent .item:last-child {
  margin-bottom: 2.5rem;
}

.posts-recent .item-inner {
  border: none;
  padding-bottom: 0 !important;
}

.posts-big {
  margin-bottom: 3.125rem;
  margin-left: auto;
  margin-right: auto;
}

.posts-big .item-inner {
  display: flex;
}

.posts-big .item-inner .img {
  flex: 0 0 50%;
  margin-bottom: 0;
}

.posts-big .item-inner .text {
  flex: 0 0 50%;
  padding: 3.125rem;
  background: #E8E8E8;
  display: flex;
  align-items: center;
}

.post-img-main {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
}

.post-img-main img {
  width: 100%;
  /*height: 100%;
		object-fit: cover !important;*/
  position: relative;
  z-index: 20;
}

.post-img-main:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  margin-left: -50%;
  height: 12.5rem;
  background: #09304C;
}

.post-excerpt {
  font-size: 1.375rem;
  line-height: 1.5em;
  margin: 0 0 2.5rem 0;
}

.post-text {
  margin: 2.5rem 0;
  position: relative;
}

.post-img {
  position: relative;
}

.post-detail {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.post-detail h2 {
  font-size: 2.1875rem;
  margin-bottom: 1.25rem;
}

.post-detail h3 {
  font-size: 1.5625rem;
  margin-bottom: 1.25rem;
}

.post-detail ol {
  list-style: none;
  margin: 0 0 1.25rem 0;
  padding: 0;
  counter-reset: counter;
  margin-left: 0;
}

.post-detail ol li {
  margin: 0 0 0.9375rem 0;
  padding: 0 0 0 2.5rem;
  counter-increment: counter;
  position: relative;
}

.post-detail ol li:before {
  font-size: 0.9375rem;
  font-weight: 600;
  color: #59B0A3;
  background: #E8E8E8;
  border-radius: 50%;
  content: counter(counter) ".";
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5625rem;
  height: 1.5625rem;
}

.post-info {
  max-width: 200px;
  position: absolute;
  left: -250px;
  top: 0;
}

.post-info .author {
  padding-bottom: 1.875rem;
  margin-bottom: 1.875rem;
  border-bottom: 1px solid #59B0A3;
}

.post-info .author .img {
  margin-bottom: 0.625rem;
}

.post-info .author .img .frame {
  width: 5rem;
  height: 5rem;
  flex: 0 0 5rem;
  border-radius: 50%;
}

.post-info .author .img img {
  width: 100%;
}

.post-info .author .label {
  font-size: 0.8125rem;
  line-height: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 0;
}

.post-info .author .name {
  font-size: 1.125rem;
  color: #59B0A3;
  margin: 0;
}

.post-info .author .name a {
  text-decoration: none;
}

.post-info .author .name a:hover {
  text-decoration: underline;
}

.post-info .cats .head {
  flex: 0 0 100px;
}

.post-info .cats ul li {
  padding: 0;
  position: relative;
}

.post-info .cats ul li a {
  font-size: 0.8125rem;
  line-height: 1.3em;
  color: #fff;
  font-weight: 600;
  background: #59B0A3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.625rem;
  border-radius: 50px;
  text-decoration: none;
  transition: 0.3s all;
}

.post-info .cats ul li a:hover {
  background: #09304C;
}

.post-info .head {
  font-weight: 600;
  color: #09304C;
  margin-bottom: 1.25rem;
}

.post-meta {
  text-align: center;
  margin-top: 1.25rem;
}

.post-meta .pipe {
  padding: 0 10px;
  display: inline-block;
}

.post-share {
  display: flex;
  justify-content: flex-start;
  padding-top: 3.125rem;
  margin-top: 3.125rem;
  border-top: 1px solid #59B0A3;
}

.post-share .head {
  font-weight: 600;
  margin: 0.625rem 1.25rem 0.625rem 0;
  display: inline-flex;
  align-items: center;
}

.tiles-photo .item-list {
  margin: 0;
}

.tiles-photo .item {
  padding: 0;
}

.tiles-photo .item .img {
  position: relative;
  display: block;
  flex: 0 0 100%;
  width: 100%;
  z-index: 10;
}

.tiles-photo .item .img:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(9, 48, 76, 0.8);
}

.tiles-photo .item .img img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.tiles-photo .item .text {
  flex: 0 0 100%;
  width: 100%;
  margin-left: -100%;
  position: relative;
  z-index: 20;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tiles-photo .item .text .icon {
  width: 5.625rem;
  height: 5.625rem;
  flex: 0 0 5.625rem;
  margin-bottom: 1.25rem;
}

.tiles-photo .item .inner {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition: 0.3s all;
}

.tiles-photo .item-inner {
  display: flex;
  text-decoration: none;
  height: 100%;
}

.tiles-photo .item-inner:hover .img img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.tiles-photo .item-inner:hover .text .inner {
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}

.tiles-photo .img {
  position: relative;
  overflow: hidden;
}

.tiles-photo .img img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition: 0.3s all;
}

.tiles-photo .suphead {
  font-size: 1.75rem;
  line-height: 1.3em;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0.625rem;
}

.tiles-photo h3 {
  font-size: 2rem;
  color: #59B0A3;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0;
}

.vision {
  margin-bottom: 2.5rem;
}

.vision:last-child {
  margin-bottom: 0;
}

.vision .logo-vision {
  max-width: 8.875rem;
  margin-bottom: 3.4375rem;
  opacity: 0.6;
  position: relative;
  left: 5px;
}

.vision .section-head {
  text-align: left;
  margin: 0;
  margin-bottom: 4.375rem;
  max-width: 460px;
}

.vision .section-head.wide {
  max-width: 790px;
}

.vision .main-text .desc {
  max-width: 820px;
}

.vision .item-list {
  counter-reset: counter;
  margin-top: -3.125rem;
  margin-bottom: -3.125rem;
}

.vision .item {
  font-size: 1.375rem;
  line-height: 1.3em;
  font-weight: 500;
  counter-increment: counter;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 12.5rem;
  position: relative;
}

.vision .item:after {
  content: '';
}

.vision .item:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80%;
  height: 1px;
  background: #fff;
}

.vision .item:last-child:before {
  content: none;
}

.vision .item-inner {
  position: relative;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  padding-left: 5.625rem;
}

.vision .item-inner:before {
  font-size: 5.625rem;
  line-height: 0.8em;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.3);
  content: counter(counter);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.vision .foot {
  display: none;
}

.persons .item-list {
  margin: 0;
}

.persons .item {
  padding: 0;
}

.persons .item .text {
  display: flex;
  flex-wrap: wrap;
}

.persons .item img {
  width: 100%;
}

.persons .item-inner {
  position: relative;
  display: flex;
}

.persons .item-inner .img {
  flex: 0 0 100%;
  width: 100%;
  z-index: 10;
  position: relative;
  z-index: 4;
  overflow: hidden;
}

.persons .item-inner .img img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  transition: 0.3s all;
}

.persons .item-inner .text {
  flex: 0 0 100%;
  width: 100%;
  margin-left: -100%;
  position: relative;
  z-index: 5;
  z-index: 20;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.persons .item-inner:after {
  content: '';
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  pointer-events: none;
  background: rgba(255, 255, 255, 0);
  transition: 0.3s all;
}

.persons .item-inner.hoverable {
  cursor: pointer;
}

.persons .item-inner.hoverable:hover .cite {
  opacity: 0;
  margin-top: 30px;
}

.persons .item-inner.hoverable:hover .cite-btn {
  opacity: 1;
  margin-top: 0;
}

.persons .item-inner.hoverable:hover .img img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.persons .item-inner.hoverable:hover:after {
  background: rgba(255, 255, 255, 0.3);
}

.persons .cite {
  font-size: 3rem;
  line-height: 1.25em;
  letter-spacing: -0.05em;
  font-weight: 300;
  font-style: italic;
  width: 100%;
  max-width: 670px;
  padding: 0 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 1;
  margin-top: 0;
  transition: 0.3s all;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.persons .cite-btn {
  width: 100%;
  max-width: 670px;
  padding: 0 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  margin-top: 30px;
  transition: 0.3s all;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.persons .sign {
  display: inline-block;
  margin-right: 70px;
  margin-bottom: 25px;
}

.persons .sign img {
  width: auto;
}

.persons .item-foot {
  flex: 0 0 100%;
  max-width: 910px;
  padding: 0 30px;
  align-self: flex-end;
  padding-bottom: 10%;
  display: flex;
  justify-content: space-between;
}

.persons .item-foot-r {
  text-align: right;
}

.persons .name {
  font-size: 1.75rem;
  font-weight: 500;
  margin: 0 0 0.9375rem 0;
}

.persons .foot {
  display: flex;
  justify-content: center;
  padding: 1.875rem 0.9375rem;
  display: none;
}

.act-nav {
  color: #59B0A3;
}

.act-nav h3 {
  text-transform: uppercase;
}

.act-nav .item-inner {
  font-size: 1.125rem;
  width: 100%;
  color: #fff;
  padding: 1.875rem 0;
  border-bottom: 5px solid #fff;
  text-decoration: none;
  display: flex;
  transition: 0.3s all;
}

.act-nav .item-inner:hover {
  background: #fff;
  color: #09304C;
}

.act-nav .item-inner:hover .img .icon {
  color: #59B0A3;
}

.act-nav .item-inner:hover .link .txt {
  opacity: 1;
  margin-left: 0;
}

.act-nav .item {
  display: flex;
}

.act-nav .item .img {
  flex: 0 0 5.3125rem;
  display: flex;
  justify-content: center;
}

.act-nav .item .img .icon {
  width: 2.625rem;
  height: 2.625rem;
  flex: 0 0 2.625rem;
  color: #fff;
  position: relative;
  top: 0.3125rem;
}

.act-nav .item .text {
  display: flex;
  flex-direction: column;
}

.act-nav .item .text .desc {
  display: block;
  flex-grow: 1;
}

.act-nav .item .text .desc .icon-new-window {
  margin-left: 10px;
  width: 0.625rem;
  height: 0.625rem;
  color: #59B0A3;
  position: relative;
  top: 0.3125rem;
}

.act-nav .item .link {
  display: inline-flex;
  align-items: center;
  margin-top: 0.625rem;
  color: #59B0A3;
}

.act-nav .item .link .txt {
  opacity: 0;
  margin-left: 15px;
  transition: 0.3s all;
}

.act-nav .item .link .icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #59B0A3;
  margin-right: 10px;
}

.investment-intro h2 {
  position: relative;
  z-index: 10;
}

.investment-intro .desc {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  max-width: 65.625rem;
}

.investment-intro .container {
  position: relative;
}

.investment-intro .leaf-1 {
  position: absolute;
  right: 0;
  top: 0;
  top: -12.5rem;
  z-index: 1;
  pointer-events: none;
}

.investment-intro .leaf-2 {
  position: absolute;
  right: 0;
  top: -3.125rem;
  z-index: 1;
  pointer-events: none;
}

.investment {
  overflow: hidden;
  padding-top: 2.5rem;
  position: relative;
  margin-top: -2.5rem;
}

.investment.no-offset .item-list {
  margin-top: 0 !important;
}

.investment .section-head.narrow {
  max-width: 900px;
}

.investment .item-list {
  margin: 0;
  border-top: 2px solid #fff;
}

.investment .item {
  text-align: center;
  padding: 0;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.investment .item:first-child {
  border-left: none;
}

.investment .item .desc {
  max-width: 23.125rem;
  padding-top: 3.125rem;
}

.investment .item .icon-arr-r {
  color: #59B0A3;
}

.investment .item .price {
  font-size: 1.5625rem;
  font-weight: 500;
  color: #59B0A3;
  position: absolute;
  left: 50%;
  top: -2.5rem;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: #fff;
  width: 18.125rem;
  height: 5rem;
  border-radius: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #09304C;
}

.investment .item h3 {
  text-transform: uppercase;
  color: #09304C;
}

.investment .item-inner {
  height: 30.625rem;
  padding: 1.875rem;
  background: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: #09304C;
  transition: 0.3s all;
}

.investment .item-inner h3 {
  transition: 0.4s all;
  margin-bottom: 0.625rem;
}

.investment .item-inner .btn-flip {
  display: none;
  transition: 0.3s all;
}

.investment .item-inner .icon-arr-r {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  max-height: 50px;
  transition: 0.4s all;
}

.investment .item-inner:hover {
  background: #09304C;
  color: #fff;
}

.investment .item-inner:hover h3 {
  color: #fff;
  margin-bottom: -10px;
}

.investment .item-inner:hover .btn-flip {
  display: inline-flex;
  animation: showBtn 400ms ease-in-out forwards;
  margin-top: 1.25rem;
}

.investment .item-inner:hover .icon-arr-r {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  max-height: 0;
}

.investment.alt .item {
  border-top: 7px solid #fff;
  border-left: 7px solid #fff;
}

.investment.alt .item:nth-child(odd) {
  border-left: none;
}

.investment.alt .item-inner {
  height: 19.375rem;
}

.investment.alpha {
  padding-top: 0;
}

.investment-action {
  display: flex;
}

.investment-action .section-head {
  min-height: 11.25rem;
  margin: 0 auto 80px auto;
}

.investment-action .section-head h2 {
  margin-bottom: 1.25rem;
}

.investment-action .investment {
  flex: 0 0 50%;
  width: 50%;
  padding-top: 0;
}

.investment-action .investment .section-head {
  max-width: 600px;
}

.investment-action .investment .item:nth-child(1), .investment-action .investment .item:nth-child(2) {
  border-top: none;
}

.investment-action .investment-form {
  flex: 0 0 50%;
  width: 50%;
  padding: 0 3.125rem;
  margin-bottom: 2.5rem;
}

.investment-action .investment-form .section-head {
  max-width: 500px;
}

.investment-action .investment-form .section-head h2 {
  margin-bottom: 1.25rem;
}

.investment-action .investment-form .subhead {
  color: #59B0A3;
}

.investment-form {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.investment-form .anchor {
  top: -3.125rem;
}

.investment-form .section-head {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}

.investment-form .section-head.wide {
  max-width: 900px;
}

.investment-form .section-head h2 {
  margin-bottom: 2.5rem;
}

.investment-form .subhead {
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #09304C;
  margin: 0;
}

.investment-form.invert-2 .subhead {
  color: #59B0A3;
}

.investment-form.invert-2 form.form-contact input.text, .investment-form.invert-2 form.form-contact textarea {
  background: none;
  color: #fff;
}

.investment-form.invert-2 form.form-contact select {
  background-color: transparent;
  background-image: url("../img/select-alt.svg");
  background-position: right center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.investment-form.invert-2 form.form-contact select option {
  color: #09304C;
}

.media {
  /*display: flex;
	align-items: center;
	overflow: hidden;*/
}

.media .head {
  display: flex;
  align-items: center;
}

.media .item {
  display: flex;
  justify-content: center;
}

.media .item-inner {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.numbers .section-head {
  text-align: left;
}

.numbers .main-text {
  max-width: 490px;
  align-self: center;
  position: relative;
}

.numbers .main-text > * {
  position: relative;
  z-index: 10;
}

.numbers .main-text .bg {
  width: 34.375rem;
  position: absolute;
  left: 50%;
  top: -100%;
  z-index: 1;
}

.numbers .main-text .bg-fish {
  width: 34.375rem;
  position: absolute;
  left: -10%;
  top: -5.625rem;
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%);
  z-index: 1;
  pointer-events: none;
}

.numbers .item-list {
  margin-top: -3.75rem;
  margin-bottom: -3.75rem;
}

.numbers .item {
  position: relative;
  z-index: 20;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.numbers .item .img {
  color: #09304C;
  width: 3.75rem;
  padding-bottom: 1.5625rem;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #09304C;
}

.numbers .item .img .icon {
  width: 2.8125rem;
  height: 2.8125rem;
}

.numbers .item .desc {
  font-size: 1.375rem;
  line-height: 1.7em;
  max-width: 280px;
}

.numbers .item .desc.full {
  max-width: 100%;
}

.numbers .item h2 {
  color: #09304C;
}

.numbers .item h2 sup {
  font-size: 1.25rem;
  top: -1.8rem;
}

.numbers .item p:last-child {
  margin-bottom: 0;
}

.numbers .foot {
  display: none;
}

.state {
  width: 100%;
  margin: 1.25rem auto;
}

.state .state-title {
  line-height: 1.3em;
  font-weight: 600;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
}

.state .state-title .icon {
  width: 2.5rem;
  height: 2.5rem;
  flex: 0 0 2.5rem;
  margin-right: 10px;
}

.state .state-amount {
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 1.1em;
  margin-bottom: 5px;
}

.state .state-amount .small {
  font-size: 1.25rem;
}

.state .state-note {
  font-size: 0.875rem;
  margin-top: 0.3125rem;
}

.state .loader {
  width: 100%;
  background: #fff;
  border-radius: 20px;
  height: 2.1875rem;
  overflow: hidden;
  position: relative;
  border: 2px solid #fff;
}

.state .loader .bar {
  font-weight: 600;
  height: 100%;
  width: 50%;
  background: #59B0A3;
  color: #09304C;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
}

.state .loader .bar .txt {
  white-space: nowrap;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.numbers-state .numbers .item {
  border: none;
}

.numbers-col {
  display: flex;
  align-items: center;
}

.roi .section-head {
  text-align: left;
}

.roi .img {
  padding-top: 1.875rem;
}

.roi .img .frame {
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: 50%;
}

.roi .img .frame.big {
  max-width: 55rem;
}

.roi .desc {
  margin-top: 2.5rem;
  margin-bottom: 6.25rem;
  max-width: 950px;
}

.roi .desc p:last-child {
  margin-bottom: 0;
}

.roi .foot {
  justify-content: center;
  display: none;
  padding-top: 1.875rem;
}

.expansion .section-head {
  text-align: left;
  margin-bottom: 5rem;
}

.expansion .cite {
  font-size: 1.625rem;
  line-height: 1.3em;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 2.5rem;
  max-width: 40.625rem;
}

.cz-sk-map {
  margin: 0 auto;
  max-width: 1325px;
  position: relative;
}

.cz-sk-map .map-marker {
  position: absolute;
  width: 1.1875rem;
  height: 1.375rem;
  margin-left: -0.59375rem;
  margin-top: -0.6875rem;
  background: url("../img/map-marker.svg") 0 0 no-repeat;
  background-size: cover !important;
  transition: 0.3s all;
  cursor: pointer;
}

.cz-sk-map .map-marker:after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: pulsePlus 4s infinite;
}

.cz-sk-map .map-marker .popup {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5em;
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translate(-50%, -100%);
  -moz-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  -o-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  width: 15rem;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  color: #59B0A3;
  padding: 0.9375rem;
  margin-top: -0.9375rem;
  display: none;
}

.cz-sk-map .map-marker .popup:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 7.5px 0 7.5px;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -7px;
  margin-left: -8px;
}

.cz-sk-map .map-marker:hover .popup {
  display: block;
}

.cz-sk-map .map-marker.alt {
  width: 2.5rem;
  height: 2.875rem;
  background-image: url("../img/map-marker-alt.svg");
}

.cz-sk-map .map-marker.alt .popup {
  display: block;
}

.faq .section-head-l, .faq-list .section-head-l {
  padding-top: 6.875rem;
}

.faq .section-head-l .suphead, .faq-list .section-head-l .suphead {
  max-width: 550px;
}

.faq .section-head-l h2, .faq-list .section-head-l h2 {
  margin-bottom: 3.125rem;
  max-width: 350px;
}

.faq .item-list, .faq-list .item-list {
  width: 100%;
}

.faq .item, .faq-list .item {
  width: 100%;
  margin: 0;
  border-top: 1px solid #09304C;
}

.faq .item .question, .faq-list .item .question {
  cursor: pointer;
  display: block;
  position: relative;
  transition: 0.3s all;
  text-decoration: none;
  color: #09304C;
}

.faq .item .question .plus, .faq-list .item .question .plus {
  width: 1.375rem;
  height: 1.375rem;
  position: absolute;
  right: 1.875rem;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.faq .item .question .plus:before, .faq-list .item .question .plus:before {
  content: '';
  width: 1.375rem;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.6875rem;
  margin-top: -0.0625rem;
  background: #09304C;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: 0.3s all;
}

.faq .item .question .plus:after, .faq-list .item .question .plus:after {
  content: '';
  width: 1.375rem;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.6875rem;
  margin-top: -0.0625rem;
  background: #09304C;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.3s all;
}

.faq .item .question:hover, .faq-list .item .question:hover {
  color: #59B0A3;
}

.faq .item .answer, .faq-list .item .answer {
  display: none;
}

.faq .item.active .answer, .faq-list .item.active .answer {
  display: block;
}

.faq .item.active .question .plus:before, .faq-list .item.active .question .plus:before {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.faq .item.active .question .plus:after, .faq-list .item.active .question .plus:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.faq .question, .faq-list .question {
  font-weight: 600;
  padding: 2.1875rem 5.625rem 2.1875rem 1.25rem;
}

.faq .answer, .faq-list .answer {
  padding: 0 5.625rem 2.1875rem 1.25rem;
}

.faq .foot, .faq-list .foot {
  display: none;
  justify-content: center;
}

.roi-farm .primary {
  max-width: 890px;
  margin-bottom: 6.25rem;
}

.roi-farm .primary .desc {
  margin-bottom: 2.5rem;
}

.roi-farm .secondary {
  font-size: 1.125rem;
  font-weight: 600;
  color: #09304C;
}

.roi-farm .secondary h2, .roi-farm .secondary .h2, .roi-farm .secondary h3, .roi-farm .secondary .h3 {
  color: #09304C;
  margin-bottom: 2.8125rem;
  text-transform: uppercase;
}

.roi-farm .secondary ol {
  list-style: none;
  margin: 0 0 1.25rem 0;
  padding: 0;
  counter-reset: counter;
  margin-left: 0;
}

.roi-farm .secondary ol li {
  padding: 0 0 0 2.5rem;
}

.roi-farm .secondary ol li:before {
  color: #09304C;
  content: "0" counter(counter) ". ";
}

.roi-farm .numbers .item-list {
  margin-top: 0;
  margin-bottom: 0;
}

.roi-farm .numbers .item {
  margin-top: 0;
  margin-bottom: 8.125rem;
}

.roi-farm .numbers .item .desc {
  color: #fff;
}

.phases {
  overflow: hidden;
  position: relative;
}

.phases .section-head-l {
  margin-bottom: 6.25rem;
  max-width: 980px;
}

.phases .section-head-l .desc {
  margin-top: 2.5rem;
}

.phases .item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 2.5rem;
  border-left: 1px solid rgba(12, 49, 75, 0.4);
  padding-top: 3.125rem;
}

.phases .item .img {
  width: 3.75rem;
  height: 3.75rem;
  margin-bottom: 4.375rem;
}

.phases .item .img .icon {
  width: 3.75rem;
  height: 3.75rem;
  flex: 0 0 3.75rem;
}

.phases .item .text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}

.phases .item h3 {
  color: #09304C;
  flex-grow: 1;
}

.phases .item p {
  line-height: 1.5em;
}

.phases p:last-child {
  margin-bottom: 0;
}

.phases .foot {
  position: relative;
  z-index: 20;
}

.phases.invert .item-list {
  margin: 0 -2.5rem;
}

.phases.invert .item {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.phases.invert .item .img .icon {
  color: #fff;
}

.phases.invert .item h3 {
  color: #fff;
}

.phases.phases-list .item {
  margin-bottom: 2.5rem;
  padding-top: 2.5rem;
}

.phases.phases-list .item .img {
  width: 4.375rem;
  height: 4.375rem;
  margin-bottom: 3.125rem;
  position: relative;
}

.phases.phases-list .item .img .icon {
  width: 4.375rem;
  height: 4.375rem;
}

.phases.phases-list .item .img.cross:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 130%;
  height: 4px;
  margin-left: -65%;
  background: #59B0A3;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.development {
  padding-right: 15px;
}

.development h2 {
  max-width: 1180px;
  margin-bottom: 2.5rem;
}

.development .desc {
  max-width: 1130px;
}

.development .numbers {
  padding-top: 3.125rem;
  margin-bottom: 0;
  max-width: 945px;
}

.development .additional {
  max-width: 1130px;
}

.development .additional .text {
  max-width: 480px;
}

.development .foot {
  margin-top: 3.125rem;
}

.skyscraper-image {
  display: flex;
}

.skyscraper-image .sky-img {
  flex: 0 0 30%;
  max-width: 30%;
}

.skyscraper-image .sky-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.skyscraper-image .sky-text {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.skyscraper-image .sky-text .block.alt {
  flex-grow: 1;
}

.stocks-program {
  overflow: hidden;
}

.stocks-program .container-fluid {
  padding: 0;
}

.stocks-program .main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.stocks-program .main .text {
  max-width: 960px;
  margin-bottom: 6.25rem;
  padding-right: 175px;
  padding-left: 15px;
}

.stocks-program .main .img {
  width: 100%;
  flex-grow: 1;
  margin-left: -15px;
  padding-right: 175px;
}

.stocks-program .main .img img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.stocks-program .tabs {
  padding-right: 15px;
}

.ordered-list {
  counter-reset: counter;
}

.ordered-list .item {
  margin-bottom: 3.75rem;
  counter-increment: counter;
}

.ordered-list .item .desc {
  max-width: 400px;
}

.ordered-list .item:before {
  font-size: 3.75rem;
  line-height: 1em;
  font-weight: 600;
  content: counter(counter) ". ";
  margin-bottom: 1.25rem;
  display: block;
}

.ordered-list h3 {
  color: #09304C;
  text-transform: uppercase;
}

.gallery-text {
  padding: 8.125rem 8.125rem;
}

.gallery-text:last-child {
  padding-bottom: 0;
}

.gallery-text .text {
  display: flex;
  align-items: center;
}

.gallery-text .text .inner {
  max-width: 60.625rem;
  padding-left: 3.125rem;
}

.gallery-text .text h2 {
  margin-bottom: 3.75rem;
}

.gallery-text .text p:last-child {
  margin-bottom: 0;
}

.gallery-text .gallery-slider-wrapper {
  max-width: 1280px;
}

.platforms {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.platforms .head {
  font-size: 1.125rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1.875rem;
}

.platforms .item-list {
  justify-content: space-between;
  margin: 0 -15px;
}

.platforms .item {
  padding: 0 15px;
  margin-bottom: 1.875rem;
}

.platforms .item .item-inner {
  width: 100%;
  height: 9.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
  padding: 0.9375rem;
  box-shadow: 0 0 15px 0 rgba(9, 48, 76, 0.1);
}

.platforms.alt {
  margin: 0;
  max-width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.platforms.alt .head {
  text-align: left;
  max-width: 200px;
  margin-bottom: 0;
}

.platforms.alt .item-list {
  justify-content: flex-start;
  margin: 0 -30px;
}

.platforms.alt .item {
  flex: 0 0 auto;
  padding: 0 30px;
  margin-bottom: 0;
}

.platforms.alt .item img {
  opacity: .5;
  filter: brightness(0) invert(1);
  -ms-filter: brightness(0) invert(1);
  transition: 0.3s all;
}

.platforms.alt .item-inner {
  box-shadow: none;
  height: 80px;
  padding: 0;
  justify-content: flex-start;
  background: none;
}

.platforms.alt .item-inner:hover img {
  opacity: 1;
}

.chapters h2 {
  margin-bottom: 2.5rem;
}

.chapters .time {
  color: #59B0A3;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 10px;
}

.chapters ul li {
  position: relative;
  padding: 10px 10px 10px 80px !important;
  border-bottom: 1px solid #e8e8e8;
}

.chapters ul li a {
  color: #09304C;
  text-decoration: none;
}

.chapters ul li a:hover {
  text-decoration: underline;
}

.box-guest {
  background: #09304C;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.box-guest .img {
  flex: 0 0 100%;
  padding: 40px 40px 0 0;
  display: flex;
  justify-content: flex-end;
}

.box-guest .img img {
  opacity: 0.2;
}

.box-guest .text {
  flex: 0 0 100%;
  margin-left: -100%;
  padding: 2.5rem;
}

.box-guest .text .desc {
  max-width: 350px;
}

.box-guest .text h3 {
  margin-bottom: 2.5rem;
}

.box {
  background: #E8E8E8;
  padding: 2.5rem;
}

.box-contact {
  background: #59B0A3;
  padding: 2.5rem;
}

.box-contact h2, .box-contact h3, .box-contact a {
  color: #fff;
}

.box-contact .contact-list-medium a {
  color: #09304C;
}

.contact-data .item-list {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.contact-data .item {
  margin: 1.25rem 0;
  display: flex;
}

.contact-data .item h3 {
  margin: 0 0 1.875rem 0;
}

.contact-data .item-inner {
  background: #E8E8E8;
  padding: 2.5rem;
  width: 100%;
}

.contact-data .item-inner.alt {
  background: #fff;
}

.contact-data .link-map {
  display: inline-flex;
  align-items: center;
}

.contact-data .link-map .icon {
  margin-right: 0.625rem;
}

/* Calculator */
.calculator .section-head {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5.625rem;
}

.calculator .section-head.wide {
  max-width: 950px;
  margin-bottom: 2.5rem;
}

.calculator .foot {
  display: flex;
  justify-content: center;
}

.calculator.double-cols {
  padding: 0 50px;
}

.calculator.double-cols #range-calculator {
  margin-bottom: 7.5rem;
}

.calculator.double-cols #range-calculator .min {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.calculator.double-cols #range-calculator .max {
  -webkit-transform: translate(-100%, 0);
  -moz-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  -o-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}

.calculator-element .head {
  font-size: 1.125rem;
  line-height: 1.3em;
  font-weight: 600;
  color: #09304C;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.25rem;
}

.calculator-element .amount {
  font-size: 3.125rem;
  line-height: 1.3em;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-bottom: 3.75rem;
  white-space: nowrap;
}

.calculator-element.alt #range-calculator.noUi-horizontal {
  background: #09304C;
}

.calculator-element.alt #range-calculator.noUi-horizontal .noUi-connect {
  background: #59B0A3;
}

.calculator-element.alt .amount {
  font-size: 2.3125rem;
  color: #59B0A3;
}

.amounts {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 9.375rem;
}

.amounts .item-list {
  justify-content: center;
}

.amounts .item {
  text-align: center;
  color: #09304C;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.amounts .item .label {
  font-size: 1.375rem;
  margin-bottom: 0;
}

.amounts .item .amount {
  font-weight: 600;
  color: #09304C;
  margin: 0;
  white-space: nowrap;
}

.amounts .item .amount.small {
  font-size: 2.5rem;
  line-height: 1.3em;
  margin-top: 0.625rem;
}

.amounts .item .amount.light {
  font-weight: 400;
}

#range-calculator {
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14.375rem;
}

#range-calculator.noUi-horizontal {
  height: 1px;
  box-shadow: none;
  border: none;
  background: #fff;
}

#range-calculator.noUi-horizontal .noUi-handle {
  border-radius: 50%;
  width: 4.25rem;
  height: 4.25rem;
  border: none;
  right: -2.125rem;
  top: -2.125rem;
  cursor: grab;
  border: 2px solid #59B0A3;
  background: #fff url("../img/handle.svg") center center no-repeat;
  background-size: 2.125rem;
}

#range-calculator.noUi-horizontal .noUi-handle:before, #range-calculator.noUi-horizontal .noUi-handle:after {
  content: none;
}

#range-calculator.noUi-horizontal .noUi-connects {
  height: 6px;
  top: -3px;
  z-index: 1;
}

#range-calculator.noUi-horizontal .noUi-connect {
  background: #fff;
}

#range-calculator.noUi-horizontal .noUi-base {
  z-index: auto;
}

#range-calculator.noUi-horizontal .noUi-origin {
  z-index: 3;
}

#range-calculator:before {
  content: '';
  position: absolute;
  left: -10.5px;
  top: -10.5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #59B0A3;
  z-index: 2;
}

#range-calculator:after {
  content: '';
  position: absolute;
  right: -10.5px;
  top: -10.5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #59B0A3;
  z-index: 2;
}

#range-calculator .min {
  font-size: 1.125rem;
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 45px;
  text-align: center;
  white-space: nowrap;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  white-space: nowrap;
}

#range-calculator .max {
  font-size: 1.125rem;
  font-weight: 600;
  position: absolute;
  left: 100%;
  top: 45px;
  text-align: center;
  white-space: nowrap;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  white-space: nowrap;
}

.history {
  max-width: 2200px;
  margin: 0 auto;
}

.history .section-head {
  margin-bottom: 6.25rem;
}

.history .entry ul li:before {
  background: #fff;
}

.milestones .item-inner {
  max-width: 350px;
  padding-right: 30px;
}

.milestones .head {
  font-size: 1.125rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.625rem;
}

.milestones .date {
  font-size: 14px;
  color: #59B0A3;
  margin-bottom: 0.625rem;
}

.milestones .vline {
  width: 1px;
  height: 90px;
  background: #59B0A3;
  margin-bottom: 1.5625rem;
}

.link-nav {
  margin-top: 2.5rem;
}

.link-nav .item-list {
  margin: 0 -10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.link-nav .item-list .item {
  padding: 0 10px;
  margin: 5px 0;
}

.link-nav .item-list .item a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.3125rem 0.9375rem;
  color: #fff;
  background: #59B0A3;
  text-decoration: none;
  border-radius: 20px;
  transition: 0.3s all;
}

.link-nav .item-list .item a:hover {
  background: #fff;
  color: #59B0A3;
}

.contact-list-big {
  font-size: 1.75rem;
}

.contact-list-big ul li {
  font-weight: 500;
  line-height: 1.3em;
  margin-bottom: 0.625rem;
  padding: 0;
}

.contact-list-big a {
  color: #59B0A3;
}

.contact-list-medium h3 {
  margin-bottom: 2.5rem;
}

.contact-list-medium ul li {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3em;
  margin-bottom: 0.625rem;
  padding: 0 0 0 3.75rem;
  position: relative;
}

.contact-list-medium ul li .icon {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  left: 0;
  top: 0;
}

.contact-list-medium a {
  color: #59B0A3;
}

.contact-list-medium .note {
  padding: 0 0 0 3.75rem;
  margin-bottom: 1.875rem;
}

.contact-list-medium .note:last-child {
  margin-bottom: 0;
}

.tiles-downloads .item-list {
  margin: 0;
}

.tiles-downloads .item {
  padding: 0;
  display: flex;
}

.tiles-downloads .item-inner {
  display: flex;
}

.tiles-downloads .item-inner .img {
  flex: 0 0 100%;
  margin-left: -100%;
  position: relative;
  z-index: 5;
}

.tiles-downloads .item-inner .img:after {
  content: '';
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(9, 48, 76, 0.8);
}

.tiles-downloads .item-inner .img img {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tiles-downloads .item-inner .text {
  flex: 0 0 100%;
  position: relative;
  z-index: 10;
  padding: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tiles-downloads .item-inner .text h3 {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}

.tiles-downloads .item-inner .text ul li {
  padding: 0;
  margin: 0 auto;
  border-top: 1px solid #59B0A3;
  max-width: 300px;
}

.tiles-downloads .item-inner .text ul li:first-child {
  border-top: none;
}

.tiles-downloads .item-inner .text a {
  display: block;
  padding: 1.25rem 0;
  color: #fff;
  text-decoration: none;
}

.tiles-downloads .item-inner .text a:hover {
  text-decoration: underline;
}

.tiles-downloads .item-inner .symbol {
  display: flex;
  justify-content: center;
}

.tiles-downloads .item-inner .symbol img {
  margin-bottom: 1.25rem;
}

.newsletter.lined {
  text-align: left;
  padding: 2.5rem 0;
}

.newsletter.lined .section-head {
  text-align: left;
  margin-bottom: 0.625rem;
}

.newsletter.lined .section-head .symbol {
  justify-content: flex-start;
}

.newsletter.lined .intro {
  text-align: left;
  max-width: 100%;
}

.newsletter.lined .intro p {
  margin-bottom: 0;
}

.newsletter.lined .row {
  align-items: center;
}

.newsletter.lined .note {
  margin: 0;
}

.schedule {
  margin: 5.625rem auto 0 auto;
  padding-top: 3.125rem;
  max-width: 560px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.schedule h2, .schedule .h2 {
  text-transform: uppercase;
}

.schedule h3, .schedule .h3 {
  margin-bottom: 4.0625rem;
}

.schedule:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 10000%;
  margin-left: -5000%;
  height: 5rem;
  background: #09304C;
}

.schedule-foot {
  padding-top: 2.5rem;
}

.schedule-foot .note {
  margin-bottom: 5rem;
}

.countdown {
  position: relative;
  z-index: 10;
}

.countdown .item-list {
  margin: 0 -10px;
}

.countdown .item {
  padding: 0 10px;
}

.countdown .item .val {
  font-size: 2.1875rem;
  font-weight: 600;
  background: #fff;
  color: #59B0A3;
  height: 5rem;
  border-radius: 2.5rem;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.countdown .item .label {
  display: block;
  margin-top: 0.75rem;
  text-align: center;
  color: #fff;
  height: 1.75rem;
}

.interest-nav {
  position: relative;
}

.interest-nav .section-head {
  margin-bottom: 4.375rem;
}

.interest-nav .item-list {
  justify-content: center;
  margin: -1.875rem 0;
}

.interest-nav .item-inner {
  color: #09304C;
  text-transform: uppercase;
  padding: 0.5rem 2.5rem;
  margin: 0;
  text-decoration: none;
  border-right: 1px solid #09304C;
  display: block;
  width: 100%;
}

.interest-nav .item-inner:hover {
  text-decoration: underline;
}

.interest-nav .item {
  padding: 0;
  margin: 1.875rem 0;
  text-align: center;
}

.interest-nav .item:last-child .item-inner {
  border: none;
}

.interest-nav .leaf-4 {
  position: absolute;
  right: 0;
  bottom: -6.875rem;
  pointer-events: none;
}

.boxes-img .section-head {
  margin-bottom: 7.5rem;
}

.boxes-img .section-head.narrow {
  max-width: 1410px;
}

.boxes-img .item {
  margin-bottom: 2.5rem;
  display: flex;
  padding-top: 10rem;
}

.boxes-img .item h3 {
  text-transform: uppercase;
  color: #09304C;
  margin-bottom: 1.875rem;
}

.boxes-img .item .img {
  margin-bottom: 5rem;
  max-width: 20rem;
  margin: -10rem auto 5rem auto;
  overflow: hidden;
  border-radius: 50%;
}

.boxes-img .item .text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.boxes-img .item .main {
  width: 100%;
}

.boxes-img .item .main.flex-grow {
  flex-grow: 1;
}

.boxes-img .item .additional {
  width: 100%;
  margin-top: 2.5rem;
  padding-top: 3.125rem;
  border-top: 1px solid #E8E8E8;
  font-size: 1.125rem;
  font-weight: 600;
  flex-grow: 1;
}

.boxes-img .item .foot {
  width: 100%;
  margin-top: 2.5rem;
  padding-top: 3.125rem;
  border-top: 1px solid #E8E8E8;
  font-size: 1.125rem;
  font-weight: 600;
}

.boxes-img .item-inner {
  background: #fff;
  padding: 0 5rem 5rem 5rem;
  display: flex;
  flex-direction: column;
}

.connection-list .item {
  font-size: 1.875rem;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 7.1875rem;
  position: relative;
}

.connection-list .item:after {
  content: '';
  position: absolute;
  left: 4.6875rem;
  top: 0;
  height: 100%;
  width: 2px;
  background: #09304C;
}

.connection-list .item .img {
  flex: 0 0 9.375rem;
  justify-content: center;
  display: flex;
  background: #59B0A3;
  padding: 1.875rem 0;
  position: relative;
  z-index: 10;
}

.connection-list .item .img .icon {
  width: 4.6875rem;
  height: 4.6875rem;
  flex: 0 0 4.6875rem;
}

.connection-list .item .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.connection-list .item .icon-arr-r {
  color: #fff;
  display: block;
  margin-top: 0.9375rem;
}

.connection-list .item:last-child:after {
  content: none;
}

.connection-list .item-inner {
  display: flex;
}

.share-success .section-head-l {
  margin-bottom: 4.375rem;
}

.farm-cats {
  max-width: 1940px;
  margin-left: auto;
  margin-right: auto;
}

.farm-cats .section-head {
  margin-bottom: 4.375rem;
}

.farm-cats .item-list {
  margin: 0 -1.6875rem;
  justify-content: center;
}

.farm-cats .item {
  padding: 0 1.6875rem;
  font-size: 1.75rem;
  margin-bottom: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}

.farm-cats .item .item-inner {
  border-bottom: 7px solid #9F1DA5;
  height: 5.625rem;
  padding: 0 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.farm-cats .item.blue .item-inner {
  border-bottom: 7px solid #4EAED1;
}

.farm-cats .item.green .item-inner {
  border-bottom: 7px solid #92B42B;
}

.icon-circle {
  margin-right: 1.875rem;
  width: 3.375rem;
  height: 3.375rem;
  flex: 0 0 3.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #09304C;
  border-radius: 50%;
}

.icon-circle .icon {
  width: 1.75rem;
  height: 1.75rem;
  flex: 0 0 1.75rem;
}

.modern h2 {
  margin-bottom: 4.0625rem;
}

.modern .symbol {
  margin-bottom: 3.125rem;
}

.modern .desc {
  margin-bottom: 6.25rem;
}

.modern .img {
  display: flex;
  justify-content: flex-end;
}

.trust .item {
  display: flex;
  max-width: 640px;
  margin: 0 auto;
}

.trust .item .img {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trust .item .img .frame {
  max-width: 400px;
}

.trust .item .text {
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: -100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trust .intro {
  max-width: 730px;
}

.trust .btn-set {
  display: flex;
  justify-content: center;
}

.icon-list {
  color: #09304C;
}

.icon-list .item {
  text-align: center;
  margin-bottom: 6.25rem;
}

.icon-list .item h3 {
  color: #09304C;
}

.icon-list .item .icon {
  width: 4.0625rem;
  height: 4.0625rem;
  margin-bottom: 1.25rem;
}

.bar-nav {
  max-width: 1150px;
}

.bar-nav .item-list {
  margin: 0 -10px;
}

.bar-nav .item {
  margin-bottom: 1.25rem;
  padding: 0 10px;
}

.bar-nav .item .icon-circle {
  margin-right: 1.25rem;
}

.bar-nav .item .icon-circle .icon {
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
}

.bar-nav .item-inner {
  font-size: 1.5625rem;
  text-transform: uppercase;
  font-weight: 600;
  background: #E8E8E8;
  height: 6.5625rem;
  padding: 0 2.1875rem;
  display: inline-flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: #09304C;
}

.lector {
  display: flex;
  padding-bottom: 7.5rem;
  margin-bottom: 7.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.lector .main {
  flex: 0 0 35%;
}

.lector .main h2 {
  max-width: 580px;
  margin-bottom: 3.125rem;
}

.lector .main .name {
  font-size: 1.75rem;
  font-weight: 600;
}

.lector .img {
  flex: 0 0 30%;
}

.lector .img .frame {
  display: block;
  margin: 0 auto;
  max-width: 258px;
}

.lector .text {
  flex: 0 0 35%;
}

.lector .entry ul li:before {
  background: #fff;
}

.vocabulary .item {
  position: relative;
  top: 0;
  transition: 0.3s all;
}

.vocabulary .item:hover {
  top: -5px;
}

.vocabulary .item-inner {
  width: 100%;
  font-weight: 600;
  padding: 1.25rem 4.375rem 1.25rem 0;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  border-top: 1px solid #09304C;
  height: 100%;
  color: #09304C;
  text-decoration: none;
}

.vocabulary .item-inner:hover {
  color: #59B0A3;
}

.people .section-head {
  margin-bottom: 3.75rem;
}

.people .item-list {
  margin-bottom: -5rem;
}

.people .item {
  margin-bottom: 5rem;
}

.people .item .img {
  margin-bottom: 5rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.people .item .img .frame {
  position: relative;
}

.people .item .img .frame:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  left: -1.875rem;
  top: 1.875rem;
  background: #E8E8E8;
}

.people .item .text .job {
  font-weight: 700;
  max-width: 250px;
}

.steps .item-list {
  margin: 0 -1px;
}

.steps .item {
  display: flex;
  padding: 0 1px;
  margin-bottom: 2px;
}

.steps .item .img {
  margin-bottom: 1.25rem;
}

.steps .item .icon {
  width: 4.375rem;
  height: 4.375rem;
}

.steps .item h3 {
  margin-bottom: 1.875rem;
  flex-grow: 1;
  max-width: 315px;
}

.steps .text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.steps .item-inner {
  background: #fff;
  padding: 2.1875rem;
  display: flex;
  flex-direction: column;
}

.steps .foot {
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
}

.development-photo {
  display: flex;
  color: #fff;
  position: relative;
}

.development-photo .section-head {
  max-width: 950px;
}

.development-photo .img {
  flex: 0 0 100%;
}

.development-photo .img:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(9, 48, 76, 0.8);
}

.development-photo .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.development-photo .symbol {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.5;
  text-align: center;
}

.development-photo .symbol img {
  width: 150%;
  height: 150%;
  display: inline-block;
  mix-blend-mode: exclusion;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.development-photo .text {
  flex: 0 0 100%;
  margin-left: -100%;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.development-photo .text .section-head, .development-photo .text .intro {
  position: relative;
  z-index: 10;
}

.company-structure h2 {
  margin-bottom: 2.5rem;
}

.company-structure .box {
  margin-bottom: 2.5rem;
}

.company-structure .item {
  margin-bottom: 2.5rem;
  display: flex;
}

.company-structure .item .img {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-bottom: 2px solid #E8E8E8;
  margin-bottom: 2.5rem;
}

.company-structure .item .text {
  font-size: 0.9375rem;
}

.company-structure .item .text h4 {
  min-height: 4.0625rem;
  font-weight: 600;
}

.company-structure .item-posts .head {
  width: 100%;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  height: 150px;
  display: inline-flex;
  align-items: center;
  border-bottom: 2px solid #E8E8E8;
}

.company-structure .item-posts h3 {
  font-size: 1.5625rem;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.company-structure .item-posts h3 .icon {
  margin-right: 1.25rem;
  width: 3.125rem;
  height: 3.125rem;
  order: 1;
}

.company-structure .item-posts ul li {
  font-size: 1rem;
  font-weight: 700;
  margin: 0.625rem 0;
  padding: 0 0 0 30px;
  position: relative;
}

.company-structure .item-posts ul li:before {
  content: '';
  position: absolute;
  left: 0.625rem;
  top: 0.625rem;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: #59B0A3;
}

.company-structure .item-posts ul li a {
  text-decoration: none;
  padding: 0.625rem 0;
}

.company-structure .item-posts ul li a:hover {
  text-decoration: underline;
}

.company-structure .item-inner {
  width: 100%;
  background: #fff;
  padding: 1.25rem;
}

.base-timer {
  position: relative;
  width: 12.5rem;
  height: 12.5rem;
  margin: 0 auto 20px auto;
}

.base-timer .base-timer__svg {
  transform: scaleX(-1);
}

.base-timer .base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer .base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: #09304C;
}

.base-timer .base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: #59B0A3;
}

.base-timer .base-timer__label {
  position: absolute;
  width: 12.5rem;
  height: 12.5rem;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5625rem;
}

.base-timer-head {
  text-align: center;
}

/* Form search suggestion */
.search-bar .wp_autosearch_form_wrapper {
  background: none !important;
  height: auto !important;
  border: none !important;
  padding-right: 50px;
}

.search-bar .wp_autosearch_input, .search-bar input.text {
  font: 500 15px/24px Montserrat,sans-serif;
  color: #59B0A3 !important;
  border: 1px solid #59B0A3 !important;
  padding: 0 10px !important;
  height: 50px !important;
  width: 100% !important;
  float: none !important;
  background: transparent !important;
}

.search-bar .wp_autosearch_input.wp_autosearch_indicator, .search-bar input.text.wp_autosearch_indicator {
  background-image: url("../img/spinner.svg") !important;
  background-position: right center !important;
  background-repeat: no-repeat !important;
  background-size: 30px !important;
}

.search-bar .wp_autosearch_input::placeholder, .search-bar input.text::placeholder {
  color: #fff;
}

.search-bar .wp_autosearch_submit, .search-bar .btn {
  background: #59B0A3 !important;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  padding: 0;
  transition: 0.3s all;
}

.search-bar .wp_autosearch_submit:hover, .search-bar .btn:hover {
  width: 50px;
  background: #74d9ca !important;
  border-color: #74d9ca !important;
}

.search-bar .wp_autosearch_submit:after, .search-bar .btn:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  background: url("../img/search.svg") 0 0 no-repeat;
  background-size: contain;
}

.search-bar.main {
  margin-bottom: 40px;
}

.search-bar.main .wp_autosearch_form_wrapper {
  padding-right: 50px;
}

.search-bar.main .wp_autosearch_input {
  background-color: #f4f4f4 !important;
  height: 50px !important;
  float: none !important;
}

.search-bar.main .wp_autosearch_submit,
.search-bar.main .wp_autosearch_submit:hover {
  width: 50px;
  height: 50px;
}

body .wp_autosearch_result {
  padding-left: 10px;
  padding-right: 10px;
}

body .wp_autosearch_suggestions {
  font-family: Montserrat,sans-serif;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

body .wp_autosearch_suggestions .wp_autosearch_image {
  display: none !important;
}

body .wp_autosearch_suggestions .wp_autosearch_category {
  font-size: 16px;
  font-family: Montserrat,sans-serif;
  font-weight: 600;
  padding: 15px 10px;
  height: auto;
  background: #fff !important;
  border-bottom: 1px solid #e5e5e5 !important;
  color: #000 !important;
}

body .wp_autosearch_suggestions .wp_autosearch_more {
  display: none !important;
}

body .wp_autosearch_suggestions li {
  padding-left: 0;
}

body .wp_autosearch_suggestions li a {
  display: flex;
  align-items: center;
  height: 45px;
  border-bottom: 1px solid #e5e5e5;
}

body .wp_autosearch_suggestions li a img {
  float: none;
}

body .wp_autosearch_suggestions li a .searchheading {
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
  height: 50px;
  padding-top: 0;
}

body .wp_autosearch_suggestions li:before {
  content: none;
}

body .wp_autosearch_suggestions li:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

body .wp_autosearch_suggestions li.wps_odd {
  background: #fff !important;
}

body .wp_autosearch_suggestions li.wps_odd.ac_over {
  background: rgba(0, 0, 0, 0.05) !important;
  color: #000;
}

body .wp_autosearch_suggestions li.wps_odd.ac_over a .searchheading {
  color: #000 !important;
}

body .wp_autosearch_suggestions li.wps_even {
  background: #fff !important;
}

body .wp_autosearch_suggestions li.wps_even.ac_over {
  background: rgba(0, 0, 0, 0.05) !important;
  color: #000;
}

body .wp_autosearch_suggestions li.wps_even.ac_over a .searchheading {
  color: #000 !important;
}

.search-results {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.search-results h3 a {
  text-decoration: none;
}

.search-results h3 a:hover {
  text-decoration: underline;
}

.search-results p {
  margin: 0;
}

.search-results .item {
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  border-bottom: 2px solid #e5e5e5;
}

/* Various */
.text-big-18 {
  font-size: 1.125rem;
  font-weight: 600;
}

.text-big-20 {
  font-size: 1.25rem;
}

.text-big-22 {
  font-size: 1.375rem;
}

.text-orange {
  color: #F2A921;
}

.pl-130 {
  padding-left: 8.125rem;
}

.pb-140 {
  padding-bottom: 8.75rem;
}

.mb-90 {
  margin-bottom: 5.625rem;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.ttu {
  text-transform: uppercase;
}

@keyframes showBtn {
  0% {
    display: none;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    max-height: 0;
  }
  5% {
    display: inline-flex;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    max-height: 0;
  }
  100% {
    display: inline-flex;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    max-height: 63px;
  }
}

@keyframes showMapMarker {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  5% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsePlus {
  0% {
    box-shadow: 0 0 0 0 #59b0a3, 0 0 0 0 #59b0a3;
  }
  40% {
    box-shadow: 0 0 0 30px rgba(89, 176, 163, 0), 0 0 0 0 #59b0a3;
  }
  80% {
    box-shadow: 0 0 0 30px rgba(89, 176, 163, 0), 0 0 0 30px rgba(89, 176, 163, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(89, 176, 163, 0), 0 0 0 30px rgba(89, 176, 163, 0);
  }
}

.dropdown-menu li a {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  white-space: nowrap;
  position: relative;
  padding-left: 30px;
}

.dropdown-menu li a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -7.5px;
  width: 20px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: 0 0;
}

.dropdown-menu li[data-value="+93 (Afghanistan)"] a:after {
  background-image: url("../img/flags/af.svg");
}

.dropdown-menu li[data-value="+358 (Aland Islands)"] a:after {
  background-image: url("../img/flags/ax.svg");
}

.dropdown-menu li[data-value="+355 (Albania)"] a:after {
  background-image: url("../img/flags/al.svg");
}

.dropdown-menu li[data-value="+213 (Algeria)"] a:after {
  background-image: url("../img/flags/dz.svg");
}

.dropdown-menu li[data-value="+1684 (American Samoa)"] a:after {
  background-image: url("../img/flags/as.svg");
}

.dropdown-menu li[data-value="+376 (Andorra)"] a:after {
  background-image: url("../img/flags/ad.svg");
}

.dropdown-menu li[data-value="+244 (Angola)"] a:after {
  background-image: url("../img/flags/ao.svg");
}

.dropdown-menu li[data-value="+1264 (Anguilla)"] a:after {
  background-image: url("../img/flags/ai.svg");
}

.dropdown-menu li[data-value="+672 (Antarctica)"] a:after {
  background-image: url("../img/flags/aq.svg");
}

.dropdown-menu li[data-value="+1268 (Antigua and Barbuda)"] a:after {
  background-image: url("../img/flags/ag.svg");
}

.dropdown-menu li[data-value="+54 (Argentina)"] a:after {
  background-image: url("../img/flags/ar.svg");
}

.dropdown-menu li[data-value="+374 (Armenia)"] a:after {
  background-image: url("../img/flags/am.svg");
}

.dropdown-menu li[data-value="+297 (Aruba)"] a:after {
  background-image: url("../img/flags/aw.svg");
}

.dropdown-menu li[data-value="+61 (Australia)"] a:after {
  background-image: url("../img/flags/au.svg");
}

.dropdown-menu li[data-value="+43 (Austria)"] a:after {
  background-image: url("../img/flags/at.svg");
}

.dropdown-menu li[data-value="+994 (Azerbaijan)"] a:after {
  background-image: url("../img/flags/az.svg");
}

.dropdown-menu li[data-value="+1242 (Bahamas)"] a:after {
  background-image: url("../img/flags/bs.svg");
}

.dropdown-menu li[data-value="+973 (Bahrain)"] a:after {
  background-image: url("../img/flags/bh.svg");
}

.dropdown-menu li[data-value="+880 (Bangladesh)"] a:after {
  background-image: url("../img/flags/bd.svg");
}

.dropdown-menu li[data-value="+1246 (Barbados)"] a:after {
  background-image: url("../img/flags/bb.svg");
}

.dropdown-menu li[data-value="+375 (Belarus)"] a:after {
  background-image: url("../img/flags/by.svg");
}

.dropdown-menu li[data-value="+32 (Belgium)"] a:after {
  background-image: url("../img/flags/be.svg");
}

.dropdown-menu li[data-value="+501 (Belize)"] a:after {
  background-image: url("../img/flags/bz.svg");
}

.dropdown-menu li[data-value="+229 (Benin)"] a:after {
  background-image: url("../img/flags/bj.svg");
}

.dropdown-menu li[data-value="+1441 (Bermuda)"] a:after {
  background-image: url("../img/flags/bm.svg");
}

.dropdown-menu li[data-value="+975 (Bhutan)"] a:after {
  background-image: url("../img/flags/bt.svg");
}

.dropdown-menu li[data-value="+591 (Bolivia)"] a:after {
  background-image: url("../img/flags/bo.svg");
}

.dropdown-menu li[data-value="+599 (Bonaire, Sint Eustatius and Saba)"] a:after {
  background-image: url("../img/flags/bq.svg");
}

.dropdown-menu li[data-value="+387 (Bosnia and Herzegovina)"] a:after {
  background-image: url("../img/flags/ba.svg");
}

.dropdown-menu li[data-value="+267 (Botswana)"] a:after {
  background-image: url("../img/flags/bw.svg");
}

.dropdown-menu li[data-value="+55 (Bouvet Island)"] a:after {
  background-image: url("../img/flags/bv.svg");
}

.dropdown-menu li[data-value="+55 (Brazil)"] a:after {
  background-image: url("../img/flags/br.svg");
}

.dropdown-menu li[data-value="+246 (British Indian Ocean Territory)"] a:after {
  background-image: url("../img/flags/io.svg");
}

.dropdown-menu li[data-value="+673 (Brunei Darussalam)"] a:after {
  background-image: url("../img/flags/bn.svg");
}

.dropdown-menu li[data-value="+359 (Bulgaria)"] a:after {
  background-image: url("../img/flags/bg.svg");
}

.dropdown-menu li[data-value="+226 (Burkina Faso)"] a:after {
  background-image: url("../img/flags/bf.svg");
}

.dropdown-menu li[data-value="+257 (Burundi)"] a:after {
  background-image: url("../img/flags/bi.svg");
}

.dropdown-menu li[data-value="+855 (Cambodia)"] a:after {
  background-image: url("../img/flags/kh.svg");
}

.dropdown-menu li[data-value="+237 (Cameroon)"] a:after {
  background-image: url("../img/flags/cm.svg");
}

.dropdown-menu li[data-value="+1 (Canada)"] a:after {
  background-image: url("../img/flags/ca.svg");
}

.dropdown-menu li[data-value="+238 (Cape Verde)"] a:after {
  background-image: url("../img/flags/cv.svg");
}

.dropdown-menu li[data-value="+1345 (Cayman Islands)"] a:after {
  background-image: url("../img/flags/ky.svg");
}

.dropdown-menu li[data-value="+236 (Central African Republic)"] a:after {
  background-image: url("../img/flags/cf.svg");
}

.dropdown-menu li[data-value="+235 (Chad)"] a:after {
  background-image: url("../img/flags/td.svg");
}

.dropdown-menu li[data-value="+56 (Chile)"] a:after {
  background-image: url("../img/flags/cl.svg");
}

.dropdown-menu li[data-value="+86 (China)"] a:after {
  background-image: url("../img/flags/cn.svg");
}

.dropdown-menu li[data-value="+61 (Christmas Island)"] a:after {
  background-image: url("../img/flags/cx.svg");
}

.dropdown-menu li[data-value="+672 (Cocos (Keeling) Islands)"] a:after {
  background-image: url("../img/flags/cc.svg");
}

.dropdown-menu li[data-value="+57 (Colombia)"] a:after {
  background-image: url("../img/flags/co.svg");
}

.dropdown-menu li[data-value="+269 (Comoros)"] a:after {
  background-image: url("../img/flags/km.svg");
}

.dropdown-menu li[data-value="+242 (Congo)"] a:after {
  background-image: url("../img/flags/cg.svg");
}

.dropdown-menu li[data-value="+242 (Congo, Democratic Republic of the Congo)"] a:after {
  background-image: url("../img/flags/cd.svg");
}

.dropdown-menu li[data-value="+682 (Cook Islands)"] a:after {
  background-image: url("../img/flags/ck.svg");
}

.dropdown-menu li[data-value="+506 (Costa Rica)"] a:after {
  background-image: url("../img/flags/cr.svg");
}

.dropdown-menu li[data-value="+385 (Croatia)"] a:after {
  background-image: url("../img/flags/hr.svg");
}

.dropdown-menu li[data-value="+53 (Cuba)"] a:after {
  background-image: url("../img/flags/cu.svg");
}

.dropdown-menu li[data-value="+599 (Curacao)"] a:after {
  background-image: url("../img/flags/cw.svg");
}

.dropdown-menu li[data-value="+357 (Cyprus)"] a:after {
  background-image: url("../img/flags/cy.svg");
}

.dropdown-menu li[data-value="+420 (Czech Republic)"] a:after {
  background-image: url("../img/flags/cz.svg");
}

.dropdown-menu li[data-value="+45 (Denmark)"] a:after {
  background-image: url("../img/flags/dk.svg");
}

.dropdown-menu li[data-value="+253 (Djibouti)"] a:after {
  background-image: url("../img/flags/dj.svg");
}

.dropdown-menu li[data-value="+1767 (Dominica)"] a:after {
  background-image: url("../img/flags/dm.svg");
}

.dropdown-menu li[data-value="+1809 (Dominican Republic)"] a:after {
  background-image: url("../img/flags/do.svg");
}

.dropdown-menu li[data-value="+593 (Ecuador)"] a:after {
  background-image: url("../img/flags/ec.svg");
}

.dropdown-menu li[data-value="+20 (Egypt)"] a:after {
  background-image: url("../img/flags/eg.svg");
}

.dropdown-menu li[data-value="+503 (El Salvador)"] a:after {
  background-image: url("../img/flags/sv.svg");
}

.dropdown-menu li[data-value="+240 (Equatorial Guinea)"] a:after {
  background-image: url("../img/flags/gq.svg");
}

.dropdown-menu li[data-value="+291 (Eritrea)"] a:after {
  background-image: url("../img/flags/er.svg");
}

.dropdown-menu li[data-value="+372 (Estonia)"] a:after {
  background-image: url("../img/flags/ee.svg");
}

.dropdown-menu li[data-value="+251 (Ethiopia)"] a:after {
  background-image: url("../img/flags/et.svg");
}

.dropdown-menu li[data-value="+500 (Falkland Islands (Malvinas) )"] a:after {
  background-image: url("../img/flags/fk.svg");
}

.dropdown-menu li[data-value="+298 (Faroe Islands)"] a:after {
  background-image: url("../img/flags/fo.svg");
}

.dropdown-menu li[data-value="+679 (Fiji)"] a:after {
  background-image: url("../img/flags/fj.svg");
}

.dropdown-menu li[data-value="+358 (Finland)"] a:after {
  background-image: url("../img/flags/fi.svg");
}

.dropdown-menu li[data-value="+33 (France)"] a:after {
  background-image: url("../img/flags/fr.svg");
}

.dropdown-menu li[data-value="+594 (French Guiana)"] a:after {
  background-image: url("../img/flags/gf.svg");
}

.dropdown-menu li[data-value="+689 (French Polynesia)"] a:after {
  background-image: url("../img/flags/pf.svg");
}

.dropdown-menu li[data-value="+262 (French Southern Territories)"] a:after {
  background-image: url("../img/flags/tf.svg");
}

.dropdown-menu li[data-value="+241 (Gabon)"] a:after {
  background-image: url("../img/flags/ga.svg");
}

.dropdown-menu li[data-value="+220 (Gambia)"] a:after {
  background-image: url("../img/flags/gm.svg");
}

.dropdown-menu li[data-value="+995 (Georgia)"] a:after {
  background-image: url("../img/flags/ge.svg");
}

.dropdown-menu li[data-value="+49 (Germany)"] a:after {
  background-image: url("../img/flags/de.svg");
}

.dropdown-menu li[data-value="+233 (Ghana)"] a:after {
  background-image: url("../img/flags/gh.svg");
}

.dropdown-menu li[data-value="+350 (Gibraltar)"] a:after {
  background-image: url("../img/flags/gi.svg");
}

.dropdown-menu li[data-value="+30 (Greece)"] a:after {
  background-image: url("../img/flags/gr.svg");
}

.dropdown-menu li[data-value="+299 (Greenland)"] a:after {
  background-image: url("../img/flags/gl.svg");
}

.dropdown-menu li[data-value="+1473 (Grenada)"] a:after {
  background-image: url("../img/flags/gd.svg");
}

.dropdown-menu li[data-value="+590 (Guadeloupe)"] a:after {
  background-image: url("../img/flags/gp.svg");
}

.dropdown-menu li[data-value="+1671 (Guam)"] a:after {
  background-image: url("../img/flags/gu.svg");
}

.dropdown-menu li[data-value="+502 (Guatemala)"] a:after {
  background-image: url("../img/flags/gt.svg");
}

.dropdown-menu li[data-value="+44 (Guernsey)"] a:after {
  background-image: url("../img/flags/gg.svg");
}

.dropdown-menu li[data-value="+224 (Guinea)"] a:after {
  background-image: url("../img/flags/gn.svg");
}

.dropdown-menu li[data-value="+245 (Guinea-Bissau)"] a:after {
  background-image: url("../img/flags/gw.svg");
}

.dropdown-menu li[data-value="+592 (Guyana)"] a:after {
  background-image: url("../img/flags/gy.svg");
}

.dropdown-menu li[data-value="+509 (Haiti)"] a:after {
  background-image: url("../img/flags/ht.svg");
}

.dropdown-menu li[data-value="+39 (Holy See (Vatican City State) )"] a:after {
  background-image: url("../img/flags/va.svg");
}

.dropdown-menu li[data-value="+504 (Honduras)"] a:after {
  background-image: url("../img/flags/hn.svg");
}

.dropdown-menu li[data-value="+852 (Hong Kong)"] a:after {
  background-image: url("../img/flags/hk.svg");
}

.dropdown-menu li[data-value="+36 (Hungary)"] a:after {
  background-image: url("../img/flags/hu.svg");
}

.dropdown-menu li[data-value="+354 (Iceland)"] a:after {
  background-image: url("../img/flags/is.svg");
}

.dropdown-menu li[data-value="+91 (India)"] a:after {
  background-image: url("../img/flags/in.svg");
}

.dropdown-menu li[data-value="+62 (Indonesia)"] a:after {
  background-image: url("../img/flags/id.svg");
}

.dropdown-menu li[data-value="+98 (Iran, Islamic Republic of)"] a:after {
  background-image: url("../img/flags/ir.svg");
}

.dropdown-menu li[data-value="+964 (Iraq)"] a:after {
  background-image: url("../img/flags/iq.svg");
}

.dropdown-menu li[data-value="+353 (Ireland)"] a:after {
  background-image: url("../img/flags/ie.svg");
}

.dropdown-menu li[data-value="+44 (Isle of Man)"] a:after {
  background-image: url("../img/flags/im.svg");
}

.dropdown-menu li[data-value="+972 (Israel)"] a:after {
  background-image: url("../img/flags/il.svg");
}

.dropdown-menu li[data-value="+39 (Italy)"] a:after {
  background-image: url("../img/flags/it.svg");
}

.dropdown-menu li[data-value="+1876 (Jamaica)"] a:after {
  background-image: url("../img/flags/jm.svg");
}

.dropdown-menu li[data-value="+81 (Japan)"] a:after {
  background-image: url("../img/flags/jp.svg");
}

.dropdown-menu li[data-value="+44 (Jersey)"] a:after {
  background-image: url("../img/flags/je.svg");
}

.dropdown-menu li[data-value="+962 (Jordan)"] a:after {
  background-image: url("../img/flags/jo.svg");
}

.dropdown-menu li[data-value="+7 (Kazakhstan)"] a:after {
  background-image: url("../img/flags/kz.svg");
}

.dropdown-menu li[data-value="+254 (Kenya)"] a:after {
  background-image: url("../img/flags/ke.svg");
}

.dropdown-menu li[data-value="+686 (Kiribati)"] a:after {
  background-image: url("../img/flags/ki.svg");
}

.dropdown-menu li[data-value="+850 (Korea, Democratic Peoples Republic of)"] a:after {
  background-image: url("../img/flags/kp.svg");
}

.dropdown-menu li[data-value="+82 (Korea, Republic of)"] a:after {
  background-image: url("../img/flags/kr.svg");
}

.dropdown-menu li[data-value="+383 (Kosovo)"] a:after {
  background-image: url("../img/flags/xk.svg");
}

.dropdown-menu li[data-value="+965 (Kuwait)"] a:after {
  background-image: url("../img/flags/kw.svg");
}

.dropdown-menu li[data-value="+996 (Kyrgyzstan)"] a:after {
  background-image: url("../img/flags/kg.svg");
}

.dropdown-menu li[data-value="+856 (Lao Peoples Democratic Republic)"] a:after {
  background-image: url("../img/flags/la.svg");
}

.dropdown-menu li[data-value="+371 (Latvia)"] a:after {
  background-image: url("../img/flags/lv.svg");
}

.dropdown-menu li[data-value="+961 (Lebanon)"] a:after {
  background-image: url("../img/flags/lb.svg");
}

.dropdown-menu li[data-value="+266 (Lesotho)"] a:after {
  background-image: url("../img/flags/ls.svg");
}

.dropdown-menu li[data-value="+231 (Liberia)"] a:after {
  background-image: url("../img/flags/lr.svg");
}

.dropdown-menu li[data-value="+218 (Libyan Arab Jamahiriya)"] a:after {
  background-image: url("../img/flags/ly.svg");
}

.dropdown-menu li[data-value="+423 (Liechtenstein)"] a:after {
  background-image: url("../img/flags/li.svg");
}

.dropdown-menu li[data-value="+370 (Lithuania)"] a:after {
  background-image: url("../img/flags/lt.svg");
}

.dropdown-menu li[data-value="+352 (Luxembourg)"] a:after {
  background-image: url("../img/flags/lu.svg");
}

.dropdown-menu li[data-value="+853 (Macao)"] a:after {
  background-image: url("../img/flags/mo.svg");
}

.dropdown-menu li[data-value="+389 (Macedonia, the Former Yugoslav Republic of)"] a:after {
  background-image: url("../img/flags/mk.svg");
}

.dropdown-menu li[data-value="+261 (Madagascar)"] a:after {
  background-image: url("../img/flags/mg.svg");
}

.dropdown-menu li[data-value="+265 (Malawi)"] a:after {
  background-image: url("../img/flags/mw.svg");
}

.dropdown-menu li[data-value="+60 (Malaysia)"] a:after {
  background-image: url("../img/flags/my.svg");
}

.dropdown-menu li[data-value="+960 (Maldives)"] a:after {
  background-image: url("../img/flags/mv.svg");
}

.dropdown-menu li[data-value="+223 (Mali)"] a:after {
  background-image: url("../img/flags/ml.svg");
}

.dropdown-menu li[data-value="+356 (Malta)"] a:after {
  background-image: url("../img/flags/mt.svg");
}

.dropdown-menu li[data-value="+692 (Marshall Islands)"] a:after {
  background-image: url("../img/flags/mh.svg");
}

.dropdown-menu li[data-value="+596 (Martinique)"] a:after {
  background-image: url("../img/flags/mq.svg");
}

.dropdown-menu li[data-value="+222 (Mauritania)"] a:after {
  background-image: url("../img/flags/mr.svg");
}

.dropdown-menu li[data-value="+230 (Mauritius)"] a:after {
  background-image: url("../img/flags/mu.svg");
}

.dropdown-menu li[data-value="+262 (Mayotte)"] a:after {
  background-image: url("../img/flags/yt.svg");
}

.dropdown-menu li[data-value="+52 (Mexico)"] a:after {
  background-image: url("../img/flags/mx.svg");
}

.dropdown-menu li[data-value="+691 (Micronesia, Federated States of)"] a:after {
  background-image: url("../img/flags/fm.svg");
}

.dropdown-menu li[data-value="+373 (Moldova, Republic of)"] a:after {
  background-image: url("../img/flags/md.svg");
}

.dropdown-menu li[data-value="+377 (Monaco)"] a:after {
  background-image: url("../img/flags/mc.svg");
}

.dropdown-menu li[data-value="+976 (Mongolia)"] a:after {
  background-image: url("../img/flags/mn.svg");
}

.dropdown-menu li[data-value="+382 (Montenegro)"] a:after {
  background-image: url("../img/flags/me.svg");
}

.dropdown-menu li[data-value="+1664 (Montserrat)"] a:after {
  background-image: url("../img/flags/ms.svg");
}

.dropdown-menu li[data-value="+212 (Morocco)"] a:after {
  background-image: url("../img/flags/ma.svg");
}

.dropdown-menu li[data-value="+258 (Mozambique)"] a:after {
  background-image: url("../img/flags/mz.svg");
}

.dropdown-menu li[data-value="+95 (Myanmar)"] a:after {
  background-image: url("../img/flags/mm.svg");
}

.dropdown-menu li[data-value="+264 (Namibia)"] a:after {
  background-image: url("../img/flags/na.svg");
}

.dropdown-menu li[data-value="+674 (Nauru)"] a:after {
  background-image: url("../img/flags/nru.svg");
}

.dropdown-menu li[data-value="+977 (Nepal)"] a:after {
  background-image: url("../img/flags/npl.svg");
}

.dropdown-menu li[data-value="+31 (Netherlands)"] a:after {
  background-image: url("../img/flags/nld.svg");
}

.dropdown-menu li[data-value="+599 (Netherlands Antilles)"] a:after {
  background-image: url("../img/flags/ant.svg");
}

.dropdown-menu li[data-value="+687 (New Caledonia)"] a:after {
  background-image: url("../img/flags/ncl.svg");
}

.dropdown-menu li[data-value="+64 (New Zealand)"] a:after {
  background-image: url("../img/flags/nzl.svg");
}

.dropdown-menu li[data-value="+505 (Nicaragua)"] a:after {
  background-image: url("../img/flags/nic.svg");
}

.dropdown-menu li[data-value="+227 (Niger)"] a:after {
  background-image: url("../img/flags/ne.svg");
}

.dropdown-menu li[data-value="+234 (Nigeria)"] a:after {
  background-image: url("../img/flags/ng.svg");
}

.dropdown-menu li[data-value="+683 (Niue)"] a:after {
  background-image: url("../img/flags/nu.svg");
}

.dropdown-menu li[data-value="+672 (Norfolk Island)"] a:after {
  background-image: url("../img/flags/nf.svg");
}

.dropdown-menu li[data-value="+1670 (Northern Mariana Islands)"] a:after {
  background-image: url("../img/flags/mp.svg");
}

.dropdown-menu li[data-value="+47 (Norway)"] a:after {
  background-image: url("../img/flags/no.svg");
}

.dropdown-menu li[data-value="+968 (Oman)"] a:after {
  background-image: url("../img/flags/om.svg");
}

.dropdown-menu li[data-value="+92 (Pakistan)"] a:after {
  background-image: url("../img/flags/pk.svg");
}

.dropdown-menu li[data-value="+680 (Palau)"] a:after {
  background-image: url("../img/flags/pw.svg");
}

.dropdown-menu li[data-value="+970 (Palestinian Territory, Occupied)"] a:after {
  background-image: url("../img/flags/ps.svg");
}

.dropdown-menu li[data-value="+507 (Panama)"] a:after {
  background-image: url("../img/flags/pa.svg");
}

.dropdown-menu li[data-value="+675 (Papua New Guinea)"] a:after {
  background-image: url("../img/flags/pg.svg");
}

.dropdown-menu li[data-value="+595 (Paraguay)"] a:after {
  background-image: url("../img/flags/py.svg");
}

.dropdown-menu li[data-value="+51 (Peru)"] a:after {
  background-image: url("../img/flags/pe.svg");
}

.dropdown-menu li[data-value="+63 (Philippines)"] a:after {
  background-image: url("../img/flags/ph.svg");
}

.dropdown-menu li[data-value="+64 (Pitcairn)"] a:after {
  background-image: url("../img/flags/pn.svg");
}

.dropdown-menu li[data-value="+48 (Poland)"] a:after {
  background-image: url("../img/flags/pl.svg");
}

.dropdown-menu li[data-value="+351 (Portugal)"] a:after {
  background-image: url("../img/flags/pt.svg");
}

.dropdown-menu li[data-value="+1787 (Puerto Rico)"] a:after {
  background-image: url("../img/flags/pr.svg");
}

.dropdown-menu li[data-value="+974 (Qatar)"] a:after {
  background-image: url("../img/flags/qa.svg");
}

.dropdown-menu li[data-value="+262 (Reunion)"] a:after {
  background-image: url("../img/flags/re.svg");
}

.dropdown-menu li[data-value="+40 (Romania)"] a:after {
  background-image: url("../img/flags/ro.svg");
}

.dropdown-menu li[data-value="+7 (Russian Federation)"] a:after {
  background-image: url("../img/flags/ru.svg");
}

.dropdown-menu li[data-value="+250 (Rwanda)"] a:after {
  background-image: url("../img/flags/rw.svg");
}

.dropdown-menu li[data-value="+590 (Saint Barthelemy)"] a:after {
  background-image: url("../img/flags/bl.svg");
}

.dropdown-menu li[data-value="+290 (Saint Helena)"] a:after {
  background-image: url("../img/flags/sh.svg");
}

.dropdown-menu li[data-value="+1869 (Saint Kitts and Nevis)"] a:after {
  background-image: url("../img/flags/kn.svg");
}

.dropdown-menu li[data-value="+1758 (Saint Lucia)"] a:after {
  background-image: url("../img/flags/lc.svg");
}

.dropdown-menu li[data-value="+590 (Saint Martin)"] a:after {
  background-image: url("../img/flags/mf.svg");
}

.dropdown-menu li[data-value="+508 (Saint Pierre and Miquelon)"] a:after {
  background-image: url("../img/flags/pm.svg");
}

.dropdown-menu li[data-value="+1784 (Saint Vincent and the Grenadines)"] a:after {
  background-image: url("../img/flags/vc.svg");
}

.dropdown-menu li[data-value="+684 (Samoa)"] a:after {
  background-image: url("../img/flags/ws.svg");
}

.dropdown-menu li[data-value="+378 (San Marino)"] a:after {
  background-image: url("../img/flags/sm.svg");
}

.dropdown-menu li[data-value="+239 (Sao Tome and Principe)"] a:after {
  background-image: url("../img/flags/st.svg");
}

.dropdown-menu li[data-value="+966 (Saudi Arabia)"] a:after {
  background-image: url("../img/flags/sa.svg");
}

.dropdown-menu li[data-value="+221 (Senegal)"] a:after {
  background-image: url("../img/flags/sn.svg");
}

.dropdown-menu li[data-value="+381 (Serbia)"] a:after {
  background-image: url("../img/flags/rs.svg");
}

.dropdown-menu li[data-value="+381 (Serbia and Montenegro)"] a:after {
  background-image: url("../img/flags/cs.svg");
}

.dropdown-menu li[data-value="+248 (Seychelles)"] a:after {
  background-image: url("../img/flags/sc.svg");
}

.dropdown-menu li[data-value="+232 (Sierra Leone)"] a:after {
  background-image: url("../img/flags/sl.svg");
}

.dropdown-menu li[data-value="+65 (Singapore)"] a:after {
  background-image: url("../img/flags/sg.svg");
}

.dropdown-menu li[data-value="+721 (Sint Maarten)"] a:after {
  background-image: url("../img/flags/sx.svg");
}

.dropdown-menu li[data-value="+421 (Slovakia)"] a:after {
  background-image: url("../img/flags/sk.svg");
}

.dropdown-menu li[data-value="+386 (Slovenia)"] a:after {
  background-image: url("../img/flags/si.svg");
}

.dropdown-menu li[data-value="+677 (Solomon Islands)"] a:after {
  background-image: url("../img/flags/sb.svg");
}

.dropdown-menu li[data-value="+252 (Somalia)"] a:after {
  background-image: url("../img/flags/so.svg");
}

.dropdown-menu li[data-value="+27 (South Africa)"] a:after {
  background-image: url("../img/flags/za.svg");
}

.dropdown-menu li[data-value="+500 (South Georgia and the South Sandwich Islands)"] a:after {
  background-image: url("../img/flags/gs.svg");
}

.dropdown-menu li[data-value="+211 (South Sudan)"] a:after {
  background-image: url("../img/flags/ss.svg");
}

.dropdown-menu li[data-value="+34 (Spain)"] a:after {
  background-image: url("../img/flags/es.svg");
}

.dropdown-menu li[data-value="+94 (Sri Lanka)"] a:after {
  background-image: url("../img/flags/lk.svg");
}

.dropdown-menu li[data-value="+249 (Sudan)"] a:after {
  background-image: url("../img/flags/sd.svg");
}

.dropdown-menu li[data-value="+597 (Suriname)"] a:after {
  background-image: url("../img/flags/sr.svg");
}

.dropdown-menu li[data-value="+47 (Svalbard and Jan Mayen)"] a:after {
  background-image: url("../img/flags/sj.svg");
}

.dropdown-menu li[data-value="+268 (Swaziland)"] a:after {
  background-image: url("../img/flags/sz.svg");
}

.dropdown-menu li[data-value="+46 (Sweden)"] a:after {
  background-image: url("../img/flags/se.svg");
}

.dropdown-menu li[data-value="+41 (Switzerland)"] a:after {
  background-image: url("../img/flags/ch.svg");
}

.dropdown-menu li[data-value="+963 (Syrian Arab Republic)"] a:after {
  background-image: url("../img/flags/sy.svg");
}

.dropdown-menu li[data-value="+886 (Taiwan, Province of China)"] a:after {
  background-image: url("../img/flags/tw.svg");
}

.dropdown-menu li[data-value="+992 (Tajikistan)"] a:after {
  background-image: url("../img/flags/tj.svg");
}

.dropdown-menu li[data-value="+255 (Tanzania, United Republic of)"] a:after {
  background-image: url("../img/flags/tz.svg");
}

.dropdown-menu li[data-value="+66 (Thailand)"] a:after {
  background-image: url("../img/flags/th.svg");
}

.dropdown-menu li[data-value="+670 (Timor-Leste)"] a:after {
  background-image: url("../img/flags/tl.svg");
}

.dropdown-menu li[data-value="+228 (Togo)"] a:after {
  background-image: url("../img/flags/tg.svg");
}

.dropdown-menu li[data-value="+690 (Tokelau)"] a:after {
  background-image: url("../img/flags/tk.svg");
}

.dropdown-menu li[data-value="+676 (Tonga)"] a:after {
  background-image: url("../img/flags/to.svg");
}

.dropdown-menu li[data-value="+1868 (Trinidad and Tobago)"] a:after {
  background-image: url("../img/flags/tt.svg");
}

.dropdown-menu li[data-value="+216 (Tunisia)"] a:after {
  background-image: url("../img/flags/tn.svg");
}

.dropdown-menu li[data-value="+90 (Turkey)"] a:after {
  background-image: url("../img/flags/tr.svg");
}

.dropdown-menu li[data-value="+7370 (Turkmenistan)"] a:after {
  background-image: url("../img/flags/tm.svg");
}

.dropdown-menu li[data-value="+1649 (Turks and Caicos Islands)"] a:after {
  background-image: url("../img/flags/tc.svg");
}

.dropdown-menu li[data-value="+688 (Tuvalu)"] a:after {
  background-image: url("../img/flags/tv.svg");
}

.dropdown-menu li[data-value="+256 (Uganda)"] a:after {
  background-image: url("../img/flags/ug.svg");
}

.dropdown-menu li[data-value="+380 (Ukraine)"] a:after {
  background-image: url("../img/flags/ua.svg");
}

.dropdown-menu li[data-value="+971 (United Arab Emirates)"] a:after {
  background-image: url("../img/flags/ae.svg");
}

.dropdown-menu li[data-value="+44 (United Kingdom)"] a:after {
  background-image: url("../img/flags/gb.svg");
}

.dropdown-menu li[data-value="+1 (United States)"] a:after {
  background-image: url("../img/flags/us.svg");
}

.dropdown-menu li[data-value="+1 (United States Minor Outlying Islands)"] a:after {
  background-image: url("../img/flags/um.svg");
}

.dropdown-menu li[data-value="+598 (Uruguay)"] a:after {
  background-image: url("../img/flags/uy.svg");
}

.dropdown-menu li[data-value="+998 (Uzbekistan)"] a:after {
  background-image: url("../img/flags/uz.svg");
}

.dropdown-menu li[data-value="+678 (Vanuatu)"] a:after {
  background-image: url("../img/flags/vu.svg");
}

.dropdown-menu li[data-value="+58 (Venezuela)"] a:after {
  background-image: url("../img/flags/ve.svg");
}

.dropdown-menu li[data-value="+84 (Viet Nam)"] a:after {
  background-image: url("../img/flags/vn.svg");
}

.dropdown-menu li[data-value="+1284 (Virgin Islands, British)"] a:after {
  background-image: url("../img/flags/vg.svg");
}

.dropdown-menu li[data-value="+1340 (Virgin Islands, U.s.)"] a:after {
  background-image: url("../img/flags/vi.svg");
}

.dropdown-menu li[data-value="+681 (Wallis and Futuna)"] a:after {
  background-image: url("../img/flags/wf.svg");
}

.dropdown-menu li[data-value="+212 (Western Sahara)"] a:after {
  background-image: url("../img/flags/eh.svg");
}

.dropdown-menu li[data-value="+967 (Yemen)"] a:after {
  background-image: url("../img/flags/ye.svg");
}

.dropdown-menu li[data-value="+260 (Zambia)"] a:after {
  background-image: url("../img/flags/zm.svg");
}

.dropdown-menu li[data-value="+263 (Zimbabwe)"] a:after {
  background-image: url("../img/flags/zw.svg");
}

/************************************************************************************
FOOTER
*************************************************************************************/
.footer {
  color: #fff;
  background: #09304C;
  overflow: hidden;
  position: relative;
}

.footer .head {
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
}

.footer .f_1 {
  padding-bottom: 6.25rem;
  position: relative;
}

.footer .foot-item {
  padding: 1.25rem 0;
}

.footer .foot-item-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer .foot-item-info .contact-info {
  margin-bottom: 12.5rem;
}

.footer .foot-newsletter {
  padding-left: 40px;
}

.footer .foot-links {
  padding-left: 40px;
}

.footer .europe {
  position: absolute;
  max-width: 41.875rem;
  right: -15rem;
  top: 0;
}

.footer .logo-foot {
  max-width: 8.875rem;
}

.footer .footer-inner {
  position: relative;
}

.footer a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: 0.3s all;
}

.footer a:hover {
  color: #fff;
}

.footer ul li {
  padding: 0;
}

.footer ul li a {
  display: inline-flex;
  align-items: center;
}

.footer ul li .icon-new-window {
  margin-left: 10px;
  width: 0.625rem;
  height: 0.625rem;
  color: #59B0A3;
  position: relative;
  top: -5px;
}

.supfooter-bar {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.supfooter-bar .inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.25rem 0;
}

.footer-bar {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.footer-bar .inner {
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding: 1.25rem 0;
}

.footer-bar .inner ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.footer-bar .inner ul li {
  padding: 0 15px;
  margin: 0;
}

.contact-list-foot {
  font-size: 1.75rem;
}

.contact-list-foot ul li {
  font-weight: 500;
  line-height: 1.3em;
  margin-bottom: 0.625rem;
}

.contact-list-foot a {
  color: #59B0A3;
}

.gff {
  max-width: 225px;
  position: absolute;
  right: 0;
  bottom: 2.1875rem;
  z-index: 20;
}

.social-nav {
  display: flex;
  align-items: center;
}

.social-nav ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.social-nav ul li {
  padding: 0 10px;
  margin: 0;
}

.social-nav ul li a {
  width: 1.75rem;
  height: 1.75rem;
  flex: 0 0 1.75rem;
  color: #fff;
  transition: 0.3s all;
}

.social-nav ul li a:hover {
  color: #59B0A3;
}

.social-nav ul li a .icon {
  width: 1.75rem;
  height: 1.75rem;
  flex: 0 0 1.75rem;
}

.social-nav.alt ul {
  margin: 0 -5px;
}

.social-nav.alt ul li {
  padding: 0 5px;
}

.social-nav.alt ul li a {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  flex: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #59B0A3;
  transition: 0.3s all;
}

.social-nav.alt ul li a .icon {
  width: 1.375rem;
  height: 1.375rem;
  flex: 1.375rem;
}

.social-nav.alt ul li a:hover {
  background: #09304C;
  color: #fff;
}

.address-list-foot {
  font-size: 1.125rem;
  margin-bottom: 1.875rem;
}

.lng-link path {
  fill: #59B0A3;
  transition: 0.3s all;
}

.lng-link:hover path {
  fill: #fff;
}

/************************************************************************************
MEDIA QUERIES
*************************************************************************************/
/*========================= Viewport width 1200px and higher =========================*/
@media screen and (max-width: 1200px) {
  .xl-last {
    margin-bottom: 0 !important;
  }
}

/*========================= Viewport width 2050px and lower =========================*/
@media screen and (max-width: 2050px) {
  h2, .h2 {
    font-size: 2.8125rem;
  }
  h2.big, .h2.big {
    font-size: 3.75rem;
    line-height: 1.1em;
  }
  .block {
    padding: 120px 0;
  }
  .teaser-alt h1, .teaser-alt .desc {
    margin-bottom: 2.5rem;
  }
  .teaser-poster .teaser-text .teaser-content {
    padding: 6.25rem 0 5rem 0;
  }
  .suphead {
    font-size: 1.5625rem;
  }
  .act-nav .item .img {
    width: 5rem;
    flex: 0 0 5rem;
  }
  .act-nav .item-inner {
    font-size: 1.0625rem;
  }
  .phases .item .img {
    margin-bottom: 2.5rem;
  }
  .phases .item h3 {
    font-size: 1.25rem;
  }
  .stocks-program .main .text {
    max-width: 47.5rem;
    padding-right: 100px;
  }
  .stocks-program .main .img {
    padding-right: 100px;
  }
  .phase-slider .glide-nav .glide__arrow--right, .phase-slider .glide-nav .glide__arrow--left {
    width: 3.75rem;
    height: 3.75rem;
  }
  .phase-slider .glide-nav .glide__arrow--right .icon, .phase-slider .glide-nav .glide__arrow--left .icon {
    width: 1.5625rem;
    height: 1.5625rem;
    flex: 0 0 1.5625rem;
  }
  .gallery-slider .glide-nav .glide__arrow--right, .gallery-slider .glide-nav .glide__arrow--left {
    width: 3.75rem;
    height: 3.75rem;
  }
  .gallery-slider .glide-nav .glide__arrow--right .icon, .gallery-slider .glide-nav .glide__arrow--left .icon {
    width: 1.5625rem;
    height: 1.5625rem;
    flex: 0 0 1.5625rem;
  }
  .gallery-text {
    max-width: 1545px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .investment .item .desc {
    padding-top: 0.625rem;
  }
  .investment .item .price {
    font-size: 1.25rem;
    top: -1.5625rem;
    width: 15rem;
    height: 3.125rem;
    border-radius: 1.875rem;
  }
  .investment .item-inner {
    height: 27.5rem;
  }
  .development h2 {
    max-width: 50rem;
  }
  .development .desc {
    max-width: 50rem;
  }
  .development .additional {
    max-width: 50rem;
  }
  .milestones-slider-inner {
    padding-left: 20%;
  }
  .history-slider-wrapper .headline {
    display: none;
  }
  .offer .head {
    padding-right: calc((100vw - 1515px)/2);
  }
  .offer .item .text .suphead {
    font-size: 1rem;
  }
  .offer .item .text h3 {
    font-size: 1.375rem;
  }
  .offer .item .icon-circle {
    margin-right: 15px;
  }
  .tabs.tabs-expansion .tab-nav ul li > a {
    font-size: 1rem;
  }
  .posts-big, .posts-recent, .posts-archive {
    max-width: 75rem;
  }
  .boxes-img .section-head {
    margin-bottom: 5rem;
  }
  .boxes-img .section-head.narrow {
    max-width: 850px;
  }
  .boxes-img .item-inner {
    padding: 0 1.875rem 1.875rem 1.875rem;
  }
  .boxes-img .item {
    padding-top: 6.25rem;
  }
  .boxes-img .item .img {
    margin-top: -6.25rem;
    margin-bottom: 2.5rem;
    width: 12.5rem;
    height: 12.5rem;
  }
  .farm-cats .item {
    padding: 0 1.25rem;
    font-size: 1.375rem;
  }
  .schedule {
    margin-top: 3.125rem;
  }
  .schedule h3, .schedule .h3 {
    margin-bottom: 2.5rem;
  }
  .modern h2 {
    margin-bottom: 2.1875rem;
  }
  .modern .desc {
    margin-bottom: 3.125rem;
  }
  .numbers .item h2 sup {
    font-size: 1.25rem;
    top: -1rem;
  }
  .numbers .item .desc {
    font-size: 1.125rem;
  }
  .bar-nav {
    max-width: 800px;
  }
  .bar-nav .item-inner {
    font-size: 1.125rem;
    height: 5.625rem;
  }
  .interest-nav .item-inner.h3 {
    font-size: 1.375rem;
  }
  .interest-nav .leaf-4 {
    max-width: 17.5rem;
    top: -11.25rem;
  }
  .about .about-text .text {
    padding-left: 3.75rem;
    padding-right: 2.8125rem;
  }
  .about.alt .about-text .text {
    padding-left: 2.8125rem;
    padding-right: 3.75rem;
  }
  .roi .desc {
    margin-bottom: 3.125rem;
  }
  .roi-slider {
    margin: 3.125rem 0;
  }
}

/*========================= Viewport width 1605px and lower =========================*/
@media screen and (max-width: 1605px) {
  h1, .h1 {
    font-size: 3.125rem;
    line-height: 1.16em;
  }
  h2, .h2 {
    font-size: 2.8125rem;
  }
  h3, .h3 {
    font-size: 1.5rem;
  }
  h3.big, .h3.big {
    font-size: 1.625rem;
  }
  .suphead {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }
  .block {
    padding: 6.875rem 0;
  }
  .block.narrow {
    padding: 5rem 0;
  }
  .block.narrow-alpha {
    padding-top: 5rem;
  }
  .block.narrow-omega {
    padding-bottom: 5rem;
  }
  .teaser-alt .numbers .item-list {
    margin: 0 -20px;
  }
  .teaser-alt .numbers .item {
    padding: 0 20px;
    border: none;
  }
  .posts-overview .main-text {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 2.5rem;
  }
  .posts-overview .main-text .inner {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .posts-overview .main-text .inner .section-head {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .posts-overview .posts-main {
    margin-left: 0;
  }
  .post-detail {
    max-width: 750px;
  }
  .persons .cite {
    font-size: 1.875rem;
  }
  .offer {
    padding-left: 15%;
  }
  .offer .container {
    max-width: 1140px;
  }
  .offer .item-list {
    margin: 0 -15px;
  }
  .offer .item {
    padding: 0 15px;
  }
  .offer .item .text {
    padding: 1.875rem 1.875rem 0 1.875rem;
  }
  .offer .item .text .suphead {
    font-size: 0.875rem;
    line-height: 1.5em;
  }
  .offer .item .text h3 {
    font-size: 1rem;
  }
  .offer .item .label {
    font-size: 0.875rem;
  }
  .offer .head {
    padding-right: calc((100vw - 1110px)/2);
  }
  .numbers .main-text .bg {
    left: 20%;
  }
  .numbers .main-text .bg-fish {
    left: -35%;
  }
  .investment-action .section-head {
    min-height: 6.25rem;
  }
  .calculator-element .amount {
    font-size: 2.8125rem;
  }
  #range-calculator {
    margin-bottom: 9.375rem;
  }
  .calculator.double-cols {
    padding: 0;
  }
  .calculator.double-cols #range-calculator {
    margin-bottom: 9.375rem;
  }
  .calculator.double-cols #range-calculator .min {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .calculator.double-cols #range-calculator .max {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .stocks-program .main .img, .stocks-program .main .text {
    padding-right: 40px;
  }
  .tabs .tab-nav ul {
    margin: 0 -0.9375rem;
  }
  .tabs .tab-nav li {
    padding: 0 0.9375rem;
  }
  .tabs .tab-nav li a {
    font-size: 0.9375rem;
  }
  .ordered-list .item:before {
    font-size: 2.8125rem;
  }
  .stocks-program .main .text {
    max-width: 35rem;
    padding-right: 50px;
  }
  .stocks-program .main .img {
    padding-right: 50px;
  }
  .gallery-text {
    max-width: 1140px;
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .gallery-text .img .inner {
    padding-right: 40px;
  }
  .gallery-text .text .inner {
    padding-left: 0;
  }
  .tiles-photo .item .text .icon {
    width: 3.75rem;
    height: 3.75rem;
    flex: 0 0 3.75rem;
  }
  .tiles-photo .suphead {
    font-size: 1.5625rem;
  }
  .tiles-photo h3 {
    font-size: 1.5625rem;
  }
  .boxes-img .item-inner {
    padding: 0 1.875rem 1.875rem 1.875rem;
  }
  .boxes-img .item {
    padding-top: 5rem;
  }
  .boxes-img .item .img {
    margin-top: -5rem;
    margin-bottom: 2.5rem;
    width: 10rem;
    height: 10rem;
  }
  .modern .img .frame {
    max-width: 430px;
  }
  .footer .f_1 {
    padding-bottom: 1.875rem;
  }
  .footer .foot-item-info .contact-info {
    margin-bottom: 3.125rem;
  }
  .footer .foot-newsletter {
    padding-left: 0;
  }
  .footer .foot-links {
    padding-left: 0;
  }
  .lector .img .frame {
    max-width: 180px;
  }
}

/*========================= Viewport width 1299px and lower =========================*/
@media screen and (max-width: 1299px) {
  h2, .h2 {
    font-size: 2.1875rem;
    line-height: 1.2em;
  }
  h2.big, .h2.big {
    font-size: 2.5rem;
  }
  .suphead {
    font-size: 1.125rem;
  }
  .post-info {
    position: relative;
    max-width: 100%;
    left: 0;
    padding-bottom: 1.875rem;
    margin-bottom: 1.875rem;
    border-bottom: 1px solid #59B0A3;
  }
  .post-info .author {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
    display: flex;
    flex: 0 0 250px;
    margin-bottom: 1.25rem;
  }
  .post-info .author .img {
    margin: 0;
  }
  .post-info .author .img .frame {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
  }
  .post-info .author .text {
    padding-left: 15px;
  }
  .post-info .cats {
    display: flex;
  }
  .post-info .cats .head {
    margin-bottom: 0.3125rem;
  }
  .post-info .cats ul {
    margin: 0 -5px 5px -5px;
    display: flex;
    flex-wrap: wrap;
  }
  .post-info .cats ul li {
    padding: 0 5px;
  }
  .post-info .cats ul li:before {
    content: none;
  }
}

/*========================= Viewport width 1199px and lower =========================*/
@media screen and (max-width: 1199px) {
  .logo {
    max-width: 10rem;
  }
  .header-nav ul {
    margin: 0 -8px;
  }
  .header-nav ul li {
    padding: 0 8px;
  }
  .lng-nav {
    margin-left: 15px;
  }
  .header.alt .nav-btn {
    margin-left: 5.625rem;
  }
  .header .btn-flip:before {
    padding: 0 0.625rem;
  }
  .block, .block.narrow {
    padding: 5rem 0;
  }
  .block.narrow-alpha {
    padding-top: 5rem;
  }
  .block.narrow-omega {
    padding-bottom: 5rem;
  }
  .teaser-alt {
    flex-wrap: wrap;
  }
  .teaser-alt .teaser-text {
    margin-left: 0;
    padding-top: 0;
    order: 0;
  }
  .teaser-alt .teaser-img {
    order: 1;
    padding-left: 0;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
  }
  .teaser-alt .teaser-img .frame {
    padding-left: 0;
  }
  .teaser-alt .btn-set {
    margin-bottom: 0;
  }
  .teaser-alt .btn, .teaser-alt .btn-flip {
    top: 0;
  }
  .teaser-poster .teaser-text .teaser-content {
    padding: 6.25rem 0;
  }
  .offer {
    padding-left: 0;
  }
  .offer .container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
  .offer .item {
    margin-bottom: 5rem;
  }
  .offer .item .text .suphead {
    font-size: 1.0625rem;
    line-height: 1.5em;
  }
  .offer .item .text h3 {
    font-size: 1.5625rem;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    display: block;
  }
  .offer .item .text h3 .icon-circle {
    margin-bottom: 1.25rem;
    margin-left: auto;
    margin-right: auto;
  }
  .offer .head {
    padding-right: 0;
  }
  .about .about-text .text {
    padding-left: 50px;
  }
  .persons .cite {
    font-size: 1.5625rem;
  }
  .persons .name {
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
  }
  .persons .item-foot {
    padding-bottom: 1.25rem;
  }
  .persons .sign {
    max-width: 11.25rem;
  }
  .persons .foot {
    display: flex;
  }
  .investment .item:nth-child(3) {
    border-left: none;
  }
  .investment .item-inner {
    height: 27.5rem;
  }
  .investment-action {
    flex-wrap: wrap;
  }
  .investment-action .section-head {
    min-height: auto;
    margin-bottom: 4.375rem;
  }
  .investment-action .investment {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 5rem;
  }
  .investment-action .investment-form {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 5rem;
  }
  .investment-action .investment-form .section-head {
    margin-bottom: 5rem;
  }
  .numbers .main-text .bg {
    display: none;
  }
  .numbers .item .desc {
    font-size: 1.125rem;
  }
  .roi .desc {
    margin-bottom: 2.5rem;
  }
  .sky-img {
    display: none;
  }
  .development {
    max-width: 960px;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .amounts .item .label {
    font-size: 1.25rem;
  }
  .amounts .item .amount.small {
    font-size: 1.875rem;
  }
  .amounts.alt {
    margin-bottom: 3.75rem;
  }
  .stocks-program .main .img {
    padding-right: 0;
  }
  .stocks-program .main .text {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 960px;
    margin: 0 auto;
  }
  .stocks-program .main .img {
    margin: 3.125rem 0;
  }
  .stocks-program .tabs, .stocks-program .foot {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 960px;
    margin: 0 auto;
  }
  .gallery-text {
    max-width: 930px;
    padding: 5rem 0;
  }
  .gallery-text .img {
    margin-bottom: 2.5rem;
  }
  .gallery-text .img .inner {
    padding-right: 0;
  }
  .gallery-text .text h2 {
    margin-bottom: 1.875rem;
  }
  .history-slider-wrapper {
    padding: 0 4.375rem;
  }
  .history-slider-wrapper .head {
    left: 70px;
  }
  .milestones-slider-wrapper {
    padding: 0 4.375rem;
  }
  .milestones-slider .glide-nav .glide__arrow--right, .milestones-slider .glide-nav .glide__arrow--left {
    width: 3.75rem;
    height: 3.75rem;
  }
  .milestones-slider .glide-nav .glide__arrow--right .icon, .milestones-slider .glide-nav .glide__arrow--left .icon {
    width: 1.5625rem;
    height: 1.5625rem;
    flex: 0 0 1.5625rem;
  }
  .tabs.tabs-expansion .tab-nav ul li > a {
    font-size: 0.875rem;
    line-height: 1.3em;
  }
  .tabs.tabs-why .tab-nav ul li > a {
    font-size: 0.875rem;
    line-height: 1.3em;
  }
  .expansion .cite {
    font-size: 1.875rem;
  }
  .platforms.alt {
    flex-wrap: wrap;
  }
  .platforms.alt .head {
    max-width: 100%;
    margin-bottom: 1.875rem;
  }
  .connection-list {
    margin: 3.125rem auto 0 auto;
    max-width: 25rem;
  }
  .connection-list .item {
    font-size: 1.5625rem;
    padding-bottom: 2.5rem;
  }
  .interest-nav .item-inner {
    padding: 0 1.25rem;
  }
  .interest-nav .item-inner.h3 {
    font-size: 1.125rem;
  }
  .interest-nav .leaf-4 {
    max-width: 11.25rem;
    top: -6.25rem;
  }
  .contact-form {
    order: 1;
  }
  .contact-box {
    order: 0;
    margin-bottom: 3.75rem;
  }
  .numbers .main-text .bg-fish {
    display: none;
  }
}

/*========================= Viewport width 991px and lower =========================*/
@media screen and (max-width: 991px) {
  html {
    font-size: 15px;
  }
  .teaser-hp .teaser-img, .teaser-hp .teaser-text {
    min-height: 0;
  }
  .teaser-alt .teaser-img {
    max-width: 720px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .teaser-alt .teaser-text {
    padding-top: 8.125rem;
  }
  .teaser-alt-2 .teaser-text {
    padding-top: 6.25rem;
  }
  .teaser-alt-2 .teaser-text .text.a-md-c {
    text-align: center;
  }
  .teaser-poster .teaser-text .teaser-content {
    padding-top: 12.5rem;
  }
  .header.alt .header-r .btn, .header.alt .header-r .btn-flip {
    display: inline-flex;
  }
  .header.alt .h_0 {
    background: url("../img/header-line.svg") center bottom no-repeat;
    border-color: transparent;
  }
  .header.alt .h_1 {
    display: block;
  }
  .header .h_2 {
    display: none;
  }
  .main-nav {
    display: none;
  }
  .nav-btn {
    display: block;
  }
  .mobile-nav-wrap {
    display: block;
  }
  .header-nav {
    display: none;
  }
  .header-r {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
  }
  .header-r .btn-nav {
    order: 2;
  }
  .header-r .btn-flip {
    margin-left: 0;
    z-index: inherit !important;
  }
  .header-r .btn-taste {
    margin-left: 10px;
  }
  .about {
    flex-wrap: wrap;
  }
  .about .about-img {
    flex: 0 0 100%;
    margin-bottom: 2.5rem;
    padding-left: 15px;
    padding-right: 15px;
  }
  .about .about-img .frame {
    max-width: 100%;
  }
  .about .about-text {
    flex: 0 0 100%;
    margin-left: 0;
    padding: 0 15px;
  }
  .about .about-text .text {
    padding: 0;
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
  }
  .about.alt .about-img {
    order: 0;
  }
  .about.alt .about-text {
    order: 1;
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    padding: 0 15px;
  }
  .about.alt .about-text .text {
    padding: 0;
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
  }
  .posts-overview .main-text .btn-flip, .posts-overview .main-text .btn {
    display: none;
  }
  .posts-overview .posts-main .item-inner {
    display: flex;
    flex-wrap: wrap;
  }
  .posts-overview .posts-main .item-inner .img {
    order: 1;
    flex: 0 0 100%;
  }
  .posts-overview .posts-main .item-inner .text {
    order: 0;
    flex: 0 0 100%;
    margin-bottom: 2.5rem;
  }
  .posts-overview .posts-list {
    max-width: 100%;
    float: none;
  }
  .posts-overview .posts-list .foot {
    display: block;
    padding-top: 2.5rem;
  }
  .vision .section-head {
    margin-bottom: 0;
  }
  .vision .main-text {
    display: flex;
    flex-direction: column;
  }
  .vision .main-text .btn, .vision .main-text .btn-flip {
    display: none;
  }
  .vision .main-text .logo-vision {
    order: 0;
    margin-bottom: 1.25rem;
  }
  .vision .main-text .section-head {
    order: 1;
    margin-bottom: 2.5rem;
    text-align: center;
  }
  .vision .main-text .desc {
    order: 2;
    text-align: center;
  }
  .vision .foot {
    display: block;
    padding-top: 2.5rem;
  }
  .persons .item .item-foot {
    padding-left: 15px;
    padding-right: 15px;
  }
  .persons .item .sign {
    margin-bottom: 0;
  }
  .persons .item .job {
    margin-bottom: 0;
  }
  .persons .item-inner {
    pointer-events: none;
  }
  .investment.alt .item, .investment.alt .item:nth-child(2) {
    border-left: none;
    border-top: 0.4375rem solid #fff;
  }
  .investment .item {
    border-left: none;
  }
  .numbers .main-text .btn, .numbers .main-text .btn-flip {
    display: none;
  }
  .numbers .foot {
    display: flex;
    padding-top: 2.5rem;
  }
  .roi .img {
    padding-top: 0;
  }
  .roi .img .frame.big {
    max-width: 28.125rem;
  }
  .roi .foot {
    display: flex;
  }
  .roi .btn-set {
    display: none;
  }
  .roi-slider {
    border-top: none !important;
    border-bottom: none !important;
    padding-top: 0;
    margin: 1.875rem 0;
  }
  .roi-slider .item-inner {
    display: block;
  }
  .roi-slider .item {
    text-align: center;
  }
  .roi-slider .item .img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.25rem;
    padding-top: 0;
  }
  .roi-slider .glide-nav {
    padding-left: 0;
    display: flex;
    justify-content: center;
  }
  .roi-slider .glide-nav .glide__arrow--left,
  .roi-slider .glide-nav .glide__arrow--right {
    display: none;
  }
  .roi-slider.invert {
    padding-top: 3.125rem;
  }
  .roi-farm .numbers {
    margin-top: 3.125rem;
  }
  .roi-farm .numbers .item {
    margin-bottom: 0;
  }
  .development {
    max-width: 720px;
  }
  .phase-slider-wrapper {
    padding-left: 0;
    margin-left: -2px;
  }
  .phase-slider {
    margin-bottom: 3.75rem;
  }
  .phases {
    padding: 0;
  }
  .phases .section-head-l, .phases .foot {
    max-width: 720px;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .phases .section-head-l {
    margin-bottom: 1.875rem;
  }
  .calculator {
    overflow: hidden;
  }
  .amounts .item .label {
    font-size: 1.25rem;
  }
  .amounts .item .amount {
    font-size: 2.1875rem;
  }
  .amounts.alt {
    margin-bottom: 3.125rem;
  }
  .range-calculator-wrapper {
    padding: 0 3.75rem;
  }
  .gallery-text {
    max-width: 690px;
  }
  .history-slider-wrapper .head {
    display: none;
  }
  .history-slider-inner,
  .milestones-slider-inner {
    padding-left: 0;
  }
  .offer {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
  .tabs.tabs-expansion .tab-nav {
    position: relative;
  }
  .tabs.tabs-expansion .tab-nav ul {
    flex-wrap: wrap;
  }
  .tabs.tabs-expansion .tab-nav ul li {
    flex: 0 0 100%;
  }
  .tabs.tabs-expansion .tab-nav ul li > a {
    font-size: 1rem;
    line-height: 1.3em;
    text-align: left;
    justify-content: flex-start;
    padding: 0 2.5rem;
    display: none;
  }
  .tabs.tabs-expansion .tab-nav ul li > a.selected {
    display: inline-flex;
    border-top: none;
  }
  .tabs.tabs-expansion .tab-nav.active ul li a {
    display: inline-flex;
  }
  .tabs.tabs-expansion .tab-nav:after {
    content: '';
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    width: 1rem;
    height: 1rem;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }
  .tabs.tabs-why .tab-nav {
    position: relative;
  }
  .tabs.tabs-why .tab-nav ul {
    flex-wrap: wrap;
  }
  .tabs.tabs-why .tab-nav ul li {
    flex: 0 0 100%;
  }
  .tabs.tabs-why .tab-nav ul li > a {
    font-size: 1rem;
    line-height: 1.3em;
    text-align: left;
    justify-content: flex-start;
    padding: 0 2.5rem;
    display: none;
  }
  .tabs.tabs-why .tab-nav ul li > a.selected {
    display: inline-flex;
    border-top: none;
  }
  .tabs.tabs-why .tab-nav.active ul li a {
    display: inline-flex;
  }
  .tabs.tabs-why .tab-nav:after {
    content: '';
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    width: 1rem;
    height: 1rem;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-left: 2px solid #09304C;
    border-bottom: 2px solid #09304C;
  }
  .posts .item-inner {
    flex-wrap: wrap;
  }
  .posts .item .img {
    flex: 0 0 100%;
  }
  .posts .item .text {
    flex: 0 0 100%;
  }
  .platforms.alt {
    display: block;
    margin: 0 auto;
    max-width: 650px;
  }
  .platforms.alt .head {
    text-align: center;
  }
  .platforms.alt .item-list {
    justify-content: center;
  }
  .connection-list .item:after {
    left: 2.5rem;
  }
  .connection-list .item .img {
    flex: 0 0 5rem;
  }
  .connection-list .item .text {
    padding-left: 1.25rem;
  }
  .boxes-img .section-head {
    margin-bottom: 2.5rem;
  }
  .farm-cats .item {
    font-size: 1.125rem;
  }
  .modern .img {
    order: 0;
    justify-content: center;
    margin-bottom: 2.5rem;
  }
  .modern .img .inner {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .modern .text {
    order: 1;
  }
  .lector {
    display: block;
    padding-bottom: 3.125rem;
    margin-bottom: 5rem;
  }
  .lector .main {
    text-align: center;
  }
  .lector .main h2 {
    margin: 0 auto 1.875rem auto;
  }
  .lector .main .name {
    margin-bottom: 1.875rem;
  }
  .lector .img {
    margin-bottom: 1.875rem;
  }
  .tabs-links .tab-nav ul {
    flex-wrap: wrap;
    margin: 0 -10px;
    overflow: hidden;
  }
  .tabs-links .tab-nav ul li {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding: 0 10px;
    margin-bottom: -2px;
  }
  .faq-detail .box {
    margin-bottom: 4.375rem;
  }
  .interest-nav .leaf-4 {
    display: none;
  }
  body.fix .header .logo {
    display: none;
  }
  body.fix .header .h_0 {
    background-image: none;
  }
  body.fix.mobile-nav-opened .header .btn-flip {
    opacity: .3;
    pointer-events: none;
  }
}

/*========================= Viewport width 767px and lower =========================*/
@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }
  .header-r .btn-flip {
    z-index: 130 !important;
  }
  .teaser-alt .teaser-img {
    max-width: 540px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .mobile-nav .inner {
    max-width: 100%;
    float: none;
  }
  .mobile-nav .inner:after {
    left: -15px;
  }
  .persons .item .sign {
    margin-bottom: 2.5rem;
  }
  .persons .item .item-foot {
    padding: 0 1.875rem 1.875rem 1.875rem;
  }
  .persons .cite {
    font-size: 2.5rem;
  }
  .act-nav .item-inner {
    border-bottom: 3px solid #fff;
  }
  .faq .section-head-l, .faq-list .section-head-l {
    padding-top: 0;
  }
  .faq .section-head-l .btn-flip, .faq-list .section-head-l .btn-flip {
    display: none;
  }
  .faq .item-list, .faq-list .item-list {
    border-bottom: 1px solid #09304C;
  }
  .faq .foot, .faq-list .foot {
    display: flex;
    padding-top: 2.5rem;
  }
  .calculator .section-head {
    margin-bottom: 2.5rem;
  }
  .calculator .foot {
    padding-top: 2.5rem;
  }
  .gallery-text {
    max-width: 510px;
  }
  .interest-nav .section-head {
    margin-bottom: 3.125rem;
  }
  .interest-nav .item-list {
    margin: -0.625rem 0;
  }
  .interest-nav .item {
    margin: 0.625rem 0;
    text-align: center;
  }
  .interest-nav .item-inner {
    border: none;
    margin: 0;
  }
  .about .about-text .text {
    max-width: 510px;
  }
  .about.alt .about-text .text {
    max-width: 510px;
  }
  .tabs.tabs-why .tab-nav ul {
    flex-wrap: wrap;
  }
  .tabs.tabs-why .tab-nav ul li {
    flex: 0 0 100%;
  }
  .tabs.tabs-why .tab-nav ul li > a {
    font-size: 0.875rem;
    line-height: 1.3em;
  }
  .text-slider .item .img {
    margin-bottom: 2.5rem;
  }
  .footer .europe {
    position: relative;
    right: auto;
  }
  .footer .foot-item-info .contact-info {
    margin-bottom: 1.875rem;
  }
  form.form-nl.centered {
    max-width: 100%;
    margin-top: 2.5rem;
  }
  body.fix.mobile-nav-opened .header .btn-flip {
    opacity: 1;
    pointer-events: auto;
  }
}

/*========================= Viewport width 575px and lower =========================*/
@media screen and (max-width: 575px) {
  html {
    font-size: 13px;
  }
  h1, .h1 {
    font-size: 2.5rem;
    line-height: 1.16em;
  }
  h1.medium, .h1.medium {
    font-size: 2.1875rem;
    line-height: 1.16em;
  }
  h2, .h2 {
    font-size: 1.875rem;
    line-height: 1.2em;
  }
  h2.big, .h2.big {
    font-size: 2.1875rem;
    line-height: 1.2em;
  }
  .suphead {
    font-size: 1rem;
  }
  .content .btn, .content .btn-flip {
    width: 100%;
    max-width: 395px;
  }
  .content .btn:before, .content .btn:after, .content .btn-flip:before, .content .btn-flip:after {
    width: 100%;
    max-width: 395px;
  }
  .nav-btn {
    width: 2.5rem;
  }
  .btn-taste {
    display: none !important;
  }
  .mobile-nav .inner {
    padding-left: 0;
  }
  .block, .block.narrow {
    padding: 2.5rem 0;
  }
  .block.narrow-alpha {
    padding-top: 2.5rem;
  }
  .block.narrow-omega {
    padding-bottom: 2.5rem;
  }
  .header.alt .nav-btn {
    margin-left: 1.25rem;
  }
  .header.alt .nav-btn .txt {
    display: none;
  }
  .header-r .btn-flip {
    font-size: 13px;
    text-indent: 0;
    color: #F2A921;
    padding-left: 12px;
    position: relative;
  }
  .header-r .btn-flip:before, .header-r .btn-flip:after {
    content: none;
  }
  .header-r .btn-flip:before {
    content: '';
    width: 5px;
    height: 5px;
    padding: 0;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -2.5px;
    background: #F2A921;
  }
  .logo {
    max-width: 120px;
  }
  .post-detail h2 {
    font-size: 1.875rem;
  }
  .post-detail h3 {
    font-size: 1.5625rem;
  }
  .teaser-hp .teaser-img {
    height: auto;
  }
  .teaser-hp .teaser-content {
    padding: 150px 0;
  }
  .teaser-hp .desc {
    display: none;
  }
  .teaser-alt h1 {
    margin-bottom: 1.25rem;
  }
  .offer {
    margin-top: -8.125rem;
  }
  .offer .head .link-play {
    display: none;
  }
  .vision .section-head {
    margin-bottom: 1.25rem;
  }
  .vision .item {
    font-size: 1.25rem;
  }
  .vision .item:before {
    margin-left: -15px;
    width: 35%;
  }
  .vision .foot .btn, .vision .foot .btn-flip {
    width: 100%;
    max-width: 395px;
  }
  .vision .foot .btn:before, .vision .foot .btn:after, .vision .foot .btn-flip:before, .vision .foot .btn-flip:after {
    width: 100%;
    max-width: 395px;
  }
  .vision .logo-vision {
    display: none;
  }
  .about .btn-set {
    flex-wrap: wrap;
  }
  .about .btn-set .yt-link {
    order: 0;
    margin-bottom: 1.25rem;
  }
  .about .btn-set .btn, .about .btn-set .btn-flip {
    order: 1;
    flex: 0 0 100%;
    max-width: 395px;
  }
  .about .btn-set .btn:after, .about .btn-set .btn-flip:after {
    max-width: 395px;
    flex: 0 0 100%;
    background: #09304C;
  }
  .about .btn-set .btn:before, .about .btn-set .btn-flip:before {
    max-width: 395px;
    flex: 0 0 100%;
    background: #59B0A3;
  }
  .posts-overview .posts-main .item h3 {
    max-width: 100%;
  }
  .posts-overview .posts-list .foot .btn, .posts-overview .posts-list .foot .btn-flip {
    order: 1;
    flex: 0 0 100%;
    max-width: 395px;
  }
  .posts-overview .posts-list .foot .btn:after, .posts-overview .posts-list .foot .btn-flip:after {
    max-width: 395px;
    flex: 0 0 100%;
    background: #09304C;
  }
  .posts-overview .posts-list .foot .btn:before, .posts-overview .posts-list .foot .btn-flip:before {
    max-width: 395px;
    flex: 0 0 100%;
    background: #59B0A3;
  }
  .posts-big .item .text {
    padding: 1.875rem;
  }
  .tiles-photo .item .text .icon {
    width: 3.75rem;
    height: 3.75rem;
    flex: 0 0 3.75rem;
  }
  .tiles-photo .item .suphead {
    font-size: 1.25rem;
  }
  .tiles-photo .item h3 {
    font-size: 1.5625rem;
  }
  .persons .item .sign {
    max-width: 15rem;
    margin-bottom: 0.625rem;
    margin-right: 30px;
  }
  .persons .item .item-foot {
    padding: 0 1.875rem 1.875rem 1.875rem;
  }
  .persons .cite {
    display: none;
  }
  .persons .foot .btn, .persons .foot .btn-flip {
    order: 1;
    flex: 0 0 100%;
    max-width: 395px;
  }
  .persons .foot .btn:after, .persons .foot .btn-flip:after {
    max-width: 395px;
    flex: 0 0 100%;
    background: #09304C;
  }
  .persons .foot .btn:before, .persons .foot .btn-flip:before {
    max-width: 395px;
    flex: 0 0 100%;
    background: #59B0A3;
  }
  .video .play {
    width: 3.75rem;
    height: 3.75rem;
  }
  .video .play .icon {
    width: 3.75rem;
    height: 3.75rem;
  }
  .act-nav .item-inner {
    border-bottom: 2px solid #fff;
  }
  .roi .btn, .roi .btn-flip {
    order: 1;
    flex: 0 0 100%;
    max-width: 395px;
  }
  .roi .btn:after, .roi .btn-flip:after {
    max-width: 395px;
    flex: 0 0 100%;
  }
  .roi .btn:before, .roi .btn-flip:before {
    max-width: 395px;
    flex: 0 0 100%;
  }
  .sky-text .block:first-child {
    padding-top: 0;
  }
  .numbers .item-list {
    margin-top: 0;
    margin-bottom: -1.875rem;
  }
  .numbers .item {
    margin: 0 0 1.875rem 0;
  }
  .numbers .foot .btn, .numbers .foot .btn-flip {
    order: 1;
    flex: 0 0 100%;
    max-width: 395px;
  }
  .numbers .foot .btn:after, .numbers .foot .btn-flip:after {
    flex: 0 0 100%;
    max-width: 395px;
  }
  .numbers .foot .btn:before, .numbers .foot .btn-flip:before {
    flex: 0 0 100%;
    max-width: 395px;
  }
  .phases .section-head-l {
    text-align: center;
  }
  .phases .item {
    text-align: center;
    padding-top: 0;
    align-items: center;
    border: none;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .phases .item .img {
    margin-bottom: 1.25rem;
  }
  .phases .item .text {
    flex-grow: 0;
    max-width: 100%;
    margin: 0;
    padding: 0 15px;
  }
  .phases .item .text h3 {
    margin: 0;
  }
  .phases .foot {
    display: flex;
    justify-content: center;
  }
  .phases.phases-list .item {
    border: none;
  }
  .phase-slider {
    width: 100%;
    position: relative;
  }
  .phase-slider:after {
    content: none;
  }
  .phase-slider .glide__slide {
    display: flex;
    align-items: center;
  }
  .phase-slider .glide-nav .glide__arrow--right, .phase-slider .glide-nav .glide__arrow--left {
    top: 50%;
    width: 3.125rem;
    height: 3.125rem;
  }
  .phase-slider .glide-nav .glide__arrow--right .icon, .phase-slider .glide-nav .glide__arrow--left .icon {
    width: 1.5625rem;
    height: 1.5625rem;
    flex: 0 0 1.5625rem;
  }
  .investment .item-list {
    margin-top: 0;
  }
  .investment .item {
    padding: 0 15px;
  }
  .investment .item .item-inner {
    margin-bottom: 3.125rem;
    height: auto;
    padding: 3.75rem 1.25rem 2.5rem 1.25rem;
  }
  .investment .item .desc {
    padding-top: 1.25rem;
  }
  .investment.alt .item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .investment.alt .item:last-child .item-inner {
    margin-bottom: 0;
  }
  .investment.alt .item-inner {
    padding-top: 3.4375rem;
    height: 10rem;
    margin-bottom: 3.125rem;
  }
  .investment.alpha {
    padding-top: inherit;
  }
  .investment .item-inner {
    height: 23.75rem;
  }
  .investment-action .section-head {
    margin-bottom: 3.125rem;
  }
  .investment-action .investment-form {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 2.5rem;
  }
  .investment-action .investment-form .section-head {
    margin-bottom: 2.5rem;
  }
  .range-calculator-wrapper {
    padding: 0 30px;
  }
  #range-calculator .min, #range-calculator .max {
    width: 80px;
    white-space: normal;
    display: block;
  }
  .amounts .item {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .amounts .item .amount {
    font-size: 1.5625rem;
  }
  .amounts .item .amount.small {
    font-size: 1.5rem;
  }
  .amounts.alt {
    margin-bottom: 3.125rem;
  }
  .amounts.alt .item {
    border-left: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;
    margin: 0;
    padding: 1.25rem;
  }
  .amounts.alt .item:nth-child(1), .amounts.alt .item:nth-child(2) {
    border-top: none;
  }
  .amounts.alt .item:nth-child(1), .amounts.alt .item:nth-child(3) {
    border-left: none;
  }
  .stocks-program .tab-nav {
    display: flex;
    justify-content: center;
    display: block;
  }
  .stocks-program .tab-nav ul {
    margin: 0 -30px;
  }
  .stocks-program .tab-nav ul li {
    flex: 0 0 50%;
    padding: 0 5px;
  }
  .stocks-program .foot {
    display: flex;
    justify-content: center;
  }
  .expansion .section-head {
    margin-bottom: 2.5rem;
  }
  .history .section-head {
    margin-bottom: 2.5rem;
  }
  .tabs .tab-nav li {
    padding: 0 15px;
  }
  .tabs .tab-nav li a {
    width: 100%;
    padding: 0 0.625rem;
  }
  .tabs .tab-content .tab {
    padding-top: 3.125rem;
  }
  .gallery-text {
    padding: 2.5rem 0;
  }
  .history-slider-wrapper {
    padding: 0 2.5rem;
  }
  .milestones-slider-wrapper {
    padding: 0 2.5rem;
  }
  .milestones-slider:after {
    content: none;
  }
  .milestones-slider .glide-nav .glide__arrow--left {
    left: -15px;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    top: 80px;
    width: 3.125rem;
    height: 3.125rem;
  }
  .milestones-slider .glide-nav .glide__arrow--left .icon {
    width: 1.25rem;
    height: 1.25rem;
    flex: 0 0 1.25rem;
  }
  .milestones-slider .glide-nav .glide__arrow--right {
    right: -15px;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    top: 80px;
    width: 3.125rem;
    height: 3.125rem;
  }
  .milestones-slider .glide-nav .glide__arrow--right .icon {
    width: 1.25rem;
    height: 1.25rem;
    flex: 0 0 1.25rem;
  }
  .milestones-slider .vline {
    position: relative;
    left: 30px;
    height: 60px !important;
    margin-bottom: 20px;
  }
  .gff {
    width: 100%;
    max-width: 100%;
    position: relative;
    padding: 40px 15px 0 15px;
    display: flex;
    justify-content: flex-end;
  }
  .gff .iframe {
    max-width: 225px;
  }
  .post-info .cats {
    flex-wrap: wrap;
  }
  .countdown {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
  .countdown .item-list {
    margin: 0 -5px;
  }
  .countdown .item {
    padding: 0 5px;
  }
  .countdown .item .val {
    font-size: 1.5625rem;
    height: 3.75rem;
  }
  .schedule:after {
    height: 4.375rem;
  }
  .boxes-img .item .btn, .boxes-img .item .btn-flip {
    width: 100%;
    max-width: 395px;
  }
  .boxes-img .item .btn:before, .boxes-img .item .btn:after, .boxes-img .item .btn-flip:before, .boxes-img .item .btn-flip:after {
    width: 100%;
    max-width: 395px;
  }
  .ordered-list .item {
    margin-bottom: 1.875rem;
  }
  .ordered-list .item:before {
    content: none;
  }
  .ordered-list h3:before {
    font-weight: 600;
    content: counter(counter) ". ";
  }
  .trust .item {
    display: block;
  }
  .trust .item .img {
    margin-bottom: 1.875rem;
  }
  .trust .item .img .frame {
    max-width: 300px;
  }
  .trust .item .text {
    display: block;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .icon-list .item {
    margin-bottom: 3.125rem;
  }
  .tabs-links .tab-nav ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .faq-detail .box {
    padding: 1.5625rem;
    margin-bottom: 2.5rem;
  }
  .people {
    margin-bottom: 2.5rem;
  }
  .people .item-list {
    margin-bottom: -2.5rem;
  }
  .people .item {
    margin-bottom: 2.5rem;
  }
  .people .item .img {
    margin-bottom: 3.75rem;
  }
  .interest-nav {
    margin-bottom: 2.5rem;
  }
  .company-structure .box {
    padding: 1.875rem;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .platforms .item-list {
    margin-left: -10px;
    margin-right: -10px;
  }
  .platforms .item {
    padding: 0 10px;
  }
  .box-contact {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -2.5rem;
  }
  .box-contact h3 {
    text-align: center;
  }
  .investment-intro .leaf-1, .investment-intro .leaf-2 {
    display: none;
  }
  .footer .f_1 {
    padding-bottom: 1.25rem;
  }
  .footer .logo-foot {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5rem;
  }
  .footer .europe {
    width: 150%;
    margin-right: -50%;
    margin-left: 15px;
  }
  .modal .modal-box.modal-exit .modal-content {
    padding: 20px 15px;
  }
  form.form-contact {
    margin-bottom: 1.875rem;
  }
  form.form-contact .btn-set {
    text-align: center;
    padding-top: 0;
  }
  body.mobile-nav-opened .header-r .btn-flip {
    color: #09304C;
  }
  body.mobile-nav-opened .header-r .btn-flip:before {
    background: #09304C;
  }
}
